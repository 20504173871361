const CREDIT_CARD_TYPE = Object.freeze({
  VISA: 'Visa',
  VISA_ELECTRON: 'Visa Electron',
  MASTERCARD: 'Mastercard',
  MAESTRO: 'Maestro',
  AMEX: 'Amex',
  AMERICAN_EXPRESS: 'American Express',
  DISCOVER: 'Discover',
});

export default CREDIT_CARD_TYPE;
