import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function cancelGmCampaign(companyId: string, campaignId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.cancelGmCampaign(companyId, campaignId).then((res) => res.data);
}

export default function useCancelCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; campaignId: string }) => cancelGmCampaign(values.companyId, values.campaignId, axiosInstance),
    props
  );
}
