import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function showTemplate(companyId: string, templateId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showTemplate(companyId, templateId).then((res) => res.data);
}

export default function useShowTemplate({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useShowTemplate', companyId, params.templateId],
    () => showTemplate(companyId, params.templateId, axiosInstance),
    props
  );
}
