import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormGMApi } from '@app/api';
import type { ContactFormCreateRequestInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createContactForm(
  companyId: string,
  contactFormCreateInput: ContactFormCreateRequestInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new ContactFormGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createContactFormForCompany(companyId, contactFormCreateInput).then((res) => res.data);
}

export default function useCreateContactForm(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; contactFormCreateInput: ContactFormCreateRequestInput }) =>
      createContactForm(values.companyId, values.contactFormCreateInput, axiosInstance),
    props
  );
}
