import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CompaniesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function getCompany(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CompaniesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getGeneralManagerCompany(companyId).then((res) => res.data);
}

export default function useGetCompany(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(['getGeneralManagerCompany'], () => getCompany(companyId, axiosInstance), props);
}
