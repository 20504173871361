import { ANALYTICS_CONTENT } from '@app/constants';

function percent(
  value: number,
  options: { isCompact?: boolean; precision?: number; locale?: string } = { isCompact: false, precision: 2, locale: 'tr' }
) {
  return Intl.NumberFormat(options.locale, {
    notation: options.isCompact ? 'compact' : 'standard',
    compactDisplay: 'short',
    style: 'percent',
    maximumFractionDigits: options.precision,
  }).format(value as number);
}

function number(
  value: number,
  options: { isCompact?: boolean; precision?: number; locale?: string } = { isCompact: false, precision: 2, locale: 'tr' }
) {
  return new Intl.NumberFormat(options.locale, {
    notation: options.isCompact ? 'compact' : 'standard',
    maximumFractionDigits: options.precision,
  }).format(value as number);
}

function formatedNumberToNumber(value: string) {
  return value.replaceAll(',', '').replaceAll('.', '');
}

function money(
  value: number | string,
  currency: string,
  options: { isCompact?: boolean; precision?: number; locale?: string } = { isCompact: false, precision: 2, locale: 'en' }
) {
  return new Intl.NumberFormat(options.locale, {
    notation: options.isCompact ? 'compact' : 'standard',
    compactDisplay: 'short',
    style: 'currency',
    currency: currency,
    maximumFractionDigits: options.precision,
  }).format(value as number);
}

function data(value: string | number | undefined, currency = 'TRY', key?: string) {
  if (Number.isNaN(value)) {
    return '-';
  }

  const handledCount = Number(value);

  if (key === ANALYTICS_CONTENT.COST.KEY || key === ANALYTICS_CONTENT.CPC.KEY) {
    return money(handledCount, currency, { isCompact: handledCount > 1000, precision: 2 });
  }

  if (handledCount > 1000) {
    return number(handledCount, { isCompact: true, precision: 0 });
  }

  if (key === ANALYTICS_CONTENT.CPC.KEY) {
    return Number(handledCount.toFixed(3));
  }

  return Number(handledCount.toFixed(2));
}

export default { percent, number, money, formatedNumberToNumber, data };
