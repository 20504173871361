import { useRouter } from 'next/router';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { useAuth, useToast } from '@app/hooks';
import { HEADER_KEYS } from '@app/constants';

type AxiosHookReturnTypes = {
  api: AxiosInstance;
};

type ParamTypes = {
  automaticToast?: boolean;
  authorization?: string;
  axiosProps?: AxiosRequestConfig;
};

export default function useAxios(
  params: ParamTypes = {
    automaticToast: true,
  }
): AxiosHookReturnTypes {
  const router = useRouter();
  const { token, refreshToken } = useAuth();
  const toast = useToast();
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    headers: {
      [HEADER_KEYS.CLIENT_OS]: 'gm-web',
      [HEADER_KEYS.CLIENT_OS_VERSION]: '1.0.0',
      [HEADER_KEYS.CLIENT_APP_VERSION]: '1.0.0',
    },
    ...params.axiosProps,
  });

  if (params?.authorization) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${params.authorization}`;
  } else {
    if (token !== null) {
      instance.defaults.headers.common.Authorization = `${token?.token_type} ${token?.access_token}`;
    } else {
      delete instance.defaults.headers.common.Authorization;
    }
  }

  instance.interceptors.response.use(
    function (response) {
      return response;
    },
    async function (error) {
      const status = error.response.status;

      if (status === 401) {
        refreshToken();
      }

      if (status === 403) {
        router.push('/settings');
      }

      if (params.automaticToast && status === 422) {
        if (Object.prototype.hasOwnProperty.call(error.response, 'data')) {
          if (Object.prototype.hasOwnProperty.call(error.response.data, 'errors')) {
            error.response.data.errors.map((message: string) => toast.error(message));
          }
        }
      }

      if (status === 500) {
        console.warn(status);
      }

      return Promise.reject(error);
    }
  );

  return { api: instance };
}
