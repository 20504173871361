import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-input-range/lib/css/index.css';
import '@app/styles/globals.scss';

import { useEffect } from 'react';
import App from 'next/app';

import { hotjar } from 'react-hotjar';

import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { Waiter } from 'react-wait';

import { AuthProvider } from '@app/hooks/useAuth';
import { LocaleProvider } from '@app/hooks/useLocale';
import { CheckCompanyInformation } from '@app/components';
import { COOKIE_KEYS } from '@app/constants';
import CableProvider from '@app/hooks/useCableProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

function MyApp({ Component, pageProps, locale }: any) {
  useEffect(() => {
    hotjar.initialize(Number(process.env.NEXT_PUBLIC_HOTJAR_HJID), Number(process.env.NEXT_PUBLIC_HOTJAR_HJSV));
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Waiter>
        <AuthProvider>
          <CableProvider>
            <LocaleProvider initialLocale={locale}>
              <ToastContainer
                autoClose={false}
                position="bottom-center"
                toastClassName="shadow-none bg-transparent p-0 min-h-full"
                bodyClassName="p-0 !m-0"
                className="max-w-100 w-full p-0"
              />
              <CheckCompanyInformation>
                <Component {...pageProps} />
              </CheckCompanyInformation>
            </LocaleProvider>
          </CableProvider>
        </AuthProvider>
      </Waiter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = async (appContext: any) => {
  const appProps = await App.getInitialProps(appContext);
  const req = appContext.ctx.req;

  if (req?.cookies) {
    if (Object.prototype.hasOwnProperty.call(req?.cookies, COOKIE_KEYS.LOCALE)) {
      const locale = req.cookies[COOKIE_KEYS.LOCALE];

      return { ...appProps, locale };
    }
  }

  return { ...appProps };
};

export default MyApp;
