import parsePhoneNumber from 'libphonenumber-js';

function format(phoneNumberString: string): string {
  const phoneNumberText = `+${phoneNumberString.replaceAll('+', '')}`;
  const phoneNumber = parsePhoneNumber(phoneNumberText);

  if (phoneNumber?.isValid()) {
    const formatedPhoneNumber = phoneNumber.formatInternational();

    return formatedPhoneNumber;
  }

  return phoneNumberText;
}

function isValid(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (phoneNumber?.isValid()) {
    return true;
  }

  return false;
}

function uri(phoneNumberString: string) {
  const phoneNumber = parsePhoneNumber(phoneNumberString);

  if (phoneNumber?.isValid()) {
    return phoneNumber.getURI();
  }

  return null;
}
export default {
  format,
  isValid,
  uri,
};
