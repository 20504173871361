import React, { useState } from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { Box } from '@app/components';
import { ANALYTICS_CONTENT, ANALYTICS_FILTERS } from '@app/constants';

import LineChart from './LineChart';
import Legend from './Legend';

const DEFAULT_SELECTED_TYPES = [ANALYTICS_CONTENT.IMPRESSION, ANALYTICS_CONTENT.CLICK].map((content) => content.KEY);

type AnalyticsPropTypes = {
  data: {
    labels: string[];
    data: {
      impression: number[];
      click: number[];
      cost: string[];
      cpc: string[];
    };
  };
  onFilterChange: (key: string) => void;
  selectedFilter: string;
  totalInsights: any;
};

const Analytics: React.FC<AnalyticsPropTypes> = ({ data, totalInsights, selectedFilter, onFilterChange }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.index',
  };
  const { t } = useLocale();
  const [selectedTypes, setSelectedTypes] = useState<Array<string>>(DEFAULT_SELECTED_TYPES);

  function handleSelected(type: string) {
    if (selectedTypes.includes(type) && selectedTypes.length === 1) {
      return;
    }

    if (selectedTypes.includes(type)) {
      setSelectedTypes((prev) => prev.filter((item) => item !== type));
      return;
    }

    setSelectedTypes((prev) => [...prev, type]);
  }
  return (
    <Box className="overflow-hidden">
      <Legend selected={selectedTypes} onClick={handleSelected} totalInsights={totalInsights} />
      <LineChart selected={selectedTypes} data={data.data} labels={data.labels} />
      <div className="w-full mt-5 flex items-center justify-center">
        <div className="inline-flex flex-row border border-gray-300 rounded-2 overflow-hidden">
          {Object.values(ANALYTICS_FILTERS).map((filter, index) => (
            <button
              key={filter}
              onClick={() => onFilterChange(filter)}
              className={cn('text-gray-500 w-22 h-8 text-3 font-semibold text-center border-gray-300 transition-colors', {
                'border-l': index !== 0,
                'bg-white': filter !== selectedFilter,
                'bg-gray-300': filter === selectedFilter,
              })}
            >
              {t(`labels.${filter}`, SCOPE_OPTIONS)}
            </button>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default Analytics;
