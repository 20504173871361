import React, { useState, forwardRef } from 'react';

import cn from 'classnames';

type SelectboxPropTypes = {
  defaultValue?: string | number;
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  onBlur?: React.FocusEventHandler<HTMLSelectElement>;
  name?: string;
  options: { id: number | string; label: string; value: string }[];
};

export const Selectbox: React.ForwardRefRenderFunction<HTMLSelectElement, React.PropsWithChildren<SelectboxPropTypes>> = (
  {
    label,
    id,
    error,
    className,
    onChange = () => {
      return;
    },
    onBlur = () => {
      return;
    },
    name,
    options,
    defaultValue,
    ...props
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLSelectElement>) {
    setIsFocused(false);
    onBlur(event);
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between">
        <label htmlFor={id} className="block text-sm font-semibold text-gray-900">
          {label}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <select
        ref={ref}
        onFocus={onFocus}
        name={name}
        onChange={onChange}
        onBlur={handleOnBlur}
        className={cn('mt-1.5 py-3.5 px-4 border rounded-2.5 font-normal text-sm text-gray-900 placeholder-gray-200 outline-none', {
          'bg-white border-gray-400': !error,
          'bg-white border-red-400': error,
          'border-gray-600': !error && isFocused,
        })}
        id={id}
        {...props}
      >
        {options &&
          options.map((option) => (
            <option
              key={option.id}
              value={option.value}
              selected={defaultValue?.toString().toLocaleLowerCase() === option.value.toLocaleLowerCase()}
            >
              {option.label}
            </option>
          ))}
      </select>
    </div>
  );
};

export default forwardRef(Selectbox);
