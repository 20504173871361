import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10 20C4.47667 20 0 15.5233 0 10C0 4.47667 4.47667 0 10 0C15.5233 0 20 4.47667 20 10C20 15.5233 15.5233 20 10 20Z"
      fill="currentColor"
    />
    <path d="M7.778 6.667 13.333 10l-5.555 3.333" stroke="white" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgComponent;
