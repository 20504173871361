/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface CampaignUpdatedLogOldAttributes
 */
export interface CampaignUpdatedLogOldAttributes {
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    name?: string;
    /**
     * 
     * @type {object}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    targeting?: object;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    tags?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    stop_time?: string;
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    geo_location_type?: CampaignUpdatedLogOldAttributesGeoLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    status?: CampaignUpdatedLogOldAttributesStatusEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof CampaignUpdatedLogOldAttributes
     */
    permissions?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum CampaignUpdatedLogOldAttributesGeoLocationTypeEnum {
    Free = 'free',
    Range = 'range',
    City = 'city'
}
/**
    * @export
    * @enum {string}
    */
export enum CampaignUpdatedLogOldAttributesStatusEnum {
    Active = 'active',
    Finished = 'finished',
    Cancelled = 'cancelled'
}



