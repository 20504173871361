import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M6.444 4.222A2.222 2.222 0 018.667 2h11.11A2.222 2.222 0 0122 4.222v11.111a2.222 2.222 0 01-2.222 2.223H8.667a2.222 2.222 0 01-2.223-2.223V4.223z"
    ></path>
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
      d="M17.556 17.556v2.222A2.223 2.223 0 0115.333 22H4.223A2.222 2.222 0 012 19.778V8.667a2.222 2.222 0 012.222-2.222h2.222"
    ></path>
  </svg>
);

export default SvgComponent;
