import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Box } from '@app/components';
import { api, useLocale, useDebounce } from '@app/hooks';
import { array } from '@app/lib';
import { DLA_GROUP_TYPES } from '@app/constants';

const DEBOUNCE_MS = 200;

type GroupSelectPropTypes = {
  defaultValue?: string[];
  locationType?: string;
  isRequired?: boolean;
};

const GroupSelect: React.FC<GroupSelectPropTypes> = ({ defaultValue, locationType, isRequired }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.GroupSelect',
  };
  const { t } = useLocale();
  const [query, setQuery] = useState<string>('');
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();
  const { debouncedValue: debouncedQuery, isLoading: isDebounceLoading } = useDebounce(query, DEBOUNCE_MS);
  const {
    data: groups,
    isLoading: isGroupsLoading,
    refetch,
  } = api.useGetCompanyGroups({
    params: {
      page: 1,
      perPage: 9999,
      query: {
        name_i_cont_any: query,
        group_type_eq: locationType?.toLocaleLowerCase() ?? DLA_GROUP_TYPES.BRANCHSIGHT,
      },
    },
  });

  useEffect(() => {
    refetch();
  }, [debouncedQuery, refetch]);

  useEffect(() => {
    setValue('groups', dataToSelectInput(defaultValue));

    if (defaultValue?.length === 0 && groups?.groups.length > 0) {
      setValue('groups', [
        {
          value: String(array.first(groups?.groups)?.id),
          label: array.first(groups?.groups)?.name,
        },
      ]);
    }
  }, [groups]);

  function dataToSelectInput(defaultValue: string[] | undefined) {
    if (groups?.groups) {
      return groups?.groups
        .map((group: { name: string; id: string }) => ({
          label: group.name,
          value: group.id,
        }))
        .filter((group: { value: string }) => defaultValue?.find((id: string) => id === group.value));
    }
  }
  return (
    <Box>
      <Form.Select
        name="groups"
        label={t('form.groups.label', SCOPE_OPTIONS)}
        rules={{
          required: { value: isRequired ?? true, message: t('form.groups.errors.required', SCOPE_OPTIONS) },
        }}
        control={control}
        isMulti={true}
        onInputChange={setQuery}
        isLoading={isGroupsLoading || isDebounceLoading}
        automaticHandleValue={false}
        options={groups?.groups?.map((group: { id: string; name: string }) => ({ value: group.id, label: group.name }))}
        error={errors.groups}
      />
    </Box>
  );
};

export default GroupSelect;
