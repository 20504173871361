import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" fill="currentColor" viewBox="0 0 150 150" {...props}>
    <path fill="currentColor" d="M60.938 70.313v37.499l32.812-18.75-32.813-18.75z"></path>
    <path
      fill="currentColor"
      d="M121.875 131.25h-93.75a9.381 9.381 0 01-9.375-9.375V56.25a9.385 9.385 0 019.375-9.375h93.75a9.382 9.382 0 019.375 9.375v65.625a9.379 9.379 0 01-2.749 6.626 9.379 9.379 0 01-6.626 2.749zm-93.75-75v65.625h93.75V56.25h-93.75zm0-28.125h93.75V37.5h-93.75v-9.375zM37.5 9.375h75v9.375h-75V9.375z"
    ></path>
  </svg>
);

export default SvgComponent;
