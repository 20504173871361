import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CompaniesGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function listGeneralManagerCompanies(axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new CompaniesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGeneralManagerCompanies().then((res) => res.data);
}

export default function useListGeneralManagerCompanies(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(['useListGeneralManagerCompanies', props?.id ?? ''], () => listGeneralManagerCompanies(axiosInstance), props);
}
