function unique(array) {
  return Array.from(new Set(array.map(JSON.stringify))).map(JSON.parse);
}

function prev(array = [], index = 0) {
  if (!Array.isArray(array)) {
    return null;
  }

  if (array.length === 0) {
    return null;
  }

  if (index === 0) {
    return null;
  }

  return array[index - 1];
}

function next(array = [], index = 0) {
  if (!Array.isArray(array)) {
    return null;
  }

  if (array.length === 0) {
    return null;
  }

  if (index + 1 === array.length) {
    return null;
  }

  return array[index + 1];
}

function first(array) {
  if (Array.isArray(array) === false) {
    return null;
  }

  if (array.length === 0) {
    return null;
  }

  return array[0];
}

function second(array) {
  if (Array.isArray(array) === false) {
    return null;
  }

  if (array.length < 2) {
    return null;
  }

  return array[1];
}

function last(array) {
  if (Array.isArray(array) === false) {
    return null;
  }

  if (array.length === 0) {
    return null;
  }

  return array[array.length - 1];
}

function isEmpty(array) {
  if (Array.isArray(array) === false) {
    return true;
  }

  if (array.length === 0) {
    return true;
  }

  return false;
}

function isLastItem(array, item) {
  const lastItem = last(array);

  if (!item) {
    return false;
  }

  if (lastItem === null) {
    return false;
  }

  if (JSON.stringify(lastItem) !== JSON.stringify(item)) {
    return false;
  }

  return true;
}

export default {
  unique,
  prev,
  next,
  first,
  second,
  last,
  isEmpty,
  isLastItem,
};
