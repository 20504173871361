import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0"
    y="0"
    enableBackground="new 0 0 122.88 122.88"
    version="1.1"
    viewBox="0 0 122.88 122.88"
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <path d="M68.23 13.49c10.44 1.49 19.79 6.36 26.91 13.48 7.29 7.29 12.23 16.93 13.58 27.68h14.17v13.58H108.5a47.51 47.51 0 01-13.36 26.13c-7.11 7.11-16.47 11.99-26.91 13.48v15.04H54.65v-15.04c-10.44-1.49-19.79-6.36-26.9-13.48-6.94-6.94-11.74-16-13.36-26.13H0V54.65h14.16c1.35-10.75 6.29-20.39 13.58-27.68 7.11-7.11 16.46-11.99 26.9-13.48V0h13.58v13.49h.01zm-6.79 21.92c13.95 0 25.25 11.31 25.25 25.25 0 13.95-11.31 25.25-25.25 25.25-13.95 0-25.25-11.31-25.25-25.25s11.3-25.25 25.25-25.25zM89 33.11c-7.05-7.05-16.8-11.42-27.56-11.42-10.76 0-20.51 4.36-27.56 11.42-7.05 7.05-11.42 16.8-11.42 27.56 0 10.76 4.36 20.51 11.42 27.56 7.05 7.05 16.8 11.42 27.56 11.42 10.76 0 20.51-4.36 27.56-11.42 7.05-7.05 11.42-16.8 11.42-27.56-.01-10.77-4.37-20.51-11.42-27.56z"></path>
  </svg>
);

export default SvgComponent;
