import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAuth, useAxios } from '@app/hooks';
import { WalletCompanyV2Api } from 'api/api';

export function getSavedCards(branchId: string, axiosInstance: AxiosInstance) {
  const api = new WalletCompanyV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getCompanySavedCards(branchId);
}

export default function useGetSavedCards() {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  return useQuery(['savedCards', companyId], () => getSavedCards(companyId, axiosInstance));
}
