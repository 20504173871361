const FACEBOOK_POSITIONS = Object.freeze({
  FEED: 'feed',
  RIGHT_HAND_COLUMN: 'right_hand_column',
  INSTANT_ARTICLE: 'instant_article',
  MARKETPLACE: 'marketplace',
  VIDEO_FEEDS: 'video_feeds',
  STORY: 'story',
  SEARCH: 'search',
  INSTREAM_VIDEO: 'instream_video',
});

export default FACEBOOK_POSITIONS;
