import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.4619 9.00013C22.4619 8.38815 22.2181 7.80095 21.785 7.36804C21.3531 6.93514 20.7661 6.69239 20.1537 6.69239H11.8458C11.2337 6.69239 10.6464 6.93512 10.2145 7.36804C9.78143 7.80095 9.53768 8.3882 9.53768 9.00013V9.92323C9.53768 10.5352 9.78143 11.1224 10.2145 11.5553C10.6464 11.9882 11.2336 12.231 11.8458 12.231H20.1537C20.7661 12.231 21.3531 11.9882 21.785 11.5553C22.2181 11.1224 22.4619 10.5352 22.4619 9.92323V9.00013ZM20.6148 9.00013V9.92323C20.6148 10.046 20.5666 10.1633 20.4801 10.25C20.3933 10.3359 20.2765 10.3848 20.1537 10.3848H11.8458C11.723 10.3848 11.6062 10.3359 11.5197 10.25C11.4329 10.1633 11.3847 10.046 11.3847 9.92323V9.00013C11.3847 8.87733 11.4329 8.76008 11.5197 8.67334C11.6062 8.58742 11.723 8.53858 11.8458 8.53858H20.1537C20.2765 8.53858 20.3933 8.58742 20.4801 8.67334C20.5666 8.76008 20.6148 8.87733 20.6148 9.00013Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1535 7.61549V5.30774C14.1535 5.18494 14.2023 5.0677 14.2882 4.98095C14.375 4.89503 14.4922 4.84619 14.615 4.84619H17.3843C17.5071 4.84619 17.6244 4.89503 17.7111 4.98095C17.797 5.0677 17.8459 5.18494 17.8459 5.30774V7.61549C17.8459 8.12505 18.2594 8.53858 18.769 8.53858C19.2785 8.53858 19.6921 8.12505 19.6921 7.61549V5.30774C19.6921 4.69576 19.4493 4.10856 19.0164 3.67565C18.5835 3.24275 17.9963 3 17.3843 3H14.615C14.003 3 13.4158 3.24273 12.9829 3.67565C12.55 4.10856 12.3073 4.69581 12.3073 5.30774V7.61549C12.3073 8.12505 12.7208 8.53858 13.2304 8.53858C13.7399 8.53858 14.1535 8.12505 14.1535 7.61549Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.1837 10.3837L25.2307 10.3845H25.2323C25.7419 10.3845 26.1542 10.7973 26.1542 11.3058V26.0775C26.1542 26.322 26.0572 26.5576 25.8833 26.73C25.7104 26.9037 25.4761 27.0006 25.2307 27.0006H6.76929C6.52389 27.0006 6.28961 26.9037 6.11674 26.73C5.94283 26.5576 5.84579 26.322 5.84579 26.0775V11.3079C5.84579 11.0633 5.94283 10.8278 6.11674 10.6554C6.28961 10.4817 6.5239 10.3848 6.76929 10.3848C6.76929 10.3848 6.75734 9.24023 6.76929 8.53862C6.03493 8.53862 5.33026 8.83038 4.81122 9.35005C4.29156 9.86888 4 10.5732 4 11.3079V26.0775C4 26.8123 4.29156 27.5165 4.81122 28.0353C5.33025 28.555 6.03493 28.8468 6.76929 28.8468H25.2307C25.9651 28.8468 26.6697 28.555 27.1888 28.0353C27.7084 27.5165 28 26.8122 28 26.0775V11.3058C28 9.77715 26.761 8.53836 25.2332 8.53836C24.7723 8.53836 24.6581 8.79963 24.6946 9.1254C24.4611 8.87711 24.3078 9.15136 24.3078 9.46146C24.3078 9.95536 24.6964 10.3588 25.1837 10.3837Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53799 16.8465H22.4614C22.9709 16.8465 23.3845 16.4329 23.3845 15.9234C23.3845 15.4138 22.9709 15.0003 22.4614 15.0003H9.53799C9.02843 15.0003 8.6149 15.4138 8.6149 15.9234C8.6149 16.4329 9.02843 16.8465 9.53799 16.8465Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53799 20.5388H22.4614C22.9709 20.5388 23.3845 20.1253 23.3845 19.6158C23.3845 19.1062 22.9709 18.6927 22.4614 18.6927H9.53799C9.02843 18.6927 8.6149 19.1062 8.6149 19.6158C8.6149 20.1253 9.02843 20.5388 9.53799 20.5388Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.53799 24.2312H22.4614C22.9709 24.2312 23.3845 23.8177 23.3845 23.3081C23.3845 22.7986 22.9709 22.385 22.4614 22.385H9.53799C9.02843 22.385 8.6149 22.7986 8.6149 23.3081C8.6149 23.8177 9.02843 24.2312 9.53799 24.2312Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.6918 9.46168C7.6918 9.97145 7.27847 10.3848 6.7687 10.3848C6.25894 10.3848 5.84561 9.97145 5.84561 9.46168C5.84561 8.95191 6.25894 8.53858 6.7687 8.53858C7.27847 8.53858 7.6918 8.95191 7.6918 9.46168Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1537 9.46168C26.1537 9.97145 25.7404 10.3848 25.2306 10.3848C24.7209 10.3848 24.3075 9.97145 24.3075 9.46168C24.3075 8.95191 24.7209 8.53858 25.2306 8.53858C25.7404 8.53858 26.1537 8.95191 26.1537 9.46168Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;

<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"></svg>;
