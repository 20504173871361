import type { CurrentFacebookPageResponse, Template } from '@app/api';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

function accountInfo(data: CurrentFacebookPageResponse) {
  return {
    instagramUsername: data?.instagram_account?.username,
    instagramProfile: data?.instagram_account?.profile_pic,
    facebookUsername: data?.username,
    facebookName: data?.name,
    facebookProfile: data?.picture?.url as string,
  };
}

function campaignPreviews(template: Template) {
  const foundedStory = template?.attachments?.find((item) => item.placement_type === IMAGE_PLACEMENT_TYPE.STORY);
  const foundedFeed = template?.attachments?.find((item) => item.placement_type === IMAGE_PLACEMENT_TYPE.FEED);

  return {
    message: template.creative.message,
    headline: template.creative.headline,
    description: template?.creative?.description,
    storyImage: foundedStory,
    feedImage: foundedFeed,
  };
}

export default { accountInfo, campaignPreviews };
