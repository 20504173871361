import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import { GOOGLE_PLACEMENT_TYPE, GOOGLE_TEXT_TYPE } from '@app/constants';
import { useLocale } from '@app/hooks';
import { url as urlLib } from '@app/lib';

import ImageUpload from './ImageUpload';
import Text from './Text';

const TemplateForm: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.GoogleForm.index',
  };
  const { t } = useLocale();
  const {
    watch,
    register,
    formState: { errors },
    getValues,
  } = useFormContext();
  const platformsWatch = watch('platforms');

  const headline = getValues('creative')?.google_headline;
  const longHeadline = getValues('creative')?.long_headline;
  const description = getValues('creative')?.google_description;
  const youtubeUrl = getValues('creative')?.youtube_video_urls;

  const squareImage = getValues('attachments')?.filter(
    (item: { placement_type: string }) => item.placement_type === GOOGLE_PLACEMENT_TYPE.SQUARE_MARKETING_IMAGE
  );
  const marketingImage = getValues('attachments')?.filter(
    (item: { placement_type: string }) => item.placement_type === GOOGLE_PLACEMENT_TYPE.MARKETING_IMAGE
  );
  const logoImage = getValues('attachments')?.filter(
    (item: { placement_type: string }) => item.placement_type === GOOGLE_PLACEMENT_TYPE.LOGO
  );
  const portraitImage = getValues('attachments')?.filter(
    (item: { placement_type: string }) => item.placement_type === GOOGLE_PLACEMENT_TYPE.PORTRAIT_MARKETING_IMAGE
  );

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          className="mb-4"
        />
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <ImageUpload
            key={index}
            size={{ width: 1200, height: 1200 }}
            index={index}
            type={GOOGLE_PLACEMENT_TYPE.SQUARE_MARKETING_IMAGE}
            platforms={platformsWatch}
            defaultValue={squareImage && squareImage[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <ImageUpload
            key={index}
            size={{ width: 1200, height: 628 }}
            index={index}
            type={GOOGLE_PLACEMENT_TYPE.MARKETING_IMAGE}
            platforms={platformsWatch}
            defaultValue={marketingImage && marketingImage[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <ImageUpload
            key={index}
            size={{ width: 960, height: 1200 }}
            index={index}
            type={GOOGLE_PLACEMENT_TYPE.PORTRAIT_MARKETING_IMAGE}
            platforms={platformsWatch}
            defaultValue={portraitImage && portraitImage[index]}
          />
        ))}
      </Box>
      <Box>
        {Array.from({ length: 5 }).map((_, index) => (
          <Form.Input
            key={index}
            type="text"
            id={`creative.youtube_video_urls_${index}`}
            label={index === 0 ? t('form.youtube_video_urls.label', SCOPE_OPTIONS) : ''}
            className="mb-4.5"
            placeholder={t('form.youtube_video_urls.placeholder', SCOPE_OPTIONS)}
            {...register(`creative.youtube_video_urls_${index}`, {
              pattern: {
                value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
                message: t('form.youtube_video_urls.errors.pattern', SCOPE_OPTIONS),
              },
              validate: (url) => {
                if ((url && urlLib.isValid(url)) || !url) {
                  return true;
                }

                return t('form.youtube_video_urls.errors.validate', SCOPE_OPTIONS);
              },
            })}
            error={errors.creative?.[`youtube_video_urls_${index}`]}
            defaultValue={youtubeUrl && youtubeUrl[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <ImageUpload
            key={index}
            size={{ width: 1200, height: 1200 }}
            index={index}
            type={GOOGLE_PLACEMENT_TYPE.LOGO}
            platforms={platformsWatch}
            defaultValue={logoImage && logoImage[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <Text
            key={index}
            type={GOOGLE_TEXT_TYPE.HEADLINE}
            rows={1}
            size={index === 0 ? 15 : 30}
            index={index}
            defaultValue={headline && headline[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <Text
            key={index}
            type={GOOGLE_TEXT_TYPE.LONG_HEADLINE}
            rows={2}
            size={90}
            index={index}
            defaultValue={longHeadline && longHeadline[index]}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <Text
            key={index}
            type={GOOGLE_TEXT_TYPE.DESCRIPTION}
            rows={2}
            size={index === 0 ? 60 : 90}
            index={index}
            defaultValue={description && description[index]}
          />
        ))}
      </Box>
    </form>
  );
};

export default TemplateForm;
