import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsGMApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function deleteGmIntegration(companyId: string, integrationId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new IntegrationsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteGmIntegration(companyId, integrationId).then((res) => res.data);
}

export default function useDeleteGmIntegration(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useMutation((values: { integrationId: string }) => deleteGmIntegration(companyId, values.integrationId, axiosInstance), props);
}
