import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width={147} height={40} viewBox="0 0 147 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.375 0a7.375 7.375 0 0 1 7.375 7.375c0 .245-.025.536-.076.874-.064.426-.152.687-.578 1.089-.284.267-.65.405-1.099.412H12.48l-.123.001a7.48 7.48 0 0 0 0 14.959H15.22l.072.002a2.634 2.634 0 0 1 0 5.266H7.5l-.07.002a2.5 2.5 0 0 0 0 4.998h7.917a7.634 7.634 0 0 0 0-15.266l-.127-.001h-2.74l-.069-.001a2.48 2.48 0 0 1 0-4.959l.07-.001h2.894C22.348 14.75 28 20.402 28 27.375S22.348 40 15.375 40H5a5 5 0 0 1-5-5V5a5 5 0 0 1 5-5h10.375Z"
      fill="#fff"
    />
  </svg>
);

export default SvgComponent;
