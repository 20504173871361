/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GmUserPasswordErrorResponse } from '../model';
// @ts-ignore
import { GmUserPasswordSuccessResponse } from '../model';
// @ts-ignore
import { GmUserResetPasswordInput } from '../model';
// @ts-ignore
import { GmUserUpdatePasswordInput } from '../model';
/**
 * PasswordGMApi - axios parameter creator
 * @export
 */
export const PasswordGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send reset password instructions to general manager user
         * @summary Send an email about resetting password
         * @param {GmUserResetPasswordInput} gmUserResetPasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserResetPassword: async (gmUserResetPasswordInput: GmUserResetPasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gmUserResetPasswordInput' is not null or undefined
            assertParamExists('generalManagerUserResetPassword', 'gmUserResetPasswordInput', gmUserResetPasswordInput)
            const localVarPath = `/gm_user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmUserResetPasswordInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update password of general manager user
         * @summary Update password
         * @param {GmUserUpdatePasswordInput} gmUserUpdatePasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserUpdatePassword: async (gmUserUpdatePasswordInput: GmUserUpdatePasswordInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gmUserUpdatePasswordInput' is not null or undefined
            assertParamExists('generalManagerUserUpdatePassword', 'gmUserUpdatePasswordInput', gmUserUpdatePasswordInput)
            const localVarPath = `/gm_user/password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmUserUpdatePasswordInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordGMApi - functional programming interface
 * @export
 */
export const PasswordGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Send reset password instructions to general manager user
         * @summary Send an email about resetting password
         * @param {GmUserResetPasswordInput} gmUserResetPasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalManagerUserResetPassword(gmUserResetPasswordInput: GmUserResetPasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmUserPasswordSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalManagerUserResetPassword(gmUserResetPasswordInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update password of general manager user
         * @summary Update password
         * @param {GmUserUpdatePasswordInput} gmUserUpdatePasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalManagerUserUpdatePassword(gmUserUpdatePasswordInput: GmUserUpdatePasswordInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmUserPasswordSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalManagerUserUpdatePassword(gmUserUpdatePasswordInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PasswordGMApi - factory interface
 * @export
 */
export const PasswordGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordGMApiFp(configuration)
    return {
        /**
         * Send reset password instructions to general manager user
         * @summary Send an email about resetting password
         * @param {GmUserResetPasswordInput} gmUserResetPasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserResetPassword(gmUserResetPasswordInput: GmUserResetPasswordInput, options?: any): AxiosPromise<GmUserPasswordSuccessResponse> {
            return localVarFp.generalManagerUserResetPassword(gmUserResetPasswordInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update password of general manager user
         * @summary Update password
         * @param {GmUserUpdatePasswordInput} gmUserUpdatePasswordInput Sign up as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserUpdatePassword(gmUserUpdatePasswordInput: GmUserUpdatePasswordInput, options?: any): AxiosPromise<GmUserPasswordSuccessResponse> {
            return localVarFp.generalManagerUserUpdatePassword(gmUserUpdatePasswordInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordGMApi - interface
 * @export
 * @interface PasswordGMApi
 */
export interface PasswordGMApiInterface {
    /**
     * Send reset password instructions to general manager user
     * @summary Send an email about resetting password
     * @param {GmUserResetPasswordInput} gmUserResetPasswordInput Sign up as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordGMApiInterface
     */
    generalManagerUserResetPassword(gmUserResetPasswordInput: GmUserResetPasswordInput, options?: any): AxiosPromise<GmUserPasswordSuccessResponse>;

    /**
     * Update password of general manager user
     * @summary Update password
     * @param {GmUserUpdatePasswordInput} gmUserUpdatePasswordInput Sign up as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordGMApiInterface
     */
    generalManagerUserUpdatePassword(gmUserUpdatePasswordInput: GmUserUpdatePasswordInput, options?: any): AxiosPromise<GmUserPasswordSuccessResponse>;

}

/**
 * PasswordGMApi - object-oriented interface
 * @export
 * @class PasswordGMApi
 * @extends {BaseAPI}
 */
export class PasswordGMApi extends BaseAPI implements PasswordGMApiInterface {
    /**
     * Send reset password instructions to general manager user
     * @summary Send an email about resetting password
     * @param {GmUserResetPasswordInput} gmUserResetPasswordInput Sign up as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordGMApi
     */
    public generalManagerUserResetPassword(gmUserResetPasswordInput: GmUserResetPasswordInput, options?: any) {
        return PasswordGMApiFp(this.configuration).generalManagerUserResetPassword(gmUserResetPasswordInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update password of general manager user
     * @summary Update password
     * @param {GmUserUpdatePasswordInput} gmUserUpdatePasswordInput Sign up as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordGMApi
     */
    public generalManagerUserUpdatePassword(gmUserUpdatePasswordInput: GmUserUpdatePasswordInput, options?: any) {
        return PasswordGMApiFp(this.configuration).generalManagerUserUpdatePassword(gmUserUpdatePasswordInput, options).then((request) => request(this.axios, this.basePath));
    }
}
