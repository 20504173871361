import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
    <path
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M5 22V3a1 1 0 011-1h12a1 1 0 011 1v19l-7-6.111L5 22z"
    ></path>
  </svg>
);

export default SvgComponent;
