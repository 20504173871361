import React from 'react';

import { Box } from '@app/components';

type InfoBoxPropTypes = {
  title: string;
  value: string | number;
};

const InfoBox: React.FC<InfoBoxPropTypes> = ({ title, value }) => {
  return (
    <Box className="flex flex-col items-center flex-center">
      <span className="text-4 text-gray-500">{title}</span>
      <span className="text-gray-900 font-medium text-10 leading-none mt-2 block">{value ?? '-'}</span>
    </Box>
  );
};

export default InfoBox;
