/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchAccountCampaignLeadListResponse } from '../model';
// @ts-ignore
import { Campaign } from '../model';
// @ts-ignore
import { CampaignCreateErrorResponse } from '../model';
// @ts-ignore
import { CampaignCreateInput } from '../model';
// @ts-ignore
import { CampaignInsightResponse } from '../model';
// @ts-ignore
import { CampaignListResponse } from '../model';
// @ts-ignore
import { CampaignUpdateInput } from '../model';
// @ts-ignore
import { DynamicLocationAdCampaignCreateErrorResponse } from '../model';
// @ts-ignore
import { DynamicLocationAdCampaignCreateInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { GoogleCampaignCreateInput } from '../model';
// @ts-ignore
import { GoogleCampaignUpdateInput } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
// @ts-ignore
import { UNKNOWN_BASE_TYPE } from '../model';
/**
 * CampaignsGMApi - axios parameter creator
 * @export
 */
export const CampaignsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Cancel campaign
         * @summary Cancel campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGmCampaign: async (companyId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('cancelGmCampaign', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('cancelGmCampaign', 'campaignId', campaignId)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/facebook/{campaign_id}/cancel`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a campaign to company
         * @summary Create a campaign to company
         * @param {string} companyId The id of the company
         * @param {CampaignCreateInput} campaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign: async (companyId: string, campaignCreateInput: CampaignCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCampaign', 'companyId', companyId)
            // verify required parameter 'campaignCreateInput' is not null or undefined
            assertParamExists('createCampaign', 'campaignCreateInput', campaignCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/facebook`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a dynamic location ad campaign
         * @summary Create a dynamic location ad campaign
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdCampaignCreateInput} dynamicLocationAdCampaignCreateInput Create a dynamic location ad campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDynamicLocationAdCampaign: async (companyId: string, dynamicLocationAdCampaignCreateInput: DynamicLocationAdCampaignCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createDynamicLocationAdCampaign', 'companyId', companyId)
            // verify required parameter 'dynamicLocationAdCampaignCreateInput' is not null or undefined
            assertParamExists('createDynamicLocationAdCampaign', 'dynamicLocationAdCampaignCreateInput', dynamicLocationAdCampaignCreateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/campaigns/dynamic_location_ad_campaigns`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicLocationAdCampaignCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a google campaign to company
         * @summary Create a google campaign to company
         * @param {string} companyId The id of the company
         * @param {GoogleCampaignCreateInput} googleCampaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleCampaign: async (companyId: string, googleCampaignCreateInput: GoogleCampaignCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createGoogleCampaign', 'companyId', companyId)
            // verify required parameter 'googleCampaignCreateInput' is not null or undefined
            assertParamExists('createGoogleCampaign', 'googleCampaignCreateInput', googleCampaignCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/google`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleCampaignCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaigns of company
         * @summary Return campaigns of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listCampaigns', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return leads with by campaign id
         * @summary Return leads with by campaign id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsByGmCampaignId: async (companyId: string, campaignId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listLeadsByGmCampaignId', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('listLeadsByGmCampaignId', 'campaignId', campaignId)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/facebook/{campaign_id}/leads`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaign with by id
         * @summary Return campaign with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaign: async (companyId: string, campaignId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showCampaign', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showCampaign', 'campaignId', campaignId)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/{campaign_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaignInsights: async (companyId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showCampaignInsights', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('showCampaignInsights', 'campaignId', campaignId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('showCampaignInsights', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('showCampaignInsights', 'endDate', endDate)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/{campaign_id}/insights`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Toggle a campaign status
         * @summary Toggle campaign status
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Toggle campaign status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleGmCampaign: async (companyId: string, campaignId: string, uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('toggleGmCampaign', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('toggleGmCampaign', 'campaignId', campaignId)
            // verify required parameter 'uNKNOWNBASETYPE' is not null or undefined
            assertParamExists('toggleGmCampaign', 'uNKNOWNBASETYPE', uNKNOWNBASETYPE)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/facebook/{campaign_id}/toggle_status`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uNKNOWNBASETYPE, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {CampaignUpdateInput} campaignUpdateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGmCampaign: async (companyId: string, campaignId: string, campaignUpdateInput: CampaignUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGmCampaign', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('updateGmCampaign', 'campaignId', campaignId)
            // verify required parameter 'campaignUpdateInput' is not null or undefined
            assertParamExists('updateGmCampaign', 'campaignUpdateInput', campaignUpdateInput)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/facebook/{campaign_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(campaignUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a google campaign
         * @summary Update a google campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {GoogleCampaignUpdateInput} googleCampaignUpdateInput Update a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleGmCampaign: async (companyId: string, campaignId: string, googleCampaignUpdateInput: GoogleCampaignUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGoogleGmCampaign', 'companyId', companyId)
            // verify required parameter 'campaignId' is not null or undefined
            assertParamExists('updateGoogleGmCampaign', 'campaignId', campaignId)
            // verify required parameter 'googleCampaignUpdateInput' is not null or undefined
            assertParamExists('updateGoogleGmCampaign', 'googleCampaignUpdateInput', googleCampaignUpdateInput)
            const localVarPath = `/gm_user/companies/{company_id}/campaigns/google/{campaign_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"campaign_id"}}`, encodeURIComponent(String(campaignId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleCampaignUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CampaignsGMApi - functional programming interface
 * @export
 */
export const CampaignsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CampaignsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Cancel campaign
         * @summary Cancel campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cancelGmCampaign(companyId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cancelGmCampaign(companyId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a campaign to company
         * @summary Create a campaign to company
         * @param {string} companyId The id of the company
         * @param {CampaignCreateInput} campaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCampaign(companyId: string, campaignCreateInput: CampaignCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCampaign(companyId, campaignCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a dynamic location ad campaign
         * @summary Create a dynamic location ad campaign
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdCampaignCreateInput} dynamicLocationAdCampaignCreateInput Create a dynamic location ad campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDynamicLocationAdCampaign(companyId: string, dynamicLocationAdCampaignCreateInput: DynamicLocationAdCampaignCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDynamicLocationAdCampaign(companyId, dynamicLocationAdCampaignCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a google campaign to company
         * @summary Create a google campaign to company
         * @param {string} companyId The id of the company
         * @param {GoogleCampaignCreateInput} googleCampaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleCampaign(companyId: string, googleCampaignCreateInput: GoogleCampaignCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleCampaign(companyId, googleCampaignCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaigns of company
         * @summary Return campaigns of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listCampaigns(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listCampaigns(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return leads with by campaign id
         * @summary Return leads with by campaign id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listLeadsByGmCampaignId(companyId: string, campaignId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchAccountCampaignLeadListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listLeadsByGmCampaignId(companyId, campaignId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaign with by id
         * @summary Return campaign with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showCampaign(companyId: string, campaignId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showCampaign(companyId, campaignId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showCampaignInsights(companyId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CampaignInsightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showCampaignInsights(companyId, campaignId, startDate, endDate, granularity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Toggle a campaign status
         * @summary Toggle campaign status
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Toggle campaign status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async toggleGmCampaign(companyId: string, campaignId: string, uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.toggleGmCampaign(companyId, campaignId, uNKNOWNBASETYPE, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {CampaignUpdateInput} campaignUpdateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGmCampaign(companyId: string, campaignId: string, campaignUpdateInput: CampaignUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGmCampaign(companyId, campaignId, campaignUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a google campaign
         * @summary Update a google campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {GoogleCampaignUpdateInput} googleCampaignUpdateInput Update a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGoogleGmCampaign(companyId: string, campaignId: string, googleCampaignUpdateInput: GoogleCampaignUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Campaign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGoogleGmCampaign(companyId, campaignId, googleCampaignUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CampaignsGMApi - factory interface
 * @export
 */
export const CampaignsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CampaignsGMApiFp(configuration)
    return {
        /**
         * Cancel campaign
         * @summary Cancel campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelGmCampaign(companyId: string, campaignId: string, options?: any): AxiosPromise<Campaign> {
            return localVarFp.cancelGmCampaign(companyId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a campaign to company
         * @summary Create a campaign to company
         * @param {string} companyId The id of the company
         * @param {CampaignCreateInput} campaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCampaign(companyId: string, campaignCreateInput: CampaignCreateInput, options?: any): AxiosPromise<Campaign> {
            return localVarFp.createCampaign(companyId, campaignCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a dynamic location ad campaign
         * @summary Create a dynamic location ad campaign
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdCampaignCreateInput} dynamicLocationAdCampaignCreateInput Create a dynamic location ad campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDynamicLocationAdCampaign(companyId: string, dynamicLocationAdCampaignCreateInput: DynamicLocationAdCampaignCreateInput, options?: any): AxiosPromise<Campaign> {
            return localVarFp.createDynamicLocationAdCampaign(companyId, dynamicLocationAdCampaignCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a google campaign to company
         * @summary Create a google campaign to company
         * @param {string} companyId The id of the company
         * @param {GoogleCampaignCreateInput} googleCampaignCreateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleCampaign(companyId: string, googleCampaignCreateInput: GoogleCampaignCreateInput, options?: any): AxiosPromise<Campaign> {
            return localVarFp.createGoogleCampaign(companyId, googleCampaignCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaigns of company
         * @summary Return campaigns of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCampaigns(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse> {
            return localVarFp.listCampaigns(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return leads with by campaign id
         * @summary Return leads with by campaign id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listLeadsByGmCampaignId(companyId: string, campaignId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignLeadListResponse> {
            return localVarFp.listLeadsByGmCampaignId(companyId, campaignId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaign with by id
         * @summary Return campaign with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaign(companyId: string, campaignId: string, options?: any): AxiosPromise<Campaign> {
            return localVarFp.showCampaign(companyId, campaignId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return campaign insights with by id
         * @summary Return campaign insights with by id
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showCampaignInsights(companyId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<CampaignInsightResponse> {
            return localVarFp.showCampaignInsights(companyId, campaignId, startDate, endDate, granularity, options).then((request) => request(axios, basePath));
        },
        /**
         * Toggle a campaign status
         * @summary Toggle campaign status
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Toggle campaign status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        toggleGmCampaign(companyId: string, campaignId: string, uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<Campaign> {
            return localVarFp.toggleGmCampaign(companyId, campaignId, uNKNOWNBASETYPE, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a campaign
         * @summary Update a campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {CampaignUpdateInput} campaignUpdateInput Create a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGmCampaign(companyId: string, campaignId: string, campaignUpdateInput: CampaignUpdateInput, options?: any): AxiosPromise<Campaign> {
            return localVarFp.updateGmCampaign(companyId, campaignId, campaignUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a google campaign
         * @summary Update a google campaign
         * @param {string} companyId The id of the company
         * @param {string} campaignId The id of the campaign
         * @param {GoogleCampaignUpdateInput} googleCampaignUpdateInput Update a campaign to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleGmCampaign(companyId: string, campaignId: string, googleCampaignUpdateInput: GoogleCampaignUpdateInput, options?: any): AxiosPromise<Campaign> {
            return localVarFp.updateGoogleGmCampaign(companyId, campaignId, googleCampaignUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CampaignsGMApi - interface
 * @export
 * @interface CampaignsGMApi
 */
export interface CampaignsGMApiInterface {
    /**
     * Cancel campaign
     * @summary Cancel campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    cancelGmCampaign(companyId: string, campaignId: string, options?: any): AxiosPromise<Campaign>;

    /**
     * Create a campaign to company
     * @summary Create a campaign to company
     * @param {string} companyId The id of the company
     * @param {CampaignCreateInput} campaignCreateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    createCampaign(companyId: string, campaignCreateInput: CampaignCreateInput, options?: any): AxiosPromise<Campaign>;

    /**
     * Create a dynamic location ad campaign
     * @summary Create a dynamic location ad campaign
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdCampaignCreateInput} dynamicLocationAdCampaignCreateInput Create a dynamic location ad campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    createDynamicLocationAdCampaign(companyId: string, dynamicLocationAdCampaignCreateInput: DynamicLocationAdCampaignCreateInput, options?: any): AxiosPromise<Campaign>;

    /**
     * Create a google campaign to company
     * @summary Create a google campaign to company
     * @param {string} companyId The id of the company
     * @param {GoogleCampaignCreateInput} googleCampaignCreateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    createGoogleCampaign(companyId: string, googleCampaignCreateInput: GoogleCampaignCreateInput, options?: any): AxiosPromise<Campaign>;

    /**
     * Return campaigns of company
     * @summary Return campaigns of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    listCampaigns(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CampaignListResponse>;

    /**
     * Return leads with by campaign id
     * @summary Return leads with by campaign id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    listLeadsByGmCampaignId(companyId: string, campaignId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<BranchAccountCampaignLeadListResponse>;

    /**
     * Return campaign with by id
     * @summary Return campaign with by id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    showCampaign(companyId: string, campaignId: string, options?: any): AxiosPromise<Campaign>;

    /**
     * Return campaign insights with by id
     * @summary Return campaign insights with by id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    showCampaignInsights(companyId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<CampaignInsightResponse>;

    /**
     * Toggle a campaign status
     * @summary Toggle campaign status
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Toggle campaign status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    toggleGmCampaign(companyId: string, campaignId: string, uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any): AxiosPromise<Campaign>;

    /**
     * Update a campaign
     * @summary Update a campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {CampaignUpdateInput} campaignUpdateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    updateGmCampaign(companyId: string, campaignId: string, campaignUpdateInput: CampaignUpdateInput, options?: any): AxiosPromise<Campaign>;

    /**
     * Update a google campaign
     * @summary Update a google campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {GoogleCampaignUpdateInput} googleCampaignUpdateInput Update a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApiInterface
     */
    updateGoogleGmCampaign(companyId: string, campaignId: string, googleCampaignUpdateInput: GoogleCampaignUpdateInput, options?: any): AxiosPromise<Campaign>;

}

/**
 * CampaignsGMApi - object-oriented interface
 * @export
 * @class CampaignsGMApi
 * @extends {BaseAPI}
 */
export class CampaignsGMApi extends BaseAPI implements CampaignsGMApiInterface {
    /**
     * Cancel campaign
     * @summary Cancel campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public cancelGmCampaign(companyId: string, campaignId: string, options?: any) {
        return CampaignsGMApiFp(this.configuration).cancelGmCampaign(companyId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a campaign to company
     * @summary Create a campaign to company
     * @param {string} companyId The id of the company
     * @param {CampaignCreateInput} campaignCreateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public createCampaign(companyId: string, campaignCreateInput: CampaignCreateInput, options?: any) {
        return CampaignsGMApiFp(this.configuration).createCampaign(companyId, campaignCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a dynamic location ad campaign
     * @summary Create a dynamic location ad campaign
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdCampaignCreateInput} dynamicLocationAdCampaignCreateInput Create a dynamic location ad campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public createDynamicLocationAdCampaign(companyId: string, dynamicLocationAdCampaignCreateInput: DynamicLocationAdCampaignCreateInput, options?: any) {
        return CampaignsGMApiFp(this.configuration).createDynamicLocationAdCampaign(companyId, dynamicLocationAdCampaignCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a google campaign to company
     * @summary Create a google campaign to company
     * @param {string} companyId The id of the company
     * @param {GoogleCampaignCreateInput} googleCampaignCreateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public createGoogleCampaign(companyId: string, googleCampaignCreateInput: GoogleCampaignCreateInput, options?: any) {
        return CampaignsGMApiFp(this.configuration).createGoogleCampaign(companyId, googleCampaignCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaigns of company
     * @summary Return campaigns of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public listCampaigns(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CampaignsGMApiFp(this.configuration).listCampaigns(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return leads with by campaign id
     * @summary Return leads with by campaign id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public listLeadsByGmCampaignId(companyId: string, campaignId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return CampaignsGMApiFp(this.configuration).listLeadsByGmCampaignId(companyId, campaignId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaign with by id
     * @summary Return campaign with by id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public showCampaign(companyId: string, campaignId: string, options?: any) {
        return CampaignsGMApiFp(this.configuration).showCampaign(companyId, campaignId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return campaign insights with by id
     * @summary Return campaign insights with by id
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public showCampaignInsights(companyId: string, campaignId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any) {
        return CampaignsGMApiFp(this.configuration).showCampaignInsights(companyId, campaignId, startDate, endDate, granularity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Toggle a campaign status
     * @summary Toggle campaign status
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {UNKNOWN_BASE_TYPE} uNKNOWNBASETYPE Toggle campaign status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public toggleGmCampaign(companyId: string, campaignId: string, uNKNOWNBASETYPE: UNKNOWN_BASE_TYPE, options?: any) {
        return CampaignsGMApiFp(this.configuration).toggleGmCampaign(companyId, campaignId, uNKNOWNBASETYPE, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a campaign
     * @summary Update a campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {CampaignUpdateInput} campaignUpdateInput Create a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public updateGmCampaign(companyId: string, campaignId: string, campaignUpdateInput: CampaignUpdateInput, options?: any) {
        return CampaignsGMApiFp(this.configuration).updateGmCampaign(companyId, campaignId, campaignUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a google campaign
     * @summary Update a google campaign
     * @param {string} companyId The id of the company
     * @param {string} campaignId The id of the campaign
     * @param {GoogleCampaignUpdateInput} googleCampaignUpdateInput Update a campaign to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CampaignsGMApi
     */
    public updateGoogleGmCampaign(companyId: string, campaignId: string, googleCampaignUpdateInput: GoogleCampaignUpdateInput, options?: any) {
        return CampaignsGMApiFp(this.configuration).updateGoogleGmCampaign(companyId, campaignId, googleCampaignUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
