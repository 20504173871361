import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1282.667"
    height="642.667"
    version="1"
    viewBox="0 0 962 482"
    {...props}
    fill="currentColor"
  ></svg>
);

export default SvgComponent;
