import { useState } from 'react';

import cn from 'classnames';
import Head from 'next/head';

import { Breadcrumb, Loader, Sidebar, Header } from '@app/components';
import { useAuth } from '@app/hooks';

import { array } from '@app/lib';

const CONTAINER_TYPES = {
  BOXED: 'boxed',
  FULL: 'full',
  HEADER_BOXED: 'headerBoxed',
};

type LayoutPropTypes = {
  isLoading?: boolean;
  containerClassName?: string;
  title: string;
  containerType?: 'boxed' | 'headerBoxed' | 'full';
  right?: JSX.Element;
  breadcrumbs?: string[];
};

const Layout: React.FC<LayoutPropTypes> = ({
  children,
  containerClassName,
  containerType = CONTAINER_TYPES.BOXED,
  isLoading,
  title,
  right,
  breadcrumbs,
}) => {
  const { isRefreshTokenLoading } = useAuth();
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);

  return (
    <div className="min-h-screen flex flex-col">
      <Head>{title && <title>{`${title} | Branchsight`}</title>}</Head>
      <Sidebar
        onCollapseClick={setIsMiniSidebar}
        miniSidebar={isMiniSidebar}
        className={cn({
          '!w-20': isMiniSidebar,
          '!w-50': !isMiniSidebar,
        })}
      />
      <Header
        title={title}
        className={cn('sticky top-0 z-10 transition-all', {
          'ml-20': isMiniSidebar,
          'ml-50': !isMiniSidebar,
        })}
        containerClassName={cn({
          'max-w-233': containerType === CONTAINER_TYPES.BOXED || containerType === CONTAINER_TYPES.HEADER_BOXED,
        })}
        right={right}
      />
      {!array.isEmpty(breadcrumbs) && (
        <Breadcrumb
          className={cn('transition-all', {
            'ml-20': isMiniSidebar,
            'ml-50': !isMiniSidebar,
          })}
          items={breadcrumbs ?? []}
        />
      )}
      <main
        className={cn('transition-all p-5 overflow', containerClassName, {
          'ml-20': isMiniSidebar,
          'ml-50': !isMiniSidebar,
          'max-w-242.5': containerType === CONTAINER_TYPES.BOXED,
        })}
      >
        {children}
      </main>
      {(isLoading || isRefreshTokenLoading) && <Loader />}
    </div>
  );
};

export default Layout;
