import React, { Dispatch, SetStateAction } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Box } from '@app/components';
import { useActiveInput, useLocale } from '@app/hooks';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

import BannerbearInput from './BannerbearInput';

type FormPropTypes = {
  index: number;
  bannerbearSession: BannerbearSessionStateType;
  setBannerbearSession: Dispatch<SetStateAction<BannerbearSessionStateType>>;
};

const MAX_MESSAGE_LENGTH = 255;

const TemplateForm: React.FC<FormPropTypes> = ({ index, bannerbearSession, setBannerbearSession }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.index',
  };
  const { t } = useLocale();
  const {
    getValues,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const messageWatch = watch('message');
  const activeInput = useActiveInput([`templates[${index}].message`, `templates[${index}].headline`, `templates[${index}].description`]);

  function InputVariables({ id, activeInput }: { id: string; activeInput: string | null }) {
    if (activeInput !== id) {
      return null;
    }

    function handleText(value: string) {
      const currentText = getValues(id);

      if (!currentText) {
        setValue(id, value);
        return;
      }

      setValue(id, `${currentText} ${value}`);
    }

    return <Form.BranchButtons isDynamicLocationAds className="mb-4.5 -mt-1.5" onClick={handleText} />;
  }

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id={`templates[${index}].name`}
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register(`templates[${index}].name`, {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.templates?.[index]?.name}
          className="mb-4"
        />
      </Box>
      <Box>
        <BannerbearInput
          index={index}
          type={IMAGE_PLACEMENT_TYPE.FEED}
          setBannerbearSession={setBannerbearSession}
          bannerbearSession={bannerbearSession}
          isMetaTemplate={false}
        />
      </Box>
      <Box>
        <BannerbearInput
          index={index}
          type={IMAGE_PLACEMENT_TYPE.STORY}
          setBannerbearSession={setBannerbearSession}
          bannerbearSession={bannerbearSession}
          isMetaTemplate={false}
        />
      </Box>
      <Box>
        <Form.Textarea
          id="message"
          label={t('form.message.label', SCOPE_OPTIONS)}
          placeholder={t('form.message.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${index}].message`, {
            required: { value: true, message: t('form.message.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          maxLength={MAX_MESSAGE_LENGTH}
          length={messageWatch?.length ?? 0}
          error={errors.templates?.[index]?.message}
        />
        <InputVariables id={`templates[${index}].message`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id="headline"
          label={t('form.headline.label', SCOPE_OPTIONS)}
          placeholder={t('form.headline.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${index}].headline`, {
            required: { value: true, message: t('form.headline.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.templates?.[index]?.headline}
        />
        <InputVariables id={`templates[${index}].headline`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id="description"
          label={t('form.description.label', SCOPE_OPTIONS)}
          placeholder={t('form.description.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${index}].description`, {
            required: { value: true, message: t('form.description.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.templates?.[index]?.description}
        />
        <InputVariables id={`templates[${index}].description`} activeInput={activeInput} />
      </Box>
    </form>
  );
};

export default TemplateForm;
