import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MetabaseResourcesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function listGmMetabaseResources(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new MetabaseResourcesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGmMetabaseResources(companyId).then((res) => res.data);
}

export default function useListGmMetabaseResources(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(['useListGmMetabaseResources', companyId], () => listGmMetabaseResources(companyId, axiosInstance), props);
}
