import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="33" viewBox="0 0 40 33" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-274 -519)">
        <g transform="translate(270 511)">
          <g fill="#1E8E3E" transform="translate(4 8)">
            <path d="M36 .02H4c-2.2 0-3.98 1.8-3.98 4l-.02 24c0 2.2 1.8 4 4 4h32c2.2 0 4-1.8 4-4v-24c0-2.22-1.78-4-4-4zm-12 28H4v-8h20v8zm0-10H4v-8h20v8zm12 10H26v-18h10v18z"></path>
          </g>
          <path d="M0 0H48V48H0z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
