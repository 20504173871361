import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m6.352 20.495 1.709.262a2.445 2.445 0 0 1 2.069 2.594l-.126 1.725c-.037.502.237.975.69 1.194l1.264.608c.453.219.995.137 1.365-.204l1.27-1.173a2.445 2.445 0 0 1 3.318 0l1.27 1.173c.37.342.91.423 1.365.204l1.266-.61a1.22 1.22 0 0 0 .688-1.19l-.126-1.727a2.445 2.445 0 0 1 2.069-2.594l1.708-.262a1.227 1.227 0 0 0 1.011-.94l.312-1.366c.112-.491-.087-1-.503-1.284l-1.426-.977a2.445 2.445 0 0 1-.738-3.235l.862-1.498c.25-.436.209-.983-.105-1.376l-.874-1.096a1.227 1.227 0 0 0-1.319-.409l-1.652.506a2.446 2.446 0 0 1-2.992-1.44l-.633-1.604A1.227 1.227 0 0 0 16.95 5l-1.4.004a1.227 1.227 0 0 0-1.14.782l-.618 1.586a2.444 2.444 0 0 1-2.996 1.45l-1.721-.528a1.227 1.227 0 0 0-1.322.41l-.868 1.098a1.226 1.226 0 0 0-.097 1.38l.882 1.503a2.445 2.445 0 0 1-.728 3.253l-1.409.965a1.225 1.225 0 0 0-.502 1.285l.312 1.366c.11.493.512.865 1.01.941v0Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.593 13.697a3.312 3.312 0 1 1-4.684 4.683 3.312 3.312 0 0 1 4.684-4.683Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
