import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';
import type { V2TemplateCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createMetaTemplate(
  companyId: string,
  templateCreateInput: V2TemplateCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createMetaTemplateV2(companyId, templateCreateInput).then((res) => res.data);
}

export default function useCreateMetaTemplate(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; templateCreateInput: V2TemplateCreateInput }) =>
      createMetaTemplate(values.companyId, values.templateCreateInput, axiosInstance),
    props
  );
}
