import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { ProfileApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function getProfile(axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ProfileApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getProfile().then((res) => res.data);
}

export default function useGetProfile(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(['useGetProfile'], () => getProfile(axiosInstance), props);
}
