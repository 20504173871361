import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletCompanyV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getCompanyTransactions(branchId: string, axiosInstance: AxiosInstance, page: number, perPage: number) {
  const api = new WalletCompanyV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getCompanyWalletTransactions(branchId, page, perPage);
}

export default function useGetCompanyTransactions(page: number, perPage: number) {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  return useQuery(['userTransactions', companyId, page, perPage], () => getCompanyTransactions(companyId, axiosInstance, page, perPage));
}
