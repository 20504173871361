import cn from 'classnames';
import { CheckCircleIcon, XIcon, ExclamationCircleIcon } from '@heroicons/react/solid';

enum ToastTypes {
  SUCCESS,
  WARNING,
  ERROR,
}

type ToastPropTypes = {
  type: ToastTypes;
  message: string;
  disableCloseClick?: boolean;
};

const CloseIcon: React.FC = () => {
  return (
    <div className="h-full">
      <XIcon className="text-white w-5 h-5" />
    </div>
  );
};

const TypeIcon: React.FC<{ type: ToastTypes }> = ({ type }) => {
  return (
    <div className="h-full my-auto">
      {type === ToastTypes.SUCCESS ? (
        <CheckCircleIcon className="text-white w-5 h-5" />
      ) : (
        <ExclamationCircleIcon className="text-white w-5 h-5" />
      )}
    </div>
  );
};

const Toast: React.FC<ToastPropTypes> = ({ type, message, disableCloseClick = false }) => {
  return (
    <div
      className={cn('p-3 rounded-3 text-white shadow-box flex-row flex', {
        'bg-green-400': type === ToastTypes.SUCCESS,
        'bg-yellow-900': type === ToastTypes.WARNING,
        'bg-red-400': type === ToastTypes.ERROR,
      })}
    >
      <TypeIcon type={type} />
      <div className="flex flex-grow mx-2">
        <p className="text-3.5 select-none">{message}</p>
      </div>
      {disableCloseClick === false && <CloseIcon />}
    </div>
  );
};

export default Toast;
