import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function showMessagingApplication(
  companyId: string,
  messagingApplicationId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showMessagingApplicationForCompany(companyId, messagingApplicationId).then((res) => res.data);
}

export default function useShowMessagingApplication({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useShowMessagingApplication', companyId, params.messagingApplicationId],
    () => showMessagingApplication(companyId, params.messagingApplicationId, axiosInstance),
    props
  );
}
