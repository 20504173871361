import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="currentColor" {...props}>
    <path d="M3 2a3 3 0 00-3 3v17a3 3 0 003 3h20a3 3 0 003-3V8a3 3 0 00-3-3H11a3 3 0 00-3-3zm0 5h20c.55 0 1 .45 1 1v14c0 .55-.45 1-1 1H3c-.55 0-1-.45-1-1V8c0-.55.45-1 1-1z"></path>
  </svg>
);

export default SvgComponent;
