import React from 'react';

import { RadioGroup } from '@headlessui/react';
import cn from 'classnames';

import { array } from '@app/lib';

type RadioPickerPropTypes = {
  options: { label: string; value: string }[];
  value: string;
  onChange: (value: string) => void;
  className?: string;
};

const RadioPicker: React.FC<RadioPickerPropTypes> = ({ options, value, onChange, className }) => {
  function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="currentColor" />
        <path d="M7 13l3 3 7-7" stroke="#fff" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    );
  }

  return (
    <RadioGroup value={value} onChange={onChange}>
      <div className={className}>
        {!array.isEmpty(options) &&
          options.map((option) => (
            <RadioGroup.Option
              key={option.value}
              value={option.value}
              className={({ checked }) =>
                cn('relative rounded-xl px-3 py-3 border-0.5 cursor-pointer flex focus:outline-none transition-colors', {
                  'border-gray-600': !checked,
                  'border-gray-800': checked,
                })
              }
            >
              {({ checked }) => (
                <div className="flex items-center w-full">
                  <div className={cn('flex-shrink-0 mr-2.5 transition-colors', { 'text-gray-500': !checked, 'text-gray-900': checked })}>
                    <CheckIcon className="w-6 h-6" />
                  </div>

                  <div className="flex items-center">
                    <div className="text-sm line-clamp-2">
                      <RadioGroup.Label as="p" className="text-gray-900 font-semibold text-3.5 line-clamp-2 break-all">
                        {option.label}
                      </RadioGroup.Label>
                    </div>
                  </div>
                </div>
              )}
            </RadioGroup.Option>
          ))}
      </div>
    </RadioGroup>
  );
};

export default RadioPicker;
