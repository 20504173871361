/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PunditResponse } from '../model';
// @ts-ignore
import { V2FileManagerCreateRequestInput } from '../model';
// @ts-ignore
import { V2FileManagerListResponse } from '../model';
/**
 * FileManagerGMApi - axios parameter creator
 * @export
 */
export const FileManagerGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create file for the company
         * @summary Create file for the company
         * @param {string} companyId The id of the company
         * @param {V2FileManagerCreateRequestInput} v2FileManagerCreateRequestInput Create file request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileForCompany: async (companyId: string, v2FileManagerCreateRequestInput: V2FileManagerCreateRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createFileForCompany', 'companyId', companyId)
            // verify required parameter 'v2FileManagerCreateRequestInput' is not null or undefined
            assertParamExists('createFileForCompany', 'v2FileManagerCreateRequestInput', v2FileManagerCreateRequestInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/file_manager`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2FileManagerCreateRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Destroy file for the company
         * @summary Destroy file for the company
         * @param {string} companyId The id of the company
         * @param {string} [fullPath] The full path of the file or folder
         * @param {string} [categoryName] Category name of the file or folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyFileForCompany: async (companyId: string, fullPath?: string, categoryName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('destroyFileForCompany', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/file_manager`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullPath !== undefined) {
                localVarQueryParameter['full_path'] = fullPath;
            }

            if (categoryName !== undefined) {
                localVarQueryParameter['category_name'] = categoryName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List files for the company
         * @summary List files for the company
         * @param {string} companyId The id of the company
         * @param {string} [currentFolder] The current folder
         * @param {string} [objectName] The file or folder that searched by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilesForCompany: async (companyId: string, currentFolder?: string, objectName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listFilesForCompany', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/file_manager`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (currentFolder !== undefined) {
                localVarQueryParameter['current_folder'] = currentFolder;
            }

            if (objectName !== undefined) {
                localVarQueryParameter['object_name'] = objectName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileManagerGMApi - functional programming interface
 * @export
 */
export const FileManagerGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileManagerGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create file for the company
         * @summary Create file for the company
         * @param {string} companyId The id of the company
         * @param {V2FileManagerCreateRequestInput} v2FileManagerCreateRequestInput Create file request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createFileForCompany(companyId: string, v2FileManagerCreateRequestInput: V2FileManagerCreateRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createFileForCompany(companyId, v2FileManagerCreateRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Destroy file for the company
         * @summary Destroy file for the company
         * @param {string} companyId The id of the company
         * @param {string} [fullPath] The full path of the file or folder
         * @param {string} [categoryName] Category name of the file or folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async destroyFileForCompany(companyId: string, fullPath?: string, categoryName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.destroyFileForCompany(companyId, fullPath, categoryName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List files for the company
         * @summary List files for the company
         * @param {string} companyId The id of the company
         * @param {string} [currentFolder] The current folder
         * @param {string} [objectName] The file or folder that searched by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listFilesForCompany(companyId: string, currentFolder?: string, objectName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2FileManagerListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listFilesForCompany(companyId, currentFolder, objectName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileManagerGMApi - factory interface
 * @export
 */
export const FileManagerGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileManagerGMApiFp(configuration)
    return {
        /**
         * Create file for the company
         * @summary Create file for the company
         * @param {string} companyId The id of the company
         * @param {V2FileManagerCreateRequestInput} v2FileManagerCreateRequestInput Create file request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFileForCompany(companyId: string, v2FileManagerCreateRequestInput: V2FileManagerCreateRequestInput, options?: any): AxiosPromise<boolean> {
            return localVarFp.createFileForCompany(companyId, v2FileManagerCreateRequestInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Destroy file for the company
         * @summary Destroy file for the company
         * @param {string} companyId The id of the company
         * @param {string} [fullPath] The full path of the file or folder
         * @param {string} [categoryName] Category name of the file or folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        destroyFileForCompany(companyId: string, fullPath?: string, categoryName?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.destroyFileForCompany(companyId, fullPath, categoryName, options).then((request) => request(axios, basePath));
        },
        /**
         * List files for the company
         * @summary List files for the company
         * @param {string} companyId The id of the company
         * @param {string} [currentFolder] The current folder
         * @param {string} [objectName] The file or folder that searched by user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilesForCompany(companyId: string, currentFolder?: string, objectName?: string, options?: any): AxiosPromise<V2FileManagerListResponse> {
            return localVarFp.listFilesForCompany(companyId, currentFolder, objectName, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileManagerGMApi - interface
 * @export
 * @interface FileManagerGMApi
 */
export interface FileManagerGMApiInterface {
    /**
     * Create file for the company
     * @summary Create file for the company
     * @param {string} companyId The id of the company
     * @param {V2FileManagerCreateRequestInput} v2FileManagerCreateRequestInput Create file request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApiInterface
     */
    createFileForCompany(companyId: string, v2FileManagerCreateRequestInput: V2FileManagerCreateRequestInput, options?: any): AxiosPromise<boolean>;

    /**
     * Destroy file for the company
     * @summary Destroy file for the company
     * @param {string} companyId The id of the company
     * @param {string} [fullPath] The full path of the file or folder
     * @param {string} [categoryName] Category name of the file or folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApiInterface
     */
    destroyFileForCompany(companyId: string, fullPath?: string, categoryName?: string, options?: any): AxiosPromise<boolean>;

    /**
     * List files for the company
     * @summary List files for the company
     * @param {string} companyId The id of the company
     * @param {string} [currentFolder] The current folder
     * @param {string} [objectName] The file or folder that searched by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApiInterface
     */
    listFilesForCompany(companyId: string, currentFolder?: string, objectName?: string, options?: any): AxiosPromise<V2FileManagerListResponse>;

}

/**
 * FileManagerGMApi - object-oriented interface
 * @export
 * @class FileManagerGMApi
 * @extends {BaseAPI}
 */
export class FileManagerGMApi extends BaseAPI implements FileManagerGMApiInterface {
    /**
     * Create file for the company
     * @summary Create file for the company
     * @param {string} companyId The id of the company
     * @param {V2FileManagerCreateRequestInput} v2FileManagerCreateRequestInput Create file request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApi
     */
    public createFileForCompany(companyId: string, v2FileManagerCreateRequestInput: V2FileManagerCreateRequestInput, options?: any) {
        return FileManagerGMApiFp(this.configuration).createFileForCompany(companyId, v2FileManagerCreateRequestInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Destroy file for the company
     * @summary Destroy file for the company
     * @param {string} companyId The id of the company
     * @param {string} [fullPath] The full path of the file or folder
     * @param {string} [categoryName] Category name of the file or folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApi
     */
    public destroyFileForCompany(companyId: string, fullPath?: string, categoryName?: string, options?: any) {
        return FileManagerGMApiFp(this.configuration).destroyFileForCompany(companyId, fullPath, categoryName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List files for the company
     * @summary List files for the company
     * @param {string} companyId The id of the company
     * @param {string} [currentFolder] The current folder
     * @param {string} [objectName] The file or folder that searched by user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileManagerGMApi
     */
    public listFilesForCompany(companyId: string, currentFolder?: string, objectName?: string, options?: any) {
        return FileManagerGMApiFp(this.configuration).listFilesForCompany(companyId, currentFolder, objectName, options).then((request) => request(this.axios, this.basePath));
    }
}
