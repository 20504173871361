/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface NotificationMetadataNewLead
 */
export interface NotificationMetadataNewLead {
    /**
     * 
     * @type {string}
     * @memberof NotificationMetadataNewLead
     */
    type: NotificationMetadataNewLeadTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof NotificationMetadataNewLead
     */
    branch_campaign_id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMetadataNewLead
     */
    branch_id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMetadataNewLead
     */
    company_id: string;
    /**
     * 
     * @type {string}
     * @memberof NotificationMetadataNewLead
     */
    campaign_name: string;
    /**
     * 
     * @type {number}
     * @memberof NotificationMetadataNewLead
     */
    leads_count: number;
}

/**
    * @export
    * @enum {string}
    */
export enum NotificationMetadataNewLeadTypeEnum {
    NewLead = 'new_lead',
    TodayEndCampaigns = 'today_end_campaigns',
    JoinableCampaigns = 'joinable_campaigns',
    CampaignPublished = 'campaign_published',
    CampaignRejected = 'campaign_rejected',
    CampaignUpdated = 'campaign_updated',
    WeeklyReport = 'weekly_report'
}



