import React, { useState, useEffect } from 'react';

import cn from 'classnames';

import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';

type SearchInputPropTypes = {
  className?: string;
  placeholder?: string;
  value?: string;
  defaultValue?: string;
  onChange: (text: string) => void;
  triggerOnEnter?: boolean;
};

const SearchInput: React.FC<SearchInputPropTypes> = ({
  className,
  placeholder,
  defaultValue = '',
  onChange,
  triggerOnEnter = true,
  ...props
}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SearchInput',
  };
  const { t } = useLocale();
  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (typeof value === 'string' && value.length === 0) {
      onChange('');
    }
  }, [value]);

  return (
    <div className={cn('relative', className)}>
      <Icons.Magnifyer className="w-5 h-5 fill-inherit text-gray-500 absolute left-3 top-2.5" />
      <input
        onKeyUp={(e) => {
          if (!triggerOnEnter) {
            onChange(value);
          } else if (e.key === 'Enter') {
            onChange(value);
          }
        }}
        onChange={(event) => setValue(event.target.value)}
        type="text"
        value={value}
        placeholder={placeholder ?? t('labels.search', SCOPE_OPTIONS)}
        className="w-full h-10 rounded-3 font-normal pl-10 pr-16.5 text-3 text-gray-900 placeholder-gray-500 outline-none bg-white border-0.5 border-gray-300 placeholder:text-3"
        {...props}
      />
      <div className="h-10 flex items-center absolute right-3 top-0">
        <button className="py-1 px-1.5 leading-none text-2.5 bg-green-400 text-white rounded-1" onClick={() => onChange(value)}>
          {t('labels.search', SCOPE_OPTIONS)}
        </button>
      </div>
    </div>
  );
};

export default SearchInput;
