import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi, TemplateCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createGoogleTemplateAndPublish(
  companyId: string,
  templateCreateInput: TemplateCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createGoogleTemplateAndPublish(companyId, templateCreateInput).then((res) => res.data);
}

export default function useCreateGoogleTemplateAndPublish(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; templateCreateInput: TemplateCreateInput }) =>
      createGoogleTemplateAndPublish(values.companyId, values.templateCreateInput, axiosInstance),
    props
  );
}
