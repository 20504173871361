import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';
import { useAxios, useAuth } from '@app/hooks';

export function listPreviewsByTemplateId(
  companyId: string,
  templateId: string,
  page?: number,
  perPage?: number,
  query?: object,
  options?: any,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showRelatedFacebookBranchCampaignPreviews(companyId, templateId, page, perPage, query, options).then((res) => {
    return res.data;
  });
}

export default function useListPreviewsByTemplateId({ params, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListPreviewsByTemplateId', companyId, params.templateId, params.page, params.perPage, params.query],
    () => listPreviewsByTemplateId(companyId, params.templateId, params.page, params.perPage, params.query, params.options, axiosInstance),
    props
  );
}
