import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationGMApi } from 'api/api';
import { useAxios } from '@app/hooks';

export function updateMessagingApplication(
  companyId: string,
  messagingApplicationId: string,
  messagingApplicationRequestInput: any,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api
    .updateMessagingApplicationForCompany(companyId, messagingApplicationId, messagingApplicationRequestInput)
    .then((res) => res.data);
}

export default function useUpdateMessagingApplication(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; messagingApplicationId: string; messagingApplicationRequestInput: any }) =>
      updateMessagingApplication(values.companyId, values.messagingApplicationId, values.messagingApplicationRequestInput, axiosInstance),
    props
  );
}
