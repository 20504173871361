import React, { useState } from 'react';

import { GOOGLE_PREVIEW_TYPES } from '@app/constants';

import Header from './Header';
import Content from './Content';

const SmartCampignPreview: React.FC = () => {
  const [selectedPlacementType, setSelectedPlacementType] = useState<typeof GOOGLE_PREVIEW_TYPES[keyof typeof GOOGLE_PREVIEW_TYPES]>(
    GOOGLE_PREVIEW_TYPES.SEARCH
  );

  return (
    <div className="flex flex-1 flex-col overflow-hidden h-125 border-gray-300 rounded-3">
      <Header placementType={selectedPlacementType} onPlacementTypeChange={setSelectedPlacementType} />
      <Content placementType={selectedPlacementType} />
    </div>
  );
};

export default SmartCampignPreview;
