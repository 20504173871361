import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { DashboardGMApi } from '@app/api';
import { date } from '@app/lib';
import { useAuth, useAxios } from '@app/hooks';

export function gmDashboard(
  companyId: string,
  startDate: string,
  endDate: string,
  granularity?: 'day' | 'month' | 'week',
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new DashboardGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.gmDashboard(companyId, startDate, endDate, granularity).then((res) => res.data);
}

export default function useGmDashboard({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  const formatedStartDate = date.format(date.JSDateToISO(params.startDate), 'yyyy-MM-dd');
  const formatedEndDate = date.format(date.JSDateToISO(params.endDate), 'yyyy-MM-dd');

  return useQuery(
    ['useGmDashboard', companyId, formatedStartDate, formatedEndDate, params.granularity],
    () => gmDashboard(companyId, formatedStartDate, formatedEndDate, params.granularity, axiosInstance),
    props
  );
}
