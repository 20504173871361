import React from 'react';

import { FacebookProvider, Login } from 'react-facebook';
import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { Icons } from '@app/components';

type FacebookLoginPropTypes = {
  callback: (response: any) => void;
  disabled?: boolean;
};

const ACCOUNT_LIMIT = 10;

const FacebookLogin: React.FC<FacebookLoginPropTypes> = ({ callback, disabled = false }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Facebook.Login',
  };
  const { t } = useLocale();

  function handleResponse(data: any) {
    callback(Object.assign(data?.tokenDetail, data?.profile, data?.error));
  }

  function handleError(response: any) {
    callback({ status: 'unknown' });
    console.error(response);
  }

  return (
    <FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string} version={process.env.NEXT_PUBLIC_FACEBOOK_API_VERSION}>
      <Login
        scope="email,ads_management,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,business_management,catalog_management,leads_retrieval,instagram_basic"
        fields={`name,email,picture,adaccounts.limit(${ACCOUNT_LIMIT}){id,account_id,name,currency}`}
        onCompleted={handleResponse}
        onError={handleError}
      >
        {({ loading, handleClick }: any) => (
          <button
            disabled={loading}
            onClick={handleClick}
            style={{ backgroundColor: '#4267B2' }}
            className={cn('text-white flex items-center px-2.5 py-1.5 rounded-1', {
              'opacity-50 cursor-not-allowed': loading || disabled,
            })}
          >
            <Icons.FacebookSquare className="text-white w-8 h-8 mr-2" />
            <span className="text-4">{t('actions.connect', SCOPE_OPTIONS)}</span>
          </button>
        )}
      </Login>
    </FacebookProvider>
  );
};

export default FacebookLogin;
