import React from 'react';

import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';

type SearchProps = {
  headline?: string;
  description?: string;
};
const Search: React.FC<SearchProps> = ({ headline, description }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SmartCampaignPreview.Content',
  };
  const { t } = useLocale();
  return (
    <div className="w-full border-0.1 border-gray-300 bg-gray-200 px-4 pt-6 rounded-t-10">
      <div className="h-full w-full border-0.1 border-gray-300 bg-white rounded-t-8 p-3">
        <div className="h-10 w-full rounded-10 border-0.1 border-gray-300 justify-between flex">
          <div className="flex items-center pl-3">
            <div className="ml-2 w-35 h-2.5 bg-gray-200"></div>
          </div>
          <div className="w-12 h-full bg-gray-400 rounded-r-10 flex items-center justify-center pr-2">
            <Icons.Search className="w-7 h-7 text-white" />
          </div>
        </div>
        <div className="w-full h-full border-0.1 border-gray-300 mt-3 rounded-2">
          <div className="text-gray-600 py-3 px-2 border-b-[0.1rem] border-gray-300 h-49">
            <div className="flex items-center px-2">
              <span className="text-gray-700 mr-1 text-sm">{t('labels.ad', SCOPE_OPTIONS)}</span>
              <span className="text-sm">{t('labels.adProfile', SCOPE_OPTIONS)}</span>
            </div>
            <div className="flex items-center p-2 pb-0">
              <span className="text-purple-700 mr-1 text-lg line-clamp-2 w-135">{headline ?? t('labels.headline', SCOPE_OPTIONS)}</span>
            </div>
            <div className="flex items-center p-2">
              <span className="text-gray-600 mr-1 text-md line-clamp-3 w-135">{description ?? t('labels.description', SCOPE_OPTIONS)}</span>
            </div>
          </div>
          <div className="h-15 flex items-center text-gray-600 p-3 border-b-[0.1rem] border-gray-300">
            <Icons.LocationPin className="w-7 mr-2" />
            <span>{t('labels.getDirections', SCOPE_OPTIONS)}</span>
          </div>

          <div className="h-15 flex items-center text-gray-600 p-3 border-b-[0.1rem] border-gray-300">
            <Icons.Call className="w-6 mx-2" />
            <span>{t('labels.callBusiness', SCOPE_OPTIONS)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
