import { useEffect, useState } from 'react';

import { Consumer } from '@rails/actioncable';

const loadConsumer = async () => {
  const { createConsumer } = await import('@rails/actioncable');
  return createConsumer;
};

const useActionCable = (token: string | undefined) => {
  const [actionCable, setActionCable] = useState<Consumer | null>(null);

  useEffect(() => {
    if (typeof globalThis.window !== 'undefined' && token !== undefined) {
      loadConsumer().then((createConsumer) => {
        const newUrl = `${process.env.NEXT_PUBLIC_SOCKET_URL}?token=${token}`;
        setActionCable(createConsumer(newUrl));
      });
    }
  }, [globalThis.window, token]);

  useEffect(() => {
    return () => {
      actionCable?.disconnect();
    };
  }, []);

  return {
    actionCable,
  };
};

export default useActionCable;
