/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CompaniesListResponse } from '../model';
// @ts-ignore
import { Company } from '../model';
// @ts-ignore
import { CompanyUpdateMobileApplicationInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
/**
 * CompaniesGMApi - axios parameter creator
 * @export
 */
export const CompaniesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return company of general manager user
         * @summary Return company of general manager user
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralManagerCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getGeneralManagerCompany', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return companies of general manager user
         * @summary Return companies of general manager user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGeneralManagerCompanies: async (page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/gm_user/companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFacebookModalAsFalse: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('markFacebookModalAsFalse', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/mark_force_open_facebook_login_modal_as_false`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update company logo
         * @summary Update company logo
         * @param {string} companyId The id of the company
         * @param {object} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralManagerCompanyLogo: async (companyId: string, logo: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGeneralManagerCompanyLogo', 'companyId', companyId)
            // verify required parameter 'logo' is not null or undefined
            assertParamExists('updateGeneralManagerCompanyLogo', 'logo', logo)
            const localVarPath = `/gm_user/companies/{company_id}/update_logo`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (logo !== undefined) { 
                localVarFormParams.append('logo', new Blob([JSON.stringify(logo)], { type: "application/json", }));
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update mobile application
         * @summary Update mobile application
         * @param {string} companyId The id of the company
         * @param {CompanyUpdateMobileApplicationInput} companyUpdateMobileApplicationInput Update mobile application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileApplication: async (companyId: string, companyUpdateMobileApplicationInput: CompanyUpdateMobileApplicationInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateMobileApplication', 'companyId', companyId)
            // verify required parameter 'companyUpdateMobileApplicationInput' is not null or undefined
            assertParamExists('updateMobileApplication', 'companyUpdateMobileApplicationInput', companyUpdateMobileApplicationInput)
            const localVarPath = `/gm_user/companies/{company_id}/update_mobile_application`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyUpdateMobileApplicationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompaniesGMApi - functional programming interface
 * @export
 */
export const CompaniesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompaniesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Return company of general manager user
         * @summary Return company of general manager user
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGeneralManagerCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGeneralManagerCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return companies of general manager user
         * @summary Return companies of general manager user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGeneralManagerCompanies(page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompaniesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGeneralManagerCompanies(page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async markFacebookModalAsFalse(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.markFacebookModalAsFalse(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update company logo
         * @summary Update company logo
         * @param {string} companyId The id of the company
         * @param {object} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGeneralManagerCompanyLogo(companyId: string, logo: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGeneralManagerCompanyLogo(companyId, logo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update mobile application
         * @summary Update mobile application
         * @param {string} companyId The id of the company
         * @param {CompanyUpdateMobileApplicationInput} companyUpdateMobileApplicationInput Update mobile application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMobileApplication(companyId: string, companyUpdateMobileApplicationInput: CompanyUpdateMobileApplicationInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Company>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMobileApplication(companyId, companyUpdateMobileApplicationInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompaniesGMApi - factory interface
 * @export
 */
export const CompaniesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompaniesGMApiFp(configuration)
    return {
        /**
         * Return company of general manager user
         * @summary Return company of general manager user
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGeneralManagerCompany(companyId: string, options?: any): AxiosPromise<Company> {
            return localVarFp.getGeneralManagerCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return companies of general manager user
         * @summary Return companies of general manager user
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGeneralManagerCompanies(page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CompaniesListResponse> {
            return localVarFp.listGeneralManagerCompanies(page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Mark facebook modal as false
         * @summary Mark facebook modal as false
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        markFacebookModalAsFalse(companyId: string, options?: any): AxiosPromise<Company> {
            return localVarFp.markFacebookModalAsFalse(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update company logo
         * @summary Update company logo
         * @param {string} companyId The id of the company
         * @param {object} logo 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGeneralManagerCompanyLogo(companyId: string, logo: object, options?: any): AxiosPromise<Company> {
            return localVarFp.updateGeneralManagerCompanyLogo(companyId, logo, options).then((request) => request(axios, basePath));
        },
        /**
         * Update mobile application
         * @summary Update mobile application
         * @param {string} companyId The id of the company
         * @param {CompanyUpdateMobileApplicationInput} companyUpdateMobileApplicationInput Update mobile application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMobileApplication(companyId: string, companyUpdateMobileApplicationInput: CompanyUpdateMobileApplicationInput, options?: any): AxiosPromise<Company> {
            return localVarFp.updateMobileApplication(companyId, companyUpdateMobileApplicationInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompaniesGMApi - interface
 * @export
 * @interface CompaniesGMApi
 */
export interface CompaniesGMApiInterface {
    /**
     * Return company of general manager user
     * @summary Return company of general manager user
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApiInterface
     */
    getGeneralManagerCompany(companyId: string, options?: any): AxiosPromise<Company>;

    /**
     * Return companies of general manager user
     * @summary Return companies of general manager user
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApiInterface
     */
    listGeneralManagerCompanies(page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<CompaniesListResponse>;

    /**
     * Mark facebook modal as false
     * @summary Mark facebook modal as false
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApiInterface
     */
    markFacebookModalAsFalse(companyId: string, options?: any): AxiosPromise<Company>;

    /**
     * Update company logo
     * @summary Update company logo
     * @param {string} companyId The id of the company
     * @param {object} logo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApiInterface
     */
    updateGeneralManagerCompanyLogo(companyId: string, logo: object, options?: any): AxiosPromise<Company>;

    /**
     * Update mobile application
     * @summary Update mobile application
     * @param {string} companyId The id of the company
     * @param {CompanyUpdateMobileApplicationInput} companyUpdateMobileApplicationInput Update mobile application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApiInterface
     */
    updateMobileApplication(companyId: string, companyUpdateMobileApplicationInput: CompanyUpdateMobileApplicationInput, options?: any): AxiosPromise<Company>;

}

/**
 * CompaniesGMApi - object-oriented interface
 * @export
 * @class CompaniesGMApi
 * @extends {BaseAPI}
 */
export class CompaniesGMApi extends BaseAPI implements CompaniesGMApiInterface {
    /**
     * Return company of general manager user
     * @summary Return company of general manager user
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApi
     */
    public getGeneralManagerCompany(companyId: string, options?: any) {
        return CompaniesGMApiFp(this.configuration).getGeneralManagerCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return companies of general manager user
     * @summary Return companies of general manager user
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApi
     */
    public listGeneralManagerCompanies(page?: number, perPage?: number, query?: object, options?: any) {
        return CompaniesGMApiFp(this.configuration).listGeneralManagerCompanies(page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Mark facebook modal as false
     * @summary Mark facebook modal as false
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApi
     */
    public markFacebookModalAsFalse(companyId: string, options?: any) {
        return CompaniesGMApiFp(this.configuration).markFacebookModalAsFalse(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update company logo
     * @summary Update company logo
     * @param {string} companyId The id of the company
     * @param {object} logo 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApi
     */
    public updateGeneralManagerCompanyLogo(companyId: string, logo: object, options?: any) {
        return CompaniesGMApiFp(this.configuration).updateGeneralManagerCompanyLogo(companyId, logo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update mobile application
     * @summary Update mobile application
     * @param {string} companyId The id of the company
     * @param {CompanyUpdateMobileApplicationInput} companyUpdateMobileApplicationInput Update mobile application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompaniesGMApi
     */
    public updateMobileApplication(companyId: string, companyUpdateMobileApplicationInput: CompanyUpdateMobileApplicationInput, options?: any) {
        return CompaniesGMApiFp(this.configuration).updateMobileApplication(companyId, companyUpdateMobileApplicationInput, options).then((request) => request(this.axios, this.basePath));
    }
}
