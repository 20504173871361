import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { AudiencesGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function deleteAudience(companyId: string, audienceId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new AudiencesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteAudience(companyId, audienceId).then((res) => res.data);
}

export default function useDeleteAudience(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; audienceId: string }) => deleteAudience(values.companyId, values.audienceId, axiosInstance),
    props
  );
}
