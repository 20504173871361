import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsGMApi } from '@app/api';
import type { UpdateFacebookIntegrationInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function updateGmFacebookIntegration(
  companyId: string,
  updateFacebookIntegrationInput: UpdateFacebookIntegrationInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new IntegrationsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateGmFacebookIntegration(companyId, updateFacebookIntegrationInput).then((res) => res.data);
}

export default function useUpdateGmFacebookIntegration(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useMutation((values: UpdateFacebookIntegrationInput) => updateGmFacebookIntegration(companyId, values, axiosInstance), props);
}
