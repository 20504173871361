/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FacebookReachEstimateInputTargetingGeoLocations } from './facebook-reach-estimate-input-targeting-geo-locations';
import { FacebookReachEstimateInputTargetingInterests } from './facebook-reach-estimate-input-targeting-interests';

/**
 * 
 * @export
 * @interface FacebookReachEstimateInputTargeting
 */
export interface FacebookReachEstimateInputTargeting {
    /**
     * 
     * @type {string}
     * @memberof FacebookReachEstimateInputTargeting
     */
    genders?: FacebookReachEstimateInputTargetingGendersEnum;
    /**
     * 
     * @type {number}
     * @memberof FacebookReachEstimateInputTargeting
     */
    age_min?: number;
    /**
     * 
     * @type {number}
     * @memberof FacebookReachEstimateInputTargeting
     */
    age_max?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof FacebookReachEstimateInputTargeting
     */
    locales?: Array<FacebookReachEstimateInputTargetingLocalesEnum>;
    /**
     * 
     * @type {Array<FacebookReachEstimateInputTargetingInterests>}
     * @memberof FacebookReachEstimateInputTargeting
     */
    interests?: Array<FacebookReachEstimateInputTargetingInterests>;
    /**
     * 
     * @type {FacebookReachEstimateInputTargetingGeoLocations}
     * @memberof FacebookReachEstimateInputTargeting
     */
    geo_locations?: FacebookReachEstimateInputTargetingGeoLocations;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacebookReachEstimateInputTargeting
     */
    publisher_platforms?: Array<FacebookReachEstimateInputTargetingPublisherPlatformsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacebookReachEstimateInputTargeting
     */
    facebook_positions?: Array<FacebookReachEstimateInputTargetingFacebookPositionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FacebookReachEstimateInputTargeting
     */
    instagram_positions?: Array<FacebookReachEstimateInputTargetingInstagramPositionsEnum>;
}

/**
    * @export
    * @enum {string}
    */
export enum FacebookReachEstimateInputTargetingGendersEnum {
    All = 'ALL',
    Males = 'MALES',
    Females = 'FEMALES'
}
/**
    * @export
    * @enum {string}
    */
export enum FacebookReachEstimateInputTargetingLocalesEnum {
    NUMBER_19 = 19,
    NUMBER_6 = 6,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_56 = 56,
    NUMBER_3 = 3,
    NUMBER_4 = 4,
    NUMBER_5 = 5,
    NUMBER_59 = 59,
    NUMBER_23 = 23,
    NUMBER_7 = 7,
    NUMBER_66 = 66,
    NUMBER_8 = 8,
    NUMBER_9 = 9,
    NUMBER_65 = 65,
    NUMBER_30 = 30,
    NUMBER_10 = 10,
    NUMBER_11 = 11,
    NUMBER_12 = 12,
    NUMBER_13 = 13,
    NUMBER_84 = 84,
    NUMBER_14 = 14,
    NUMBER_63 = 63,
    NUMBER_15 = 15,
    NUMBER_16 = 16,
    NUMBER_31 = 31,
    NUMBER_32 = 32,
    NUMBER_17 = 17,
    NUMBER_33 = 33,
    NUMBER_34 = 34,
    NUMBER_18 = 18,
    NUMBER_35 = 35,
    NUMBER_76 = 76,
    NUMBER_20 = 20,
    NUMBER_21 = 21,
    NUMBER_22 = 22,
    NUMBER_36 = 36,
    NUMBER_87 = 87,
    NUMBER_68 = 68,
    NUMBER_53 = 53,
    NUMBER_54 = 54,
    NUMBER_45 = 45,
    NUMBER_55 = 55,
    NUMBER_37 = 37,
    NUMBER_38 = 38,
    NUMBER_83 = 83,
    NUMBER_24 = 24,
    NUMBER_57 = 57,
    NUMBER_58 = 58,
    NUMBER_62 = 62,
    NUMBER_44 = 44,
    NUMBER_72 = 72,
    NUMBER_39 = 39,
    NUMBER_67 = 67,
    NUMBER_46 = 46,
    NUMBER_69 = 69,
    NUMBER_25 = 25,
    NUMBER_64 = 64,
    NUMBER_71 = 71,
    NUMBER_75 = 75,
    NUMBER_73 = 73,
    NUMBER_78 = 78,
    NUMBER_40 = 40,
    NUMBER_79 = 79,
    NUMBER_41 = 41,
    NUMBER_81 = 81,
    NUMBER_80 = 80,
    NUMBER_82 = 82,
    NUMBER_47 = 47,
    NUMBER_42 = 42,
    NUMBER_88 = 88,
    NUMBER_26 = 26,
    NUMBER_48 = 48,
    NUMBER_49 = 49,
    NUMBER_50 = 50,
    NUMBER_52 = 52,
    NUMBER_91 = 91,
    NUMBER_27 = 27,
    NUMBER_74 = 74,
    NUMBER_89 = 89,
    NUMBER_28 = 28,
    NUMBER_29 = 29,
    NUMBER_90 = 90,
    NUMBER_60 = 60,
    NUMBER_85 = 85,
    NUMBER_86 = 86,
    NUMBER_70 = 70,
    NUMBER_1001 = 1001,
    NUMBER_1002 = 1002,
    NUMBER_1003 = 1003,
    NUMBER_1004 = 1004,
    NUMBER_1005 = 1005
}
/**
    * @export
    * @enum {string}
    */
export enum FacebookReachEstimateInputTargetingPublisherPlatformsEnum {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Messenger = 'messenger',
    AudienceNetwork = 'audience_network'
}
/**
    * @export
    * @enum {string}
    */
export enum FacebookReachEstimateInputTargetingFacebookPositionsEnum {
    Feed = 'feed',
    RightHandColumn = 'right_hand_column',
    InstantArticle = 'instant_article',
    Marketplace = 'marketplace',
    VideoFeeds = 'video_feeds',
    Story = 'story',
    Search = 'search',
    InstreamVideo = 'instream_video'
}
/**
    * @export
    * @enum {string}
    */
export enum FacebookReachEstimateInputTargetingInstagramPositionsEnum {
    Stream = 'stream',
    Story = 'story',
    Shop = 'shop',
    Explore = 'explore',
    Reels = 'reels'
}



