import React from 'react';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';

type Attachment = {
  id: string;
  placement_type?: string;
  url: string;
};

type DownloadImagesPropTypes = {
  attachments: Array<Attachment>;
};

const DownloadImages: React.FC<DownloadImagesPropTypes> = ({ attachments }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreviewCampaign.DownloadImages',
  };
  const { t } = useLocale();

  async function onClickDownloadWithZip() {
    const zip = new JSZip();

    for (const attachment of attachments) {
      if (!attachment?.url) {
        continue;
      }

      const blobImage = await fetch(`${attachment.url}?not-from-cache-please`);

      zip.file(`image-${attachment.id}.jpeg`, blobImage.blob());
    }

    zip.generateAsync({ type: 'blob' }).then(function (content: any) {
      saveAs(content, 'images.zip');
    });
  }
  return (
    <button
      className="flex items-center space-x-2 border border-black-800 hover:border-gray-600 hover:text-gray-600 px-3 py-2 w-64 rounded-2 mt-3"
      onClick={onClickDownloadWithZip}
    >
      <Icons.Export className="w-5 h-5" />
      <span className="text-3.5"> {t('actions.downloadImages', SCOPE_OPTIONS)}</span>
    </button>
  );
};

export default DownloadImages;
