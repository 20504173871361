import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TokenGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function getTokenInfo(access_token: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TokenGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.tokenInfo({ access_token: access_token }).then((res) => res);
}

export default function useGetTokenInfo(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({
    authorization: props?.token,
  });

  return useMutation((values: { access_token: string }) => getTokenInfo(values.access_token, axiosInstance), props);
}
