import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Form as FormComponents, Location } from '@app/components';
import { useLocale } from '@app/hooks';
import { phoneNumber, url as urlLib } from '@app/lib';
import { CITIES, DISTRICTS } from '@app/constants';
import { Company } from 'api/model';

type LocationObjectType = {
  lat: number;
  lng: number;
};
type FormPropTypes = {
  autoLocation?: boolean;
  hasOwnerEmail?: boolean;
  isOwnerEmailDisabled?: boolean;
  isEdit?: boolean;
  company?: Company;
  branch_code?: string;
};
const MAX_BRANCH_NAME_LENGTH = 25;
const MAX_ADDRESS_DIRECTION_LENGTH = 25;

const Form: React.FC<FormPropTypes> = ({ isOwnerEmailDisabled = false, hasOwnerEmail, autoLocation, isEdit, company }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Branch.Form',
  };
  const { t } = useLocale();
  const {
    control,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const locationWatch = watch('location');
  const isLocationWatch = watch('is_location');
  const cityWatch = watch('city');

  const isDynamicLocationAdsFeature = company?.feature_flags?.has_dynamic_location_ads_feature ?? false;
  const isBranchType = isLocationWatch === 'false';

  const IS_LOCATION_OPTIONS = [
    { label: t('form.type.options.branch', SCOPE_OPTIONS), value: 'false' },
    { label: t('form.type.options.location', SCOPE_OPTIONS), value: 'true' },
  ];

  function handlePickerChange(data: LocationObjectType) {
    setValue('location.latitude', data.lat);
    setValue('location.longitude', data.lng);
  }

  function getLocation() {
    if (!locationWatch?.latitude || !locationWatch?.longitude) {
      return;
    }

    return {
      lat: locationWatch?.latitude,
      lng: locationWatch?.longitude,
    };
  }

  function handleDropdownSearch(option: { data: { label: string; id: number; value: string } }, query: string) {
    return (
      option.data.label.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      option.data.value.toLocaleLowerCase().includes(query.toLocaleLowerCase()) ||
      option.data.id.toString().includes(query)
    );
  }

  return (
    <form className="bg-white drop-shadow-box py-5 px-4 rounded-3">
      {!isEdit && isDynamicLocationAdsFeature && (
        <div className="flex flex-col mb-4.5">
          <div className="text-3.5 font-semibold text-gray-900 mb-1.5">
            {t('form.type.label', SCOPE_OPTIONS)}
            <span className="text-red-400">*</span>
          </div>
          <FormComponents.RadioGroup name="is_location" options={IS_LOCATION_OPTIONS} register={register} />
        </div>
      )}
      <FormComponents.Input
        type="text"
        id="name"
        label={t(isBranchType ? 'form.name.label' : 'form.name.locationLabel', SCOPE_OPTIONS)}
        className="mb-4.5"
        {...register('name', {
          required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          validate: (value) => {
            if (value.length > MAX_BRANCH_NAME_LENGTH) {
              return t(`form.name.errors.maxValue`, { scope: SCOPE_OPTIONS.scope, value: MAX_BRANCH_NAME_LENGTH });
            }
          },
        })}
        requiredSign={true}
        error={errors.name}
      />
      <FormComponents.PhoneInput
        className="mb-4.5"
        control={control}
        name="phoneNumber"
        label={t(isBranchType ? 'form.phoneNumber.label' : 'form.phoneNumber.locationLabel', SCOPE_OPTIONS)}
        id="phoneNumber"
        rules={{
          required: { value: true, message: t('form.phoneNumber.errors.required', SCOPE_OPTIONS) },
          validate: (phoneNumberString: string) => {
            if (phoneNumber.isValid(phoneNumberString)) {
              return true;
            }

            return t('form.phoneNumber.errors.validate', SCOPE_OPTIONS);
          },
        }}
        requiredSign={true}
        error={errors.phoneNumber}
      />
      <FormComponents.Input
        type="text"
        id="website"
        label={isBranchType ? t('form.website.label', SCOPE_OPTIONS) : t('form.locationWebsite.label', SCOPE_OPTIONS)}
        className="mb-4.5"
        placeholder={t('form.website.placeholder', SCOPE_OPTIONS)}
        {...register('website', {
          required: {
            value: isBranchType,
            message: isBranchType
              ? t('form.website.errors.required', SCOPE_OPTIONS)
              : t('form.locationWebsite.errors.required', SCOPE_OPTIONS),
          },
          pattern: {
            value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\\+.~#?&//=]*)/g,
            message: isBranchType
              ? t('form.website.errors.pattern', SCOPE_OPTIONS)
              : t('form.locationWebsite.errors.pattern', SCOPE_OPTIONS),
          },
          validate: (url) => {
            if (!isBranchType) {
              return true;
            }

            if (urlLib.isValid(url)) {
              return true;
            }

            return t('form.website.errors.validate', SCOPE_OPTIONS);
          },
        })}
        requiredSign={isBranchType}
        error={errors.website}
      />
      <FormComponents.Input
        type="text"
        id="branchCode"
        label={t('form.branchCode.label', SCOPE_OPTIONS)}
        className="mb-4.5"
        {...register('branch_code')}
        error={errors.branch_code}
      />

      {isBranchType && hasOwnerEmail && (
        <FormComponents.Input
          type="text"
          id="ownerEmail"
          disabled={isOwnerEmailDisabled}
          label={t('form.ownerEmail.label', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register('ownerEmail', {
            required: { value: true, message: t('form.ownerEmail.errors.required', SCOPE_OPTIONS) },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: t('form.ownerEmail.errors.pattern', SCOPE_OPTIONS),
            },
          })}
          requiredSign={true}
          error={errors.ownerEmail}
        />
      )}
      <FormComponents.Input
        type="text"
        id="address_direction"
        label={t('form.address_direction.label', SCOPE_OPTIONS)}
        description={t('form.address_direction.description', SCOPE_OPTIONS)}
        className="mb-4.5"
        requiredSign={isBranchType}
        {...register('address_direction', {
          required: { value: isBranchType, message: t('form.address_direction.errors.required', SCOPE_OPTIONS) },
          validate: (value) => {
            if (!isBranchType) {
              return true;
            }
            if (value.length > MAX_ADDRESS_DIRECTION_LENGTH) {
              return t(`form.address_direction.errors.maxValue`, { scope: SCOPE_OPTIONS.scope, value: MAX_ADDRESS_DIRECTION_LENGTH });
            }
          },
        })}
        error={errors.address_direction}
      />
      {isLocationWatch === 'true' && (
        <div className="flex items-center justify-between gap-4 mb-4.5">
          <FormComponents.Select
            id="city"
            className="flex-1"
            label={t('form.city.label', SCOPE_OPTIONS)}
            name="city"
            rules={{
              required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
            }}
            control={control}
            options={CITIES.map((city) => ({ label: city.name, value: city.name, id: city.id }))}
            filterOptions={handleDropdownSearch}
            automaticHandleValue={false}
          />
          <FormComponents.Select
            id="district"
            className="flex-1"
            label={t('form.district.label', SCOPE_OPTIONS)}
            name="district"
            rules={{
              required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
            }}
            control={control}
            disabled={!cityWatch?.id}
            options={DISTRICTS.filter((district) => district.cityId === cityWatch?.id).map((district) => ({
              label: district.name,
              value: district.name,
              id: district.cityId,
            }))}
            filterOptions={handleDropdownSearch}
          />
        </div>
      )}
      <Location.LoadScript>
        <>
          <Location.PlacesAutocomplete onSelect={(address) => setValue('address', address)} onCoordinatesChange={handlePickerChange}>
            {({ onChange, ready }) => {
              return (
                <FormComponents.Textarea
                  id="address"
                  label={t(isBranchType ? 'form.address.label' : 'form.address.locationLabel', SCOPE_OPTIONS)}
                  className="mb-4.5"
                  {...register('address', {
                    onChange: onChange,
                    required: { value: true, message: t('form.address.errors.required', SCOPE_OPTIONS) },
                  })}
                  requiredSign={true}
                  error={errors.address}
                  disabled={!ready}
                />
              );
            }}
          </Location.PlacesAutocomplete>
          <div className="mb-3 h-72 rounded-2.5 overflow-hidden">
            <Location.Picker autoLocation={autoLocation} value={getLocation()} onChange={handlePickerChange} />
          </div>
        </>
      </Location.LoadScript>
    </form>
  );
};

export default Form;
