import { useState, useEffect } from 'react';

function useAdBlock() {
  const [hasAdBlock, setHasAdBlock] = useState(false);

  useEffect(() => {
    async function detect() {
      try {
        // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
        const url = `https://www3.doubleclick.net?=${new Date().getTime()}`;

        await fetch(url, {
          method: 'HEAD',
          mode: 'no-cors',
          cache: 'no-store',
        });

        hasAdBlock && setHasAdBlock(false);
      } catch (error) {
        setHasAdBlock(true);
      }
    }

    detect();
  }, []);

  return hasAdBlock;
}

export default useAdBlock;
