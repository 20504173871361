import AuditableBranch from './AuditableBranch';
import AuditableBranchUser from './AuditableBranchUser';
import AuditableBranchCampaign from './AuditableBranchCampaign';
import AuditableCampaign from './AuditableCampaign';
import AuditableCompany from './AuditableCompany';
import AuditableFacebookIntegration from './AuditableFacebookIntegration';
import AuditableTemplate from './AuditableTemplate';

export default {
  AuditableBranch,
  AuditableBranchUser,
  AuditableBranchCampaign,
  AuditableCampaign,
  AuditableCompany,
  AuditableFacebookIntegration,
  AuditableTemplate,
};
