import { CALL_TO_ACTION, PUBLISHER_PLATFORMS, INSTAGRAM_POSITIONS, FACEBOOK_POSITIONS, AD_FORMAT } from '@app/constants';

function creative(object: any) {
  return {
    storyImage: object?.images?.story?.id ?? null,
    feedImage: object?.images?.feed?.id ?? null,
    link_data: {
      link: 'https://branchsight.com',
      message: getData(object, 'message'),
      name: getData(object, 'headline'),
      description: getData(object, 'description'),
      call_to_action: {
        type: CALL_TO_ACTION.LEARN_MORE,
      },
    },
  };
}

function positions(object: any) {
  if (!Array.isArray(object?.platforms)) {
    return {
      facebookPositions: [],
      instagramPositions: [],
    };
  }

  if (!object.platforms.includes(PUBLISHER_PLATFORMS.INSTAGRAM)) {
    return {
      facebookPositions: [FACEBOOK_POSITIONS.FEED, FACEBOOK_POSITIONS.STORY],
      instagramPositions: [],
    };
  }

  if (!object.platforms.includes(PUBLISHER_PLATFORMS.FACEBOOK)) {
    return {
      facebookPositions: [],
      instagramPositions: [INSTAGRAM_POSITIONS.STORY, INSTAGRAM_POSITIONS.STREAM],
    };
  }

  return {
    facebookPositions: [FACEBOOK_POSITIONS.FEED, FACEBOOK_POSITIONS.STORY],
    instagramPositions: [INSTAGRAM_POSITIONS.STORY, INSTAGRAM_POSITIONS.STREAM],
  };
}

function getData(formData: object, key: string) {
  const property = (formData as any)[key];

  if (!!property && typeof property === 'string' && property !== '') {
    return property;
  }

  return null;
}

function getTitle(selected: string) {
  if (selected === AD_FORMAT.MOBILE_FEED_STANDARD) {
    return {
      platform: 'Facebook',
      place: 'Feed',
    };
  }

  if (selected === AD_FORMAT.FACEBOOK_STORY_MOBILE) {
    return {
      platform: 'Facebook',
      place: 'Story',
    };
  }

  if (selected === AD_FORMAT.INSTAGRAM_STANDARD) {
    return {
      platform: 'Instagram',
      place: 'Feed',
    };
  }

  if (selected === AD_FORMAT.INSTAGRAM_STORY) {
    return {
      platform: 'Instagram',
      place: 'Story',
    };
  }

  return {};
}

function getImage(creative: any, type: string) {
  if ([AD_FORMAT.FACEBOOK_STORY_MOBILE, AD_FORMAT.INSTAGRAM_STORY].includes(type)) {
    return creative?.storyImage ?? creative?.feedImage;
  }

  if ([AD_FORMAT.MOBILE_FEED_STANDARD, AD_FORMAT.INSTAGRAM_STANDARD].includes(type)) {
    return creative?.feedImage ?? creative?.storyImage;
  }

  return null;
}

export default {
  positions,
  creative,
  getTitle,
  getImage,
};
