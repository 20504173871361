import React from 'react';

import { useFormContext } from 'react-hook-form';

import { GOOGLE_PREVIEW_TYPES, GOOGLE_TEXT_TYPE } from '@app/constants';

import Search from './Search';
import Maps from './Maps';

type ContentPropsType = {
  placementType: typeof GOOGLE_PREVIEW_TYPES[keyof typeof GOOGLE_PREVIEW_TYPES];
};

const Content: React.FC<ContentPropsType> = ({ placementType }) => {
  const { getValues } = useFormContext();

  const headlines = Array.from({ length: 3 }, (_, i) => getValues(`creative.${String(GOOGLE_TEXT_TYPE.HEADLINE).toLowerCase()}_${i}`))
    .filter(Boolean)
    .flat();
  const descriptions = Array.from({ length: 3 }, (_, i) => getValues(`creative.${String(GOOGLE_TEXT_TYPE.DESCRIPTION).toLowerCase()}_${i}`))
    .filter(Boolean)
    .flat();

  const headline = headlines?.slice(0, 3).join(' | ');
  const description = descriptions?.slice(0, 3).join('. ');

  return (
    <div className="bg-white h-full pt-3">
      <div className="flex justify-center h-full px-6">
        {placementType === GOOGLE_PREVIEW_TYPES.SEARCH && <Search headline={headline} description={description} />}
        {placementType === GOOGLE_PREVIEW_TYPES.MAPS && <Maps />}
      </div>
    </div>
  );
};

export default Content;
