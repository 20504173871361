import React from 'react';

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { Box, Campaign, Form, Icons } from '@app/components';
import { api, useAuth, useLocale, useToast } from '@app/hooks';
import { BUDGET_LEVELS, DYNAMIC_LOCATION_ADS_LOCATION_TYPES } from '@app/constants';

import type { Template as TemplateType } from '@app/api';
import GroupSelect from './GroupSelect';
import classNames from 'classnames';

const MIN_BUDGET = '1000,00';

type DynamicLocationAdsTemplateFormPropTypes = {
  templates: Array<TemplateType>;
  isEdit?: boolean;
};

const DynamicLocationAdsTemplateForm: React.FC<DynamicLocationAdsTemplateFormPropTypes> = ({ templates = [], isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.DlaTemplateForm',
  };
  const { t, locale } = useLocale();
  const { companyId } = useAuth();
  const {
    watch,
    control,
    setValue,
    register,
    formState: { errors },
  } = useFormContext();
  const { error: errorToast } = useToast();
  const templateIdWatch = watch('templateId');
  const geoLocationTypeWatch = watch('geoLocationType');
  const fileWatch = watch('attachment_file.id');
  const allowedObjectiveWatch = watch('allowedObjective');
  const groupIdsWatch = watch('groups');
  const canDownloadXlsx = !!templateIdWatch && !!geoLocationTypeWatch && !!allowedObjectiveWatch;
  const { campaignBudget = MIN_BUDGET } = watch();
  const budgetLevelWatch = watch('budgetLevel');

  const BUDGET_TYPE_OPTIONS = [
    { label: t('budgetTypes.campaign', SCOPE_OPTIONS), value: 'true', defaultChecked: true },
    { label: t('budgetTypes.adset', SCOPE_OPTIONS), value: 'false', defaultChecked: false },
  ];
  const { mutate: downloadXlsx } = api.useGenerateXlsx({
    onSuccess: (data: BinaryData) => {
      const templateName = templates.find((template) => template.id === templateIdWatch)?.name;
      const fileName = `${templateName} - ${geoLocationTypeWatch}.xlsx`;

      const href = URL.createObjectURL(new Blob([data]));

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
  });

  const { mutate: uploadXlsx, isLoading: uploadLoading } = api.useUploadXlsx({
    onSuccess: (data: any) => {
      setValue('attachment_file.id', data.id);
    },
  });

  function downloadFile(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    e.preventDefault();

    if (!templateIdWatch || !geoLocationTypeWatch) {
      return errorToast(t('form.attachment.error', SCOPE_OPTIONS));
    }
    const groupIds = groupIdsWatch?.map((item: { value: string }) => item.value);

    downloadXlsx({
      companyId,
      generateXlsxInput: {
        template_id: templateIdWatch,
        location_type: geoLocationTypeWatch,
        allowed_objective: allowedObjectiveWatch,
        group_ids: groupIds,
        budget_level: budgetLevelWatch === 'true' ? BUDGET_LEVELS.CAMPAIGN : BUDGET_LEVELS.ADSET,
      },
    });
  }

  function uploadFile(file: File | undefined) {
    if (!templateIdWatch || !geoLocationTypeWatch) {
      return errorToast(t('form.attachment.error', SCOPE_OPTIONS));
    }

    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append('location_type', geoLocationTypeWatch);
    formData.append('file', file);

    uploadXlsx({ companyId, uploadXlsxInput: formData });
  }

  function validateForFileInput(e: React.MouseEvent) {
    if (!templateIdWatch || !geoLocationTypeWatch) {
      e.preventDefault();
      errorToast(t('form.attachment.error', SCOPE_OPTIONS));
      return false;
    }

    return true;
  }

  function fileStatusText() {
    if (uploadLoading) {
      return t('form.locationBudget.loading', SCOPE_OPTIONS);
    }

    if (fileWatch) {
      return t('form.locationBudget.uploaded', SCOPE_OPTIONS);
    }

    return t('form.locationBudget.pending', SCOPE_OPTIONS);
  }

  function replaceAmount(amount: string | number) {
    const cleanAndConvertToNumber = (inputString: string) => {
      const cleanedString = inputString.replace(/[^0-9,-]/g, '').replace(/,../g, '');
      return parseFloat(cleanedString.replace(',', '.'));
    };

    if (typeof amount === 'string') {
      return cleanAndConvertToNumber(amount);
    }

    return amount;
  }

  function formatCurrency(amount: string | number) {
    if (!amount || Number(amount) === 0) {
      return MIN_BUDGET;
    }

    const numericAmount = replaceAmount(amount);

    const formattedNumber = new Intl.NumberFormat(locale, {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(numericAmount);
    return formattedNumber;
  }

  return (
    <>
      <Box>
        <Form.Select
          name="templateId"
          label={t('form.templateId.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.templateId.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={templates.map((template) => ({ value: template.id, label: template.name }))}
          error={errors.templateId}
          disabled={isEdit}
        />
      </Box>
      <Box>
        <Form.Select
          name="geoLocationType"
          label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={[
            { value: DYNAMIC_LOCATION_ADS_LOCATION_TYPES.LOCATION, label: t('form.geoLocationType.options.location', SCOPE_OPTIONS) },
            { value: DYNAMIC_LOCATION_ADS_LOCATION_TYPES.CITY, label: t('form.geoLocationType.options.city', SCOPE_OPTIONS) },
          ]}
          error={errors.templateId}
          disabled={isEdit}
        />
      </Box>
      <Campaign.DynamicLocationAdsObjectivesInput />
      {geoLocationTypeWatch && <GroupSelect isRequired={false} locationType={geoLocationTypeWatch} />}
      <Box>
        <label className="block text-3.5 font-semibold text-gray-900 mb-1">
          {t('form.campaignBudget.label', SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
        <Form.RadioGroup name="budgetLevel" labelStyle="text-3.5" options={BUDGET_TYPE_OPTIONS} register={register} />
        {budgetLevelWatch === 'true' && (
          <Form.BudgetInput
            className="mt-2"
            id="campaignBudget"
            type="text"
            name="campaignBudget"
            control={control}
            rules={{
              validate: (budget: string) => {
                const budgetAmount = replaceAmount(budget);
                const minBudgetAmount = replaceAmount(MIN_BUDGET);
                if (budgetAmount >= minBudgetAmount) {
                  return true;
                }
                return t('form.campaignBudget.errors.min', { ...SCOPE_OPTIONS, value: MIN_BUDGET });
              },
            }}
            defaultValue={`${campaignBudget}`}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const formattedAmount = formatCurrency(event.target.value);
              setValue('campaignBudget', formattedAmount, { shouldValidate: true });
            }}
            requiredSign={true}
            error={errors.campaignBudget}
          />
        )}
      </Box>
      <Box>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <span className="font-medium">
              {t('form.locationBudget.label', SCOPE_OPTIONS)}
              <span className="text-red-500">*</span>
            </span>
            {errors?.attachment_file?.id?.message && fileWatch === undefined && (
              <div className="flex items-center">
                <span className="inline text-red-500 text-3 whitespace-nowrap">{errors.attachment_file.id.message}</span>
              </div>
            )}
          </div>
          <label
            htmlFor="xlsx"
            className={cn('cursor-pointer w-full bg-indigo-100 flex items-center justify-center py-6 rounded-xl border border-gray-400', {
              'opacity-40 cursor-not-allowed': uploadLoading,
            })}
          >
            <Icons.File className={cn('w-6 h-6 mr-2', { 'text-blue-400': !fileWatch, 'text-green-600': fileWatch })} />
            <span className={cn({ 'text-blue-400': !fileWatch, 'text-green-600': fileWatch })}>{fileStatusText()}</span>
          </label>
          <input
            id="xlsx"
            type="file"
            className="hidden"
            onClick={validateForFileInput}
            onChange={(e) => uploadFile(e?.target?.files?.[0])}
          ></input>
          <a
            role="button"
            aria-hidden="true"
            className={classNames('underline cursor-pointer font-medium text-center mt-2', {
              'pointer-events-none cursor-default text-gray-500': !canDownloadXlsx,
            })}
            onClick={downloadFile}
          >
            {t('form.locationBudget.download', SCOPE_OPTIONS)}
          </a>
        </div>
      </Box>
    </>
  );
};

export default DynamicLocationAdsTemplateForm;
