import React, { useState } from 'react';

import cn from 'classnames';

import { Icons } from '@app/components';

type AlertBoxPropTypes = {
  message: string;
  className?: string;
};

const AlertBox: React.FC<AlertBoxPropTypes> = ({ message, className }) => {
  const [canMessageShow, setCanMessageShow] = useState(true);

  if (canMessageShow === false) {
    return null;
  }

  return (
    <div
      className={cn(
        'border border-red-400 border-opacity-40 bg-red-400 bg-opacity-20 w-full shadow-box py-5 px-4 rounded-3 flex flex-row items-start gap-5',
        className
      )}
    >
      <span className="text-red-400 text-3.5">{message}</span>
      <button onClick={() => setCanMessageShow(false)} className="p-2 -mt-2 -mr-2 opacity-70 hover:opacity-100 transition-opacity">
        <Icons.Cross className="text-red-400 h-4.5 w-4.5" />
      </button>
    </div>
  );
};

export default AlertBox;
