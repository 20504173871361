import React, { useState, useEffect } from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { Dropdown, Icons, Preview as PreviewComponents } from '@app/components';
import { PUBLISHER_PLATFORMS } from '@app/constants';
import { useLocale } from '@app/hooks';
import { array } from '@app/lib';

const TEMPLATE_DEMO_IMAGE = '/images/template-demo.png';
const BUTTON_TYPES = {
  FACEBOOK_FEED: 'facebook-feed',
  FACEBOOK_STORY: 'facebook-story',
  INSTAGRAM_FEED: 'instagram-feed',
  INSTAGRAM_STORY: 'instagram-story',
};

const DEFAULT_LINK = 'https://branchsight.com';
const COMPANY_NAME = 'Test Company';

const Preview = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Preview',
  };
  const { t } = useLocale();
  const { watch } = useFormContext();
  const allFieldsWatch = watch();
  const previewProps = {
    companyName: COMPANY_NAME,
    message: getPlacegolder('message'),
    headline: getPlacegolder('headline'),
    description: getPlacegolder('description'),
    image: allFieldsWatch.image?.url ?? TEMPLATE_DEMO_IMAGE,
  };
  const [previewType, setPreviewType] = useState(BUTTON_TYPES.FACEBOOK_FEED);
  const DROPDOWN_BUTTONS = [
    {
      type: PUBLISHER_PLATFORMS.FACEBOOK,
      value: BUTTON_TYPES.FACEBOOK_FEED,
      label: (
        <span className={getButtonStyle(BUTTON_TYPES.FACEBOOK_FEED)}>
          <Icons.Facebook className="fill-current w-5 h-5 mr-2" />
          {t('labels.facebookFeed', SCOPE_OPTIONS)}
        </span>
      ),
    },
    {
      type: PUBLISHER_PLATFORMS.INSTAGRAM,
      value: BUTTON_TYPES.INSTAGRAM_FEED,
      label: (
        <span className={getButtonStyle(BUTTON_TYPES.INSTAGRAM_FEED)}>
          <Icons.Instagram className="fill-current w-5 h-5 mr-2" />
          {t('labels.instagramFeed', SCOPE_OPTIONS)}
        </span>
      ),
    },
    {
      type: PUBLISHER_PLATFORMS.FACEBOOK,
      value: BUTTON_TYPES.FACEBOOK_STORY,
      label: (
        <span className={getButtonStyle(BUTTON_TYPES.FACEBOOK_STORY)}>
          <Icons.Facebook className="fill-current w-5 h-5 mr-2" />
          {t('labels.facebookStory', SCOPE_OPTIONS)}
        </span>
      ),
    },
    {
      type: PUBLISHER_PLATFORMS.INSTAGRAM,
      value: BUTTON_TYPES.INSTAGRAM_STORY,
      label: (
        <span className={getButtonStyle(BUTTON_TYPES.INSTAGRAM_STORY)}>
          <Icons.Instagram className="fill-current w-5 h-5 mr-2" />
          {t('labels.instagramStory', SCOPE_OPTIONS)}
        </span>
      ),
    },
  ].filter((button) => allFieldsWatch?.platforms?.includes(button.type));

  useEffect(() => {
    const foundedButton = DROPDOWN_BUTTONS.find((button) => button.value === previewType);
    const firstButton = array.first(DROPDOWN_BUTTONS);

    if (foundedButton) {
      return;
    }

    if (!firstButton) {
      return;
    }

    setPreviewType(firstButton.value);
  }, [JSON.stringify(DROPDOWN_BUTTONS.map((buttton) => buttton.value))]);

  function getPlacegolder(key: string) {
    const property = allFieldsWatch[key];

    if (!!property && typeof property === 'string' && property !== '') {
      return property;
    }

    if (key === 'link') {
      return DEFAULT_LINK;
    }

    return t(`labels.${key}`, SCOPE_OPTIONS);
  }

  function getButtonStyle(type: string) {
    return cn('text-3.5 font-semibold flex flex-row', {
      'text-gray-900 font-medium': previewType === type,
      'text-gray-500 font-normal': previewType !== type,
    });
  }

  function getLabel(type: string) {
    const button = DROPDOWN_BUTTONS.find((button) => button.value === type);

    if (!button) {
      return null;
    }

    return button.label;
  }

  return (
    <div className="bg-gray-200 h-full w-full flex flex-col">
      <div className="py-5 px-5 border-b-0.5 border-white flex items-center justify-between">
        <span className="text-3.5 text-gray-900 font-semibold">{t('labels.preview', SCOPE_OPTIONS)}</span>
        <div>
          <Dropdown
            button={
              <button className="px-4.5 py-3.5 rounded-3 border border-gray-400 flex flex-row items-center">
                {getLabel(previewType)} <Icons.ChevronDown className="fill-current text-gray-900 w-4 h-4 ml-5" />
              </button>
            }
            items={DROPDOWN_BUTTONS}
            onButtonClick={setPreviewType}
          />
        </div>
      </div>
      <div className="flex items-center justify-center flex-1 py-5">
        {previewType === BUTTON_TYPES.FACEBOOK_FEED && <PreviewComponents.FacebookFeed {...previewProps} />}
        {previewType === BUTTON_TYPES.FACEBOOK_STORY && <PreviewComponents.FacebookStory {...previewProps} />}
        {previewType === BUTTON_TYPES.INSTAGRAM_FEED && <PreviewComponents.InstagramFeed {...previewProps} />}
        {previewType === BUTTON_TYPES.INSTAGRAM_STORY && <PreviewComponents.InstagramStory {...previewProps} />}
      </div>
    </div>
  );
};

export default Preview;
