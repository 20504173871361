import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { IntegrationsGMApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listGmIntegrations(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new IntegrationsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGmIntegrations(companyId).then((res) => res.data);
}

export default function useListGmIntegrations({ automaticToast = true, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({ automaticToast });
  const { companyId } = useAuth();

  return useQuery(['useListGmIntegrations', companyId], () => listGmIntegrations(companyId, axiosInstance), props);
}
