import AdsPlatform from './AdsPlatform';
import BranchPreview from './BranchPreview';
import Card from './Card';
import Form from './Form';
import GoogleForm from './GoogleForm';
import GoogleSmartForm from './GoogleSmartForm';
import DynamicLocationAdsForm from './DynamicLocationAdsForm';
import MetaTemplateForm from './MetaForm';
import Preview from './Preview';

export default { AdsPlatform, BranchPreview, Card, Form, GoogleForm, GoogleSmartForm, Preview, DynamicLocationAdsForm, MetaTemplateForm };
