import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.801 20.802" {...props}>
    <path
      id="Path_2700"
      data-name="Path 2700"
      d="M705.482,542.8a1.029,1.029,0,0,0,1.027-1.028v-1.111l.117-.016a8.3,8.3,0,0,0,7.132-7.1l.016-.117h1.082a1.027,1.027,0,1,0,0-2.054h-1.089l-.016-.115a8.308,8.308,0,0,0-7.125-7.045l-.117-.016v-1.17a1.027,1.027,0,0,0-2.054,0v1.188l-.115.017a8.329,8.329,0,0,0-7,7.026l-.016.115h-1.218a1.027,1.027,0,1,0,0,2.054h1.21l.016.117a8.317,8.317,0,0,0,7,7.083l.115.017v1.13A1.029,1.029,0,0,0,705.482,542.8Zm-1.187-3.773a6.743,6.743,0,0,1-5.361-5.444l-.027-.158h.876a1.027,1.027,0,1,0,0-2.054h-.869l.029-.158a6.757,6.757,0,0,1,5.353-5.385l.16-.03v.9a1.027,1.027,0,0,0,2.054,0v-.916l.157.027a6.731,6.731,0,0,1,5.486,5.4l.029.158h-1a1.027,1.027,0,1,0,0,2.054h1.006l-.027.158a6.723,6.723,0,0,1-5.494,5.464l-.157.026V538.1a1.027,1.027,0,0,0-2.054,0v.958Z"
      transform="translate(-695.082 -521.999)"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
