import React from 'react';

import { Loader } from '@app/components';

type LoginLayoutPropTypes = {
  isLoading?: boolean;
};

import Banner from './Banner';

const LoginLayout: React.FC<LoginLayoutPropTypes> = ({ children, isLoading }) => (
  <div className="flex min-h-full">
    <Banner className="hidden lg:flex w-110 flex-shrink-0" />
    <div className="w-full">{children}</div>
    {isLoading && <Loader />}
  </div>
);

export default LoginLayout;
