/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Audience } from '../model';
// @ts-ignore
import { AudienceCreateInput } from '../model';
// @ts-ignore
import { GroupCreateErrorResponse } from '../model';
// @ts-ignore
import { V2AudiencesListResponse } from '../model';
/**
 * AudiencesGMApi - axios parameter creator
 * @export
 */
export const AudiencesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create audience for company
         * @param {string} companyId The id of the company
         * @param {AudienceCreateInput} audienceCreateInput Create a audience to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudience: async (companyId: string, audienceCreateInput: AudienceCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createAudience', 'companyId', companyId)
            // verify required parameter 'audienceCreateInput' is not null or undefined
            assertParamExists('createAudience', 'audienceCreateInput', audienceCreateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/audiences`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(audienceCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete an audience
         * @summary Delete an audience
         * @param {string} companyId The id of the company
         * @param {string} audienceId The id of the audience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudience: async (companyId: string, audienceId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteAudience', 'companyId', companyId)
            // verify required parameter 'audienceId' is not null or undefined
            assertParamExists('deleteAudience', 'audienceId', audienceId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/audiences/{audience_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"audience_id"}}`, encodeURIComponent(String(audienceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return audiences of company
         * @summary Return audiences of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAudiences: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listAudiences', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/audiences`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AudiencesGMApi - functional programming interface
 * @export
 */
export const AudiencesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AudiencesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create audience for company
         * @param {string} companyId The id of the company
         * @param {AudienceCreateInput} audienceCreateInput Create a audience to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAudience(companyId: string, audienceCreateInput: AudienceCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Audience>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAudience(companyId, audienceCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete an audience
         * @summary Delete an audience
         * @param {string} companyId The id of the company
         * @param {string} audienceId The id of the audience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteAudience(companyId: string, audienceId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteAudience(companyId, audienceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return audiences of company
         * @summary Return audiences of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAudiences(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2AudiencesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAudiences(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AudiencesGMApi - factory interface
 * @export
 */
export const AudiencesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AudiencesGMApiFp(configuration)
    return {
        /**
         * 
         * @summary Create audience for company
         * @param {string} companyId The id of the company
         * @param {AudienceCreateInput} audienceCreateInput Create a audience to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAudience(companyId: string, audienceCreateInput: AudienceCreateInput, options?: any): AxiosPromise<Audience> {
            return localVarFp.createAudience(companyId, audienceCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete an audience
         * @summary Delete an audience
         * @param {string} companyId The id of the company
         * @param {string} audienceId The id of the audience
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAudience(companyId: string, audienceId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteAudience(companyId, audienceId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return audiences of company
         * @summary Return audiences of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAudiences(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<V2AudiencesListResponse> {
            return localVarFp.listAudiences(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AudiencesGMApi - interface
 * @export
 * @interface AudiencesGMApi
 */
export interface AudiencesGMApiInterface {
    /**
     * 
     * @summary Create audience for company
     * @param {string} companyId The id of the company
     * @param {AudienceCreateInput} audienceCreateInput Create a audience to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApiInterface
     */
    createAudience(companyId: string, audienceCreateInput: AudienceCreateInput, options?: any): AxiosPromise<Audience>;

    /**
     * Delete an audience
     * @summary Delete an audience
     * @param {string} companyId The id of the company
     * @param {string} audienceId The id of the audience
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApiInterface
     */
    deleteAudience(companyId: string, audienceId: string, options?: any): AxiosPromise<void>;

    /**
     * Return audiences of company
     * @summary Return audiences of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApiInterface
     */
    listAudiences(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<V2AudiencesListResponse>;

}

/**
 * AudiencesGMApi - object-oriented interface
 * @export
 * @class AudiencesGMApi
 * @extends {BaseAPI}
 */
export class AudiencesGMApi extends BaseAPI implements AudiencesGMApiInterface {
    /**
     * 
     * @summary Create audience for company
     * @param {string} companyId The id of the company
     * @param {AudienceCreateInput} audienceCreateInput Create a audience to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApi
     */
    public createAudience(companyId: string, audienceCreateInput: AudienceCreateInput, options?: any) {
        return AudiencesGMApiFp(this.configuration).createAudience(companyId, audienceCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete an audience
     * @summary Delete an audience
     * @param {string} companyId The id of the company
     * @param {string} audienceId The id of the audience
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApi
     */
    public deleteAudience(companyId: string, audienceId: string, options?: any) {
        return AudiencesGMApiFp(this.configuration).deleteAudience(companyId, audienceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return audiences of company
     * @summary Return audiences of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AudiencesGMApi
     */
    public listAudiences(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return AudiencesGMApiFp(this.configuration).listAudiences(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }
}
