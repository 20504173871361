import React from 'react';

import cn from 'classnames';
import InputRange from 'react-input-range';
import { Controller } from 'react-hook-form';

type RangePropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  defaultValue?: number;
  control: any;
  rules?: any;
  maxValue?: number;
  minValue?: number;
};

export const Range: React.FC<RangePropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  defaultValue,
  control,
  rules,
  maxValue = 100,
  minValue = 0,
}) => {
  const isRequired = getRequiredStatus();

  function getRequiredStatus() {
    if (rules?.required) {
      if (!Object.prototype.hasOwnProperty.call(rules?.required, 'value')) {
        return true;
      }

      if (rules?.required.value === true) {
        return true;
      }

      return false;
    }
    return false;
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {isRequired && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => (
          <div
            className={cn('mt-1.5 h-12.5 px-4 border rounded-2.5 flex items-center', {
              'bg-white border-gray-400': !error,
              'bg-white border-red-400': error,
            })}
          >
            <InputRange maxValue={maxValue} minValue={minValue} value={value} onChange={onChange} />
          </div>
        )}
        defaultValue={defaultValue ?? minValue}
      />
    </div>
  );
};

export default Range;
