/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCampaignAttachment } from './branch-campaign-attachment';
import { BranchCampaignCreative } from './branch-campaign-creative';
import { BranchCampaignExternalIds } from './branch-campaign-external-ids';
import { BranchCampaignTargeting } from './branch-campaign-targeting';
import { Campaign } from './campaign';
import { TemplateExternalErrors } from './template-external-errors';
import { TotalInsightsResponse } from './total-insights-response';

/**
 * 
 * @export
 * @interface BranchCampaign
 */
export interface BranchCampaign {
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    start_time: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    stop_time?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    budget: string;
    /**
     * 
     * @type {Array<TemplateExternalErrors>}
     * @memberof BranchCampaign
     */
    external_errors: Array<TemplateExternalErrors>;
    /**
     * Base campaign name
     * @type {string}
     * @memberof BranchCampaign
     */
    name: string;
    /**
     * 
     * @type {BranchCampaignExternalIds}
     * @memberof BranchCampaign
     */
    external_ids: BranchCampaignExternalIds;
    /**
     * 
     * @type {TotalInsightsResponse}
     * @memberof BranchCampaign
     */
    total_insights: TotalInsightsResponse;
    /**
     * 
     * @type {BranchCampaignCreative}
     * @memberof BranchCampaign
     */
    creative: BranchCampaignCreative;
    /**
     * 
     * @type {Array<BranchCampaignAttachment>}
     * @memberof BranchCampaign
     */
    branch_campaign_attachments?: Array<BranchCampaignAttachment>;
    /**
     * 
     * @type {BranchCampaignTargeting}
     * @memberof BranchCampaign
     */
    targeting: BranchCampaignTargeting;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    status: BranchCampaignStatusEnum;
    /**
     * 
     * @type {Campaign}
     * @memberof BranchCampaign
     */
    parent_campaign: Campaign;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaign
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Running = 'running',
    Rejected = 'rejected',
    Finished = 'finished',
    Paused = 'paused',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Archived = 'archived',
    CancelledPermanently = 'cancelled_permanently'
}



