const LOCALES = Object.freeze([
  { name: 'English (US)', key: 6, short_name: 'en' },
  { name: 'Catalan', key: 1, short_name: 'ca' },
  { name: 'Czech', key: 2, short_name: 'cs' },
  { name: 'Cebuano', key: 56, short_name: 'ceb' },
  { name: 'Welsh', key: 3, short_name: 'cy' },
  { name: 'Danish', key: 4, short_name: 'da' },
  { name: 'German', key: 5, short_name: 'de' },
  { name: 'Basque', key: 59, short_name: 'eu' },
  { name: 'Spanish', key: 23, short_name: 'es' },
  { name: 'Spanish (Spain)', key: 7, short_name: 'es-ES' },
  { name: 'Guarani', key: 66, short_name: 'gn' },
  { name: 'Finnish', key: 8, short_name: 'fi' },
  { name: 'French (France)', key: 9, short_name: 'fr-FR' },
  { name: 'Galician', key: 65, short_name: 'gl' },
  { name: 'Hungarian', key: 30, short_name: 'hu' },
  { name: 'Italian', key: 10, short_name: 'it' },
  { name: 'Japanese', key: 11, short_name: 'ja' },
  { name: 'Korean', key: 12, short_name: 'ko' },
  { name: 'Norwegian (bokmal)', key: 13, short_name: 'nb' },
  { name: 'Norwegian (nynorsk)', key: 84, short_name: 'nn' },
  { name: 'Dutch', key: 14, short_name: 'nl' },
  { name: 'Frisian', key: 63, short_name: 'fy' },
  { name: 'Polish', key: 15, short_name: 'pl' },
  { name: 'Portuguese (Brazil)', key: 16, short_name: 'pt-BR' },
  { name: 'Portuguese (Portugal)', key: 31, short_name: 'pt-PT' },
  { name: 'Romanian', key: 32, short_name: 'ro' },
  { name: 'Russian', key: 17, short_name: 'ru' },
  { name: 'Slovak', key: 33, short_name: 'sk' },
  { name: 'Slovenian', key: 34, short_name: 'sl' },
  { name: 'Swedish', key: 18, short_name: 'sv' },
  { name: 'Thai', key: 35, short_name: 'th' },
  { name: 'Turkish', key: 19, short_name: 'tr' },
  { name: 'Kurdish (Kurmanji)', key: 76, short_name: 'ku' },
  { name: 'Simplified Chinese (China)', key: 20, short_name: 'zh-CN' },
  { name: 'Traditional Chinese (Hong Kong)', key: 21, short_name: 'zh-HK' },
  { name: 'Traditional Chinese (Taiwan)', key: 22, short_name: 'zh-TW' },
  { name: 'Afrikaans', key: 36, short_name: 'af' },
  { name: 'Albanian', key: 87, short_name: 'sq' },
  { name: 'Armenian', key: 68, short_name: 'hy' },
  { name: 'Azerbaijani', key: 53, short_name: 'az' },
  { name: 'Belarusian', key: 54, short_name: 'be' },
  { name: 'Bengali', key: 45, short_name: 'bn' },
  { name: 'Bosnian', key: 55, short_name: 'bs' },
  { name: 'Bulgarian', key: 37, short_name: 'bg' },
  { name: 'Croatian', key: 38, short_name: 'hr' },
  { name: 'Flemish', key: 83, short_name: 'nl-BE' },
  { name: 'English (UK)', key: 24, short_name: 'en-GB' },
  { name: 'Esperanto', key: 57, short_name: 'eo' },
  { name: 'Estonian', key: 58, short_name: 'et' },
  { name: 'Faroese', key: 62, short_name: 'fo' },
  { name: 'French (Canada)', key: 44, short_name: 'fr-CA' },
  { name: 'Georgian', key: 72, short_name: 'ka' },
  { name: 'Greek', key: 39, short_name: 'el' },
  { name: 'Gujarati', key: 67, short_name: 'gu' },
  { name: 'Hindi', key: 46, short_name: 'hi' },
  { name: 'Icelandic', key: 69, short_name: 'is' },
  { name: 'Indonesian', key: 25, short_name: 'id' },
  { name: 'Irish', key: 64, short_name: 'ga' },
  { name: 'Javanese', key: 71, short_name: 'jv' },
  { name: 'Kannada', key: 75, short_name: 'kn' },
  { name: 'Kazakh', key: 73, short_name: 'kk' },
  { name: 'Latvian', key: 78, short_name: 'lv' },
  { name: 'Lithuanian', key: 40, short_name: 'lt' },
  { name: 'Macedonian', key: 79, short_name: 'mk' },
  { name: 'Malay', key: 41, short_name: 'ms' },
  { name: 'Marathi', key: 81, short_name: 'mr' },
  { name: 'Mongolian', key: 80, short_name: 'mn' },
  { name: 'Nepali', key: 82, short_name: 'ne' },
  { name: 'Punjabi', key: 47, short_name: 'pa' },
  { name: 'Serbian', key: 42, short_name: 'sr' },
  { name: 'Swahili', key: 88, short_name: 'sw' },
  { name: 'Filipino', key: 26, short_name: 'fil' },
  { name: 'Tamil', key: 48, short_name: 'ta' },
  { name: 'Telugu', key: 49, short_name: 'te' },
  { name: 'Malayalam', key: 50, short_name: 'ml' },
  { name: 'Ukrainian', key: 52, short_name: 'uk' },
  { name: 'Uzbek', key: 91, short_name: 'uz' },
  { name: 'Vietnamese', key: 27, short_name: 'vi' },
  { name: 'Khmer', key: 74, short_name: 'km' },
  { name: 'Tajik', key: 89, short_name: 'tg' },
  { name: 'Arabic', key: 28, short_name: 'ar' },
  { name: 'Hebrew', key: 29, short_name: 'he' },
  { name: 'Urdu', key: 90, short_name: 'ur' },
  { name: 'Persian', key: 60, short_name: 'fa' },
  { name: 'Pashto', key: 85, short_name: 'ps' },
  { name: 'Sinhala', key: 86, short_name: 'si' },
  { name: 'Japanese (Kansai)', key: 70, short_name: 'ja-KS' },
  { name: 'English (All)', key: 1001, short_name: 'en-ALL' },
  { name: 'Spanish (All)', key: 1002, short_name: 'es-ALL' },
  { name: 'French (All)', key: 1003, short_name: 'fr-ALL' },
  { name: 'Chinese (All)', key: 1004, short_name: 'zh-ALL' },
  { name: 'Portuguese (All)', key: 1005, short_name: 'pt-ALL' },
]);

export default LOCALES;
