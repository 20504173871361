/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CompanyLogo } from './company-logo';

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    language?: ProfileLanguageEnum;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    phone_number?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    utoken?: string;
    /**
     * 
     * @type {CompanyLogo}
     * @memberof Profile
     */
    profile_picture: CompanyLogo;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ProfileLanguageEnum {
    En = 'en',
    Tr = 'tr',
    Ro = 'ro',
    Pl = 'pl',
    El = 'el'
}



