import React from 'react';

import { Box } from '@app/components';
import { api, useAuth, useLocale } from '@app/hooks';

type FacebookPageCardPropTypes = {
  className?: string;
};

const FacebookPageCard: React.FC<FacebookPageCardPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.FacebookPageCard',
  };
  const { t } = useLocale();
  const { companyId } = useAuth();
  const { data: pages } = api.useGetCompanyCurrentFacebookAccount({
    params: {
      companyId: companyId,
    },
  });
  const foundedFacebookPage = pages?.name;

  return (
    <Box className={className}>
      <div className="flex flex-col">
        <span className="font-semibold text-4 text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</span>
        <p className="font-regular text-3.5 text-gray-400">{t('labels.description', SCOPE_OPTIONS)}</p>
      </div>
      <div className="flex flex-row justify-between items-center mt-4.5">
        <span className="font-semibold text-5 text-blue-300">{foundedFacebookPage}</span>
      </div>
    </Box>
  );
};

export default FacebookPageCard;
