import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function listMessagingApplications(
  companyId: string,
  page?: number,
  perPage?: number,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listMessagingApplicationsForCompany(companyId).then((res) => res.data);
}

export default function useListMessagingApplications(props: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListMessagingApplications', companyId, 1, 1],
    () => listMessagingApplications(companyId, 1, 1, axiosInstance),
    props
  );
}
