import cn from 'classnames';

import { POSITIONS, PUBLISHER_PLATFORMS } from '@app/constants';
import { Icons } from '@app/components';

type PreviewHeaderPropTypes = {
  placement: string;
  platform: string;
  isPlatformDisabled?: boolean;
  onPlatformChange: (platform: string) => void;
};

const PreviewHeader: React.FC<PreviewHeaderPropTypes> = ({ placement, platform, isPlatformDisabled, onPlatformChange }) => {
  const Icon = platform === PUBLISHER_PLATFORMS.FACEBOOK ? Icons.Facebook : Icons.Instagram;

  function placementTextByPosition(position: string) {
    const textMap = {
      [POSITIONS.FEED as string]: 'Feed',
      [POSITIONS.STORY as string]: 'Story',
    };

    if (!Object.prototype.hasOwnProperty.call(textMap, position)) {
      return 'Feed';
    }

    return textMap[position];
  }

  function platformTextByPlatform(platform: string) {
    const textMap = {
      [PUBLISHER_PLATFORMS.FACEBOOK as string]: 'Facebook',
      [PUBLISHER_PLATFORMS.INSTAGRAM as string]: 'Instagram',
    };

    if (!Object.prototype.hasOwnProperty.call(textMap, platform)) {
      return 'Facebook';
    }

    return textMap[platform];
  }

  return (
    <div className="flex items-start justify-between w-full mb-4">
      <div className="flex items-center space-x-2">
        <span className="bg-white text-blue-500">
          <Icon className="w-8 h-8" />
        </span>
        <div className="flex flex-col">
          <span className="capitalize">{platformTextByPlatform(platform)}</span>
          <span className="text-gray-500">{placementTextByPosition(placement)}</span>
        </div>
      </div>
      <div className="flex text-gray-500">
        <button
          disabled={isPlatformDisabled}
          className={cn('p-3 rounded-3', {
            'bg-blue-500 text-white': platform === PUBLISHER_PLATFORMS.FACEBOOK,
          })}
          onClick={() => onPlatformChange(PUBLISHER_PLATFORMS.FACEBOOK)}
        >
          <Icons.Facebook className="w-5 h-5" />
        </button>
        <button
          className={cn('p-3 rounded-3', {
            'text-white': platform === PUBLISHER_PLATFORMS.INSTAGRAM,
            'ads-preview-gradient': platform === PUBLISHER_PLATFORMS.INSTAGRAM,
          })}
          onClick={() => onPlatformChange(PUBLISHER_PLATFORMS.INSTAGRAM)}
        >
          <Icons.Instagram className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default PreviewHeader;
