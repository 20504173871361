import React from 'react';

import { useLocale } from '@app/hooks';
import { ACTIVITY_LOG_TYPES } from '@app/constants';
import { date } from '@app/lib';
import type { GmAuditLog as GmAuditLogType } from '@app/api';

import AuditMessageBox from '../AuditMessageBox';

const AuditableFacebookIntegration = (activities: GmAuditLogType | any) => {
  const { t } = useLocale();
  const activitiesData = activities?.activity;

  const time = date.format(activitiesData.created_at, 'dd/MM/yyyy HH:mm');
  const log = activitiesData.log;
  const changedBy = activitiesData.branch_user?.email ?? '';
  const name = activitiesData.company.name;

  function getAuditMessage() {
    if (log === ACTIVITY_LOG_TYPES.COMPANY_FACEBOOK_INTEGRATION_CREATED) {
      return t(`facebookIntegration.created`, { scope: 'activities', by: name, time: time });
    }
    if (log === ACTIVITY_LOG_TYPES.BRANCH_FACEBOOK_INTEGRATION_CREATED) {
      return t(`facebookIntegration.created`, { scope: 'activities', by: changedBy, time: time });
    }
    if (log === ACTIVITY_LOG_TYPES.BRANCH_FACEBOOK_INTEGRATION_UPDATED) {
      return t(`facebookIntegration.updated`, { scope: 'activities', by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_FACEBOOK_INTEGRATION_DESTROYED) {
      return t(`facebookIntegration.deleted`, { scope: 'activities', by: changedBy, time: time });
    }

    return '';
  }

  return <AuditMessageBox getAuditMessage={getAuditMessage} />;
};
export default AuditableFacebookIntegration;
