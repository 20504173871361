/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AccessTokenUpdateInput } from '../model';
// @ts-ignore
import { FacebookCreateInput } from '../model';
// @ts-ignore
import { FacebookIntegrationResponse } from '../model';
// @ts-ignore
import { GoogleCreateInput } from '../model';
// @ts-ignore
import { GoogleIntegrationResponse } from '../model';
/**
 * OmniauthCallbackGMApi - axios parameter creator
 * @export
 */
export const OmniauthCallbackGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Send facebook oauth access token
         * @summary Send facebook oauth access token
         * @param {string} companyId The id of the company
         * @param {AccessTokenUpdateInput} accessTokenUpdateInput Update facebook oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callExchangeTokenForGm: async (companyId: string, accessTokenUpdateInput: AccessTokenUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('callExchangeTokenForGm', 'companyId', companyId)
            // verify required parameter 'accessTokenUpdateInput' is not null or undefined
            assertParamExists('callExchangeTokenForGm', 'accessTokenUpdateInput', accessTokenUpdateInput)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/exchange_token`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accessTokenUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send facebook oauth response
         * @summary Send facebook oauth response
         * @param {string} companyId The id of the company
         * @param {FacebookCreateInput} facebookCreateInput Create a facebook oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callFacebookOAuthCallbackForGm: async (companyId: string, facebookCreateInput: FacebookCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('callFacebookOAuthCallbackForGm', 'companyId', companyId)
            // verify required parameter 'facebookCreateInput' is not null or undefined
            assertParamExists('callFacebookOAuthCallbackForGm', 'facebookCreateInput', facebookCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/callback`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Send google oauth response
         * @summary Send google oauth response
         * @param {string} companyId The id of the company
         * @param {GoogleCreateInput} googleCreateInput Create a google oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callGoogleOAuthCallbackForGm: async (companyId: string, googleCreateInput: GoogleCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('callGoogleOAuthCallbackForGm', 'companyId', companyId)
            // verify required parameter 'googleCreateInput' is not null or undefined
            assertParamExists('callGoogleOAuthCallbackForGm', 'googleCreateInput', googleCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/google/callback`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OmniauthCallbackGMApi - functional programming interface
 * @export
 */
export const OmniauthCallbackGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OmniauthCallbackGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Send facebook oauth access token
         * @summary Send facebook oauth access token
         * @param {string} companyId The id of the company
         * @param {AccessTokenUpdateInput} accessTokenUpdateInput Update facebook oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callExchangeTokenForGm(companyId: string, accessTokenUpdateInput: AccessTokenUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callExchangeTokenForGm(companyId, accessTokenUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send facebook oauth response
         * @summary Send facebook oauth response
         * @param {string} companyId The id of the company
         * @param {FacebookCreateInput} facebookCreateInput Create a facebook oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callFacebookOAuthCallbackForGm(companyId: string, facebookCreateInput: FacebookCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callFacebookOAuthCallbackForGm(companyId, facebookCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Send google oauth response
         * @summary Send google oauth response
         * @param {string} companyId The id of the company
         * @param {GoogleCreateInput} googleCreateInput Create a google oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async callGoogleOAuthCallbackForGm(companyId: string, googleCreateInput: GoogleCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GoogleIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.callGoogleOAuthCallbackForGm(companyId, googleCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OmniauthCallbackGMApi - factory interface
 * @export
 */
export const OmniauthCallbackGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OmniauthCallbackGMApiFp(configuration)
    return {
        /**
         * Send facebook oauth access token
         * @summary Send facebook oauth access token
         * @param {string} companyId The id of the company
         * @param {AccessTokenUpdateInput} accessTokenUpdateInput Update facebook oauth token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callExchangeTokenForGm(companyId: string, accessTokenUpdateInput: AccessTokenUpdateInput, options?: any): AxiosPromise<FacebookIntegrationResponse> {
            return localVarFp.callExchangeTokenForGm(companyId, accessTokenUpdateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Send facebook oauth response
         * @summary Send facebook oauth response
         * @param {string} companyId The id of the company
         * @param {FacebookCreateInput} facebookCreateInput Create a facebook oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callFacebookOAuthCallbackForGm(companyId: string, facebookCreateInput: FacebookCreateInput, options?: any): AxiosPromise<FacebookIntegrationResponse> {
            return localVarFp.callFacebookOAuthCallbackForGm(companyId, facebookCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Send google oauth response
         * @summary Send google oauth response
         * @param {string} companyId The id of the company
         * @param {GoogleCreateInput} googleCreateInput Create a google oauth
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        callGoogleOAuthCallbackForGm(companyId: string, googleCreateInput: GoogleCreateInput, options?: any): AxiosPromise<GoogleIntegrationResponse> {
            return localVarFp.callGoogleOAuthCallbackForGm(companyId, googleCreateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OmniauthCallbackGMApi - interface
 * @export
 * @interface OmniauthCallbackGMApi
 */
export interface OmniauthCallbackGMApiInterface {
    /**
     * Send facebook oauth access token
     * @summary Send facebook oauth access token
     * @param {string} companyId The id of the company
     * @param {AccessTokenUpdateInput} accessTokenUpdateInput Update facebook oauth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApiInterface
     */
    callExchangeTokenForGm(companyId: string, accessTokenUpdateInput: AccessTokenUpdateInput, options?: any): AxiosPromise<FacebookIntegrationResponse>;

    /**
     * Send facebook oauth response
     * @summary Send facebook oauth response
     * @param {string} companyId The id of the company
     * @param {FacebookCreateInput} facebookCreateInput Create a facebook oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApiInterface
     */
    callFacebookOAuthCallbackForGm(companyId: string, facebookCreateInput: FacebookCreateInput, options?: any): AxiosPromise<FacebookIntegrationResponse>;

    /**
     * Send google oauth response
     * @summary Send google oauth response
     * @param {string} companyId The id of the company
     * @param {GoogleCreateInput} googleCreateInput Create a google oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApiInterface
     */
    callGoogleOAuthCallbackForGm(companyId: string, googleCreateInput: GoogleCreateInput, options?: any): AxiosPromise<GoogleIntegrationResponse>;

}

/**
 * OmniauthCallbackGMApi - object-oriented interface
 * @export
 * @class OmniauthCallbackGMApi
 * @extends {BaseAPI}
 */
export class OmniauthCallbackGMApi extends BaseAPI implements OmniauthCallbackGMApiInterface {
    /**
     * Send facebook oauth access token
     * @summary Send facebook oauth access token
     * @param {string} companyId The id of the company
     * @param {AccessTokenUpdateInput} accessTokenUpdateInput Update facebook oauth token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApi
     */
    public callExchangeTokenForGm(companyId: string, accessTokenUpdateInput: AccessTokenUpdateInput, options?: any) {
        return OmniauthCallbackGMApiFp(this.configuration).callExchangeTokenForGm(companyId, accessTokenUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send facebook oauth response
     * @summary Send facebook oauth response
     * @param {string} companyId The id of the company
     * @param {FacebookCreateInput} facebookCreateInput Create a facebook oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApi
     */
    public callFacebookOAuthCallbackForGm(companyId: string, facebookCreateInput: FacebookCreateInput, options?: any) {
        return OmniauthCallbackGMApiFp(this.configuration).callFacebookOAuthCallbackForGm(companyId, facebookCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Send google oauth response
     * @summary Send google oauth response
     * @param {string} companyId The id of the company
     * @param {GoogleCreateInput} googleCreateInput Create a google oauth
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OmniauthCallbackGMApi
     */
    public callGoogleOAuthCallbackForGm(companyId: string, googleCreateInput: GoogleCreateInput, options?: any) {
        return OmniauthCallbackGMApiFp(this.configuration).callGoogleOAuthCallbackForGm(companyId, googleCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
