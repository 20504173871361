/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCampaignCreativeBusinessLocation } from './branch-campaign-creative-business-location';
import { BranchCampaignCreativeFeedItemSets } from './branch-campaign-creative-feed-item-sets';
import { BranchCampaignCreativeLinkData } from './branch-campaign-creative-link-data';

/**
 * 
 * @export
 * @interface BranchCampaignCreative
 */
export interface BranchCampaignCreative {
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignCreative
     */
    objective?: BranchCampaignCreativeObjectiveEnum;
    /**
     * 
     * @type {BranchCampaignCreativeLinkData}
     * @memberof BranchCampaignCreative
     */
    link_data: BranchCampaignCreativeLinkData;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignCreative
     */
    final_url?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignCreative
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignCreative
     */
    business_name?: string;
    /**
     * 
     * @type {BranchCampaignCreativeBusinessLocation}
     * @memberof BranchCampaignCreative
     */
    business_location?: BranchCampaignCreativeBusinessLocation;
    /**
     * 
     * @type {Array<BranchCampaignCreativeFeedItemSets>}
     * @memberof BranchCampaignCreative
     */
    feed_item_sets?: Array<BranchCampaignCreativeFeedItemSets>;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignCreativeObjectiveEnum {
    AppInstalls = 'APP_INSTALLS',
    BrandAwareness = 'BRAND_AWARENESS',
    Conversions = 'CONVERSIONS',
    EventResponses = 'EVENT_RESPONSES',
    LeadGeneration = 'LEAD_GENERATION',
    LinkClicks = 'LINK_CLICKS',
    LocalAwareness = 'LOCAL_AWARENESS',
    Messages = 'MESSAGES',
    OfferClaims = 'OFFER_CLAIMS',
    PageLikes = 'PAGE_LIKES',
    PostEngagement = 'POST_ENGAGEMENT',
    ProductCatalogSales = 'PRODUCT_CATALOG_SALES',
    Reach = 'REACH',
    StoreVisits = 'STORE_VISITS',
    VideoViews = 'VIDEO_VIEWS',
    OutcomeLeads = 'OUTCOME_LEADS',
    OutcomeTraffic = 'OUTCOME_TRAFFIC',
    OutcomeAppPromotion = 'OUTCOME_APP_PROMOTION',
    OutcomeAwareness = 'OUTCOME_AWARENESS',
    OutcomeEngagement = 'OUTCOME_ENGAGEMENT',
    OutcomeSales = 'OUTCOME_SALES'
}



