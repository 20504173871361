import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesGMApi } from '@app/api';
import type { BranchCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createBranch(
  companyId: string,
  branchCreateInput: BranchCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createBranch(companyId, branchCreateInput).then((res) => res.data);
}

export default function useCreateBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; branch: BranchCreateInput }) => createBranch(values.companyId, { branch: values.branch }, axiosInstance),
    props
  );
}
