import React, { forwardRef, useState } from 'react';

import type { FieldError } from 'react-hook-form';
import cn from 'classnames';

type SwitchPropTypes = {
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
};

export const Switch: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<SwitchPropTypes>> = (
  { label, id, error, className, onChange, onBlur, name, ...props },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    setIsFocused(false);
    onBlur && onBlur(event);
  }

  return (
    <div className={cn('flex items-center select-none', className)}>
      <div className="flex justify-center">
        <div className="form-check form-switch">
          <input
            id={id}
            ref={ref}
            onFocus={onFocus}
            name={name}
            onChange={onChange}
            onBlur={handleOnBlur}
            type="checkbox"
            role="switch"
            className={cn(
              'form-check-input appearance-none w-9 -ml-10 rounded-full float-left h-5 align-top bg-white bg-no-repeat bg-contain focus:outline-none cursor-pointer shadow-sm',
              {
                'bg-white border-gray-400': !error,
                'bg-white border-red-400': error,
                'border-gray-600': !error && isFocused,
              }
            )}
            {...props}
          />
          <label className="form-check-label inline-block" htmlFor={id}>
            {label}
          </label>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(Switch);
