import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28" {...props}>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M26.89 3.638c.54-1.616-1.058-3.123-2.639-2.49l-.493.196L2.055 8.092c-1.28.398-1.435 2.144-.247 2.762l10.005 5.2c.032.017.065.033.098.047.014.033.03.065.046.097l5.206 9.995c.618 1.188 2.366 1.032 2.764-.246l6.73-21.612.234-.697zM4.274 9.496a.2.2 0 00-.033.369l8.041 4.18a.2.2 0 00.234-.037l9.706-9.697c.147-.148-.002-.394-.201-.332L4.273 9.496zm14.248 14.235a.2.2 0 01-.368.033l-4.184-8.034a.2.2 0 01.037-.234L23.712 5.8c.147-.148.394.001.332.2l-5.523 17.732z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
