import { useEffect, useState } from 'react';

import cn from 'classnames';
import { useFormContext } from 'react-hook-form';

import { IMAGE_PLACEMENT_TYPE, POSITIONS, PUBLISHER_PLATFORMS } from '@app/constants';
import { useLocale } from '@app/hooks';

import FacebookFeed from './FacebookFeed';
import FacebookStory from './FacebookStory';
import InstagramFeed from './InstagramFeed';
import InstagramStory from './InstagramStory';
import PreviewHeader from './PreviewHeader';
import DownloadImages from './DownloadImages';

const SEE_MORE = 'seeMore';

type AdsPreviewPropTypes = {
  accountInfo?: {
    instagramUsername: string;
    instagramProfile: string;
    facebookUsername: string;
    facebookName: string;
    facebookProfile: string;
  };
};

const AdsPreview: React.FC<AdsPreviewPropTypes> = ({ accountInfo }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreview.index',
  };
  const { t } = useLocale();
  const [selectedPlatform, setSelectedPlatform] = useState<string>(PUBLISHER_PLATFORMS.FACEBOOK);
  const [selectedPlacementType, setSelectedPlacementType] = useState<string>('');
  const { watch } = useFormContext();
  const isFacebookFeed = selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK && selectedPlacementType === POSITIONS.FEED;
  const isFacebookStory = selectedPlatform === PUBLISHER_PLATFORMS.FACEBOOK && selectedPlacementType === POSITIONS.STORY;
  const isInstagramFeed = selectedPlatform === PUBLISHER_PLATFORMS.INSTAGRAM && selectedPlacementType === POSITIONS.FEED;
  const isInstagramStory = selectedPlatform === PUBLISHER_PLATFORMS.INSTAGRAM && selectedPlacementType === POSITIONS.STORY;
  const headlineWatch = watch('headline');
  const messageWatch = watch('message');
  const descriptionWatch = watch('description');
  const feedImageWatch = watch(`images.${IMAGE_PLACEMENT_TYPE.FEED.toLocaleLowerCase()}`);
  const storyImageWatch = watch(`images.${IMAGE_PLACEMENT_TYPE.STORY.toLocaleLowerCase()}`);
  const storyImageUrl = storyImageWatch?.url || storyImageWatch?.file?.url;
  const feedImageUrl = feedImageWatch?.url || feedImageWatch?.file?.url;

  useEffect(() => {
    if (!storyImageUrl && feedImageUrl) {
      setSelectedPlacementType(POSITIONS.FEED);
    } else if (!feedImageUrl && storyImageUrl) {
      setSelectedPlacementType(POSITIONS.STORY);
    } else {
      setSelectedPlacementType(POSITIONS.FEED);
    }
  }, [storyImageUrl, feedImageUrl]);

  if (!storyImageUrl && !feedImageUrl) {
    return (
      <div className="flex items-center justify-center mt-50 ml-50 sticky top-22">
        <span className="px-6 py-3 rounded-3 bg-blue-300 bg-opacity-10 text-blue-300 text-4 select-none">
          {t('labels.empty', SCOPE_OPTIONS)}
        </span>
      </div>
    );
  }

  return (
    <div className="flex flex-col bg-white mt-5 p-4.5 max-w-105 rounded-2 shadow-sm origin-top sticky top-22">
      <div className="flex flex-col items-center">
        <PreviewHeader placement={selectedPlacementType} platform={selectedPlatform} onPlatformChange={setSelectedPlatform} />

        {isFacebookFeed && feedImageUrl && (
          <FacebookFeed
            companyName={accountInfo?.facebookName}
            profilePicture={accountInfo?.facebookProfile}
            image={feedImageUrl || feedImageUrl}
            targetingType={SEE_MORE}
            message={messageWatch}
            headline={headlineWatch}
            description={descriptionWatch}
          />
        )}
        {isInstagramFeed && feedImageUrl && (
          <InstagramFeed
            companyName={accountInfo?.instagramUsername}
            profilePicture={accountInfo?.instagramProfile}
            image={feedImageUrl || feedImageUrl}
            targetingType={SEE_MORE}
            message={messageWatch}
          />
        )}
        {isInstagramStory && storyImageUrl && (
          <InstagramStory
            companyName={accountInfo?.instagramUsername}
            profilePicture={accountInfo?.instagramProfile}
            image={storyImageUrl || storyImageUrl}
            targetingType={SEE_MORE}
          />
        )}
        {isFacebookStory && storyImageUrl && (
          <FacebookStory
            companyName={accountInfo?.facebookName}
            profilePicture={accountInfo?.facebookProfile}
            image={storyImageUrl || storyImageUrl}
            targetingType={SEE_MORE}
          />
        )}
        <div className="flex items-center text-gray-500 mt-4 w-full">
          <button
            className={cn('flex-1 px-4 py-1.5 border-y border-l border-gray-500 rounded-tl-md rounded-bl-md', {
              'bg-black text-white': selectedPlacementType === POSITIONS.FEED,
            })}
            onClick={() => setSelectedPlacementType(POSITIONS.FEED)}
            disabled={!feedImageUrl}
          >
            {t('actions.feed', SCOPE_OPTIONS)}
          </button>
          <button
            className={cn('flex-1 px-4 py-1.5 border-y border-r border-gray-500 rounded-tr-md rounded-br-md', {
              'bg-black text-white': selectedPlacementType === POSITIONS.STORY,
            })}
            onClick={() => setSelectedPlacementType(POSITIONS.STORY)}
            disabled={!storyImageUrl}
          >
            {t('actions.story', SCOPE_OPTIONS)}
          </button>
        </div>
        <DownloadImages
          attachments={[
            { id: feedImageWatch?.id, url: feedImageUrl },
            { id: storyImageWatch?.id, url: storyImageUrl },
          ]}
        />
      </div>
    </div>
  );
};

export default AdsPreview;
