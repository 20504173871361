import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="none" viewBox="0 0 28 28" {...props}>
    <path
      fill="#000"
      fillRule="evenodd"
      d="M11.03 3.926a8.104 8.104 0 000 16.208h5.94c.178 0 .355-.006.53-.017.315-.02.635.078.887.29l2.848 2.383a.2.2 0 00.328-.154v-3.3c0-.389.18-.746.472-.98a8.104 8.104 0 00-5.065-14.43h-5.94zM1 12.03C1 6.49 5.49 2 11.03 2h5.94C22.51 2 27 6.49 27 12.03a10.01 10.01 0 01-3.51 7.622v4.856c0 1.062-1.241 1.642-2.056.96l-4.081-3.416c-.127.005-.255.008-.383.008h-5.94C5.49 22.06 1 17.569 1 12.03z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default SvgComponent;
