/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AcceptAgreementInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InlineObject1 } from '../model';
// @ts-ignore
import { Profile } from '../model';
// @ts-ignore
import { ProfileUpdateErrorResponse } from '../model';
// @ts-ignore
import { UpdateLanguageProfileInput } from '../model';
/**
 * ProfileApi - axios parameter creator
 * @export
 */
export const ProfileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Accept agreements
         * @summary Accept agreements
         * @param {AcceptAgreementInput} acceptAgreementInput Agreement name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAgreementsBranchUser: async (acceptAgreementInput: AcceptAgreementInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'acceptAgreementInput' is not null or undefined
            assertParamExists('acceptAgreementsBranchUser', 'acceptAgreementInput', acceptAgreementInput)
            const localVarPath = `/profile/accept_agreements`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(acceptAgreementInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return current user profile
         * @summary Return current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user fcm token
         * @summary Update current user fcm token
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmTokenProfile: async (inlineObject1: InlineObject1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject1' is not null or undefined
            assertParamExists('updateFcmTokenProfile', 'inlineObject1', inlineObject1)
            const localVarPath = `/profile/sync_fcm_token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user language
         * @summary Update current user language
         * @param {UpdateLanguageProfileInput} updateLanguageProfileInput Language to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguageProfile: async (updateLanguageProfileInput: UpdateLanguageProfileInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLanguageProfileInput' is not null or undefined
            assertParamExists('updateLanguageProfile', 'updateLanguageProfileInput', updateLanguageProfileInput)
            const localVarPath = `/profile/update_language`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLanguageProfileInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update current user information
         * @summary Update current user information
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {object} [profilePicture] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile: async (name?: string, surname?: string, profilePicture?: object, phoneNumber?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (surname !== undefined) { 
                localVarFormParams.append('surname', surname as any);
            }
    
            if (profilePicture !== undefined) { 
                localVarFormParams.append('profile_picture', new Blob([JSON.stringify(profilePicture)], { type: "application/json", }));
            }
    
            if (phoneNumber !== undefined) { 
                localVarFormParams.append('phone_number', phoneNumber as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileApi - functional programming interface
 * @export
 */
export const ProfileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProfileApiAxiosParamCreator(configuration)
    return {
        /**
         * Accept agreements
         * @summary Accept agreements
         * @param {AcceptAgreementInput} acceptAgreementInput Agreement name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async acceptAgreementsBranchUser(acceptAgreementInput: AcceptAgreementInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.acceptAgreementsBranchUser(acceptAgreementInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return current user profile
         * @summary Return current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update current user fcm token
         * @summary Update current user fcm token
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateFcmTokenProfile(inlineObject1: InlineObject1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateFcmTokenProfile(inlineObject1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update current user language
         * @summary Update current user language
         * @param {UpdateLanguageProfileInput} updateLanguageProfileInput Language to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateLanguageProfile(updateLanguageProfileInput: UpdateLanguageProfileInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateLanguageProfile(updateLanguageProfileInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update current user information
         * @summary Update current user information
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {object} [profilePicture] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProfile(name?: string, surname?: string, profilePicture?: object, phoneNumber?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Profile>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProfile(name, surname, profilePicture, phoneNumber, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProfileApi - factory interface
 * @export
 */
export const ProfileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProfileApiFp(configuration)
    return {
        /**
         * Accept agreements
         * @summary Accept agreements
         * @param {AcceptAgreementInput} acceptAgreementInput Agreement name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptAgreementsBranchUser(acceptAgreementInput: AcceptAgreementInput, options?: any): AxiosPromise<Profile> {
            return localVarFp.acceptAgreementsBranchUser(acceptAgreementInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Return current user profile
         * @summary Return current user profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProfile(options?: any): AxiosPromise<Profile> {
            return localVarFp.getProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user fcm token
         * @summary Update current user fcm token
         * @param {InlineObject1} inlineObject1 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFcmTokenProfile(inlineObject1: InlineObject1, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateFcmTokenProfile(inlineObject1, options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user language
         * @summary Update current user language
         * @param {UpdateLanguageProfileInput} updateLanguageProfileInput Language to update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateLanguageProfile(updateLanguageProfileInput: UpdateLanguageProfileInput, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateLanguageProfile(updateLanguageProfileInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update current user information
         * @summary Update current user information
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {object} [profilePicture] 
         * @param {string} [phoneNumber] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProfile(name?: string, surname?: string, profilePicture?: object, phoneNumber?: string, options?: any): AxiosPromise<Profile> {
            return localVarFp.updateProfile(name, surname, profilePicture, phoneNumber, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProfileApi - interface
 * @export
 * @interface ProfileApi
 */
export interface ProfileApiInterface {
    /**
     * Accept agreements
     * @summary Accept agreements
     * @param {AcceptAgreementInput} acceptAgreementInput Agreement name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    acceptAgreementsBranchUser(acceptAgreementInput: AcceptAgreementInput, options?: any): AxiosPromise<Profile>;

    /**
     * Return current user profile
     * @summary Return current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    getProfile(options?: any): AxiosPromise<Profile>;

    /**
     * Update current user fcm token
     * @summary Update current user fcm token
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    updateFcmTokenProfile(inlineObject1: InlineObject1, options?: any): AxiosPromise<Profile>;

    /**
     * Update current user language
     * @summary Update current user language
     * @param {UpdateLanguageProfileInput} updateLanguageProfileInput Language to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    updateLanguageProfile(updateLanguageProfileInput: UpdateLanguageProfileInput, options?: any): AxiosPromise<Profile>;

    /**
     * Update current user information
     * @summary Update current user information
     * @param {string} [name] 
     * @param {string} [surname] 
     * @param {object} [profilePicture] 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApiInterface
     */
    updateProfile(name?: string, surname?: string, profilePicture?: object, phoneNumber?: string, options?: any): AxiosPromise<Profile>;

}

/**
 * ProfileApi - object-oriented interface
 * @export
 * @class ProfileApi
 * @extends {BaseAPI}
 */
export class ProfileApi extends BaseAPI implements ProfileApiInterface {
    /**
     * Accept agreements
     * @summary Accept agreements
     * @param {AcceptAgreementInput} acceptAgreementInput Agreement name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public acceptAgreementsBranchUser(acceptAgreementInput: AcceptAgreementInput, options?: any) {
        return ProfileApiFp(this.configuration).acceptAgreementsBranchUser(acceptAgreementInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return current user profile
     * @summary Return current user profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public getProfile(options?: any) {
        return ProfileApiFp(this.configuration).getProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user fcm token
     * @summary Update current user fcm token
     * @param {InlineObject1} inlineObject1 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateFcmTokenProfile(inlineObject1: InlineObject1, options?: any) {
        return ProfileApiFp(this.configuration).updateFcmTokenProfile(inlineObject1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user language
     * @summary Update current user language
     * @param {UpdateLanguageProfileInput} updateLanguageProfileInput Language to update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateLanguageProfile(updateLanguageProfileInput: UpdateLanguageProfileInput, options?: any) {
        return ProfileApiFp(this.configuration).updateLanguageProfile(updateLanguageProfileInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update current user information
     * @summary Update current user information
     * @param {string} [name] 
     * @param {string} [surname] 
     * @param {object} [profilePicture] 
     * @param {string} [phoneNumber] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileApi
     */
    public updateProfile(name?: string, surname?: string, profilePicture?: object, phoneNumber?: string, options?: any) {
        return ProfileApiFp(this.configuration).updateProfile(name, surname, profilePicture, phoneNumber, options).then((request) => request(this.axios, this.basePath));
    }
}
