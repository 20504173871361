import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookAccountGMApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function getCompanyFacebookAccountPaymentResponse(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookAccountGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCompanyFacebookAccount(companyId).then((res) => res.data);
}
export default function useGetCompanyFacebookAccountPayment({
  automaticToast = true,
  params = {},
  ...props
}: ApiQueryHookPropTypes | undefined): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({ automaticToast });
  const { companyId } = useAuth();

  return useQuery(
    ['useGetCompanyFacebookAccountPayment', companyId ?? params.companyId],
    () => getCompanyFacebookAccountPaymentResponse(companyId ?? params.companyId, axiosInstance),
    props
  );
}
