import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';
import type { DynamicLocationAdTemplateGeneratePreviewInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createFacebookBannerbearTemplatePreview(
  companyId: string,
  previewInput: DynamicLocationAdTemplateGeneratePreviewInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generatePreviewForFacebookTemplate(companyId, previewInput).then((res) => res.data);
}

export default function useCreateMetaBannerbearTemplatePreview(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; previewInput: DynamicLocationAdTemplateGeneratePreviewInput }) =>
      createFacebookBannerbearTemplatePreview(values.companyId, values.previewInput, axiosInstance),
    props
  );
}
