import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.5 4L6.044 2.368C6.1767 1.96959 6.43143 1.62305 6.7721 1.37752C7.11276 1.13198 7.52207 0.9999 7.942 1H11.058C11.4783 0.99948 11.888 1.13136 12.2291 1.37693C12.5702 1.6225 12.8252 1.96927 12.958 2.368L13.5 4M3.5 7V17C3.5 17.5304 3.71071 18.0391 4.08579 18.4142C4.46086 18.7893 4.96957 19 5.5 19H13.5C14.0304 19 14.5391 18.7893 14.9142 18.4142C15.2893 18.0391 15.5 17.5304 15.5 17V7H3.5ZM11.5 8V15V8ZM7.5 8V15V8ZM2 4H17H2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
