const GOOGLE_FONTS = Object.freeze([
  {
    family: 'Poppins',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Lemon',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Yomogi',
    variants: ['regular'],
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Explora',
    variants: ['regular'],
    subsets: ['cherokee', 'latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'DM Sans',
    variants: ['regular', 'italic', '500', '500italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Zhi Mang Xing',
    variants: ['regular'],
    subsets: ['chinese-simplified', 'latin'],
    category: 'handwriting',
  },
  {
    family: 'Trykker',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Twinkle Star',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'IM Fell French Canon',
    variants: ['regular', 'italic'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Pattaya',
    variants: ['regular'],
    subsets: ['cyrillic', 'latin', 'latin-ext', 'thai', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Ubuntu Mono',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext'],
    category: 'monospace',
  },
  {
    family: 'Gorditas',
    variants: ['regular', '700'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Crete Round',
    variants: ['regular', 'italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Noto Serif TC',
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    subsets: ['chinese-traditional', 'latin'],
    category: 'serif',
  },
  {
    family: 'Playfair Display',
    variants: ['regular', '500', '600', '700', '800', '900', 'italic', '500italic', '600italic', '700italic', '800italic', '900italic'],
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Big Shoulders Inline Display',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Sue Ellen Francisco',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Kodchasan',
    variants: ['200', '200italic', '300', '300italic', 'regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Ribeye',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Sans Malayalam',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['malayalam'],
    category: 'sans-serif',
  },
  {
    family: 'Quattrocento Sans',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Samaritan',
    variants: ['regular'],
    subsets: ['samaritan'],
    category: 'sans-serif',
  },
  {
    family: 'Shippori Mincho B1',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Koulen',
    variants: ['regular'],
    subsets: ['khmer', 'latin'],
    category: 'display',
  },
  {
    family: 'Tajawal',
    variants: ['200', '300', 'regular', '500', '700', '800', '900'],
    subsets: ['arabic', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Bungee Hairline',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'The Nautigal',
    variants: ['regular', '700'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Jomolhari',
    variants: ['regular'],
    subsets: ['latin', 'tibetan'],
    category: 'serif',
  },
  {
    family: 'Niconne',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Noto Sans Display',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Numans',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Otomanopee One',
    variants: ['regular'],
    subsets: ['japanese', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Caveat',
    variants: ['regular', '500', '600', '700'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Noto Serif Georgian',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['georgian'],
    category: 'serif',
  },
  {
    family: 'Baloo Chettan 2',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['latin', 'latin-ext', 'malayalam', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Noto Sans SC',
    variants: ['100', '300', 'regular', '500', '700', '900'],
    subsets: ['chinese-simplified', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans HK',
    variants: ['100', '300', 'regular', '500', '700', '900'],
    subsets: ['chinese-hongkong', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Monsieur La Doulaise',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Skranji',
    variants: ['regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Sura',
    variants: ['regular', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Noto Serif Telugu',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['telugu'],
    category: 'serif',
  },
  {
    family: 'Sawarabi Mincho',
    variants: ['regular'],
    subsets: ['japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Bahianita',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'KoHo',
    variants: ['200', '200italic', '300', '300italic', 'regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Chewy',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Playfair Display SC',
    variants: ['regular', 'italic', '700', '700italic', '900', '900italic'],
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Jockey One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Redacted',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Montagu Slab',
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Lexend Tera',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Reem Kufi',
    variants: ['regular', '500', '600', '700'],
    subsets: ['arabic', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Lustria',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Outfit',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Prata',
    variants: ['regular'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Encode Sans Semi Expanded',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Sarabun',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
    ],
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Bilbo Swash Caps',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Jim Nightshade',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Ruslan Display',
    variants: ['regular'],
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Roboto',
    variants: ['100', '100italic', '300', '300italic', 'regular', 'italic', '500', '500italic', '700', '700italic', '900', '900italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Serif Armenian',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['armenian'],
    category: 'serif',
  },
  {
    family: 'Noto Sans Cuneiform',
    variants: ['regular'],
    subsets: ['cuneiform'],
    category: 'sans-serif',
  },
  {
    family: 'Elsie Swash Caps',
    variants: ['regular', '900'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Literata',
    variants: [
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Lexend Zetta',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Space Mono',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'monospace',
  },
  {
    family: 'Kristi',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Neonderthaw',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Preahvihear',
    variants: ['regular'],
    subsets: ['khmer', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Eater',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Palanquin',
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Kolker Brush',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Averia Sans Libre',
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Wire One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Bahiana',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Encode Sans Semi Condensed',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Bowlby One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Ribeye Marrow',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Croissant One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Londrina Outline',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Barrio',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Fontdiner Swanky',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Smooch',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'DM Serif Text',
    variants: ['regular', 'italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Cabin Sketch',
    variants: ['regular', '700'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Stalemate',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Seymour One',
    variants: ['regular'],
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Rock Salt',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Copse',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Barriecito',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Readex Pro',
    variants: ['200', '300', 'regular', '500', '600', '700'],
    subsets: ['arabic', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Convergence',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Osmanya',
    variants: ['regular'],
    subsets: ['osmanya'],
    category: 'sans-serif',
  },
  {
    family: 'Damion',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Sunflower',
    variants: ['300', '500', '700'],
    subsets: ['korean', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Sanchez',
    variants: ['regular', 'italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Abhaya Libre',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['latin', 'latin-ext', 'sinhala'],
    category: 'serif',
  },
  {
    family: 'Macondo',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Noto Sans Pau Cin Hau',
    variants: ['regular'],
    subsets: ['pau-cin-hau'],
    category: 'sans-serif',
  },
  {
    family: 'Baloo Thambi 2',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['latin', 'latin-ext', 'tamil', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Notable',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'IM Fell Double Pica SC',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Aguafina Script',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Rum Raisin',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Style Script',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Work Sans',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Kotta One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Annie Use Your Telescope',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Bebas Neue',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Inknut Antiqua',
    variants: ['300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Yanone Kaffeesatz',
    variants: ['200', '300', 'regular', '500', '600', '700'],
    subsets: ['cyrillic', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Give You Glory',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'IBM Plex Serif',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
    ],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Alice',
    variants: ['regular'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Gideon Roman',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Snowburst One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Concert One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Mogra',
    variants: ['regular'],
    subsets: ['gujarati', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Sansita Swashed',
    variants: ['300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'El Messiri',
    variants: ['regular', '500', '600', '700'],
    subsets: ['arabic', 'cyrillic', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Capriola',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Modern Antiqua',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Cambo',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'IBM Plex Sans Arabic',
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    subsets: ['arabic', 'cyrillic-ext', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Mate',
    variants: ['regular', 'italic'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Nerko One',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Noto Sans Warang Citi',
    variants: ['regular'],
    subsets: ['warang-citi'],
    category: 'sans-serif',
  },
  {
    family: 'Gafata',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Strait',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Glegoo',
    variants: ['regular', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Karma',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Kirang Haerang',
    variants: ['regular'],
    subsets: ['korean', 'latin'],
    category: 'display',
  },
  {
    family: 'Glass Antiqua',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'IM Fell English SC',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Shippori Antique',
    variants: ['regular'],
    subsets: ['japanese', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Cuprum',
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Zen Old Mincho',
    variants: ['regular', '700', '900'],
    subsets: ['cyrillic', 'greek', 'japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Exo',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Hind Guntur',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['latin', 'latin-ext', 'telugu'],
    category: 'sans-serif',
  },
  {
    family: 'Cardo',
    variants: ['regular', 'italic', '700'],
    subsets: ['greek', 'greek-ext', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Alegreya Sans SC',
    variants: [
      '100',
      '100italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Rakkas',
    variants: ['regular'],
    subsets: ['arabic', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Sans Linear A',
    variants: ['regular'],
    subsets: ['linear-a'],
    category: 'sans-serif',
  },
  {
    family: 'Big Shoulders Stencil Display',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Sarpanch',
    variants: ['regular', '500', '600', '700', '800', '900'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'League Script',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Aleo',
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Just Another Hand',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Unkempt',
    variants: ['regular', '700'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Halant',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Noto Sans Meetei Mayek',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['meetei-mayek'],
    category: 'sans-serif',
  },
  {
    family: 'Puritan',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Nastaliq Urdu',
    variants: ['regular', '700'],
    subsets: ['arabic'],
    category: 'serif',
  },
  {
    family: 'Fenix',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Snippet',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Metamorphous',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Diplomata',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Sans Egyptian Hieroglyphs',
    variants: ['regular'],
    subsets: ['egyptian-hieroglyphs'],
    category: 'sans-serif',
  },
  {
    family: 'Sree Krushnadevaraya',
    variants: ['regular'],
    subsets: ['latin', 'telugu'],
    category: 'serif',
  },
  {
    family: 'Kavivanar',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'tamil'],
    category: 'handwriting',
  },
  {
    family: 'Kreon',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Archivo Narrow',
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Kulim Park',
    variants: ['200', '200italic', '300', '300italic', 'regular', 'italic', '600', '600italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Mada',
    variants: ['200', '300', 'regular', '500', '600', '700', '900'],
    subsets: ['arabic', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Hatran',
    variants: ['regular'],
    subsets: ['hatran'],
    category: 'sans-serif',
  },
  {
    family: 'Libre Barcode 128',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Poller One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Noto Sans Coptic',
    variants: ['regular'],
    subsets: ['coptic'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Gothic',
    variants: ['regular'],
    subsets: ['gothic'],
    category: 'sans-serif',
  },
  {
    family: 'Averia Serif Libre',
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Gentium Book Basic',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Dr Sugiyama',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Spirax',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Jura',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'kayah-li', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Average Sans',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Asar',
    variants: ['regular'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Montaga',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'STIX Two Text',
    variants: ['regular', '500', '600', '700', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Adamina',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Yatra One',
    variants: ['regular'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Farsan',
    variants: ['regular'],
    subsets: ['gujarati', 'latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'M PLUS Rounded 1c',
    variants: ['100', '300', 'regular', '500', '700', '800', '900'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'hebrew', 'japanese', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'BenchNine',
    variants: ['300', 'regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Plaster',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Coda Caption',
    variants: ['800'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Encode Sans',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Atomic Age',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Public Sans',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Myanmar',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['myanmar'],
    category: 'sans-serif',
  },
  {
    family: 'Ewert',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Rasa',
    variants: ['300', 'regular', '500', '600', '700', '300italic', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['gujarati', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Averia Libre',
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Miltonian',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Dekko',
    variants: ['regular'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Merienda One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Freckle Face',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Russo One',
    variants: ['regular'],
    subsets: ['cyrillic', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'MuseoModerno',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Ole',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Zen Antique Soft',
    variants: ['regular'],
    subsets: ['cyrillic', 'greek', 'japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Orienta',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Nova Flat',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Coda',
    variants: ['regular', '800'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Molengo',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Metrophobic',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Gupter',
    variants: ['regular', '500', '700'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Modak',
    variants: ['regular'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Sans Soyombo',
    variants: ['regular'],
    subsets: ['soyombo'],
    category: 'sans-serif',
  },
  {
    family: 'Lexend',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Train One',
    variants: ['regular'],
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'News Cycle',
    variants: ['regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Slabo 13px',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Allison',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Shippori Mincho',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Mr De Haviland',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Noto Sans Carian',
    variants: ['regular'],
    subsets: ['carian'],
    category: 'sans-serif',
  },
  {
    family: 'Emilys Candy',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'New Rocker',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Serif Tamil',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['tamil'],
    category: 'serif',
  },
  {
    family: 'Original Surfer',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Felipa',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Dhurjati',
    variants: ['regular'],
    subsets: ['latin', 'telugu'],
    category: 'sans-serif',
  },
  {
    family: 'Bokor',
    variants: ['regular'],
    subsets: ['khmer', 'latin'],
    category: 'display',
  },
  {
    family: 'Noto Sans Chakma',
    variants: ['regular'],
    subsets: ['chakma'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Tifinagh',
    variants: ['regular'],
    subsets: ['tifinagh'],
    category: 'sans-serif',
  },
  {
    family: 'Oooh Baby',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Kaushan Script',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Noto Serif Nyiakeng Puachue Hmong',
    variants: ['regular', '500', '600', '700'],
    subsets: ['nyiakeng-puachue-hmong'],
    category: 'serif',
  },
  {
    family: 'Asap Condensed',
    variants: ['regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Zen Kurenaido',
    variants: ['regular'],
    subsets: ['cyrillic', 'greek', 'japanese', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'IM Fell Great Primer',
    variants: ['regular', 'italic'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Noto Rashi Hebrew',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['hebrew'],
    category: 'serif',
  },
  {
    family: 'Hepta Slab',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Yrsa',
    variants: ['300', 'regular', '500', '600', '700', '300italic', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Nanum Gothic',
    variants: ['regular', '700', '800'],
    subsets: ['korean', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Jacques Francois',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Uncial Antiqua',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Passion One',
    variants: ['regular', '700', '900'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Red Hat Text',
    variants: ['300', 'regular', '500', '600', '700', '300italic', 'italic', '500italic', '600italic', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'BhuTuka Expanded One',
    variants: ['regular'],
    subsets: ['gurmukhi', 'latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Sans Avestan',
    variants: ['regular'],
    subsets: ['avestan'],
    category: 'sans-serif',
  },
  {
    family: 'Alike Angular',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Oleo Script',
    variants: ['regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Almendra',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Barlow Semi Condensed',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Gentium Basic',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Farro',
    variants: ['300', 'regular', '500', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Gurmukhi',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['gurmukhi'],
    category: 'sans-serif',
  },
  {
    family: 'Solway',
    variants: ['300', 'regular', '500', '700', '800'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'BioRhyme Expanded',
    variants: ['200', '300', 'regular', '700', '800'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Nova Mono',
    variants: ['regular'],
    subsets: ['greek', 'latin'],
    category: 'monospace',
  },
  {
    family: 'Noto Sans Mayan Numerals',
    variants: ['regular'],
    subsets: ['mayan-numerals'],
    category: 'sans-serif',
  },
  {
    family: 'Candal',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Goudy Bookletter 1911',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Noto Sans Newa',
    variants: ['regular'],
    subsets: ['newa'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Armenian',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['armenian'],
    category: 'sans-serif',
  },
  {
    family: 'Arima Madurai',
    variants: ['100', '200', '300', 'regular', '500', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'tamil', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Trocchi',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Stick',
    variants: ['regular'],
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Inconsolata',
    variants: ['200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'monospace',
  },
  {
    family: 'Oxanium',
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Noto Serif Sinhala',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['sinhala'],
    category: 'serif',
  },
  {
    family: 'Palanquin Dark',
    variants: ['regular', '500', '600', '700'],
    subsets: ['devanagari', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Odibee Sans',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Marcellus',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Nova Slim',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Herr Von Muellerhoff',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Bona Nova',
    variants: ['regular', 'italic', '700'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'hebrew', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Sora',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Kite One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Siemreap',
    variants: ['regular'],
    subsets: ['khmer'],
    category: 'display',
  },
  {
    family: 'Federo',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Maven Pro',
    variants: ['regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Kanit',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext', 'thai', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Marcellus SC',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Cormorant',
    variants: ['300', '300italic', 'regular', 'italic', '500', '500italic', '600', '600italic', '700', '700italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Noto Sans Nushu',
    variants: ['regular'],
    subsets: ['nushu'],
    category: 'sans-serif',
  },
  {
    family: 'Atkinson Hyperlegible',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Alex Brush',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Manrope',
    variants: ['200', '300', 'regular', '500', '600', '700', '800'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Mrs Saint Delafield',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Lato',
    variants: ['100', '100italic', '300', '300italic', 'regular', 'italic', '700', '700italic', '900', '900italic'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Lovers Quarrel',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Stardos Stencil',
    variants: ['regular', '700'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Noto Sans Rejang',
    variants: ['regular'],
    subsets: ['rejang'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Serif Myanmar',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['myanmar'],
    category: 'serif',
  },
  {
    family: 'Fira Sans Extra Condensed',
    variants: [
      '100',
      '100italic',
      '200',
      '200italic',
      '300',
      '300italic',
      'regular',
      'italic',
      '500',
      '500italic',
      '600',
      '600italic',
      '700',
      '700italic',
      '800',
      '800italic',
      '900',
      '900italic',
    ],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Comic Neue',
    variants: ['300', '300italic', 'regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Limelight',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Berkshire Swash',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'handwriting',
  },
  {
    family: 'Shanti',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
  {
    family: 'Gayathri',
    variants: ['100', 'regular', '700'],
    subsets: ['latin', 'malayalam'],
    category: 'sans-serif',
  },
  {
    family: 'Flamenco',
    variants: ['300', 'regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Vidaloka',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'serif',
  },
  {
    family: 'Lobster Two',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Saira Semi Condensed',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Neucha',
    variants: ['regular'],
    subsets: ['cyrillic', 'latin'],
    category: 'handwriting',
  },
  {
    family: 'Antonio',
    variants: ['100', '200', '300', 'regular', '500', '600', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Montez',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Vast Shadow',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Pinyon Script',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Redacted Script',
    variants: ['300', 'regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Allan',
    variants: ['regular', '700'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Zeyada',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'handwriting',
  },
  {
    family: 'Gurajada',
    variants: ['regular'],
    subsets: ['latin', 'telugu'],
    category: 'serif',
  },
  {
    family: 'Roboto Serif',
    variants: [
      '100',
      '200',
      '300',
      'regular',
      '500',
      '600',
      '700',
      '800',
      '900',
      '100italic',
      '200italic',
      '300italic',
      'italic',
      '500italic',
      '600italic',
      '700italic',
      '800italic',
      '900italic',
    ],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Squada One',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Luxurious Script',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Love Light',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'handwriting',
  },
  {
    family: 'Kosugi',
    variants: ['regular'],
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans Old South Arabian',
    variants: ['regular'],
    subsets: ['old-south-arabian'],
    category: 'sans-serif',
  },
  {
    family: 'Tinos',
    variants: ['regular', 'italic', '700', '700italic'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'hebrew', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Noto Sans Old Italic',
    variants: ['regular'],
    subsets: ['old-italic'],
    category: 'sans-serif',
  },
  {
    family: 'Ma Shan Zheng',
    variants: ['regular'],
    subsets: ['chinese-simplified', 'latin'],
    category: 'handwriting',
  },
  {
    family: 'Cormorant Unicase',
    variants: ['300', 'regular', '500', '600', '700'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'M PLUS 2',
    variants: ['100', '200', '300', 'regular', '500', '600', '700', '800', '900'],
    subsets: ['japanese', 'latin', 'latin-ext', 'vietnamese'],
    category: 'sans-serif',
  },
  {
    family: 'Noto Sans JP',
    variants: ['100', '300', 'regular', '500', '700', '900'],
    subsets: ['japanese', 'latin'],
    category: 'sans-serif',
  },
  {
    family: 'Abril Fatface',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'display',
  },
  {
    family: 'Ruluko',
    variants: ['regular'],
    subsets: ['latin', 'latin-ext'],
    category: 'sans-serif',
  },
  {
    family: 'Kaisei HarunoUmi',
    variants: ['regular', '500', '700'],
    subsets: ['cyrillic', 'japanese', 'latin', 'latin-ext'],
    category: 'serif',
  },
  {
    family: 'Vollkorn SC',
    variants: ['regular', '600', '700', '900'],
    subsets: ['cyrillic', 'cyrillic-ext', 'latin', 'latin-ext', 'vietnamese'],
    category: 'serif',
  },
  {
    family: 'Baloo Bhai 2',
    variants: ['regular', '500', '600', '700', '800'],
    subsets: ['gujarati', 'latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Paprika',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Rowdies',
    variants: ['300', 'regular', '700'],
    subsets: ['latin', 'latin-ext', 'vietnamese'],
    category: 'display',
  },
  {
    family: 'Actor',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'sans-serif',
  },
]);

export default GOOGLE_FONTS;
