import React from 'react';

import { ADS_PLATFORM } from '@app/constants';
import { Button, Icons } from '@app/components';
import cn from 'classnames';
import { useLocale } from '@app/hooks';

type AdsPlatformPropTypes = {
  handlePlatform: (platform: string) => void;
  platform: string;
  page?: string | undefined;
};

const AdsPlatform: React.FC<AdsPlatformPropTypes> = ({ handlePlatform, platform, page }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPlatform',
  };
  const { t } = useLocale();

  function handleRoute() {
    if (page === 'campaigns') {
      return platform === ADS_PLATFORM.google ? `campaigns/google/create` : `campaigns/create`;
    }
    return platform === ADS_PLATFORM.google ? `templates/google/create` : `templates/create`;
  }

  return (
    <>
      <div className="grid grid-cols-2 bg-white gap-7 my-2">
        <button
          className="row-span-2 w-full bg-gray-100 rounded-3 justify-center text-center p-5"
          onClick={() => handlePlatform(ADS_PLATFORM.socialMedia)}
        >
          <div className="relative h-13">
            <Icons.Facebook className="w-13 h-13 text-blue-500 absolute left-13" />
            <Icons.InstagramIcon className="w-13 h-13 absolute left-24 z-3" />
          </div>
          <div className="mt-2 h-15">
            <p className="text-5 font-semibold">Facebook</p>
            <p className="text-5 font-semibold">Instagram</p>
          </div>
          <div className="py-3 flex justify-center items-center">
            <Icons.CheckCircle className={cn('w-9 h-9 text-gray-500', { 'text-blue-500': platform === ADS_PLATFORM.socialMedia })} />
          </div>
        </button>
        <button
          className="row-span-2 w-full bg-gray-100 rounded-3 justify-center text-center p-5"
          onClick={() => handlePlatform(ADS_PLATFORM.google)}
        >
          <div className="h-13 flex justify-center">
            <Icons.Google className="w-13 h-13" />
          </div>
          <div className="mt-2 h-15 flex justify-center items-center">
            <p className="text-5 font-semibold">Google</p>
          </div>
          <div className="py-3 flex justify-center items-center">
            <Icons.CheckCircle className={cn('w-9 h-9 text-gray-500', { 'text-blue-500': platform === ADS_PLATFORM.google })} />
          </div>
        </button>
      </div>
      <div className="flex justify-end items-center">
        <Button
          label={t('labels.continue', SCOPE_OPTIONS)}
          IconRight={Icons.ChevronRight}
          iconClassName="text-black w-3 h-3 ml-2"
          theme="gray"
          className="bg-gray-100 text-black text-4 border-none w-35 rounded-3 hover:bg-gray-300 "
          size="sm"
          href={handleRoute()}
        />
      </div>
    </>
  );
};

export default AdsPlatform;
