import React from 'react';

import { useLocale } from '@app/hooks';
import { ACTIVITY_LOG_TYPES } from '@app/constants';
import { date } from '@app/lib';
import type {
  GmAuditLog as GmAuditLogType,
  AuditableBranch as AuditableBranchType,
  BranchUpdatedAddressLog as BranchUpdatedAddressLogType,
} from '@app/api';
import AuditMessageBox from '../AuditMessageBox';

const AuditableBranch = (activities: GmAuditLogType | any) => {
  const { t } = useLocale();
  const activitiesData = activities?.activity;

  const time = date.format(activitiesData.created_at, 'dd/MM/yyyy HH:mm');
  const log = activitiesData.log;
  const auditable = activitiesData.auditable as AuditableBranchType;
  const changedBy = activitiesData.branch_user?.email ?? '';

  function getAuditMessage() {
    if (log === ACTIVITY_LOG_TYPES.BRANCH_CREATED) {
      return t(`branch.created`, { scope: 'activities', name: auditable.name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_UPDATED) {
      return t(`branch.updated`, { scope: 'activities', name: auditable.name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_ADDRESS_UPDATED) {
      const address = activitiesData.payload as BranchUpdatedAddressLogType;
      return t(`branch.addressChanged`, {
        scope: 'activities',
        name: auditable.name,
        newAddress: address.new_address,
        by: changedBy,
        time: time,
      });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_DESTROYED) {
      return t(`branch.deleted`, { scope: 'activities', name: auditable.name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_FACEBOOK_INTEGRATION_DESTROYED) {
      return t(`facebookIntegration.deleted`, { scope: 'activities', by: changedBy, time: time });
    }

    return '';
  }

  return <AuditMessageBox getAuditMessage={getAuditMessage} />;
};
export default AuditableBranch;
