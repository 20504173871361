import { useEffect } from 'react';

import { useTable, usePagination, useSortBy } from 'react-table';
import cn from 'classnames';

import { COLORS } from '@app/constants';
import { Pagination } from '@app/components';
import { array } from '@app/lib';

import SortIcon from './SortIcon';

type TablePropTypes = {
  className?: string;
  columns: any;
  data: any;
  pageSize?: number;
  totalPageCount?: number;
  currentPage?: number;
  onPageChange?: (index: number) => void;
  onSortChange?: (query: string | null) => void;
  defaultSortBy?: { id: string; desc: boolean }[];
  manualSortBy?: boolean;
  disableSortBy?: boolean;
  EmptyComponent?: () => JSX.Element;
};

const SORT_TYPE = {
  DESC: 'desc',
  ASC: 'asc',
};

const Table: React.FC<TablePropTypes> = ({
  className,
  columns,
  data,
  pageSize = 999999,
  totalPageCount = 1,
  currentPage = 1,
  onPageChange,
  onSortChange,
  defaultSortBy = [],
  manualSortBy = true,
  disableSortBy = false,
  EmptyComponent,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex, sortBy },
  } = useTable(
    {
      columns,
      data: data ?? [],
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: pageSize,
        sortBy: defaultSortBy,
      },
      manualSortBy: manualSortBy,
      disableSortBy: disableSortBy,
      manualPagination: true,
      pageCount: totalPageCount,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (!array.isEmpty(sortBy)) {
      const item = array.first(sortBy);
      const sortType = item.desc ? SORT_TYPE.DESC : SORT_TYPE.ASC;
      onSortChange && onSortChange(`${item.id} ${sortType}`);
      return;
    }

    onSortChange && onSortChange(null);
  }, [onSortChange, JSON.stringify(sortBy)]);

  useEffect(() => {
    onPageChange && onPageChange(pageIndex + 1);
  }, [onPageChange, pageIndex]);

  function getSortIcon(isSortedDesc: boolean | undefined) {
    if (isSortedDesc === true) {
      return <SortIcon downColor={COLORS.BLUE_300} className="fill-current text-gray-500" />;
    }

    if (isSortedDesc === false) {
      return <SortIcon upColor={COLORS.BLUE_300} className="fill-current text-gray-500" />;
    }

    return <SortIcon className="fill-current text-gray-500" />;
  }

  const Header = () => {
    return (
      <thead>
        {headerGroups.map((headerGroup, headerGroupIndex) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={`headerGroups-${headerGroupIndex}`}>
            {headerGroup.headers.map((column, headerColumnIndex) => (
              <th
                scope="col"
                className={cn('px-4 py-3 text-left text-xs font-semibold text-gray-500 uppercase bg-gray-300', {
                  'rounded-tl-3': headerColumnIndex === 0,
                  'rounded-tr-3': headerColumnIndex === headerGroup.headers.length - 1,
                })}
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={`headerColumn-${headerColumnIndex}`}
              >
                <div className="flex flex-row justify-start items-center select-none whitespace-nowrap">
                  {column.render('Header')}
                  {column.canSort && <span className="ml-1">{getSortIcon(column.isSortedDesc)}</span>}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
    );
  };

  const Body = () => {
    return (
      <tbody {...getTableBodyProps()} className="divide-y divide-gray-300">
        {rows.map((row, rowIndex) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
              {row.cells.map((cell, cellIndex) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    key={`cell-${cellIndex}`}
                    className={cn('p-4 font-medium whitespace-nowrap text-sm text-gray-900 bg-white', {
                      'rounded-bl-3': pageCount <= 1 && rows.length === rowIndex + 1 && cellIndex === 0,
                      'rounded-br-3': pageCount <= 1 && rows.length === rowIndex + 1 && cellIndex === row.cells.length - 1,
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    );
  };

  if (array.isEmpty(data)) {
    if (!EmptyComponent) {
      return null;
    }

    return <EmptyComponent />;
  }

  return (
    <div className={cn('flex flex-col drop-shadow-box', className)}>
      <div>
        <table {...getTableProps()} className="min-h-full min-w-full">
          <Header />
          {!array.isEmpty(data) && <Body />}
        </table>
      </div>
      {!array.isEmpty(data) && pageCount > 1 && (
        <div className="bg-white rounded-b-3 py-3 flex items-center justify-center border-t border-gray-300">
          <Pagination
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            currentPage={pageIndex + 1}
            totalPageCount={pageCount}
            onPageChange={(index) => gotoPage(index - 1)}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
