import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function showContactForm(companyId: string, contactFormId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ContactFormGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showContactFormForCompany(companyId, contactFormId).then((res) => res.data);
}

export default function useShowContactForm({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useShowContactForm', companyId, params.contactFormId],
    () => showContactForm(companyId, params.contactFormId, axiosInstance),
    props
  );
}
