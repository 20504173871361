import React, { useState, useContext, createContext } from 'react';

import Cookies from 'js-cookie';

import type { GmUserTokenSuccessResponse } from '@app/api';
import { COOKIE_KEYS } from '@app/constants';
import { api } from '@app/hooks';
import { Company as CompanyType } from '@app/api';

type ReturnTypes = {
  company: CompanyType;
  companyId: string;
  token: GmUserTokenSuccessResponse | null;
  storeToken: (token: GmUserTokenSuccessResponse) => void;
  storeCompany: (company: CompanyType) => void;
  refreshToken: () => void;
  signOut: () => void;
  isRefreshTokenLoading: boolean;
};

const REFRESH_TOKEN_GRANT_TYPE = 'refresh_token';
const cookieToken = Cookies.get(COOKIE_KEYS.TOKEN);
const parsedToken = cookieToken ? JSON.parse(cookieToken) : null;

const cookieCompanyId = Cookies.get(COOKIE_KEYS.COMPANY_ID);
const parsedCompanyId = cookieCompanyId ? JSON.parse(cookieCompanyId) : null;

const cookieCompany = Cookies.get(COOKIE_KEYS.COMPANY);
const parsedCompany = cookieCompany ? JSON.parse(cookieCompany) : null;

const AuthContext = createContext({} as ReturnTypes);

export const AuthProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<GmUserTokenSuccessResponse | null>(parsedToken);
  const [companyId, setCompanyId] = useState<string>(parsedCompanyId);
  const [company, setCompany] = useState<CompanyType>(parsedCompany);
  const { mutate, isLoading: isRefreshTokenLoading } = api.useGeneralManagerUserLogin({
    onSuccess: (data: GmUserTokenSuccessResponse) => {
      storeToken(data);

      window.location.reload();
    },
    onError: () => signOut(),
  });

  function signOut() {
    Cookies.remove(COOKIE_KEYS.TOKEN);
    Cookies.remove(COOKIE_KEYS.COMPANY);
    if (token !== null) {
      window.location.reload();
    }
  }

  function storeToken(token: GmUserTokenSuccessResponse) {
    setToken(token);
    Cookies.set(COOKIE_KEYS.TOKEN, JSON.stringify(token));
  }

  function refreshToken() {
    if (token === null) {
      signOut();
      return;
    }

    mutate({
      grant_type: REFRESH_TOKEN_GRANT_TYPE,
      refresh_token: token?.refresh_token,
      client_id: process.env.NEXT_PUBLIC_API_CLIENT_ID,
      client_secret: process.env.NEXT_PUBLIC_API_CLIENT_SECRET,
    });
  }

  function storeCompany(company: CompanyType) {
    setCompanyId(company.id);
    setCompany(company);
    Cookies.set(COOKIE_KEYS.COMPANY_ID, JSON.stringify(company.id));
    Cookies.set(COOKIE_KEYS.COMPANY, JSON.stringify(company));
  }

  if (token === undefined) {
    return null;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        companyId,
        company,
        storeToken,
        storeCompany,
        refreshToken,
        isRefreshTokenLoading,
        signOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth(): ReturnTypes {
  return useContext(AuthContext);
}
