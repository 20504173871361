import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.5002 12.5009L7.50008 5.5008M2 18.001V14.3759C2 14.1079 2.106 13.8509 2.296 13.6609L13.1192 2.83776C14.2362 1.72075 16.0462 1.72075 17.1622 2.83776C18.2793 3.95478 18.2793 5.76481 17.1622 6.88083L6.33907 17.705C6.15006 17.895 5.89306 18.001 5.62506 18.001H2Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
