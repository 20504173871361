import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 10.1516H22.1639M16.0027 1.75V5.11066M8.16114 1.75V5.11066M18.8033 22.25H5.36066C4.46936 22.25 3.61456 21.8959 2.98431 21.2657C2.35407 20.6354 2 19.7807 2 18.8893V6.79098C2 5.89968 2.35407 5.04489 2.98431 4.41464C3.61456 3.78439 4.46936 3.43033 5.36066 3.43033H18.8033C19.6946 3.43033 20.5494 3.78439 21.1796 4.41464C21.8099 5.04489 22.1639 5.89968 22.1639 6.79098V18.8893C22.1639 19.7807 21.8099 20.6354 21.1796 21.2657C20.5494 21.8959 19.6946 22.25 18.8033 22.25Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
