/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface GenerateXlsxFileAttachmentInput
 */
export interface GenerateXlsxFileAttachmentInput {
    /**
     * 
     * @type {string}
     * @memberof GenerateXlsxFileAttachmentInput
     */
    template_id: string;
    /**
     * 
     * @type {string}
     * @memberof GenerateXlsxFileAttachmentInput
     */
    location_type: GenerateXlsxFileAttachmentInputLocationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof GenerateXlsxFileAttachmentInput
     */
    allowed_objective?: GenerateXlsxFileAttachmentInputAllowedObjectiveEnum;
    /**
     * Groups
     * @type {Array<string>}
     * @memberof GenerateXlsxFileAttachmentInput
     */
    group_ids?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GenerateXlsxFileAttachmentInput
     */
    budget_level?: GenerateXlsxFileAttachmentInputBudgetLevelEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum GenerateXlsxFileAttachmentInputLocationTypeEnum {
    Location = 'location',
    City = 'city'
}
/**
    * @export
    * @enum {string}
    */
export enum GenerateXlsxFileAttachmentInputAllowedObjectiveEnum {
    FinalUrl = 'FINAL_URL',
    InstagramVisit = 'INSTAGRAM_VISIT',
    CallNow = 'CALL_NOW',
    IosAppInstalls = 'IOS_APP_INSTALLS',
    AndroidAppInstalls = 'ANDROID_APP_INSTALLS'
}
/**
    * @export
    * @enum {string}
    */
export enum GenerateXlsxFileAttachmentInputBudgetLevelEnum {
    Adset = 'ADSET',
    Campaign = 'CAMPAIGN'
}



