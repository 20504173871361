import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function listTemplates(
  companyId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listTemplatesByCompany(companyId, page, perPage, query).then((res) => res.data);
}

export default function useListTemplates(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  const { companyId } = useAuth();
  return useQuery(
    ['useListTemplates', companyId, props?.params.page, props?.params.perPage, props?.params.query],
    () => listTemplates(companyId, props?.params.page, props?.params.perPage, props?.params.query, axiosInstance),
    props
  );
}
