import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="29" height="36" viewBox="0 0 29 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M28.8242 11.8548C28.7383 11.6594 28.618 11.4808 28.4689 11.3274L17.5939 0.5274C17.4394 0.379408 17.2596 0.259941 17.0629 0.1746C17.0085 0.1494 16.9505 0.135 16.8925 0.1152C16.7408 0.0639471 16.583 0.0330735 16.4231 0.0234C16.385 0.0198 16.3506 0 16.3125 0H3.625C1.62581 0 0 1.6146 0 3.6V32.4C0 34.3854 1.62581 36 3.625 36H25.375C27.3742 36 29 34.3854 29 32.4V12.6C29 12.5622 28.9801 12.528 28.9764 12.4884C28.9676 12.3295 28.9365 12.1726 28.884 12.0222C28.8659 11.9646 28.8496 11.9088 28.8242 11.8548ZM22.8121 10.8H18.125V6.1452L22.8121 10.8ZM3.625 32.4V3.6H14.5V12.6C14.5 13.0774 14.691 13.5352 15.0309 13.8728C15.3708 14.2104 15.8318 14.4 16.3125 14.4H25.375L25.3786 32.4H3.625Z"
      fill="currentColor"
    />
    <path d="M7 18.2H21V21.8H7V18.2ZM7 25.4H21V29H7V25.4ZM7 11H10.5V14.6H7V11Z" fill="currentColor" />
  </svg>
);

export default SvgComponent;
