import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAuth, useAxios } from '@app/hooks';
import { FileManagerGMApi } from 'api/api';

export async function getCompanyFile(
  companyId: string,
  currentFolder: string,
  objectName: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FileManagerGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  const { data } = await api.listFilesForCompany(companyId, currentFolder, objectName);
  return data;
}

export default function useGetCompanyFile({
  automaticToast = true,
  params = {},
}: ApiQueryHookPropTypes | undefined): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({ automaticToast });
  const { companyId } = useAuth();
  return useQuery({
    queryKey: ['getCompaniesFile', companyId ?? params.companyId, params.currentFolder, params.objectName],
    queryFn: () => getCompanyFile(companyId ?? params.companyId, params.currentFolder, params.objectName, axiosInstance),
  });
}
