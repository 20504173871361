/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DashboardInsightResponse } from '../model';
/**
 * DashboardGMApi - axios parameter creator
 * @export
 */
export const DashboardGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return dashboard insights
         * @summary Return dashboard insights
         * @param {string} companyId The id of the company
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmDashboard: async (companyId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmDashboard', 'companyId', companyId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('gmDashboard', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('gmDashboard', 'endDate', endDate)
            const localVarPath = `/gm_user/companies/{company_id}/dashboard`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DashboardGMApi - functional programming interface
 * @export
 */
export const DashboardGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DashboardGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Return dashboard insights
         * @summary Return dashboard insights
         * @param {string} companyId The id of the company
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmDashboard(companyId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DashboardInsightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmDashboard(companyId, startDate, endDate, granularity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DashboardGMApi - factory interface
 * @export
 */
export const DashboardGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DashboardGMApiFp(configuration)
    return {
        /**
         * Return dashboard insights
         * @summary Return dashboard insights
         * @param {string} companyId The id of the company
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmDashboard(companyId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<DashboardInsightResponse> {
            return localVarFp.gmDashboard(companyId, startDate, endDate, granularity, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DashboardGMApi - interface
 * @export
 * @interface DashboardGMApi
 */
export interface DashboardGMApiInterface {
    /**
     * Return dashboard insights
     * @summary Return dashboard insights
     * @param {string} companyId The id of the company
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardGMApiInterface
     */
    gmDashboard(companyId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<DashboardInsightResponse>;

}

/**
 * DashboardGMApi - object-oriented interface
 * @export
 * @class DashboardGMApi
 * @extends {BaseAPI}
 */
export class DashboardGMApi extends BaseAPI implements DashboardGMApiInterface {
    /**
     * Return dashboard insights
     * @summary Return dashboard insights
     * @param {string} companyId The id of the company
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DashboardGMApi
     */
    public gmDashboard(companyId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any) {
        return DashboardGMApiFp(this.configuration).gmDashboard(companyId, startDate, endDate, granularity, options).then((request) => request(this.axios, this.basePath));
    }
}
