import React, { memo, useEffect, useState, useImperativeHandle, forwardRef } from 'react';

import { AD_FORMAT, FACEBOOK_POSITIONS, INSTAGRAM_POSITIONS } from '@app/constants';
import { facebookPreview } from '@app/lib';
import { Icons } from '@app/components';
import { useLocale } from '@app/hooks';

import ButtonWrapper from './ButtonWrapper';
import GenerateItem from './GenerateItem';
import PreviewItem from './PreviewItem';
import ResetButton from './ResetButton';

type PreviewsPropTypes = {
  autoReload?: boolean;
  templateId?: string;
  creative?: any;
  facebookPositions: string[];
  instagramPositions: string[];
};

type PreviewsRefTypes = {
  changeSelected: (preview: string) => void;
  getSelected: () => string;
};

const Previews: React.ForwardRefRenderFunction<PreviewsRefTypes, React.PropsWithChildren<PreviewsPropTypes>> = (
  {
    autoReload = false,
    templateId,
    creative,
    facebookPositions = [FACEBOOK_POSITIONS.FEED, FACEBOOK_POSITIONS.STORY],
    instagramPositions = [INSTAGRAM_POSITIONS.STREAM, INSTAGRAM_POSITIONS.STORY],
  },
  ref
) => {
  const SCOPE_OPTIONS = {
    scope: 'components.PreviewGroup.index',
  };
  const { t } = useLocale();
  const isPreview = !!templateId;
  const showFeed = !!creative?.feedImage;
  const showStory = !!creative?.storyImage;

  const [refreshCount, setRefreshCount] = useState<number>(1);
  const [selected, setSelected] = useState(AD_FORMAT.MOBILE_FEED_STANDARD);
  const { platform, place } = facebookPreview.getTitle(selected);
  const Icon = platform === 'Facebook' ? Icons.Facebook : Icons.Instagram;

  useImperativeHandle(ref, () => ({
    changeSelected: (preview: string) => setSelected(preview),
    getSelected: () => selected,
  }));

  useEffect(() => {
    if (creative?.storyImage || creative?.feedImage) {
      setRefreshCount((prev) => prev + 1);
    }
  }, [creative?.storyImage, creative?.feedImage]);

  if (!templateId && !creative) {
    return null;
  }

  if (isPreview === false) {
    if (!showFeed && !showStory) {
      return (
        <div className="flex items-center justify-center h-full">
          <span className="px-6 py-3 rounded-3 bg-blue-300 bg-opacity-10 text-blue-300 text-4 select-none">
            {t('labels.empty', SCOPE_OPTIONS)}
          </span>
        </div>
      );
    }
  }

  return (
    <div>
      <div className="flex justify-center flex-row gap-5">
        <div className="flex items-center justify-center h-full">
          <div>
            <div className="flex flex-row justify-between items-center mb-3">
              <div className="flex flex-row items-center gap-2">
                <Icon className="w-6 h-6 text-blue-300" />

                <div className="flex flex-col">
                  <span className="text-3 text-gray-600 font-medium leading-none">{platform}</span>
                  <span className="text-3.5 text-gray-800 font-medium">
                    {/*
                     *FIXME: lowerCase
                     */}
                    {t(`labels.placementTypes.${place?.toLocaleLowerCase()}`, SCOPE_OPTIONS)}
                  </span>
                </div>
              </div>
              {autoReload && <ResetButton key={refreshCount} onClick={() => setRefreshCount((prev) => prev + 1)} />}
            </div>
            {isPreview && <PreviewItem adFormat={selected} templateId={templateId as string} />}
            {!isPreview && (
              <GenerateItem
                creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, selected) }}
                adFormat={selected}
                refreshCount={refreshCount}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3 bg-white p-4 rounded-3 shadow-box">
          <div className="flex flex-col">
            <span className="text-gray-600 text-3 font-medium">{t(`labels.placementTypes.feeds`, SCOPE_OPTIONS)}</span>
            <div className="grid gap-3" style={{ gridTemplateColumns: 'repeat(2, 60px)' }}>
              {facebookPositions?.includes(FACEBOOK_POSITIONS.FEED) && (
                <ButtonWrapper onClick={() => setSelected(AD_FORMAT.MOBILE_FEED_STANDARD)} title="Facebook Feed">
                  {isPreview && <PreviewItem adFormat={AD_FORMAT.MOBILE_FEED_STANDARD} templateId={templateId as string} small={true} />}
                  {!isPreview && (
                    <GenerateItem
                      creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, AD_FORMAT.MOBILE_FEED_STANDARD) }}
                      adFormat={AD_FORMAT.MOBILE_FEED_STANDARD}
                      refreshCount={refreshCount}
                      small={true}
                    />
                  )}
                </ButtonWrapper>
              )}
              {instagramPositions?.includes(INSTAGRAM_POSITIONS.STREAM) && (
                <ButtonWrapper onClick={() => setSelected(AD_FORMAT.INSTAGRAM_STANDARD)} title="Instagram Feed">
                  {isPreview && <PreviewItem adFormat={AD_FORMAT.INSTAGRAM_STANDARD} templateId={templateId as string} small={true} />}
                  {!isPreview && (
                    <GenerateItem
                      creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, AD_FORMAT.INSTAGRAM_STANDARD) }}
                      adFormat={AD_FORMAT.INSTAGRAM_STANDARD}
                      refreshCount={refreshCount}
                      small={true}
                    />
                  )}
                </ButtonWrapper>
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <span className="text-gray-600 text-3 font-medium">{t(`labels.placementTypes.story`, SCOPE_OPTIONS)}</span>
            <div className="grid gap-3" style={{ gridTemplateColumns: 'repeat(2, minmax(60px, 1fr))' }}>
              {facebookPositions?.includes(FACEBOOK_POSITIONS.STORY) && (
                <ButtonWrapper onClick={() => setSelected(AD_FORMAT.FACEBOOK_STORY_MOBILE)} title="Facebook Story">
                  {isPreview && <PreviewItem adFormat={AD_FORMAT.FACEBOOK_STORY_MOBILE} templateId={templateId as string} small={true} />}
                  {!isPreview && (
                    <GenerateItem
                      creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, AD_FORMAT.FACEBOOK_STORY_MOBILE) }}
                      adFormat={AD_FORMAT.FACEBOOK_STORY_MOBILE}
                      refreshCount={refreshCount}
                      small={true}
                    />
                  )}
                </ButtonWrapper>
              )}
              {instagramPositions?.includes(INSTAGRAM_POSITIONS.STORY) && (
                <ButtonWrapper onClick={() => setSelected(AD_FORMAT.INSTAGRAM_STORY)} title="Instagram Story">
                  {isPreview && <PreviewItem adFormat={AD_FORMAT.INSTAGRAM_STORY} templateId={templateId as string} small={true} />}
                  {!isPreview && (
                    <GenerateItem
                      creative={{ ...creative, attachment_id: facebookPreview.getImage(creative, AD_FORMAT.INSTAGRAM_STORY) }}
                      adFormat={AD_FORMAT.INSTAGRAM_STORY}
                      refreshCount={refreshCount}
                      small={true}
                    />
                  )}
                </ButtonWrapper>
              )}
            </div>
          </div>
        </div>
      </div>
      {autoReload && (
        <div className="mt-2">
          <span className="text-gray-600 text-3 block text-center">
            {t('labels.refresh', SCOPE_OPTIONS)}
            <button
              className="text-blue-300 hover:text-blue-400 transition-colors ml-1"
              onClick={() => setRefreshCount((prev) => prev + 1)}
            >
              {t('actions.refresh', SCOPE_OPTIONS)}
            </button>
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(forwardRef(Previews), (prev, next) => {
  if (prev.templateId !== next.templateId) {
    return false;
  }

  if (JSON.stringify(prev.creative) !== JSON.stringify(next.creative)) {
    return false;
  }

  if (JSON.stringify(prev.facebookPositions) !== JSON.stringify(next.facebookPositions)) {
    return false;
  }

  if (JSON.stringify(prev.instagramPositions) !== JSON.stringify(next.instagramPositions)) {
    return false;
  }

  return true;
});
