import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesGMApi, CityBranchCreateInput } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function createCityBranch(
  companyId: string,
  cityBranchInput: CityBranchCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createCityBranch(companyId, cityBranchInput).then((res) => res.data);
}

export default function useCreateCityBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useMutation(
    (values: { companyId: string; cityBranchInput: any }) => createCityBranch(companyId, values.cityBranchInput, axiosInstance),
    props
  );
}
