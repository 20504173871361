import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M25.333 8H6.667M9.333 4h13.334M25.333 28H6.667A2.667 2.667 0 0 1 4 25.333V14.667A2.667 2.667 0 0 1 6.667 12h18.666A2.667 2.667 0 0 1 28 14.667v10.666A2.667 2.667 0 0 1 25.333 28Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="m19.348 18-4.59 4.59L12 19.835" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgComponent;
