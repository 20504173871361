import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { useLocale } from '@app/hooks';
import { Form } from '@app/components';
import { GOOGLE_TEXT_TYPE, PUBLISHER_PLATFORMS } from '@app/constants';

type TextPropTypes = {
  size: number;
  type: string;
  index: number;
  rows: number;
  defaultValue?: string;
  inputVariables?: (id: string) => JSX.Element | null;
};

const Text: React.FC<TextPropTypes> = ({ type: textType, size, index, rows, defaultValue, inputVariables }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.GoogleForm.Text',
  };
  const { t } = useLocale();
  const {
    register,
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  //FIXME: lowerCase
  const type = String(textType).toLocaleLowerCase();
  const fieldName = `${type}_${index}`;
  const field = `creative.${fieldName}`;
  const headlineWatch = watch(field);
  const isPlatformSmart = watch('targeting.platforms') === PUBLISHER_PLATFORMS.SMART;

  function handleRequriedInputCount() {
    if (textType === GOOGLE_TEXT_TYPE.HEADLINE) {
      return isPlatformSmart ? 3 : 5;
    }
    if (textType === GOOGLE_TEXT_TYPE.LONG_HEADLINE) {
      return 2;
    }
    if (textType === GOOGLE_TEXT_TYPE.DESCRIPTION) {
      return isPlatformSmart ? 2 : 4;
    }
    return 0;
  }

  useEffect(() => {
    defaultValue && setValue(field, defaultValue);
  }, [defaultValue]);

  return (
    <>
      <Form.Textarea
        id={field}
        label={index === 0 ? t(`${type}.label`, SCOPE_OPTIONS) : ''}
        placeholder={t(`${type}.placeholder`, SCOPE_OPTIONS)}
        requiredSign={index === 0 ? true : false}
        maxLength={size}
        {...register(field, {
          required: { value: index < handleRequriedInputCount() ? true : false, message: t(`${type}.errors.required`, SCOPE_OPTIONS) },
          maxLength: {
            value: size,
            message: t(`${type}.errors.maxLength`, { ...SCOPE_OPTIONS, value: size }),
          },
          minLength: {
            value: textType === GOOGLE_TEXT_TYPE.LONG_HEADLINE ? 30 : 0,
            message: t(`${type}.errors.minLength`, { ...SCOPE_OPTIONS, value: 30 }),
          },
        })}
        rows={rows}
        length={headlineWatch?.length ?? 0}
        error={errors.creative?.[fieldName]}
      />
      {inputVariables && inputVariables(field)}
    </>
  );
};

export default Text;
