import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.122 7.88C13.293 9.051 13.293 10.952 12.122 12.125C10.951 13.296 9.05 13.296 7.877 12.125C6.706 10.954 6.706 9.053 7.877 7.88C9.05 6.707 10.95 6.707 12.122 7.88Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 10C1 9.341 1.152 8.689 1.446 8.088C2.961 4.991 6.309 3 10 3C13.691 3 17.039 4.991 18.554 8.088C18.848 8.689 19 9.341 19 10C19 10.659 18.848 11.311 18.554 11.912C17.039 15.009 13.691 17 10 17C6.309 17 2.961 15.009 1.446 11.912C1.152 11.311 1 10.659 1 10Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
