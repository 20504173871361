import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationGMApi } from '@app/api';
import type { MessagingApplicationRequestInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createMessagingApplication(
  companyId: string,
  messagingApplicationCreateInput: MessagingApplicationRequestInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createMessagingApplicationForCompany(companyId, messagingApplicationCreateInput).then((res) => res.data);
}

export default function useCreateMessagingApplication(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; messagingApplicationCreateInput: MessagingApplicationRequestInput }) =>
      createMessagingApplication(values.companyId, values.messagingApplicationCreateInput, axiosInstance),
    props
  );
}
