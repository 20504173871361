import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesGMApi } from '@app/api';
import type { BranchCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateBranch(
  companyId: string,
  branchId: string,
  branchCreateInput: BranchCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateBranch(companyId, branchId, branchCreateInput).then((res) => res.data);
}

export default function useUpdateBranch(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; branchId: string; branch: BranchCreateInput }) =>
      updateBranch(values.companyId, values.branchId, { branch: values.branch }, axiosInstance),
    props
  );
}
