import React, { useState, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Button, Form } from '@app/components';
import { api, useLocale, useDebounce } from '@app/hooks';

import Browse from './Browse';

const DEBOUNCE_MS = 200;

type ItemType = {
  id: string;
  name: string;
  type: string;
};

type InterestInputPropTypes = {
  defaultValue?: ItemType[];
};

const InterestInput: React.FC<InterestInputPropTypes> = ({ defaultValue }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.InterestInput',
  };
  const { t } = useLocale();
  const [isBrowseOpen, setIsBrowseOpen] = useState(false);
  const [query, setQuery] = useState<string>('');
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  const interestsWatch = watch('interests');
  const { debouncedValue: debouncedQuery, isLoading: isDebounceLoading } = useDebounce(query, DEBOUNCE_MS);
  const {
    data,
    refetch,
    isLoading: isFacebookInterestsLoading,
  } = api.useListGmFacebookInterests({
    enabled: false,
    params: {
      query: query,
    },
  });

  useEffect(() => {
    refetch();
  }, [debouncedQuery, refetch]);

  return (
    <div className="relative">
      <Form.Select
        right={
          <Button
            label={t('actions.browse', SCOPE_OPTIONS)}
            theme="blue"
            size="xs"
            onClick={() => setIsBrowseOpen(true)}
            disabled={isBrowseOpen}
            block={false}
            className="py-1 text-3 rounded-1 font-medium"
          />
        }
        automaticHandleValue={false}
        onInputChange={setQuery}
        isMulti
        isSearchable
        isClearable
        isLoading={isFacebookInterestsLoading || isDebounceLoading}
        name="interests"
        label={t('form.interests.label', SCOPE_OPTIONS)}
        placeholder={t('form.interests.placeholder', SCOPE_OPTIONS)}
        className="mt-4"
        control={control}
        options={data?.interests
          ?.filter(function (interest: ItemType) {
            return (
              interestsWatch?.filter(function (item: { value: string }) {
                return item.value == interest.id;
              }).length == 0
            );
          })
          .map((interest: ItemType) => ({
            label: interest.name,
            value: interest.id,
            type: interest.type,
          }))}
        defaultValue={defaultValue?.map((interest: ItemType) => ({
          label: interest.name as string,
          value: interest.id as string,
          type: interest.type,
        }))}
        filterOptions={(options: ItemType) => {
          return options;
        }}
        error={errors.interests}
      />
      <Browse
        value={interestsWatch}
        onChange={(value) => setValue('interests', value, { shouldTouch: true, shouldValidate: true })}
        isOpen={isBrowseOpen}
        onClose={() => setIsBrowseOpen(false)}
      />
    </div>
  );
};

export default InterestInput;
