import { useEffect, useState } from 'react';

import { array } from '@app/lib';

function useActiveInput(inputs?: string[]) {
  const [activeElement, setActiveElement] = useState<null | string>(null);

  function onFocus(event: any) {
    const target = event.target;

    if (['INPUT', 'TEXTAREA'].includes(target.tagName)) {
      if (!Array.isArray(inputs) || array.isEmpty(inputs)) {
        setActiveElement(target.name);
        return;
      }

      if (inputs.includes(target.name)) {
        setActiveElement(target.name);
        return;
      }

      activeElement !== null && setActiveElement(null);
      return;
    }

    activeElement !== null && setActiveElement(null);
  }

  function onBlur() {
    if (activeElement !== null) {
      setActiveElement(null);
    }
  }

  useEffect(() => {
    window.addEventListener('focus', onFocus, true);
    window.addEventListener('blur', onBlur, true);

    return () => {
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  return activeElement;
}

export default useActiveInput;
