/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DoorkeeperInfoTokenRespose
 */
export interface DoorkeeperInfoTokenRespose {
    /**
     * 
     * @type {string}
     * @memberof DoorkeeperInfoTokenRespose
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof DoorkeeperInfoTokenRespose
     */
    resource_owner_id: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DoorkeeperInfoTokenRespose
     */
    scopes: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DoorkeeperInfoTokenRespose
     */
    expires_in: number;
    /**
     * 
     * @type {object}
     * @memberof DoorkeeperInfoTokenRespose
     */
    application: object;
    /**
     * 
     * @type {number}
     * @memberof DoorkeeperInfoTokenRespose
     */
    created_at: number;
    /**
     * 
     * @type {string}
     * @memberof DoorkeeperInfoTokenRespose
     */
    resource_owner_type: DoorkeeperInfoTokenResposeResourceOwnerTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum DoorkeeperInfoTokenResposeResourceOwnerTypeEnum {
    BranchUser = 'BranchUser',
    GeneralManagerUser = 'GeneralManagerUser'
}



