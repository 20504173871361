/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { GmActivityListResponse } from '../model';
/**
 * ActivitiesGMApi - axios parameter creator
 * @export
 */
export const ActivitiesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return activities of branches of company
         * @summary Return activities of branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchActivitiesByCompany: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listBranchActivitiesByCompany', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/activities`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivitiesGMApi - functional programming interface
 * @export
 */
export const ActivitiesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivitiesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Return activities of branches of company
         * @summary Return activities of branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranchActivitiesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmActivityListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranchActivitiesByCompany(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ActivitiesGMApi - factory interface
 * @export
 */
export const ActivitiesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivitiesGMApiFp(configuration)
    return {
        /**
         * Return activities of branches of company
         * @summary Return activities of branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranchActivitiesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GmActivityListResponse> {
            return localVarFp.listBranchActivitiesByCompany(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivitiesGMApi - interface
 * @export
 * @interface ActivitiesGMApi
 */
export interface ActivitiesGMApiInterface {
    /**
     * Return activities of branches of company
     * @summary Return activities of branches of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesGMApiInterface
     */
    listBranchActivitiesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GmActivityListResponse>;

}

/**
 * ActivitiesGMApi - object-oriented interface
 * @export
 * @class ActivitiesGMApi
 * @extends {BaseAPI}
 */
export class ActivitiesGMApi extends BaseAPI implements ActivitiesGMApiInterface {
    /**
     * Return activities of branches of company
     * @summary Return activities of branches of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivitiesGMApi
     */
    public listBranchActivitiesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return ActivitiesGMApiFp(this.configuration).listBranchActivitiesByCompany(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }
}
