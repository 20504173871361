import React, { useState } from 'react';

import { FormProvider, useForm } from 'react-hook-form';

import { Modal, Button, Facebook, RadioPicker } from '@app/components';
import { api, useLocale, useToast } from '@app/hooks';
import { array } from '@app/lib';

import PageForm from './PageForm';
import INTEGRATION_TYPES from 'constants/integrationTypes';

type SelectedData = {
  accessToken: string;
  adaccount: {
    name: string;
    account_id: string;
    currency: string;
    id: string;
  };
};

type IntegrationFormPropTypes = {
  isLoading: boolean;
  onComplete: () => void;
};

const STEPS = Object.freeze({
  ACCOUNT: 'account' as string,
  PAGE: 'page' as string,
});

const FacebookLoginButton: React.FC<IntegrationFormPropTypes> = ({ isLoading, onComplete }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.FacebookLoginButton.index',
  };
  const { t } = useLocale();
  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const { trigger, getValues } = methods;
  const toast = useToast();
  const [activeStep, setActiveStep] = useState(STEPS.ACCOUNT);
  const [data, setData] = useState<null | FacebookLoginResponse>(null);
  const [selected, setSelected] = useState<null | SelectedData>(null);
  const { mutate: facebookOAuthMutate, isLoading: isFacebookOAuthLoading } = api.useCallFacebookOAuthCallbackForGm({
    onSuccess: () => setActiveStep(STEPS.PAGE),
  });
  const { mutate: updateFacebookIntegrationMutate, isLoading: isUpdateFacebookIntegrationLoading } = api.useUpdateGmFacebookIntegration({
    onSuccess: () => onComplete(),
  });
  const isQueryLoading = isFacebookOAuthLoading || isUpdateFacebookIntegrationLoading;

  function responseFacebook(response: FacebookLoginResponse) {
    if (Object.prototype.hasOwnProperty.call(response, 'status')) {
      if (response.status === 'unknown') {
        toast.error(t('messages.loginFail', SCOPE_OPTIONS));
        return;
      }
    }

    if (response?.error) {
      toast.error(response.error.message);
      return;
    }

    if (array.isEmpty(response.adaccounts.data)) {
      toast.error(t('messages.noAccount', SCOPE_OPTIONS));
      return;
    }

    const firstAccount = array.first(response.adaccounts.data);

    setData(response);
    setSelected({
      accessToken: response.accessToken,
      adaccount: {
        name: firstAccount.name,
        account_id: firstAccount.account_id,
        currency: firstAccount.currency,
        id: firstAccount.id,
      },
    });
  }

  const handlePagination = async (pageUrl: string | undefined) => {
    if (!pageUrl) {
      return;
    }

    const res = await fetch(pageUrl);
    const adAccountsData = await res.json();

    setData({ ...(data as FacebookLoginResponse), adaccounts: adAccountsData });
  };

  function handleAccounts() {
    if (data === null) {
      return [];
    }

    return data.adaccounts.data.map((account) => ({ label: account.name, value: account.id }));
  }

  function handleAccountChange(value: string) {
    if (data === null) {
      return;
    }

    const account = data.adaccounts.data.find((account) => account.id === value);

    if (account === undefined) {
      return;
    }

    setSelected({
      accessToken: data.accessToken,
      adaccount: {
        name: account.name,
        account_id: account.account_id,
        currency: account.currency,
        id: account.id,
      },
    });
  }

  async function handleCompleteClick() {
    const isValid = await trigger();

    if (isValid === false) {
      return;
    }

    const formData = getValues();

    if (formData.integrationType === INTEGRATION_TYPES.OWNER) {
      updateFacebookIntegrationMutate({
        facebook_integration: {
          page_id: formData.pageId,
          instagram_actor_id: formData.instagramPageId,
        },
      });
    }

    if (formData.integrationType === INTEGRATION_TYPES.PARTNERSHIP) {
      updateFacebookIntegrationMutate({
        facebook_integration: {
          page_id: formData.clientPageId,
          partnership_adaccount_id: formData.adAccountId,
          instagram_actor_id: formData.instagramAccountId,
        },
      });
    }
  }

  function handleNextClick() {
    facebookOAuthMutate({
      facebook: {
        access_token: selected?.accessToken,
        ad_account_name: selected?.adaccount.name,
        ad_account_currency: selected?.adaccount.currency,
        ad_account_id: selected?.adaccount.id,
      },
    });
  }

  return (
    <FormProvider {...methods}>
      <Facebook.Login callback={responseFacebook} disabled={isQueryLoading || isLoading} />
      <Modal isOpen={data !== null} onClose={() => null}>
        <div className="m-5 bg-gray-200 rounded-3 py-10 px-2.5 flex flex-col items-center justify-center">
          {STEPS.ACCOUNT === activeStep && (
            <>
              <span className="max-w-100 text-3.5 text-gray-500 text-center block mb-6">
                {t('labels.pleaseSelectAdsAccount', SCOPE_OPTIONS)}
              </span>
              <RadioPicker
                className="grid grid-cols-2 gap-3"
                options={handleAccounts()}
                value={selected?.adaccount.id || ''}
                onChange={handleAccountChange}
              />
            </>
          )}
          {STEPS.PAGE === activeStep && (
            <>
              <span className="max-w-100 text-3.5 text-gray-500 text-center block mb-6">
                {t('labels.pleaseSelectPages', SCOPE_OPTIONS)}
              </span>
              <PageForm />
            </>
          )}
          <div className="flex items-center gap-2 w-full px-6 mt-4 basis-auto">
            {STEPS.ACCOUNT === activeStep && (
              <Button
                disabled={!data?.adaccounts.paging?.previous}
                size="sm"
                onClick={() => handlePagination(data?.adaccounts.paging?.previous)}
                theme="blue"
                label={t('actions.prevPage', SCOPE_OPTIONS)}
              ></Button>
            )}
            {STEPS.ACCOUNT === activeStep && (
              <Button
                disabled={!data?.adaccounts.paging?.next}
                size="sm"
                onClick={() => handlePagination(data?.adaccounts.paging?.next)}
                theme="blue"
                label={t('actions.nextPage', SCOPE_OPTIONS)}
              ></Button>
            )}
          </div>
          {isQueryLoading === true && (
            <Button theme="gray" disabled={true} label={t('actions.loading', SCOPE_OPTIONS)} size="sm" className="w-40 mx-auto mt-5" />
          )}
          {isQueryLoading === false && STEPS.ACCOUNT === activeStep && (
            <Button
              theme="blue"
              label={t('actions.next', SCOPE_OPTIONS)}
              onClick={handleNextClick}
              size="sm"
              className="w-40 mx-auto mt-5"
            />
          )}
          {isQueryLoading === false && STEPS.PAGE === activeStep && (
            <Button
              theme="green"
              label={t('actions.complete', SCOPE_OPTIONS)}
              onClick={handleCompleteClick}
              size="sm"
              className="w-40 mx-auto mt-5"
            />
          )}
        </div>
      </Modal>
    </FormProvider>
  );
};

export default FacebookLoginButton;
