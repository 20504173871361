import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormGMApi } from 'api/api';

import { useAxios } from '@app/hooks';

export function deleteContactForm(companyId: string, contactFormId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ContactFormGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteContactFormForCompany(companyId, contactFormId).then((res) => res.data);
}

export default function useDeleteContactForm(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; contactFormId: string }) => deleteContactForm(values.companyId, values.contactFormId, axiosInstance),
    props
  );
}
