import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookGMApi } from '@app/api';
import type { FacebookGeneratePreviewInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function generateGmFacebookPreview(
  companyId: string,
  facebookGeneratePreviewInput: FacebookGeneratePreviewInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FacebookGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateGmFacebookPreview(companyId, facebookGeneratePreviewInput).then((res) => res.data);
}

export default function useGenerateGmFacebookPreview({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({
    automaticToast: false,
  });
  const { companyId } = useAuth();

  return useQuery(
    ['useGenerateGmFacebookPreview', companyId, params.creative],
    () => generateGmFacebookPreview(companyId, { creative: params.creative }, axiosInstance),
    props
  );
}
