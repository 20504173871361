import { GOOGLE_PLACEMENT_TYPE } from '@app/constants';

function calculateSizes(
  container: { height: number; width: number },
  size: { height: number; width: number },
  googleVariants?: keyof typeof GOOGLE_PLACEMENT_TYPE
): {
  height: number;
  width: number;
  scale: number;
} {
  if (size.width < size.height) {
    const scale = container.height / size.height;

    return {
      height: container.height,
      width: size.width * scale,
      scale: scale,
    };
  }

  if (size.width > size.height) {
    const scale = container.width / size.height;

    if (googleVariants === GOOGLE_PLACEMENT_TYPE.MARKETING_IMAGE) {
      return {
        height: size.height * scale * 0.7,
        width: size.width * scale * 0.7,
        scale: scale * 0.7,
      };
    }

    return {
      height: size.height * scale,
      width: container.width,
      scale: scale,
    };
  }

  const scale = container.height / size.width;

  return {
    height: container.height,
    width: container.width,
    scale: scale,
  };
}

export default {
  calculateSizes,
};
