/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { InlineObject } from '../model';
// @ts-ignore
import { InlineResponse200 } from '../model';
// @ts-ignore
import { V2GetCardsResponse } from '../model';
// @ts-ignore
import { V2WalletBalanceInput } from '../model';
// @ts-ignore
import { V2WalletBalanceSuccessResponse } from '../model';
// @ts-ignore
import { V2WalletTransactionsSuccessResponse } from '../model';
// @ts-ignore
import { Wallet } from '../model';
/**
 * WalletCompanyV2Api - axios parameter creator
 * @export
 */
export const WalletCompanyV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Add company wallet balance
         * @summary Add company wallet balance
         * @param {string} companyId The id of the company
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyWalletBalance: async (companyId: string, v2WalletBalanceInput: V2WalletBalanceInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('addCompanyWalletBalance', 'companyId', companyId)
            // verify required parameter 'v2WalletBalanceInput' is not null or undefined
            assertParamExists('addCompanyWalletBalance', 'v2WalletBalanceInput', v2WalletBalanceInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/wallet/balance`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2WalletBalanceInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a company saved card
         * @summary Delete a company saved card
         * @param {string} companyId The id of the company
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanySavedCard: async (companyId: string, inlineObject: InlineObject, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteCompanySavedCard', 'companyId', companyId)
            // verify required parameter 'inlineObject' is not null or undefined
            assertParamExists('deleteCompanySavedCard', 'inlineObject', inlineObject)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/wallet/cards/delete`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company saved cards
         * @summary Get company saved cards
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySavedCards: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanySavedCards', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/wallet/cards`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company wallet
         * @summary Get company wallet
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWallet: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyWallet', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/wallet`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get company wallet transactions
         * @summary Get company wallet transactions
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWalletTransactions: async (companyId: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getCompanyWalletTransactions', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/wallet/transactions`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WalletCompanyV2Api - functional programming interface
 * @export
 */
export const WalletCompanyV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WalletCompanyV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * Add company wallet balance
         * @summary Add company wallet balance
         * @param {string} companyId The id of the company
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addCompanyWalletBalance(companyId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2WalletBalanceSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addCompanyWalletBalance(companyId, v2WalletBalanceInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a company saved card
         * @summary Delete a company saved card
         * @param {string} companyId The id of the company
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCompanySavedCard(companyId: string, inlineObject: InlineObject, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCompanySavedCard(companyId, inlineObject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company saved cards
         * @summary Get company saved cards
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanySavedCards(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2GetCardsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanySavedCards(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company wallet
         * @summary Get company wallet
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyWallet(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Wallet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyWallet(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get company wallet transactions
         * @summary Get company wallet transactions
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCompanyWalletTransactions(companyId: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<V2WalletTransactionsSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCompanyWalletTransactions(companyId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * WalletCompanyV2Api - factory interface
 * @export
 */
export const WalletCompanyV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WalletCompanyV2ApiFp(configuration)
    return {
        /**
         * Add company wallet balance
         * @summary Add company wallet balance
         * @param {string} companyId The id of the company
         * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addCompanyWalletBalance(companyId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): AxiosPromise<V2WalletBalanceSuccessResponse> {
            return localVarFp.addCompanyWalletBalance(companyId, v2WalletBalanceInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a company saved card
         * @summary Delete a company saved card
         * @param {string} companyId The id of the company
         * @param {InlineObject} inlineObject 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCompanySavedCard(companyId: string, inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.deleteCompanySavedCard(companyId, inlineObject, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company saved cards
         * @summary Get company saved cards
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanySavedCards(companyId: string, options?: any): AxiosPromise<V2GetCardsResponse> {
            return localVarFp.getCompanySavedCards(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company wallet
         * @summary Get company wallet
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWallet(companyId: string, options?: any): AxiosPromise<Wallet> {
            return localVarFp.getCompanyWallet(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get company wallet transactions
         * @summary Get company wallet transactions
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCompanyWalletTransactions(companyId: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2WalletTransactionsSuccessResponse> {
            return localVarFp.getCompanyWalletTransactions(companyId, page, perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WalletCompanyV2Api - interface
 * @export
 * @interface WalletCompanyV2Api
 */
export interface WalletCompanyV2ApiInterface {
    /**
     * Add company wallet balance
     * @summary Add company wallet balance
     * @param {string} companyId The id of the company
     * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2ApiInterface
     */
    addCompanyWalletBalance(companyId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any): AxiosPromise<V2WalletBalanceSuccessResponse>;

    /**
     * Delete a company saved card
     * @summary Delete a company saved card
     * @param {string} companyId The id of the company
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2ApiInterface
     */
    deleteCompanySavedCard(companyId: string, inlineObject: InlineObject, options?: any): AxiosPromise<InlineResponse200>;

    /**
     * Get company saved cards
     * @summary Get company saved cards
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2ApiInterface
     */
    getCompanySavedCards(companyId: string, options?: any): AxiosPromise<V2GetCardsResponse>;

    /**
     * Get company wallet
     * @summary Get company wallet
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2ApiInterface
     */
    getCompanyWallet(companyId: string, options?: any): AxiosPromise<Wallet>;

    /**
     * Get company wallet transactions
     * @summary Get company wallet transactions
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2ApiInterface
     */
    getCompanyWalletTransactions(companyId: string, page?: number, perPage?: number, options?: any): AxiosPromise<V2WalletTransactionsSuccessResponse>;

}

/**
 * WalletCompanyV2Api - object-oriented interface
 * @export
 * @class WalletCompanyV2Api
 * @extends {BaseAPI}
 */
export class WalletCompanyV2Api extends BaseAPI implements WalletCompanyV2ApiInterface {
    /**
     * Add company wallet balance
     * @summary Add company wallet balance
     * @param {string} companyId The id of the company
     * @param {V2WalletBalanceInput} v2WalletBalanceInput Wallet balance request body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2Api
     */
    public addCompanyWalletBalance(companyId: string, v2WalletBalanceInput: V2WalletBalanceInput, options?: any) {
        return WalletCompanyV2ApiFp(this.configuration).addCompanyWalletBalance(companyId, v2WalletBalanceInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a company saved card
     * @summary Delete a company saved card
     * @param {string} companyId The id of the company
     * @param {InlineObject} inlineObject 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2Api
     */
    public deleteCompanySavedCard(companyId: string, inlineObject: InlineObject, options?: any) {
        return WalletCompanyV2ApiFp(this.configuration).deleteCompanySavedCard(companyId, inlineObject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company saved cards
     * @summary Get company saved cards
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2Api
     */
    public getCompanySavedCards(companyId: string, options?: any) {
        return WalletCompanyV2ApiFp(this.configuration).getCompanySavedCards(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company wallet
     * @summary Get company wallet
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2Api
     */
    public getCompanyWallet(companyId: string, options?: any) {
        return WalletCompanyV2ApiFp(this.configuration).getCompanyWallet(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get company wallet transactions
     * @summary Get company wallet transactions
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WalletCompanyV2Api
     */
    public getCompanyWalletTransactions(companyId: string, page?: number, perPage?: number, options?: any) {
        return WalletCompanyV2ApiFp(this.configuration).getCompanyWalletTransactions(companyId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
