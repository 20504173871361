import React from 'react';

import { useLocale } from '@app/hooks';

import type { TemplatesPreviewListResponseAttachments, TemplatesPreviewListResponseAttachmentsPlacementTypeEnum } from '@app/api';
import IMAGE_PLACEMENT_TYPE from 'constants/imagePlacementTypes';

type InstagramStoryPropTypes = {
  companyName: string;
  images?: TemplatesPreviewListResponseAttachments[];
};

const InstagramStory: React.FC<InstagramStoryPropTypes> = ({ companyName, images }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Preview.InstagramStory',
  };
  const { t } = useLocale();
  const imgUrl = images?.find(
    (image) => image?.placement_type === (IMAGE_PLACEMENT_TYPE.STORY as TemplatesPreviewListResponseAttachmentsPlacementTypeEnum)
  )?.file?.url as string;

  return (
    <div className="bg-white rounded-3 drop-shadow-box w-75 p-2 select-none">
      <img src={imgUrl} alt="template" className="object-cover z-1" width="300" height="550" />
      <div className="absolute z-2 left-2 top-2 right-2 h-14.5">
        <div className="bg-gradient-to-b from-gray-900 opacity-40 h-14.5" />
        <div className="flex flex-row absolute top-2 left-2">
          <div className="h-10.5 w-10.5 rounded-full bg-white" />
          <div className="flex flex-col ml-2">
            <span className="font-medium text-3.5 text-white">{companyName}</span>
            <span className="leading-none text-white text-3 font-medium opacity-70">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
        </div>
      </div>
      <span className="absolute z-2 left-2 bottom-2 right-2 h-13">
        <div className="bg-gradient-to-t from-gray-900 opacity-50 h-13" />
        <div className="flex flex-col w-full items-center justify-center absolute bottom-2">
          <span className="text-white font-medium text-3">{t('labels.action', SCOPE_OPTIONS)}</span>
        </div>
      </span>
    </div>
  );
};

export default InstagramStory;
