/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TemplateUpdatedLogOldAttributes
 */
export interface TemplateUpdatedLogOldAttributes {
    /**
     * 
     * @type {string}
     * @memberof TemplateUpdatedLogOldAttributes
     */
    name?: string;
    /**
     * 
     * @type {object}
     * @memberof TemplateUpdatedLogOldAttributes
     */
    creative?: object;
    /**
     * 
     * @type {object}
     * @memberof TemplateUpdatedLogOldAttributes
     */
    targeting?: object;
    /**
     * 
     * @type {string}
     * @memberof TemplateUpdatedLogOldAttributes
     */
    status?: TemplateUpdatedLogOldAttributesStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TemplateUpdatedLogOldAttributesStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Approved = 'approved',
    Rejected = 'rejected',
    Archived = 'archived'
}



