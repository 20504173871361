import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { PasswordGMApi } from '@app/api';
import type { GmUserUpdatePasswordInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function generalManagerUserUpdatePassword(
  gmUserUpdatePasswordInput: GmUserUpdatePasswordInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new PasswordGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generalManagerUserUpdatePassword(gmUserUpdatePasswordInput).then((res) => res.data);
}

export default function useGeneralManagerUserUpdatePassword(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: GmUserUpdatePasswordInput) => generalManagerUserUpdatePassword(values, axiosInstance), props);
}
