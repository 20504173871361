import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { MetabaseResourcesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function showGmMetabaseResource(
  companyId: string,
  metabaseResourceId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MetabaseResourcesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showGmMetabaseResource(companyId, metabaseResourceId).then((res) => res.data);
}

export default function useShowGmMetabaseResource({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useShowGmMetabaseResource', companyId, params.metabaseResourceId],
    () => showGmMetabaseResource(companyId, params.metabaseResourceId, axiosInstance),
    props
  );
}
