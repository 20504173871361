import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18" {...props}>
    <path
      fill="currentColor"
      d="M0 18a6.857 6.857 0 1113.714 0H12a5.142 5.142 0 10-10.286 0H0zm6.857-7.714a5.141 5.141 0 01-5.143-5.143A5.141 5.141 0 016.857 0 5.141 5.141 0 0112 5.143a5.141 5.141 0 01-5.143 5.143zm0-1.715a3.428 3.428 0 003.429-3.428 3.428 3.428 0 00-3.429-3.429A3.428 3.428 0 003.43 5.143 3.428 3.428 0 006.857 8.57zm7.1 3.174A6.858 6.858 0 0118 18h-1.714a5.144 5.144 0 00-3.032-4.691l.704-1.564zm-.589-9.677a4.714 4.714 0 011.686 7.54A4.713 4.713 0 0112 11.124V9.399a3 3 0 00.892-5.665l.476-1.666z"
    ></path>
  </svg>
);

export default SvgComponent;
