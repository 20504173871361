import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi } from '@app/api';
import type { CampaignCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createCampaign(
  companyId: string,
  campaignCreateInput: CampaignCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createCampaign(companyId, campaignCreateInput).then((res) => res.data);
}

export default function useCreateCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; campaignCreateInput: CampaignCreateInput }) =>
      createCampaign(values.companyId, values.campaignCreateInput, axiosInstance),
    props
  );
}
