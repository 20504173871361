import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import type { Branch as BranchType } from '@app/api';
import { useAxios } from '@app/hooks';
import { API } from '@app/constants';

export function generateTemplatePreviewByBranch(
  companyId: string,
  templateId: string,
  placementType: string,
  branch: BranchType,
  axiosInstance: AxiosInstance
) {
  return axiosInstance
    .post(API.IMAGE_GENERATOR, {
      apiUrl: process.env.NEXT_PUBLIC_API_URL,
      companyId: companyId,
      templateId: templateId,
      placementType: placementType,
      branch: branch,
    })
    .then((res) => res.data);
}

export default function useGenerateTemplatePreviewByBranch({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useGenerateTemplatePreviewByBranch', params.companyId, params.templateId, params.placementType, JSON.stringify(params.branch)],
    () => generateTemplatePreviewByBranch(params.companyId, params.templateId, params.placementType, params.branch, axiosInstance),
    props
  );
}
