import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M95 30H25M35 15h50M95 105H25a10.002 10.002 0 0 1-10-10V55a10 10 0 0 1 10-10h70a10 10 0 0 1 10 10v40a10 10 0 0 1-10 10Z"
      stroke="#9FA9B3"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M72.554 67.5 55.342 84.713 45 74.38" stroke="#9FA9B3" strokeWidth={4} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SvgComponent;
