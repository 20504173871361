import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { Icons } from '@app/components';
import { GOOGLE_PREVIEW_TYPES } from '@app/constants';

type HeaderPropTypes = {
  placementType: string;
  onPlacementTypeChange: (placementType: typeof GOOGLE_PREVIEW_TYPES[keyof typeof GOOGLE_PREVIEW_TYPES]) => void;
};

const Header: React.FC<HeaderPropTypes> = ({ placementType, onPlacementTypeChange }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.SmartCampaignPreview.Header',
  };
  const { t } = useLocale();

  const PREVIEW_DATA = [
    {
      icon: <Icons.Google className="w-10 h-10" />,
      type: GOOGLE_PREVIEW_TYPES.SEARCH,
    },
    {
      icon: <Icons.Maps className="w-10 h-10" />,
      type: GOOGLE_PREVIEW_TYPES.MAPS,
    },
  ];

  return (
    <div className="flex items-center justify-center bg-white space-x-5 py-2 w-full">
      {PREVIEW_DATA.map((item, index) => (
        <button
          key={index}
          className={cn('flex items-center hover:bg-gray-100 w-25', {
            'border-b-[0.2rem]  border-blue-500 ': item.type === placementType,
            '!justify-end': index === 0,
            'justify-start': index !== 0,
          })}
          onClick={() => onPlacementTypeChange(item.type)}
        >
          <div className="w-full">
            <span className="flex w-full justify-center">{item.icon}</span>
            <span className="text-gray-700 text-3 font-extralight">{t(`labels.placementTypes.${item.type}`, SCOPE_OPTIONS)}</span>
          </div>
        </button>
      ))}
    </div>
  );
};

export default Header;
