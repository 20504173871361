import { AxiosInstance } from 'axios';

import { useAuth, useAxios } from '@app/hooks';
import { useMutation } from 'react-query';
import { FileManagerGMApi } from 'api/api/file-manager-gmapi';

export async function deleteCategory(
  companyId: string,
  fullPath: string,
  categoryName: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FileManagerGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  const { data } = await api.destroyFileForCompany(companyId, fullPath, categoryName);
  return data;
}

export default function useDeleteCategory(): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  return useMutation((values: { categoryName: string; fullPath: string }) =>
    deleteCategory(companyId, values.fullPath, values.categoryName, axiosInstance)
  );
}
