import { useState } from 'react';
import { useAuth, api } from '@app/hooks';

function useCheckPaymentAlert<T>() {
  const { companyId } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  const { data: pages } = api.useGetCompanyCurrentFacebookAccount({
    params: {
      companyId: companyId,
    },
    onSuccess: () => {
      if (pages?.ad_account_billing_decline.declined === true) {
        setShowAlert(true);
      }
    },
  });
  const date = pages?.ad_account_billing_decline?.time ?? null;

  return { showAlert, date };
}

export default useCheckPaymentAlert;
