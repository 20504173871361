import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.894 23.334a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772ZM23.878 23.334a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772ZM8.191 11.938A2.667 2.667 0 1 1 4.42 15.71a2.667 2.667 0 0 1 3.771-3.772M17.886 4.894a2.667 2.667 0 1 1-3.772 3.772 2.667 2.667 0 0 1 3.772-3.772M27.58 11.938a2.667 2.667 0 1 1-3.77 3.772 2.667 2.667 0 0 1 3.77-3.772M17.886 14.706a2.667 2.667 0 1 1-3.771 3.772 2.667 2.667 0 0 1 3.77-3.772M17.52 18.773l2.947 4.267M14.48 18.773l-2.947 4.254M13.453 15.867l-4.6-1.32M16 13.92V9.453M18.547 15.867l4.6-1.32"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
