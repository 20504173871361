import React from 'react';

import cn from 'classnames';

import { Box } from '@app/components';
import { array } from '@app/lib';

type WizartItemType = { label: string; value: string };
type WizardPropTypes = {
  items: WizartItemType[];
  selected: string;
  onClick?: (key: string) => void;
};

const Wizard: React.FC<WizardPropTypes> = ({ items, selected, onClick }) => {
  const selectedIndex = items.findIndex((item) => item.value === selected);
  const prevItem = array.prev(items, selectedIndex);

  return (
    <Box className="!p-0">
      <div className="grid grid-cols-3 rounded-3 overflow-hidden">
        {items.map((item: WizartItemType, index: number) => (
          <button
            onClick={() => onClick && onClick(item.value)}
            key={item.value}
            className={cn('flex flex-row relative overflow-hidden bg-opacity-10 transition-all', {
              'bg-blue-300': item.value === selected,
              'bg-white': item.value !== selected,
            })}
          >
            <div className="py-3.5 px-3 h-15 flex flex-row items-center">
              <span
                className={cn('w-8.5 leading-8.5 text-center rounded-1.5 text-white text-4.5 font-semibold transition-all', {
                  'bg-blue-300': item.value === selected,
                  'bg-gray-500': item.value !== selected,
                })}
              >
                {index + 1}
              </span>
              <span
                className={cn('ml-3.5 font-medium text-3.5 transition-all', {
                  'text-blue-300': item.value === selected,
                  'text-gray-500': item.value !== selected,
                })}
              >
                {item.label}
              </span>
            </div>
            {index + 1 < items.length && (
              <>
                <div className="bg-white absolute top-0 right-0 w-7.5 h-15">
                  <div
                    className={cn('border-r-transparent border-7.5 transition-all', {
                      'border-t-white border-b-white border-l-blue-300 opacity-10': item.value === selected,
                      'border-t-blue-300 border-b-blue-300 border-l-blue-300 opacity-10': item.value === prevItem?.value,
                      'border-t-white border-b-white border-l-gray-500 opacity-20':
                        item.value !== prevItem?.value && item.value !== selected,
                    })}
                  ></div>
                </div>
                <div className="absolute right-px w-7.5 h-15">
                  <div
                    className={cn('border-t-transparent border-b-transparent border-r-transparent border-7.5 transition-all', {
                      'border-l-transparent': item.value === selected,
                      'border-l-white': item.value !== selected,
                    })}
                  ></div>
                </div>
              </>
            )}
          </button>
        ))}
      </div>
    </Box>
  );
};

export default Wizard;
