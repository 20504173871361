const PUBLISHER_PLATFORMS = Object.freeze({
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  GOOGLE: 'google',
  MESSENGER: 'messenger',
  AUDIENCE_NETWORK: 'audience_network',
  SMART: 'SMART',
  PMAX: 'PMAX',
  DYNAMICLOCATIONADS: 'dynamiclocationads',
});

export default PUBLISHER_PLATFORMS;
