import React from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { Controller } from 'react-hook-form';
import tr from 'date-fns/locale/tr';
import { useLocale } from '@app/hooks';

type DatePropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  defaultValue?: Date;
  placeholder?: string;
  control: any;
  showTimeSelect?: boolean;
  dateFormat: string;
  rules?: any;
  timeIntervals?: number;
  minDate?: Date;
  disabled?: boolean;
  filterTime?: (time: Date) => boolean;
};

export const Date: React.FC<DatePropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  defaultValue,
  control,
  timeIntervals,
  showTimeSelect = true,
  dateFormat = 'dd/MM/yyy',
  rules,
  minDate,
  disabled,
  filterTime,
}) => {
  const isRequired = getRequiredStatus();
  const { locale } = useLocale();

  function getRequiredStatus() {
    if (rules?.required) {
      if (!Object.prototype.hasOwnProperty.call(rules?.required, 'value')) {
        return true;
      }

      if (rules?.required.value === true) {
        return true;
      }

      return false;
    }
    return false;
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {isRequired && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange } }) => {
          return (
            <DatePicker
              selected={value}
              timeIntervals={timeIntervals}
              onChange={(date) => onChange(date)}
              showTimeSelect={showTimeSelect}
              withPortal={true}
              dateFormat={dateFormat}
              minDate={minDate}
              filterTime={filterTime}
              disabled={disabled}
              className={cn(
                'mt-1.5 py-3.5 px-4 border rounded-2.5 font-normal text-sm text-gray-900 placeholder-gray-500 w-full outline-none',
                {
                  'bg-white border-gray-400': !error,
                  'bg-white border-red-400': error,
                  'bg-gray-200': disabled,
                }
              )}
              locale={locale === 'tr' ? tr : ''}
            />
          );
        }}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default Date;
