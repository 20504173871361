function initHiddenTextarea(fabric, canvasId) {
  fabric.IText.prototype.initHiddenTextarea = (function (initHiddenTextarea) {
    return function () {
      const result = initHiddenTextarea.apply(this);
      if (document.querySelectorAll(`#${canvasId}`).length > 0) {
        this.hiddenTextarea.style.position = 'fixed';
        this.hiddenTextarea.style.left = '0';
        this.hiddenTextarea.style.top = '0';

        fabric.document.body.removeChild(this.hiddenTextarea);

        document.getElementById(`${canvasId}`).appendChild(this.hiddenTextarea);
      }
      return result;
    };
  })(fabric.IText.prototype.initHiddenTextarea);
}

export default {
  initHiddenTextarea,
};
