import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletCompanyV2Api } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function deleteSavedCard(branchId: string, ctoken: string, axiosInstance: AxiosInstance) {
  const api = new WalletCompanyV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.deleteCompanySavedCard(branchId, { ctoken });
}

export default function useDeleteSavedCard() {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  return useMutation((ctoken: string) => deleteSavedCard(companyId, ctoken, axiosInstance));
}
