import cn from 'classnames';

type HeaderPropTypes = {
  title: string;
  className: string;
  right?: JSX.Element;
  containerClassName: string;
};

const Header: React.FC<HeaderPropTypes> = ({ title, className, right, containerClassName }) => {
  return (
    <header className={cn('relative bg-white flex drop-shadow-header h-19 px-5', className)}>
      <div className={cn('w-full flex justify-between items-center my-auto', containerClassName)}>
        <h3 className="text-2xl font-semibold text-blue-300 leading-none">{title}</h3>
        {right && right}
      </div>
    </header>
  );
};

export default Header;
