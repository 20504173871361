import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAuth, useAxios } from '@app/hooks';
import { FacebookGMApi } from '@app/api';

export function listGmFacebookCities(
  companyId: string,
  query: string,
  perPage?: number,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FacebookGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.listGmFacebookCities(companyId, query, perPage).then((res) => res.data);
}

export default function useGetFacebookCities({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { companyId } = useAuth();
  const { api: axiosInstance } = useAxios();

  return useQuery(
    ['useGetFacebookCities', params.perPage, params.query],
    () => listGmFacebookCities(companyId, params.query, params.perPage, axiosInstance),
    props
  );
}
