import React from 'react';

import { useForm } from 'react-hook-form';

import { Box, Button, Form } from '@app/components';
import { api, useLocale, useToast } from '@app/hooks';
import type { Profile as ProfileType } from '@app/api';

type ProfileFormPropTypes = {
  className?: string;
};

const ProfileForm: React.FC<ProfileFormPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.ProfileCard',
  };
  const { t } = useLocale();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
  });
  const {
    data: profile,
    isLoading: isProfileLoading,
    refetch,
  } = api.useGetProfile({
    onSuccess: (data: ProfileType) => {
      setValue('name', data.name);
      setValue('surname', data.surname);
    },
  });
  const { mutate: updateProfileMutate, isLoading: isUpdateProfileLoading } = api.useUpdateProfile({
    onSuccess: () => {
      refetch();
      toast.success(t('messages.updated', SCOPE_OPTIONS));
    },
    onError: () => {
      toast.error(t('messages.unsuccessful', SCOPE_OPTIONS));
    },
  });
  const isLoading = isProfileLoading || isUpdateProfileLoading;

  function handleMutate(data: { [x: string]: string | number }) {
    updateProfileMutate({ name: data.name, surname: data.surname });
  }

  return (
    <Box className={className}>
      <div className="flex flex-col mb-7.5">
        <span className="font-semibold text-4 text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</span>
        <p className="font-regular text-3.5 text-gray-400">{t('labels.description', SCOPE_OPTIONS)}</p>
      </div>

      <form onSubmit={handleSubmit(handleMutate)}>
        <Form.Input
          type="text"
          name="email"
          id="email"
          label={t('form.email.label', SCOPE_OPTIONS)}
          placeholder={t('form.email.placeholder', SCOPE_OPTIONS)}
          className="mb-4"
          disabled={true}
          defaultValue={profile?.email}
        />
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          className="mb-4"
          error={errors.name}
        />
        <Form.Input
          type="text"
          id="surname"
          label={t('form.surname.label', SCOPE_OPTIONS)}
          placeholder={t('form.surname.placeholder', SCOPE_OPTIONS)}
          {...register('surname', {
            required: { value: true, message: t('form.surname.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          className="mb-4"
          error={errors.surname}
        />
        <Button
          type="submit"
          theme={isLoading ? 'gray' : 'blue'}
          label={isLoading ? t('actions.loading', SCOPE_OPTIONS) : t('form.submit', SCOPE_OPTIONS)}
          className="w-40 mx-auto"
          block={false}
          disabled={isLoading}
        />
      </form>
    </Box>
  );
};

export default ProfileForm;
