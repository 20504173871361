function SortIcon({ upColor, downColor, ...props }: any) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 6 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path
          d="M4.30303 0.403953C3.90322 -0.134661 3.09694 -0.134661 2.69712 0.403953L0.787717 2.97621C0.297909 3.63606 0.7689 4.57225 1.59067 4.57225H5.40949C6.23126 4.57225 6.70225 3.63606 6.21244 2.97621L4.30303 0.403953Z"
          fill={upColor}
        />
        <path
          d="M2.69712 11.2405C3.09694 11.7792 3.90322 11.7792 4.30304 11.2405L6.21244 8.66829C6.70225 8.00845 6.23126 7.07225 5.40949 7.07225L1.59067 7.07225C0.768903 7.07225 0.297911 8.00845 0.787719 8.66829L2.69712 11.2405Z"
          fill={downColor}
        />
      </g>
    </svg>
  );
}

export default SortIcon;
