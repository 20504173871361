import type { Company as CompanyType } from '@app/api';

function currency(company: CompanyType) {
  const DEFAULT_CURRENCY = 'TRY';

  if (!company) {
    return DEFAULT_CURRENCY;
  }

  if (!company?.facebook_integration) {
    return DEFAULT_CURRENCY;
  }

  if (!company?.facebook_integration?.ad_account_currency) {
    return DEFAULT_CURRENCY;
  }

  return company.facebook_integration.ad_account_currency;
}

function integration(company: CompanyType) {
  if (!company) {
    return null;
  }

  if (!company?.facebook_integration) {
    return null;
  }

  return company.facebook_integration;
}

function facebookPage(company: CompanyType) {
  if (!company) {
    return null;
  }

  if (!company?.facebook_integration?.page_id) {
    return null;
  }

  return company.facebook_integration.page_id;
}

function instagramPage(company: CompanyType) {
  if (!company) {
    return null;
  }

  if (!company?.facebook_integration?.instagram_actor_id) {
    return null;
  }

  return company.facebook_integration.instagram_actor_id;
}

export default { currency, integration, facebookPage, instagramPage };
