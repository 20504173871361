/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface UpdateLanguageProfileInput
 */
export interface UpdateLanguageProfileInput {
    /**
     * 
     * @type {string}
     * @memberof UpdateLanguageProfileInput
     */
    language: UpdateLanguageProfileInputLanguageEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum UpdateLanguageProfileInputLanguageEnum {
    En = 'en',
    Tr = 'tr',
    Ro = 'ro',
    Pl = 'pl',
    El = 'el'
}



