import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12.3408 11.6285L14.7599 16.4086C14.9783 16.8407 14.8052 17.3679 14.3732 17.5863L11.766 18.9056C11.3339 19.1239 10.8067 18.9508 10.5884 18.5188L8.16926 13.7387L4 16.3483L4.00929 1.8778C4.00929 1.15544 4.83501 0.743162 5.4122 1.17751L16.9108 9.81449L12.3408 11.6285Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
