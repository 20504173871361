import React from 'react';

import { useLocale } from '@app/hooks';
import { ACTIVITY_LOG_TYPES } from '@app/constants';
import { date } from '@app/lib';
import type { GmAuditLog as GmAuditLogType, AuditableCampaign as AuditableCampaignType } from '@app/api';

import AuditMessageBox from '../AuditMessageBox';

const AuditableCampaign = (activities: GmAuditLogType | any) => {
  const { t } = useLocale();
  const activitiesData = activities?.activity;

  const time = date.format(activitiesData.created_at, 'dd/MM/yyyy HH:mm');
  const log = activitiesData.log;
  const auditable = activitiesData.auditable as AuditableCampaignType;
  const changedBy = activitiesData.company?.name ?? '';

  function getAuditMessage() {
    if (log === ACTIVITY_LOG_TYPES.COMPANY_CAMPAIGN_CREATED) {
      return t(`campaign.created`, { scope: 'activities', name: auditable.name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.COMPANY_CAMPAIGN_UPDATED) {
      return t(`campaign.updated`, { scope: 'activities', by: changedBy, time: time });
    }

    return '';
  }

  return <AuditMessageBox getAuditMessage={getAuditMessage} />;
};
export default AuditableCampaign;
