import React from 'react';

import ReactCountryFlag from 'react-country-flag';
import cn from 'classnames';

import { Box } from '@app/components';
import { api, useLocale } from '@app/hooks';

type LanguageCardPropTypes = {
  className?: string;
};

const LanguageCard: React.FC<LanguageCardPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.LanguageCard',
  };
  const { locale, changeLocale, locales, t } = useLocale();
  const { mutate } = api.useUpdateLanguageProfile();

  function handleUpdate(key: string) {
    changeLocale(key);
    mutate({ language: key });
  }

  return (
    <Box className={className}>
      <div className="flex items-center justify-between">
        <span className="font-semibold text-4 text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</span>
        <div className="flex flex-row gap-4">
          {locales.map(({ key, flag, text }) => (
            <button
              key={key}
              className={cn('flex items-center justify-center border-0.5 rounded-2 py-1.5 px-2 w-30 text-4', {
                'border-blue-300 bg-blue-400 text-blue-400 bg-opacity-10': key === locale,
                'border-gray-400 text-gray-600 bg-gray-50 ': key !== locale,
              })}
              onClick={() => handleUpdate(key)}
            >
              <ReactCountryFlag
                countryCode={flag}
                svg
                style={{
                  marginRight: '0.5rem',
                  fontSize: '1.25em',
                  lineHeight: '1.25em',
                }}
              />
              {text}
            </button>
          ))}
        </div>
      </div>
    </Box>
  );
};

export default LanguageCard;
