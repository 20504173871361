import Link from 'next/link';

import Slider from 'react-slick';
import cn from 'classnames';

import { useLocale } from '@app/hooks';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import Logo from './Logo';

type BannerPropTypes = {
  className?: string;
};

type DotTypes = {
  props: {
    className: string;
  };
};

const Banner: React.FC<BannerPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AuthLayout.Banner',
  };
  const { t } = useLocale();

  const sliderSettings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dotsClass: 'py-2 h-6 flex flex-row justify-center absolute -bottom-12 w-full',
    appendDots: (dots: DotTypes[]) => (
      <div>
        {dots.map((dot, index: number) => (
          <div key={index} {...dot.props}>
            <div
              className={cn('cursor-pointer mx-1.5 h-2 w-2 rounded-full inline-flex text-transparent', {
                'bg-white': dot.props.className === 'slick-active',
                'bg-white bg-opacity-30': dot.props.className !== 'slick-active',
              })}
            >
              {index}
            </div>
          </div>
        ))}
      </div>
    ),
  };

  return (
    <div className={cn('relative flex flex-col items-center text-center py-20 px-14 min-h-full bg-blue-300', className)}>
      <Link href="/">
        <a>
          <Logo />
        </a>
      </Link>
      <Slider {...sliderSettings} className="w-full mt-16">
        <div>
          <h3 className="text-6 leading-8 font-medium text-white">{t('slider.slide1', SCOPE_OPTIONS)}</h3>
        </div>
        <div>
          <h3 className="text-6 leading-8 font-medium text-white">{t('slider.slide2', SCOPE_OPTIONS)}</h3>
        </div>
        <div>
          <h3 className="text-6 leading-8 font-medium text-white">{t('slider.slide3', SCOPE_OPTIONS)}</h3>
        </div>
      </Slider>
      <img src="/images/login-banner.svg" className="absolute left-0 bottom-0 w-full" alt="" />
    </div>
  );
};

export default Banner;
