/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2WalletTransactionsSuccessResponseCampaign
 */
export interface V2WalletTransactionsSuccessResponseCampaign {
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseCampaign
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseCampaign
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseCampaign
     */
    platform?: V2WalletTransactionsSuccessResponseCampaignPlatformEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum V2WalletTransactionsSuccessResponseCampaignPlatformEnum {
    Google = 'GOOGLE',
    Facebook = 'FACEBOOK',
    Dynamiclocationads = 'DYNAMICLOCATIONADS'
}



