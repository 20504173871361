import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useWait } from 'react-wait';

import { Button, Editor } from '@app/components';
import { GOOGLE_PLACEMENT_TYPE, PUBLISHER_PLATFORMS } from '@app/constants';
import { api, useLocale, useAuth } from '@app/hooks';
import { image as imageLib } from '@app/lib';

type ImageDataType = {
  id: string;
  file: {
    url: string;
  };
};

type ImageUploadPropTypes = {
  size: {
    width: number;
    height: number;
  };
  type: string | keyof typeof GOOGLE_PLACEMENT_TYPE;
  platforms: string[];
  index: number;
  defaultValue?: any;
};

const IMAGE_LOADER_KEY = 'image-mutate-loader-key';

const ImageUpload: React.FC<ImageUploadPropTypes> = ({ platforms, type: imageType, size, index, defaultValue }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.ImageUpload',
  };
  const { t } = useLocale();
  const { startWaiting, endWaiting } = useWait();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const {
    resetField,
    clearErrors,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();

  const { companyId } = useAuth();
  const { mutate: mutateGooglePhoto } = api.useUploadGoogleAdTypePhoto({
    onSuccess: handleImageUpload,
    onSettled: () => endWaiting(IMAGE_LOADER_KEY),
  });
  //FIXME: lowerCase
  const type = String(imageType).toLocaleLowerCase();
  const fieldName = `${type}_${index}`;
  const name = `images.${type}`;
  const field = `images.${fieldName}`;
  const imageWatch = watch(field);

  const googleVariantType =
    imageType === GOOGLE_PLACEMENT_TYPE.MARKETING_IMAGE ? (imageType as keyof typeof GOOGLE_PLACEMENT_TYPE) : undefined;

  function handleRequriedInputCount() {
    if (imageType === GOOGLE_PLACEMENT_TYPE.SQUARE_MARKETING_IMAGE) {
      return 3;
    }
    if (imageType === GOOGLE_PLACEMENT_TYPE.PORTRAIT_MARKETING_IMAGE) {
      return 1;
    }
    if (imageType === GOOGLE_PLACEMENT_TYPE.MARKETING_IMAGE) {
      return 3;
    }
    if (imageType === GOOGLE_PLACEMENT_TYPE.LOGO) {
      return 1;
    }
    return 0;
  }

  useEffect(() => {
    register(field, {
      required: { value: index < handleRequriedInputCount() ? true : false, message: t(`form.${name}.errors.required`, SCOPE_OPTIONS) },
    });
  }, []);

  useEffect(() => {
    defaultValue && setValue(field, defaultValue);
  }, [defaultValue]);

  function handleImageUpload(data: ImageDataType) {
    setValue(`${field}.url`, data.file.url);
    setValue(`${field}.id`, data.id);
    setValue(`${field}.placement_type`, imageType);
  }

  const Error: React.FC = () => {
    if (errors.images) {
      if (Object.prototype.hasOwnProperty.call(errors.images, fieldName)) {
        return (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{errors.images[fieldName].message}</span>
          </div>
        );
      }
    }

    return null;
  };

  async function handleUploadImage(imageObject: { url: string; json: object }) {
    const file = await imageLib.urlToFile(imageObject.url);

    clearErrors(field);
    setIsEditorOpen(false);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('extra', JSON.stringify({ json: imageObject.json }));
    formData.append('placement_type', imageType);

    const payload = {
      companyId: companyId,
      payload: formData,
    };

    if (platforms.includes(PUBLISHER_PLATFORMS.GOOGLE)) {
      startWaiting(IMAGE_LOADER_KEY);
      mutateGooglePhoto(payload);
      return;
    }
  }

  function handleOpenEditor() {
    if (!imageWatch) {
      setIsEditorOpen(true);
    }
  }

  return (
    <button
      type="button"
      className={cn('w-full', {
        'cursor-default': !!imageWatch,
        'cursor-pointer': !imageWatch,
      })}
      onClick={handleOpenEditor}
    >
      <div className="flex items-center justify-between">
        {index === 0 ? (
          <label className="block text-sm font-semibold text-gray-900">
            {t(`form.${name}.label`, SCOPE_OPTIONS)}
            <span className="text-red-400">*</span>
          </label>
        ) : (
          <span className="block text-sm font-semibold text-gray-900"></span>
        )}
        <Error />
      </div>
      <div
        className={cn('mt-1.5 border-0.5 border-dashed rounded-1 h-13 flex items-center justify-between px-4', {
          'border-gray-500': !errors.image,
          'border-red-400': !!errors.image,
        })}
      >
        {!imageWatch && <span className="text-gray-500 font-medium">{t(`form.${name}.placeholder`, SCOPE_OPTIONS)}</span>}
        {!!imageWatch && (
          <>
            <span className="text-gray-500 font-medium"> {t(`form.${name}.success`, SCOPE_OPTIONS)}</span>
            <Button
              type="button"
              theme="yellow"
              size="xs"
              label={t('actions.clear', SCOPE_OPTIONS)}
              onClick={() => resetField(field)}
              block={false}
            />
          </>
        )}
      </div>
      <Editor
        size={size}
        googleVariant={googleVariantType}
        isOpen={isEditorOpen}
        onClose={() => setIsEditorOpen(false)}
        onSaveClick={handleUploadImage}
      />
    </button>
  );
};

export default ImageUpload;
