import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { GroupsGMApi } from '@app/api';
import type { GroupAssignInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function assignGroup(
  companyId: string,
  groupId: string,
  groupAssignInput: GroupAssignInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new GroupsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.assignGroup(companyId, groupId, groupAssignInput).then((res) => res.data);
}

export default function useAssignGroup(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; groupId: string; groupAssignInput: GroupAssignInput }) =>
      assignGroup(values.companyId, values.groupId, values.groupAssignInput, axiosInstance),
    props
  );
}
