import React, { useState, forwardRef } from 'react';
import { FieldError } from 'react-hook-form';

import cn from 'classnames';

type InputPropTypes = {
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement>;
  name: string;
  maxLength?: number;
  rows?: number;
  length?: number;
  defaultValue?: string | number;
  disabled?: boolean;
  requiredSign?: boolean;
};

export const Input: React.ForwardRefRenderFunction<HTMLTextAreaElement, React.PropsWithChildren<InputPropTypes>> = (
  {
    label,
    id,
    error,
    className,
    onChange,
    onBlur,
    name,
    defaultValue,
    maxLength,
    rows,
    length,
    disabled = false,
    requiredSign = false,
    ...props
  },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLTextAreaElement>) {
    setIsFocused(false);
    onBlur && onBlur(event);
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {requiredSign && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <div className="mt-1.5 relative w-full">
        <textarea
          ref={ref}
          onFocus={onFocus}
          name={name}
          onChange={onChange}
          onBlur={handleOnBlur}
          className={cn(
            'py-3.5 px-4 border rounded-2.5 font-normal text-sm text-gray-900 placeholder-gray-500 outline-none w-full resize-none',
            {
              'bg-white border-gray-400': !error,
              'bg-white border-red-400': error,
              'border-gray-600': !error && isFocused,
              'bg-gray-200 select-none': disabled,
            }
          )}
          id={id}
          rows={rows}
          defaultValue={defaultValue}
          disabled={disabled}
          readOnly={disabled}
          maxLength={maxLength}
          {...props}
        />
        {maxLength && (
          <span className="absolute right-3.5 bottom-2.5 text-gray-500 text-3.5 font-normal select-none">{`${
            length ?? 0
          }/${maxLength}`}</span>
        )}
      </div>
    </div>
  );
};

export default forwardRef(Input);
