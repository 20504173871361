/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface TemplateTargeting
 */
export interface TemplateTargeting {
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateTargeting
     */
    publisher_platforms?: Array<TemplateTargetingPublisherPlatformsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateTargeting
     */
    facebook_positions?: Array<TemplateTargetingFacebookPositionsEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateTargeting
     */
    instagram_positions?: Array<TemplateTargetingInstagramPositionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof TemplateTargeting
     */
    sub_campaign_type?: TemplateTargetingSubCampaignTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum TemplateTargetingPublisherPlatformsEnum {
    Facebook = 'facebook',
    Instagram = 'instagram',
    Messenger = 'messenger',
    AudienceNetwork = 'audience_network',
    Google = 'google'
}
/**
    * @export
    * @enum {string}
    */
export enum TemplateTargetingFacebookPositionsEnum {
    Feed = 'feed',
    RightHandColumn = 'right_hand_column',
    InstantArticle = 'instant_article',
    Marketplace = 'marketplace',
    VideoFeeds = 'video_feeds',
    Story = 'story',
    Search = 'search',
    InstreamVideo = 'instream_video'
}
/**
    * @export
    * @enum {string}
    */
export enum TemplateTargetingInstagramPositionsEnum {
    Stream = 'stream',
    Story = 'story',
    Shop = 'shop',
    Explore = 'explore',
    Reels = 'reels'
}
/**
    * @export
    * @enum {string}
    */
export enum TemplateTargetingSubCampaignTypeEnum {
    Pmax = 'PMAX',
    Smart = 'SMART'
}



