import { DateTime, Settings } from 'luxon';

function JSDateToISO(date: Date) {
  return DateTime.fromJSDate(date).toISO();
}

function ISOtoJSDate(dateTimeInUtc: string) {
  return DateTime.fromISO(dateTimeInUtc).toJSDate();
}

function format(dateTimeInUtc: string, exportFormat: string, options = {}) {
  return DateTime.fromISO(dateTimeInUtc, options).toFormat(exportFormat);
}

function fromFormatToFormat(date: string, dateFormat: string, exportFormat: string) {
  return DateTime.fromFormat(date, dateFormat).toFormat(exportFormat);
}

function plusFromNow(params: object) {
  return DateTime.now().plus(params).toString();
}

function minusFromNow(params: object) {
  return DateTime.now().minus(params).toString();
}

function now() {
  return DateTime.now().toString();
}

function yesterday() {
  return DateTime.now().minus({ days: 1 }).toString();
}

function setDefaultLocale(locale: string) {
  Settings.defaultLocale = locale;
}

export default {
  JSDateToISO,
  format,
  plusFromNow,
  fromFormatToFormat,
  ISOtoJSDate,
  minusFromNow,
  now,
  yesterday,
  setDefaultLocale,
};
