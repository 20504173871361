import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form, Icons } from '@app/components';
import { GOOGLE_TEXT_TYPE, PUBLISHER_PLATFORMS } from '@app/constants';
import { useActiveInput, useLocale } from '@app/hooks';

import Text from '../GoogleForm/Text';
import Platform from './Platform';

const GoogleSmartForm = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.GoogleSmartForm.index',
  };
  const { t } = useLocale();
  const {
    register,
    setValue,
    formState: { errors },
    getValues,
  } = useFormContext();

  const activeInput = useActiveInput();

  const headline = getValues('creative')?.google_headline;
  const description = getValues('creative')?.google_description;

  const PLATFORMS = [
    { key: PUBLISHER_PLATFORMS.SMART, label: t('form.platforms.smart', SCOPE_OPTIONS), Icon: Icons.GoogleIcon, disabled: false },
    { key: PUBLISHER_PLATFORMS.PMAX, label: t('form.platforms.pmax', SCOPE_OPTIONS), Icon: Icons.GoogleIcon, disabled: true },
  ];

  function handlePlatformClick(platform: string) {
    setValue('targeting.platforms', platform);
  }

  function inputVariables(id: string) {
    if (activeInput !== id) {
      return null;
    }

    const currentText = getValues(id);

    function handleText(value: string) {
      if (!currentText) {
        setValue(id, value);
        return;
      }

      setValue(id, `${currentText} ${value}`);
    }

    return <Form.BranchButtons className="mb-4.5 -mt-1.5" onClick={handleText} />;
  }

  return (
    <form>
      <Box>
        <Platform platforms={PLATFORMS} onClick={handlePlatformClick} />
      </Box>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          className="mb-4"
        />
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 5 }).map((_, index) => (
          <Text
            key={index}
            type={GOOGLE_TEXT_TYPE.HEADLINE}
            rows={1}
            size={30}
            index={index}
            defaultValue={headline && headline[index]}
            inputVariables={inputVariables}
          />
        ))}
      </Box>
      <Box className="space-y-3">
        {Array.from({ length: 4 }).map((_, index) => (
          <Text
            key={index}
            type={GOOGLE_TEXT_TYPE.DESCRIPTION}
            rows={2}
            size={90}
            index={index}
            defaultValue={description && description[index]}
            inputVariables={inputVariables}
          />
        ))}
      </Box>
    </form>
  );
};

export default GoogleSmartForm;
