const CUSTOM_FONTS = Object.freeze([
  {
    family: 'Michelin Black',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Michelin Bold',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Michelin Light',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Michelin Regular',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Michelin SemiBold',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Volkswagen',
    variants: ['regular'],
    subsets: ['latin'],
    category: 'display',
  },
  {
    family: 'Biennale Regular',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Biennale Bold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Ridley Grotesk Medium',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Ridley Grotesk Bold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans Bold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans BoldItalic',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans ExtraBold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans ExtraBoldItalic',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans Italic',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans Light',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans LightItalic',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans Regular',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans Semibold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'OpenSans SemiboldItalic',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin Bold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin ExtraLight',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin Light',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin Medium',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin Regular',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin SemiBold',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
  {
    family: 'Palanquin Thin',
    subsets: ['latin'],
    category: 'display',
    variants: ['regular'],
  },
]);

export default CUSTOM_FONTS;
