import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';
import { useWait } from 'react-wait';

import { Button, Editor } from '@app/components';
import { PUBLISHER_PLATFORMS } from '@app/constants';
import { api, useLocale, useAuth } from '@app/hooks';
import { image as imageLib } from '@app/lib';

type ImageDataType = {
  id: string;
  file: {
    url: string;
  };
};

type ImageUploadPropTypes = {
  size: {
    width: number;
    height: number;
  };
  type: string;
  platforms: string[];
};

const IMAGE_LOADER_KEY = 'image-mutate-loader-key';

const ImageUpload: React.FC<ImageUploadPropTypes> = ({ platforms, type: imageType, size = { width: 1080, height: 1080 } }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.ImageUpload',
  };
  const { t } = useLocale();
  const { startWaiting, endWaiting } = useWait();
  const [isEditorOpen, setIsEditorOpen] = useState(false);
  const {
    resetField,
    clearErrors,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const { companyId } = useAuth();
  const { mutate: mutateFacebookPhoto } = api.useUploadFacebookAdTypePhoto({
    onSuccess: handleImageUpload,
    onSettled: () => endWaiting(IMAGE_LOADER_KEY),
  });
  const { mutate: mutateInstagramPhoto } = api.useUploadInstagramAdTypePhoto({
    onSuccess: handleImageUpload,
    onSettled: () => endWaiting(IMAGE_LOADER_KEY),
  });
  //FIXME: lowerCase
  const type = String(imageType).toLocaleLowerCase();
  const name = `images.${type}`;
  const imageWatch = watch(name);

  useEffect(() => {
    register(name, {
      required: { value: true, message: t(`form.${name}.errors.required`, SCOPE_OPTIONS) },
    });
  }, []);

  function handleImageUpload(data: ImageDataType) {
    setValue(`${name}.url`, data.file.url);
    setValue(`${name}.id`, data.id);
    setValue(`${name}.placement_type`, imageType);
  }

  const Error: React.FC = () => {
    if (errors.images) {
      if (Object.prototype.hasOwnProperty.call(errors.images, type)) {
        return (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{errors.images[type].message}</span>
          </div>
        );
      }
    }

    return null;
  };

  async function handleSend(imageObject: { url: string; json: object }) {
    const file = await imageLib.urlToFile(imageObject.url);

    clearErrors(name);
    setIsEditorOpen(false);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('extra', JSON.stringify({ json: imageObject.json }));

    const payload = {
      companyId: companyId,
      payload: formData,
    };

    if (platforms.includes(PUBLISHER_PLATFORMS.FACEBOOK)) {
      startWaiting(IMAGE_LOADER_KEY);
      mutateFacebookPhoto(payload);
      return;
    }

    if (platforms.includes(PUBLISHER_PLATFORMS.INSTAGRAM)) {
      startWaiting(IMAGE_LOADER_KEY);
      mutateInstagramPhoto(payload);
      return;
    }
  }

  function handleOpenEditor() {
    if (!imageWatch) {
      setIsEditorOpen(true);
    }
  }

  return (
    <button
      type="button"
      className={cn('w-full', {
        'cursor-default': !!imageWatch,
        'cursor-pointer': !imageWatch,
      })}
      onClick={handleOpenEditor}
    >
      <div className="flex items-center justify-between">
        <label className="block text-sm font-semibold text-gray-900">
          {t(`form.${name}.label`, SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
        <Error />
      </div>
      <div
        className={cn('mt-1.5 border-0.5 border-dashed rounded-1 h-13 flex items-center justify-between px-4', {
          'border-gray-500': !errors.image,
          'border-red-400': !!errors.image,
        })}
      >
        {!imageWatch && <span className="text-gray-500 font-medium">{t(`form.${name}.placeholder`, SCOPE_OPTIONS)}</span>}
        {!!imageWatch && (
          <>
            <span className="text-gray-500 font-medium"> {t(`form.${name}.success`, SCOPE_OPTIONS)}</span>
            <Button
              type="button"
              theme="yellow"
              size="xs"
              label={t('actions.clear', SCOPE_OPTIONS)}
              onClick={() => resetField(name)}
              block={false}
            />
          </>
        )}
      </div>
      <Editor size={size} isOpen={isEditorOpen} onClose={() => setIsEditorOpen(false)} onSaveClick={handleSend} />
    </button>
  );
};

export default ImageUpload;
