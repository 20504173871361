import { createContext, useContext } from 'react';

import useActionCable from './useActionCable';
import useAuth from './useAuth';

const CableProviderContext = createContext<any>(null);

export const useCableProvider = () => {
  return useContext(CableProviderContext);
};

const CableProvider = ({ children }: any) => {
  const { token } = useAuth();
  const { actionCable } = useActionCable(token?.access_token);

  return <CableProviderContext.Provider value={{ actionCable }}>{children}</CableProviderContext.Provider>;
};

export default CableProvider;
