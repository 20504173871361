import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.99994 4V5.5M9.99994 16V14.5M6.99994 12.255C6.99994 13.498 8.00694 14.505 9.24994 14.505H10.8929C12.0559 14.505 12.9999 13.562 12.9999 12.398C12.9999 11.432 12.3429 10.59 11.4059 10.355L8.59394 9.65C7.65694 9.415 6.99994 8.573 6.99994 7.607C6.99994 6.443 7.94294 5.5 9.10694 5.5H10.7499C11.9929 5.5 12.9999 6.507 12.9999 7.75M16.3639 3.63604C19.8787 7.15076 19.8787 12.8492 16.3639 16.3639C12.8492 19.8787 7.15074 19.8787 3.63604 16.3639C0.12132 12.8492 0.12132 7.15074 3.63604 3.63604C7.15076 0.12132 12.8492 0.12132 16.3639 3.63604Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
