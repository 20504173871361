import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 18 18.4"
    xmlSpace="preserve"
    fill="currentColor"
    {...props}
  >
    <g>
      <g>
        <path
          d="M8.3,12.6L3.5,7.8C3.2,7.5,3.2,7,3.5,6.6c0.3-0.3,0.8-0.3,1.2,0l3.2,3.2
           C8.1,10,8.2,10,8.2,9.7V0.8C8.2,0.4,8.5,0,9,0c0.5,0,0.8,0.3,0.8,0.8c0,0,0,0,0,0.1v8.9c0,0.2,0.1,0.3,0.3,0.1l3.2-3.2
           c0.3-0.3,0.8-0.3,1.2,0c0.3,0.3,0.3,0.8,0,1.2l-4.7,4.8C9.3,13,8.7,13,8.3,12.6C8.3,12.6,8.3,12.6,8.3,12.6z M16.4,16.4v-2.9
           c0-0.5,0.3-0.8,0.8-0.9c0.5,0,0.8,0.3,0.9,0.8c0,0,0,0.1,0,0.1v3.7c0,0.7-0.6,1.2-1.2,1.2c0,0,0,0,0,0H1.2c-0.7,0-1.2-0.5-1.2-1.2
           v-3.7c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v2.9c0,0.2,0.2,0.4,0.4,0.4H16C16.2,16.8,16.4,16.6,16.4,16.4L16.4,16.4z"
        />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
