import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="94" height="94" fill="none" viewBox="0 0 94 94" {...props}>
    <path
      fill="currentColor"
      d="M94 57.444v26.112A10.444 10.444 0 0183.556 94H10.444A10.444 10.444 0 010 83.556V10.444A10.444 10.444 0 0110.444 0h26.112v10.444H10.444v73.112h73.112V57.444H94z"
    ></path>
    <path fill="currentColor" d="M94 20.4H73.6V0H63.4v20.4H43v10.2h20.4V51h10.2V30.6H94V20.4z"></path>
  </svg>
);

export default SvgComponent;
