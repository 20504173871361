import React, { forwardRef } from 'react';

import { Controller, type FieldError } from 'react-hook-form';
import cn from 'classnames';

import { company as companyLib } from '@app/lib';
import useAuth from '@app/hooks/useAuth';

type BudgetInputPropTypes = {
  type: string;
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  value?: string | number;
  defaultValue?: string | number;
  disabled?: boolean;
  autoComplete?: string;
  requiredSign?: boolean;
  control?: any;
  rules?: any;
};

export const BudgetInput: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<BudgetInputPropTypes>> = (
  {
    type = 'text',
    label,
    id,
    error,
    className,
    onChange,
    onBlur,
    value,
    name,
    defaultValue,
    disabled = false,
    requiredSign = false,
    control,
    rules,
    ...props
  },
  ref
) => {
  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    onBlur && onBlur(event);
  }
  const { company } = useAuth();

  const currency = companyLib.currency(company);

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        {label && (
          <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
            {label}
            {requiredSign && <span className="text-red-400">*</span>}
          </label>
        )}
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <span className="relative w-80">
        <div className="h-10 w-10 absolute top-1 right-1 flex justify-center items-center text-5.5 border-l border-gray-400">
          <span className="text-4.5">{currency}</span>
        </div>
        <Controller
          name="campaignBudget"
          control={control}
          rules={rules}
          defaultValue={defaultValue}
          render={() => (
            <input
              ref={ref}
              name={name}
              type={type}
              id={id}
              value={value}
              onChange={onChange}
              onBlur={handleOnBlur}
              defaultValue={defaultValue}
              onFocus={(event) => event.target.select()}
              className={cn(
                'py-2.5 pr-12 border border-gray-400 rounded-2.5 text-lg font-semibold text-blue-600 placeholder-gray-500 outline-none w-80',
                {
                  'bg-white border-gray-400': !error,
                  'bg-white border-red-400': error,
                  'bg-gray-200 select-none': disabled,
                }
              )}
              disabled={disabled}
              readOnly={disabled}
              {...props}
            />
          )}
        />
      </span>
    </div>
  );
};

export default forwardRef(BudgetInput);
