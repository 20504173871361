function group(items = [], parent) {
  if (items.lenght === 0) return [];

  const filteredItems = items.filter((item) => item.parent !== parent);

  return items
    .filter((item) => item.key !== parent)
    .map((item) => {
      if (item.parent === parent) {
        return {
          id: item?.id,
          type: item?.type,
          key: item?.key,
          value: item?.id,
          label: item.name,
          children: group(filteredItems, item.key),
        };
      }

      return null;
    })
    .filter((item) => item !== null)
    .filter((item) => item.children.length !== 0 || item.id !== undefined)
    .map((item) => {
      if (item.children.length === 0) {
        return {
          key: item.key,
          type: item.type,
          value: item.value,
          label: item.label,
        };
      }

      return item;
    });
}

function dataToInput(targeting) {
  let interests = [];
  const items = [
    'education_statuses',
    'education_majors',
    'education_schools',
    'college_years',
    'life_events',
    'work_employers',
    'industries',
    'work_positions',
    'interests',
    'behaviors',
  ];

  for (const item of items) {
    if (Object.prototype.hasOwnProperty.call(targeting, item)) {
      const handledItems = targeting[item].map((interest) => ({
        ...interest,
        type: item,
      }));

      interests = [...interests, ...handledItems];
    }
  }

  return interests;
}

function groupByType(items = []) {
  return items.reduce((group, interest) => {
    const { type } = interest;
    group[type] = group[type] ?? [];
    group[type].push({ id: Number(interest.value), name: interest.label });
    return group;
  }, {});
}

export default {
  dataToInput,
  group,
  groupByType,
};
