import React from 'react';

import { useLocale } from '@app/hooks';

import type { TemplatesPreviewListResponseAttachments, TemplatesPreviewListResponseAttachmentsPlacementTypeEnum } from '@app/api';
import IMAGE_PLACEMENT_TYPE from 'constants/imagePlacementTypes';

type InstagramFeedPropTypes = {
  companyName: string;
  message: string;
  images?: TemplatesPreviewListResponseAttachments[];
};

const InstagramFeed: React.FC<InstagramFeedPropTypes> = ({ companyName, message, images }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Preview.InstagramFeed',
  };
  const { t } = useLocale();
  const imgUrl = images?.find(
    (image) => image?.placement_type === (IMAGE_PLACEMENT_TYPE.FEED as TemplatesPreviewListResponseAttachmentsPlacementTypeEnum)
  )?.file?.url as string;

  return (
    <div className="bg-white rounded-3 drop-shadow-box w-75 select-none">
      <div className="pt-4 px-3.5 pb-3.5 ">
        <div className="flex flex-row">
          <div className="h-10.5 w-10.5 rounded-full bg-gray-400" />
          <div className="flex flex-col ml-2">
            <span className="font-medium text-3.5 text-gray-900">{companyName}</span>
            <span className="leading-none text-gray-700 text-3 font-medium">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
        </div>
      </div>
      <img src={imgUrl} alt="template" className="object-cover" width="300" height="300" />
      <div className="w-full px-2">
        <span className="bg-gray-200 block text-center text-3 text-gray-900 bg-gray-20 rounded-1 leading-none w-full p-2">
          {t('labels.action', SCOPE_OPTIONS)}
        </span>
      </div>
      <div className="px-3.5 pb-4 pt-3 flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <span className="text-gray-900 text-3">{t('labels.likes', SCOPE_OPTIONS)}</span>
          <div></div>
          <span className="text-gray-900 text-3 line-clamp-3">
            <span className="font-bold mr-1">{t('labels.username', SCOPE_OPTIONS)}</span>
            {message}
          </span>
        </div>
      </div>
    </div>
  );
};

export default InstagramFeed;
