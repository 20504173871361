import React from 'react';

import { useLocale } from '@app/hooks';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

import type { TemplatesPreviewListResponseAttachments, TemplatesPreviewListResponseAttachmentsPlacementTypeEnum } from '@app/api';

type FacebookFeedPropTypes = {
  companyName: string;
  message: string;
  headline: string;
  description: string;
  images?: TemplatesPreviewListResponseAttachments[];
};

const FacebookFeed: React.FC<FacebookFeedPropTypes> = ({ companyName, images, message, headline, description }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Preview.FacebookFeed',
  };
  const { t } = useLocale();

  const imgUrl =
    (images?.find(
      (image) => image?.placement_type === (IMAGE_PLACEMENT_TYPE.FEED as TemplatesPreviewListResponseAttachmentsPlacementTypeEnum)
    )?.file?.url as string) || '';

  return (
    <div className="bg-white rounded-3 drop-shadow-box w-75 select-none">
      <div className="pt-4 px-3.5 pb-3.5 ">
        <div className="flex flex-row">
          <div className="h-10.5 w-10.5 rounded-full bg-gray-400" />
          <div className="flex flex-col ml-2">
            <span className="font-medium text-3.5 text-gray-900">{companyName}</span>
            <span className="leading-none text-gray-700 text-3 font-medium">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
        </div>
        <p className="text-gray-900 mt-3.5 text-3 line-clamp-3">{message}</p>
      </div>
      <img src={imgUrl} alt="template" className="object-cover" width="300" height="300" />
      <div className="px-3.5 pb-4 pt-3 flex flex-row items-center justify-between">
        <div className="flex flex-col">
          <span className="text-gray-900 font-medium text-3 line-clamp-1">{headline}</span>
          <span className="text-gray-900 text-3 line-clamp-2">{description}</span>
        </div>

        <span className="text-gray-900 text-3 border border-gray-900 rounded-1 py-1 px-2 whitespace-nowrap ml-2">
          {t('labels.action', SCOPE_OPTIONS)}
        </span>
      </div>
    </div>
  );
};

export default FacebookFeed;
