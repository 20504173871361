import React, { useEffect } from 'react';
import Image from 'next/image';

import { useLocale } from '@app/hooks';

type FacebookFeedPropTypes = {
  previews: any;
};

const DynamicLocationAdsPreview: React.FC<FacebookFeedPropTypes> = ({ previews }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.PreviewGroup.index',
  };
  const { t } = useLocale();

  const [feedPreview, setFeedPreview] = React.useState<any>();
  const [storyPreview, setStoryPreview] = React.useState<any>();

  useEffect(() => {
    if (previews?.feed) setFeedPreview(previews.feed);
    if (previews?.story) setStoryPreview(previews.story);
  }, [previews]);

  if (!feedPreview && !storyPreview) {
    return (
      <div className="flex items-center justify-center h-full">
        <span className="px-6 py-3 rounded-3 bg-blue-300 bg-opacity-10 text-blue-300 text-4 select-none">
          {t('labels.empty', SCOPE_OPTIONS)}
        </span>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-3 drop-shadow-box w-140 select-none flex gap-4 p-6">
      <div className="flex flex-col gap-3">
        <span className="text-center">{t('titles.short', SCOPE_OPTIONS)}</span>
        {feedPreview && (
          <Image
            src={
              previews?.feed?.preview_url?.normal_preview?.toString() ||
              previews?.feed?.preview_url?.short_preview?.toString() ||
              previews?.feed?.file?.url ||
              ''
            }
            alt="template"
            className="object-cover z-1"
            width="360"
            height="360"
          />
        )}
        {storyPreview && (
          <Image
            src={
              previews?.story?.preview_url?.normal_preview?.toString() ||
              previews?.story?.preview_url?.short_preview?.toString() ||
              previews?.story?.file?.url ||
              ''
            }
            alt="template"
            className="object-cover z-1"
            width="360"
            height="640"
          />
        )}
      </div>
      <div className="flex flex-col gap-3">
        <span className="text-center">{t('titles.long', SCOPE_OPTIONS)}</span>
        {feedPreview && (
          <Image
            src={
              previews?.feed?.preview_url?.normal_preview?.toString() ||
              previews?.feed?.preview_url?.long_preview?.toString() ||
              previews?.feed?.file?.url ||
              ''
            }
            alt="template"
            className="object-cover z-1"
            width="360"
            height="360"
          />
        )}
        {storyPreview && (
          <Image
            src={
              previews?.story?.preview_url?.normal_preview?.toString() ||
              previews?.story?.preview_url?.long_preview?.toString() ||
              previews?.story?.file?.url ||
              ''
            }
            alt="template"
            className="object-cover z-1"
            width="360"
            height="640"
          />
        )}
      </div>
    </div>
  );
};

export default DynamicLocationAdsPreview;
