import React, { useEffect, memo } from 'react';

import { useRouter } from 'next/router';

import { Box } from '@app/components';
import { api, useAuth, useToast, useLocale } from '@app/hooks';
import { company as companyLib } from '@app/lib';
import type { Company, CompaniesListResponse } from '@app/api';

import FacebookLoginButton from './FacebookLoginButton';

type IntegrationFormPropTypes = {
  className?: string;
};

const IntegrationForm: React.FC<IntegrationFormPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.IntegrationCard',
  };
  const { t } = useLocale();
  const { companyId, company, storeCompany } = useAuth();
  const toast = useToast();
  const router = useRouter();
  const {
    refetch,
    isLoading: isCompaniesLoading,
    isRefetching: isCompanieshRefetching,
  } = api.useListGeneralManagerCompanies({
    enabled: false,
    id: 'integrationFormQuery',
    onSuccess: (data: CompaniesListResponse) => {
      const foundedCompany = data?.companies.find((company: Company) => company.id === companyId);

      if (foundedCompany) {
        storeCompany(foundedCompany);

        setTimeout(() => {
          router.reload();
        }, 100);
      }
    },
  });
  const integration = companyLib.integration(company);
  const isLoading = isCompaniesLoading || isCompanieshRefetching;

  useEffect(() => {
    toast.dismissAll();
    setTimeout(() => {
      if (!integration) {
        toast.error(t('messages.connectAccount', SCOPE_OPTIONS), {
          autoClose: false,
          closeOnClick: false,
          draggable: false,
        });
      }
    }, 100);
  }, [integration]);

  function Content() {
    if (!integration) {
      return <FacebookLoginButton isLoading={isLoading} onComplete={() => refetch()} />;
    }

    return (
      <div className="flex flex-row items-center justify-between w-full">
        <div className="flex flex-col">
          <h2 className="font-semibold text-5.5 text-blue-300 truncate max-w-65">{integration?.ad_account_name ?? '-'}</h2>
          <p className="font-semibold text-3.5 text-gray-500">FACEBOOK</p>
        </div>
      </div>
    );
  }

  return (
    <Box className={className}>
      <div className="flex flex-col mb-4">
        <span className="font-semibold text-4 text-gray-900">{t('labels.title', SCOPE_OPTIONS)}</span>
        <p className="font-regular text-3.5 text-gray-400">{t('labels.description', SCOPE_OPTIONS)}</p>
      </div>
      <div>
        <Content />
      </div>
    </Box>
  );
};

export default memo(IntegrationForm, (prev, next) => {
  if (prev.className !== next.className) {
    return false;
  }

  return true;
});
