const GOOGLE_PREVIEW_TYPES = Object.freeze({
  MAPS: 'maps',
  YOUTUBE: 'youtube',
  GMAIL: 'gmail',
  SEARCH: 'search',
  DISPLAY_NEWTWORK: 'display_network',
  DISCOVERY: 'discovery',
});

export default GOOGLE_PREVIEW_TYPES;
