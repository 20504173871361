import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.0001 19.0001L14.7701 14.7701M14.7749 3.3634C17.9261 6.51459 17.9261 11.6237 14.7749 14.7749C11.6237 17.9261 6.51459 17.9261 3.3634 14.7749C0.212201 11.6237 0.212201 6.51459 3.3634 3.3634C6.51459 0.212201 11.6237 0.212201 14.7749 3.3634Z"
      stroke="currentColor"
      fill="transparent"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
