import React, { useEffect, useState } from 'react';

import { useFormContext } from 'react-hook-form';

import { Icons, Modal, Pagination, SearchInput } from '@app/components';
import { api, useAuth, useDebounce } from '@app/hooks';
import { Audience } from 'api/model';

const DATA_PER_PAGE = 15;
const DEBOUNCE_MS = 350;

type AudienceModalPropTypes = {
  isOpen: boolean;
  onClose: () => void;
};

const AudienceModal: React.FC<AudienceModalPropTypes> = ({ isOpen, onClose }) => {
  const { companyId } = useAuth();
  const { setValue } = useFormContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [query, setQuery] = useState('');
  const { debouncedValue: debouncedQuery } = useDebounce(query, DEBOUNCE_MS);
  const { data, refetch } = api.useListAudiences({
    enabled: isOpen,
    params: {
      page: currentPage,
      perPage: DATA_PER_PAGE,
      query: {
        s: 'created_at desc',
        name_cont_any: debouncedQuery,
      },
    },
  });
  const { mutate: deleteAudienceMutate } = api.useDeleteAudience({
    onSuccess: () => {
      onClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      refetch();
    }
  }, [isOpen]);

  function setInterests(audience: Audience) {
    const data = {
      gender: audience.fields.gender,
      ageMin: audience.fields.age_min,
      ageMax: audience.fields.age_max,
      locales: audience.fields.locales,
      interests: audience.fields.interests,
    };

    for (const [key, value] of Object.entries(data)) {
      setValue(key, value);
    }

    onClose();
  }

  function removeAudience(e: React.MouseEvent, id: string) {
    e.stopPropagation();
    alert('delete');
    deleteAudienceMutate({
      companyId: companyId,
      audienceId: id,
    });
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="px-2 py-3">
        <SearchInput onChange={setQuery} defaultValue={query} className="w-full pb-2" triggerOnEnter={false} />
        <div className="overflow-y-scroll h-50 px-1">
          {data?.audiences?.map((audience: Audience) => (
            <div
              aria-hidden
              key={audience.id}
              className="flex items-center justify-between hover:bg-blue-50 w-full cursor-pointer rounded-sm group p-2"
              onClick={() => setInterests(audience)}
            >
              <span>{audience.name}</span>
              <span
                role="button"
                aria-hidden
                className="invisible group-hover:visible px-2"
                onClick={(e) => removeAudience(e, audience.id)}
              >
                <Icons.Transh className="text-red-600" />
              </span>
            </div>
          ))}
        </div>
        {data?.meta?.pagination?.total_pages > 1 && (
          <div className="bg-white rounded-b-3 py-3 flex items-center justify-center border-t border-gray-300 mt-3">
            <Pagination
              canPreviousPage={data?.meta?.pagination?.previous !== null}
              canNextPage={data?.meta?.pagination?.next !== null}
              currentPage={currentPage}
              totalPageCount={data?.meta?.pagination?.total_pages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AudienceModal;
