import { AxiosInstance } from 'axios';

import { V2FileManagerCreateRequestInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';
import { useMutation } from 'react-query';

export async function createCategory(
  companyId: string,
  values: V2FileManagerCreateRequestInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  return axiosInstance
    ?.post(`/v2/general_manager_account/companies/${companyId}/file_manager`, values, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useCreateCategory(): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  return useMutation((values: V2FileManagerCreateRequestInput) => createCategory(companyId, values, axiosInstance));
}
