import React from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import type { Template as TemplateType } from '@app/api';
import { useLocale } from '@app/hooks';

type GoogleTemplateFormPropTypes = {
  templates: Array<TemplateType>;
  isEdit?: boolean;
};

const GoogleTemplateForm: React.FC<GoogleTemplateFormPropTypes> = ({ templates = [], isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.GoogleTemplateForm',
  };
  const { t } = useLocale();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Box>
        <Form.Select
          name="templateId"
          label={t('form.templateId.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.templateId.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={templates.map((template) => ({ value: template.id, label: template.name }))}
          error={errors.templateId}
          disabled={isEdit}
        />
      </Box>
    </>
  );
};

export default GoogleTemplateForm;
