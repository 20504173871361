const AUDITABLE_EVENT_TYPES = Object.freeze({
  BRANCH_USER: 'BranchUser',
  BRANCH: 'Branch',
  BRANCH_CAMPAIGN: 'Branch::Campaign',
  COMPANY:'Company',
  CAMPAIGN:'Campaign',
  TEMPLATE:'Template',
  FACEBOOK_INTEGRATION:'FacebookIntegration'
});

export default AUDITABLE_EVENT_TYPES;
