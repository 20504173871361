import React from 'react';

import { Box, Preview } from '@app/components';
import { PUBLISHER_PLATFORMS, INSTAGRAM_POSITIONS, FACEBOOK_POSITIONS } from '@app/constants';
import { useAuth, useLocale } from '@app/hooks';
import type { Template as TemplateType } from '@app/api';

const COMPANY_NAME = 'COMPANY_NAME';

type TemplatePreviewPropTypes = {
  template?: TemplateType;
  isDynamicLocationAds?: boolean;
};

const TemplatePreview: React.FC<TemplatePreviewPropTypes> = ({ template, isDynamicLocationAds }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.TemplatePreview',
  };
  const { company } = useAuth();
  const { t } = useLocale();

  if (!template) {
    return (
      <Box>
        <h4 className="text-gray-500 text-5 font-medium w-full text-center">{t('labels.selectTemplate', SCOPE_OPTIONS)}</h4>
      </Box>
    );
  }

  const previewProps = {
    companyName: company.name || COMPANY_NAME,
    message: template.creative.message ?? '',
    headline: template.creative.headline ?? '',
    description: template.creative.description ?? '',
    images: template.attachments,
  };
  const publisherPlatforms = template.targeting.publisher_platforms as string[];
  const facebookPositions = template.targeting.facebook_positions as string[];
  const instagramPositions = template.targeting.instagram_positions as string[];

  if (isDynamicLocationAds) {
    return (
      <div className="flex flex-row flex-wrap items-start justify-center gap-5 h-full overflow-y-scroll">
        <Preview.FacebookFeed {...previewProps} />
        <Preview.FacebookStory {...previewProps} />
        <Preview.InstagramFeed {...previewProps} />
        <Preview.InstagramStory {...previewProps} />
      </div>
    );
  }

  return (
    <div className="flex flex-row flex-wrap items-start justify-center gap-5">
      {publisherPlatforms?.includes(PUBLISHER_PLATFORMS.FACEBOOK) && (
        <>
          {facebookPositions?.includes(FACEBOOK_POSITIONS.FEED) && <Preview.FacebookFeed {...previewProps} />}
          {facebookPositions?.includes(FACEBOOK_POSITIONS.STORY) && <Preview.FacebookStory {...previewProps} />}
        </>
      )}
      {publisherPlatforms?.includes(PUBLISHER_PLATFORMS.INSTAGRAM) && (
        <>
          {instagramPositions?.includes(INSTAGRAM_POSITIONS.STREAM) && <Preview.InstagramFeed {...previewProps} />}
          {instagramPositions?.includes(FACEBOOK_POSITIONS.STORY) && <Preview.InstagramStory {...previewProps} />}
        </>
      )}
    </div>
  );
};

export default TemplatePreview;
