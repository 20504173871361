/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    group_type: GroupGroupTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Group
     */
    branch_count: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Group
     */
    branch_ids?: Array<string>;
}

/**
    * @export
    * @enum {string}
    */
export enum GroupGroupTypeEnum {
    Branchsight = 'branchsight',
    City = 'city',
    Location = 'location'
}



