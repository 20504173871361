const ANALYTICS_CONTENT = Object.freeze({
  IMPRESSION: {
    KEY: 'impression',
    COLOR: '#70B2FF',
  },
  CLICK: {
    KEY: 'click',
    COLOR: '#71D8E6',
  },
  COST: {
    KEY: 'cost',
    COLOR: '#FDA877',
  },
  CPC: {
    KEY: 'cpc',
    COLOR: '#9CD066',
  },
});

export default ANALYTICS_CONTENT;
