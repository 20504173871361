/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ContactForm } from '../model';
// @ts-ignore
import { ContactFormCreateRequestInput } from '../model';
// @ts-ignore
import { ContactFormListResponse } from '../model';
// @ts-ignore
import { ContactFormUpdateRequestInput } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * ContactFormGMApi - axios parameter creator
 * @export
 */
export const ContactFormGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create contact form for the company
         * @summary Create contact form for the company
         * @param {string} companyId The id of the company
         * @param {ContactFormCreateRequestInput} contactFormCreateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactFormForCompany: async (companyId: string, contactFormCreateRequestInput: ContactFormCreateRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createContactFormForCompany', 'companyId', companyId)
            // verify required parameter 'contactFormCreateRequestInput' is not null or undefined
            assertParamExists('createContactFormForCompany', 'contactFormCreateRequestInput', contactFormCreateRequestInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/contact_forms`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormCreateRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete contact form for the company
         * @summary Delete contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactFormForCompany: async (companyId: string, contactFormId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteContactFormForCompany', 'companyId', companyId)
            // verify required parameter 'contactFormId' is not null or undefined
            assertParamExists('deleteContactFormForCompany', 'contactFormId', contactFormId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/contact_forms/{contact_form_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"contact_form_id"}}`, encodeURIComponent(String(contactFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List contact forms for the company
         * @summary List contact forms for the company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContactFormsForCompany: async (companyId: string, page?: number, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listContactFormsForCompany', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/contact_forms`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show contact form for the company
         * @summary Show contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showContactFormForCompany: async (companyId: string, contactFormId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showContactFormForCompany', 'companyId', companyId)
            // verify required parameter 'contactFormId' is not null or undefined
            assertParamExists('showContactFormForCompany', 'contactFormId', contactFormId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/contact_forms/{contact_form_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"contact_form_id"}}`, encodeURIComponent(String(contactFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update contact form for the company
         * @summary Update contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {ContactFormUpdateRequestInput} contactFormUpdateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactFormForCompany: async (companyId: string, contactFormId: string, contactFormUpdateRequestInput: ContactFormUpdateRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateContactFormForCompany', 'companyId', companyId)
            // verify required parameter 'contactFormId' is not null or undefined
            assertParamExists('updateContactFormForCompany', 'contactFormId', contactFormId)
            // verify required parameter 'contactFormUpdateRequestInput' is not null or undefined
            assertParamExists('updateContactFormForCompany', 'contactFormUpdateRequestInput', contactFormUpdateRequestInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/contact_forms/{contact_form_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"contact_form_id"}}`, encodeURIComponent(String(contactFormId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(contactFormUpdateRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContactFormGMApi - functional programming interface
 * @export
 */
export const ContactFormGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContactFormGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create contact form for the company
         * @summary Create contact form for the company
         * @param {string} companyId The id of the company
         * @param {ContactFormCreateRequestInput} contactFormCreateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContactFormForCompany(companyId: string, contactFormCreateRequestInput: ContactFormCreateRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContactFormForCompany(companyId, contactFormCreateRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete contact form for the company
         * @summary Delete contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContactFormForCompany(companyId: string, contactFormId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContactFormForCompany(companyId, contactFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List contact forms for the company
         * @summary List contact forms for the company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listContactFormsForCompany(companyId: string, page?: number, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactFormListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listContactFormsForCompany(companyId, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show contact form for the company
         * @summary Show contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showContactFormForCompany(companyId: string, contactFormId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showContactFormForCompany(companyId, contactFormId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update contact form for the company
         * @summary Update contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {ContactFormUpdateRequestInput} contactFormUpdateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContactFormForCompany(companyId: string, contactFormId: string, contactFormUpdateRequestInput: ContactFormUpdateRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContactForm>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContactFormForCompany(companyId, contactFormId, contactFormUpdateRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContactFormGMApi - factory interface
 * @export
 */
export const ContactFormGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContactFormGMApiFp(configuration)
    return {
        /**
         * Create contact form for the company
         * @summary Create contact form for the company
         * @param {string} companyId The id of the company
         * @param {ContactFormCreateRequestInput} contactFormCreateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContactFormForCompany(companyId: string, contactFormCreateRequestInput: ContactFormCreateRequestInput, options?: any): AxiosPromise<ContactForm> {
            return localVarFp.createContactFormForCompany(companyId, contactFormCreateRequestInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete contact form for the company
         * @summary Delete contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContactFormForCompany(companyId: string, contactFormId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteContactFormForCompany(companyId, contactFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * List contact forms for the company
         * @summary List contact forms for the company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listContactFormsForCompany(companyId: string, page?: number, perPage?: number, options?: any): AxiosPromise<ContactFormListResponse> {
            return localVarFp.listContactFormsForCompany(companyId, page, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Show contact form for the company
         * @summary Show contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showContactFormForCompany(companyId: string, contactFormId: string, options?: any): AxiosPromise<ContactForm> {
            return localVarFp.showContactFormForCompany(companyId, contactFormId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update contact form for the company
         * @summary Update contact form for the company
         * @param {string} companyId The id of the company
         * @param {string} contactFormId The id of the contact form
         * @param {ContactFormUpdateRequestInput} contactFormUpdateRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContactFormForCompany(companyId: string, contactFormId: string, contactFormUpdateRequestInput: ContactFormUpdateRequestInput, options?: any): AxiosPromise<ContactForm> {
            return localVarFp.updateContactFormForCompany(companyId, contactFormId, contactFormUpdateRequestInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContactFormGMApi - interface
 * @export
 * @interface ContactFormGMApi
 */
export interface ContactFormGMApiInterface {
    /**
     * Create contact form for the company
     * @summary Create contact form for the company
     * @param {string} companyId The id of the company
     * @param {ContactFormCreateRequestInput} contactFormCreateRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApiInterface
     */
    createContactFormForCompany(companyId: string, contactFormCreateRequestInput: ContactFormCreateRequestInput, options?: any): AxiosPromise<ContactForm>;

    /**
     * Delete contact form for the company
     * @summary Delete contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApiInterface
     */
    deleteContactFormForCompany(companyId: string, contactFormId: string, options?: any): AxiosPromise<boolean>;

    /**
     * List contact forms for the company
     * @summary List contact forms for the company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApiInterface
     */
    listContactFormsForCompany(companyId: string, page?: number, perPage?: number, options?: any): AxiosPromise<ContactFormListResponse>;

    /**
     * Show contact form for the company
     * @summary Show contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApiInterface
     */
    showContactFormForCompany(companyId: string, contactFormId: string, options?: any): AxiosPromise<ContactForm>;

    /**
     * Update contact form for the company
     * @summary Update contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {ContactFormUpdateRequestInput} contactFormUpdateRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApiInterface
     */
    updateContactFormForCompany(companyId: string, contactFormId: string, contactFormUpdateRequestInput: ContactFormUpdateRequestInput, options?: any): AxiosPromise<ContactForm>;

}

/**
 * ContactFormGMApi - object-oriented interface
 * @export
 * @class ContactFormGMApi
 * @extends {BaseAPI}
 */
export class ContactFormGMApi extends BaseAPI implements ContactFormGMApiInterface {
    /**
     * Create contact form for the company
     * @summary Create contact form for the company
     * @param {string} companyId The id of the company
     * @param {ContactFormCreateRequestInput} contactFormCreateRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApi
     */
    public createContactFormForCompany(companyId: string, contactFormCreateRequestInput: ContactFormCreateRequestInput, options?: any) {
        return ContactFormGMApiFp(this.configuration).createContactFormForCompany(companyId, contactFormCreateRequestInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete contact form for the company
     * @summary Delete contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApi
     */
    public deleteContactFormForCompany(companyId: string, contactFormId: string, options?: any) {
        return ContactFormGMApiFp(this.configuration).deleteContactFormForCompany(companyId, contactFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List contact forms for the company
     * @summary List contact forms for the company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApi
     */
    public listContactFormsForCompany(companyId: string, page?: number, perPage?: number, options?: any) {
        return ContactFormGMApiFp(this.configuration).listContactFormsForCompany(companyId, page, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show contact form for the company
     * @summary Show contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApi
     */
    public showContactFormForCompany(companyId: string, contactFormId: string, options?: any) {
        return ContactFormGMApiFp(this.configuration).showContactFormForCompany(companyId, contactFormId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update contact form for the company
     * @summary Update contact form for the company
     * @param {string} companyId The id of the company
     * @param {string} contactFormId The id of the contact form
     * @param {ContactFormUpdateRequestInput} contactFormUpdateRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContactFormGMApi
     */
    public updateContactFormForCompany(companyId: string, contactFormId: string, contactFormUpdateRequestInput: ContactFormUpdateRequestInput, options?: any) {
        return ContactFormGMApiFp(this.configuration).updateContactFormForCompany(companyId, contactFormId, contactFormUpdateRequestInput, options).then((request) => request(this.axios, this.basePath));
    }
}
