import React, { useEffect } from 'react';

import Image from 'next/image';

import { api, useAuth, useLocale } from '@app/hooks';
import { Template as TemplateType } from 'api/model';

type DynamicLocationImageViewerTypes = {
  template: TemplateType;
};

const DynamicLocationImageViewer: React.FC<DynamicLocationImageViewerTypes> = ({ template }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.DynamicLocationImageViewer.index',
  };
  const { t } = useLocale();
  const { companyId } = useAuth();

  const { data: feedPreviews, mutate: getFeedPreviews } = api.useCreateDynamicLocationAdsTemplatePreview();
  const { data: storyPreviews, mutate: getStoryPreviews } = api.useCreateDynamicLocationAdsTemplatePreview();

  const feedPreviewExists =
    feedPreviews?.preview_url?.long_preview || feedPreviews?.preview_url?.short_preview || feedPreviews?.preview_url?.normal_preview;
  const storyPreviewExists =
    storyPreviews?.preview_url?.long_preview || storyPreviews?.preview_url?.short_preview || storyPreviews?.preview_url?.normal_preview;

  useEffect(() => {
    getFeedPreviews({
      companyId,
      previewInput: { template_id: { bannerbear_template_id: template?.external_ids?.bannerbear_feed_template_id } },
    });
    getStoryPreviews({
      companyId,
      previewInput: { template_id: { bannerbear_template_id: template?.external_ids?.bannerbear_story_template_id } },
    });
  }, [template]);

  return (
    <div className="flex flex-col gap-3 ">
      {(feedPreviewExists || storyPreviewExists) && <span className="text-sm font-semibold">{t('titles.short', SCOPE_OPTIONS)}</span>}
      {feedPreviewExists && (
        <Image
          src={feedPreviews?.preview_url?.normal_preview?.toString() || feedPreviews?.preview_url?.short_preview?.toString() || ''}
          alt="template"
          className="object-cover z-1 rounded-2"
          width="360"
          height="360"
        />
      )}
      {storyPreviewExists && (
        <Image
          src={storyPreviews?.preview_url?.normal_preview?.toString() || storyPreviews?.preview_url?.short_preview?.toString() || ''}
          alt="template"
          className="object-cover z-1 rounded-2"
          width="360"
          height="640"
        />
      )}

      {(feedPreviewExists || storyPreviewExists) && <span className="text-sm font-semibold">{t('titles.long', SCOPE_OPTIONS)}</span>}
      {feedPreviewExists && (
        <Image
          src={feedPreviews?.preview_url?.normal_preview?.toString() || feedPreviews?.preview_url?.long_preview?.toString() || ''}
          alt="template"
          className="object-cover z-1 rounded-2"
          width="360"
          height="360"
        />
      )}
      {storyPreviewExists && (
        <Image
          src={storyPreviews?.preview_url?.normal_preview?.toString() || storyPreviews?.preview_url?.long_preview?.toString() || ''}
          alt="template"
          className="object-cover z-1 rounded-2"
          width="360"
          height="640"
        />
      )}
    </div>
  );
};

export default DynamicLocationImageViewer;
