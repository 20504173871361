import { Fragment, useEffect } from 'react';

import { FieldError, useFieldArray, useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { Icons } from '@app/components';
import array from 'lib/array';

type CustomSegmentKeywordPropTypes = {
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  placeholder?: string;
  defaultValue?: Array<string>;
  requiredSign: boolean;
  disabled?: boolean;
};

const CustomSegmentKeyword: React.FC<CustomSegmentKeywordPropTypes> = ({
  label,
  id,
  error,
  requiredSign,
  disabled,
  placeholder,
  defaultValue,
}) => {
  const { watch, setValue, control, clearErrors } = useFormContext();
  const { append, remove } = useFieldArray({
    control,
    name: id,
  });
  const keywordsWatch = watch(id);

  useEffect(() => {
    setValue(id, defaultValue);
  }, []);

  useEffect(() => {
    if (!array.isEmpty(keywordsWatch)) {
      return clearErrors(id);
    }
  }, [keywordsWatch]);

  function handleKeywords(event: any) {
    if (event.key === 'Enter' && event.target.value !== '') {
      append(event.target.value.toLowerCase());
      event.target.value = '';
    } else if (event.key === 'Backspace' && event.target.value.length && event.target.value == 0) {
      const keywordsCopy = [...event.target.value.toLowerCase()];
      keywordsCopy.pop();
      event.preventDefault();
      append(keywordsCopy);
    }
  }

  return (
    <Fragment>
      <div className="flex items-center justify-between mb-1.5">
        <label className="block text-3.5 font-semibold text-gray-900">
          {label}
          {requiredSign && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error?.message}</span>
          </div>
        )}
      </div>
      {keywordsWatch && !array.isEmpty(keywordsWatch) && (
        <div className="flex mb-2 w-full flex-wrap gap-y-2">
          {keywordsWatch?.map((keyword: string, index: number) => (
            <div className="flex items-center border-[0.1rem] border-gray-200 bg-gray-200 rounded-1 h-full px-2 py-1 mx-1" key={index}>
              <span className="text-gray-900 text-3.5 capitalize">{keyword}</span>
              <Icons.Cross onClick={() => remove(index)} className="w-2 h-2 ml-2 !font-semibold" />
            </div>
          ))}
        </div>
      )}

      <input
        id={id}
        type="text"
        name="customeSegments"
        placeholder={placeholder}
        onKeyDown={(event) => handleKeywords(event)}
        className={cn(
          'py-3.5 border rounded-2.5 font-normal text-sm text-gray-900 placeholder-gray-500 outline-none w-full bg-white border-gray-400',
          {
            'bg-white !border-gray-400': !error,
            'bg-white !border-red-400 focus:!border-none': error,
            'bg-gray-200 select-none': disabled,
          }
        )}
      />
    </Fragment>
  );
};

export default CustomSegmentKeyword;
