import React from 'react';

import { FacebookProvider, Initialize } from 'react-facebook';

import { Button, Icons } from '@app/components';

declare const window: {
  FB: any;
};

type LeadGenPropTypes = {
  onModalClose: (response: any) => void;
  label: string;
  pageId: string;
  adAccountId: string;
};

const LeadGen: React.FC<LeadGenPropTypes> = ({ onModalClose, label, pageId, adAccountId }) => {
  return (
    <FacebookProvider appId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID as string} version="v14.0">
      <Initialize>
        {({ isReady }: any) => (
          <Button
            IconLeft={Icons.Plus}
            disabled={!isReady}
            label={label}
            size="sm"
            theme="green"
            block={false}
            onClick={() =>
              window.FB.ui(
                {
                  method: 'lead_gen',
                  page_id: pageId,
                  ad_account_id: adAccountId,
                },
                (response: any) => {
                  onModalClose && onModalClose(response);
                }
              )
            }
          />
        )}
      </Initialize>
    </FacebookProvider>
  );
};

export default LeadGen;
