import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" {...props}>
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g transform="translate(-274 -515)">
        <g transform="translate(270 511)">
          <g transform="translate(4 4)">
            <g>
              <path fill="#FBBC00" d="M7.98182 3.73698L24.1982 19.9532 19.9554 24.196 3.73918 7.97962z"></path>
              <path fill="#FBBC00" fillRule="nonzero" d="M0 17H22.933V23H0z"></path>
              <path fill="#34A853" d="M20 15.75684L24.2426 19.9994 7.97918 36.263 3.73654 32.0202z"></path>
              <path fill="#34A853" d="M17.0052 17.0004L23.0052 17.0004 23.0052 40.0004 17.0052 40.0004z"></path>
              <path fill="#EA4335" fillRule="nonzero" d="M17.005 0h6v20a3 3 0 01-6 0V0z"></path>
              <path fill="#EA4335" fillRule="nonzero" d="M32.018 3.737l4.243 4.243-14.142 14.142a3 3 0 11-4.243-4.243L32.018 3.737z"></path>
              <path fill="#4285F4" d="M17.005 20a3 3 0 013-3h20v6h-20a3 3 0 01-3-3z"></path>
              <path fill="#4285F4" d="M17.879 17.878a3 3 0 014.242 0L36.263 32.02l-4.242 4.243-14.142-14.142a3 3 0 010-4.243z"></path>
            </g>
          </g>
          <path d="M0 0H48V48H0z"></path>
        </g>
      </g>
    </g>
  </svg>
);

export default SvgComponent;
