import useArchiveTemplate from './useArchiveTemplate';
import useArchiveGroup from './useArchiveGroup';
import useAssignGroup from './useAssignGroup';
import useCallFacebookOAuthCallbackForGm from './useCallFacebookOAuthCallbackForGm';
import useCancelCampaign from './useCancelCampaign';
import useCreateBranch from './useCreateBranch';
import useCreateCampaign from './useCreateCampaign';
import useCreateCityBranch from './useCreateCityBranch';
import useCreateGroup from './useCreateGroup';
import useCreateGoogleCampaign from './useCreateGoogleCampaign';
import useCreateGoogleTemplate from './useCreateGoogleTemplate';
import useCreateGoogleTemplateAndPublish from './useCreateGoogleTemplateAndPublish';
import useCreateTemplate from './useCreateTemplate';
import useCreateTemplateAndPublish from './useCreateTemplateAndPublish';
import useDeleteGmIntegration from './useDeleteGmIntegration';
import useDeleteTemplate from './useDeleteTemplate';
import useDuplicateTemplate from './useDuplicateTemplate';
import useGeneralManagerUserLogin from './useGeneralManagerUserLogin';
import useGeneralManagerUserResetPassword from './useGeneralManagerUserResetPassword';
import useGeneralManagerUserUpdatePassword from './useGeneralManagerUserUpdatePassword';
import useGenerateFacebookAdPreviewByTemplateId from './useGenerateFacebookAdPreviewByTemplateId';
import useGenerateGmFacebookPreview from './useGenerateGmFacebookPreview';
import useGenerateTemplatePreviewByBranch from './useGenerateTemplatePreviewByBranch';
import useGetCompanyCurrentFacebookAccount from './useGetCompanyCurrentFacebookAccount';
import useGetCompanyFacebookAccounts from './useGetCompanyFacebookAccounts';
import useGetCompanyFacebookPromotePages from './useGetCompanyFacebookPromotePages';
import useGetCompanyGroups from './useGetCompanyGroups';
import useGetCompanyLeadGenForms from './useGetCompanyLeadGenForms';
import useUnassignBranchFromGroup from './useUnassignBranchFromGroup';
import useGetFacebookCities from './useGetFacebookCities';
import useGetProfile from './useGetProfile';
import useGetTokenInfo from './useGetTokenInfo';
import useGmDashboard from './useGmDashboard';
import useListBranchActivitiesByCompany from './useListBranchActivitiesByCompany';
import useListBranches from './useListBranches';
import useListBranchesOfGroup from './useListBranchesOfGroup';
import useListCampaigns from './useListCampaigns';
import useListGeneralManagerCompanies from './useListGeneralManagerCompanies';
import useListGmFacebookAdTargetingCategories from './useListGmFacebookAdTargetingCategories';
import useListGmFacebookInterests from './useListGmFacebookInterests';
import useListGmIntegrations from './useListGmIntegrations';
import useListGmMetabaseResources from './useListGmMetabaseResources';
import useListGroups from './useListGroups';
import useListPreviewsByTemplateId from './useListPreviewsByTemplateId';
import useListTemplates from './useListTemplates';
import useListTemplatesByCompany from './useListTemplatesByCompany';
import useShowBranch from './useShowBranch';
import useShowCampaign from './useShowCampaign';
import useShowCampaignInsights from './useShowCampaignInsights';
import useShowGmMetabaseResource from './useShowGmMetabaseResource';
import useShowTemplate from './useShowTemplate';
import useUpdateBranch from './useUpdateBranch';
import useUpdateCampaign from './useUpdateCampaign';
import useUpdateGmFacebookIntegration from './useUpdateGmFacebookIntegration';
import useUpdateGoogleCampaign from './useUpdateGoogleCampaign';
import useUpdateLanguageProfile from './useUpdateLanguageProfile';
import useUpdateProfile from './useUpdateProfile';
import useUpdateTemplate from './useUpdateTemplate';
import useUpdateGoogleTemplate from './useUpdateGoogleTemplate';
import useUploadFacebookAdTypePhoto from './useUploadFacebookAdTypePhoto';
import useUploadGoogleAdTypePhoto from './useUploadGoogleAdTypePhoto';
import useUploadInstagramAdTypePhoto from './useUploadInstagramAdTypePhoto';
import useCreateDynamicLocationAdsTemplate from './useCreateDynamicLocationAdsTemplate';
import useCreateDynamicLocationAdsTemplatePreview from './useCreateDynamicLocationAdsTemplatePreview';
import useGenerateXlsx from './useGenerateXlsx';
import useUploadXlsx from './useUploadXlsx';
import useCreateDynamicLocationAdsCampaign from './useCreateDynamicLocationAdsCampaign';
import useUpdateMobileApp from './useUpdateMobileApp';
import useGetCompany from './useGetCompany';
import useListAudiences from './useListAudiences';
import useCreateAudience from './useCreateAudience';
import useDeleteAudience from './useDeleteAudience';
import useGetCompanyFacebookAccountPayment from './useGetCompanyFacebookAccountPayment';
import useGetCompanyFile from './useGetCompanyFile';
import useCreateCategory from './useCreateCategory';
import useDeleteCategory from './useDeleteCategory';
import useCreateMetaTemplate from './useCreateMetaTemplate';
import useCreateMetaBannerbearTemplatePreview from './useCreateMetaBannerbearTemplatePreview';
import useListCompanyContactForms from './useListCompanyContactForms';
import useCreateContactForm from './useCreateContactForm';
import useShowContactForm from './useShowCompanyContactForm';
import useUpdateContactForm from './useUpdateContactForm';
import useDeleteContactForm from './useDeleteContactForm';
import useGetCompanyWallet from './useGetCompanyWallet';
import useDeposit from './useDeposit';
import useGetSavedCards from './useGetSavedCards';
import useGetCompanyTransactions from './useGetCompanyTransactions';
import useDeleteSavedCard from './useDeleteSavedCard';
import useListMessagingApplications from './useListCompanyMessagingApplications';
import useDeleteMessagingApplication from './useDeleteMessagingApplication';
import useCreateMessagingApplication from './useCreateMessagingApplication';
import useShowMessagingApplication from './useShowCompanyMessagingApplication';
import useUpdateMessagingApplication from './useUpdateMessagingApplication';

export default {
  useArchiveTemplate,
  useArchiveGroup,
  useAssignGroup,
  useCallFacebookOAuthCallbackForGm,
  useCancelCampaign,
  useCreateBranch,
  useCreateCampaign,
  useCreateCityBranch,
  useCreateGroup,
  useCreateGoogleCampaign,
  useCreateGoogleTemplate,
  useCreateGoogleTemplateAndPublish,
  useCreateTemplate,
  useCreateTemplateAndPublish,
  useDeleteGmIntegration,
  useDeleteTemplate,
  useDuplicateTemplate,
  useGeneralManagerUserLogin,
  useGeneralManagerUserResetPassword,
  useGeneralManagerUserUpdatePassword,
  useGenerateFacebookAdPreviewByTemplateId,
  useGenerateGmFacebookPreview,
  useGenerateTemplatePreviewByBranch,
  useGetCompanyCurrentFacebookAccount,
  useGetCompanyFacebookAccounts,
  useGetCompanyFacebookPromotePages,
  useGetCompanyLeadGenForms,
  useUnassignBranchFromGroup,
  useGetFacebookCities,
  useGetProfile,
  useGetTokenInfo,
  useGetCompanyGroups,
  useGmDashboard,
  useListBranchActivitiesByCompany,
  useListBranches,
  useListBranchesOfGroup,
  useListCampaigns,
  useListGeneralManagerCompanies,
  useListGmFacebookAdTargetingCategories,
  useListGmFacebookInterests,
  useListGmIntegrations,
  useListPreviewsByTemplateId,
  useListGmMetabaseResources,
  useListGroups,
  useListTemplates,
  useListTemplatesByCompany,
  useShowBranch,
  useShowCampaign,
  useShowCampaignInsights,
  useShowGmMetabaseResource,
  useShowTemplate,
  useUpdateBranch,
  useUpdateCampaign,
  useUpdateGmFacebookIntegration,
  useUpdateGoogleCampaign,
  useUpdateLanguageProfile,
  useUpdateProfile,
  useUpdateTemplate,
  useUpdateGoogleTemplate,
  useUploadFacebookAdTypePhoto,
  useUploadGoogleAdTypePhoto,
  useUploadInstagramAdTypePhoto,
  useCreateDynamicLocationAdsTemplate,
  useCreateDynamicLocationAdsTemplatePreview,
  useGenerateXlsx,
  useUploadXlsx,
  useCreateDynamicLocationAdsCampaign,
  useUpdateMobileApp,
  useGetCompany,
  useListAudiences,
  useCreateAudience,
  useDeleteAudience,
  useGetCompanyFacebookAccountPayment,
  useGetCompanyFile,
  useCreateCategory,
  useDeleteCategory,
  useCreateMetaTemplate,
  useCreateMetaBannerbearTemplatePreview,
  useListCompanyContactForms,
  useCreateContactForm,
  useShowContactForm,
  useUpdateContactForm,
  useDeleteContactForm,
  useGetCompanyWallet,
  useDeposit,
  useGetSavedCards,
  useGetCompanyTransactions,
  useDeleteSavedCard,
  useListMessagingApplications,
  useDeleteMessagingApplication,
  useCreateMessagingApplication,
  useShowMessagingApplication,
  useUpdateMessagingApplication,
};
