/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookIntegrationResponse } from '../model';
// @ts-ignore
import { IntegrationsListResponse } from '../model';
// @ts-ignore
import { UpdateFacebookIntegrationInput } from '../model';
/**
 * IntegrationsGMApi - axios parameter creator
 * @export
 */
export const IntegrationsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} companyId The id of the company
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGmIntegration: async (companyId: string, integrationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteGmIntegration', 'companyId', companyId)
            // verify required parameter 'integrationId' is not null or undefined
            assertParamExists('deleteGmIntegration', 'integrationId', integrationId)
            const localVarPath = `/gm_user/companies/{company_id}/integrations/{integration_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"integration_id"}}`, encodeURIComponent(String(integrationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmIntegrations: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmIntegrations', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/integrations`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} companyId The id of the company
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGmFacebookIntegration: async (companyId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGmFacebookIntegration', 'companyId', companyId)
            // verify required parameter 'updateFacebookIntegrationInput' is not null or undefined
            assertParamExists('updateGmFacebookIntegration', 'updateFacebookIntegrationInput', updateFacebookIntegrationInput)
            const localVarPath = `/gm_user/companies/{company_id}/integrations/facebook_integration_update`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFacebookIntegrationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationsGMApi - functional programming interface
 * @export
 */
export const IntegrationsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} companyId The id of the company
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGmIntegration(companyId: string, integrationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGmIntegration(companyId, integrationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmIntegrations(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IntegrationsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmIntegrations(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} companyId The id of the company
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGmFacebookIntegration(companyId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookIntegrationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGmFacebookIntegration(companyId, updateFacebookIntegrationInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationsGMApi - factory interface
 * @export
 */
export const IntegrationsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationsGMApiFp(configuration)
    return {
        /**
         * Deletes the integration
         * @summary Delete the integration
         * @param {string} companyId The id of the company
         * @param {string} integrationId The id of the integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGmIntegration(companyId: string, integrationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGmIntegration(companyId, integrationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return integrations of branch
         * @summary Return integrations of branch
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmIntegrations(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<IntegrationsListResponse> {
            return localVarFp.listGmIntegrations(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Update facebook integration
         * @summary Update facebook integration
         * @param {string} companyId The id of the company
         * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGmFacebookIntegration(companyId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): AxiosPromise<FacebookIntegrationResponse> {
            return localVarFp.updateGmFacebookIntegration(companyId, updateFacebookIntegrationInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationsGMApi - interface
 * @export
 * @interface IntegrationsGMApi
 */
export interface IntegrationsGMApiInterface {
    /**
     * Deletes the integration
     * @summary Delete the integration
     * @param {string} companyId The id of the company
     * @param {string} integrationId The id of the integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApiInterface
     */
    deleteGmIntegration(companyId: string, integrationId: string, options?: any): AxiosPromise<void>;

    /**
     * Return integrations of branch
     * @summary Return integrations of branch
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApiInterface
     */
    listGmIntegrations(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<IntegrationsListResponse>;

    /**
     * Update facebook integration
     * @summary Update facebook integration
     * @param {string} companyId The id of the company
     * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApiInterface
     */
    updateGmFacebookIntegration(companyId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any): AxiosPromise<FacebookIntegrationResponse>;

}

/**
 * IntegrationsGMApi - object-oriented interface
 * @export
 * @class IntegrationsGMApi
 * @extends {BaseAPI}
 */
export class IntegrationsGMApi extends BaseAPI implements IntegrationsGMApiInterface {
    /**
     * Deletes the integration
     * @summary Delete the integration
     * @param {string} companyId The id of the company
     * @param {string} integrationId The id of the integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApi
     */
    public deleteGmIntegration(companyId: string, integrationId: string, options?: any) {
        return IntegrationsGMApiFp(this.configuration).deleteGmIntegration(companyId, integrationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return integrations of branch
     * @summary Return integrations of branch
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApi
     */
    public listGmIntegrations(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return IntegrationsGMApiFp(this.configuration).listGmIntegrations(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update facebook integration
     * @summary Update facebook integration
     * @param {string} companyId The id of the company
     * @param {UpdateFacebookIntegrationInput} updateFacebookIntegrationInput Update facebook integration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationsGMApi
     */
    public updateGmFacebookIntegration(companyId: string, updateFacebookIntegrationInput: UpdateFacebookIntegrationInput, options?: any) {
        return IntegrationsGMApiFp(this.configuration).updateGmFacebookIntegration(companyId, updateFacebookIntegrationInput, options).then((request) => request(this.axios, this.basePath));
    }
}
