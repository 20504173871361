import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAxios } from '@app/hooks';

export function uploadXlsx(companyId: string, uploadXlsxInput: object, axiosInstance: AxiosInstance) {
  return axiosInstance
    .post(`/v2/general_manager_account/companies/${companyId}/attachments/files/upload_xlsx`, uploadXlsxInput, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useUploadXlsx(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; uploadXlsxInput: object }) => uploadXlsx(values.companyId, values.uploadXlsxInput, axiosInstance),
    props
  );
}
