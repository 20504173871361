import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { WalletCompanyV2Api } from '@app/api';
import useAxios from '../useAxios';

export function deposit(branchId: string, balance: number, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new WalletCompanyV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.addCompanyWalletBalance(branchId, {
    wallet: {
      balance,
    },
  });
}

export default function useDeposit() {
  const { api: axiosInstance } = useAxios();
  return useMutation((data: { companyId: string; balance: number }) => deposit(data.companyId, data.balance, axiosInstance));
}
