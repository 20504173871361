import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { ContactFormGMApi } from 'api/api';
import { useAuth, useAxios } from '@app/hooks';

export function listContactForms(companyId: string, page?: number, perPage?: number, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ContactFormGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listContactFormsForCompany(companyId, page, perPage).then((res) => res.data);
}

export default function useListContactForms({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListContactForms', companyId, params.page, params.perPage],
    () => listContactForms(companyId, params.page, params.perPage, axiosInstance),
    props
  );
}
