import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="38.299" height="38.326" data-name="Group 2885" viewBox="0 0 38.299 38.326" {...props}>
    <path
      d="M44.562 25.412l-9.01.008a25.476 25.476 0 001.824-9.489v-1.387a3.506 3.506 0 00-3.5-3.5h-.746a3.526 3.526 0 00-3.369 2.538l-.471 1.638a24.973 24.973 0 01-4.528 8.77 3.765 3.765 0 01-2.956 1.422h-7.758a3 3 0 00-2.994 3v17.956a3 3 0 002.994 3h5.962a3 3 0 002.735-1.785L25.3 48.86a4.8 4.8 0 002.14.506h14.522a3.631 3.631 0 003.138-1.824l1.352-2.364a22.043 22.043 0 002.9-10.918v-4.053a4.8 4.8 0 00-4.79-4.795zM20.608 46.368a.6.6 0 01-.6.6h-5.96a.6.6 0 01-.6-.6V28.41a.6.6 0 01.6-.6h5.962a.6.6 0 01.6.6zM46.957 34.26a19.669 19.669 0 01-2.581 9.728l-1.352 2.364a1.229 1.229 0 01-1.062.618H27.443a2.43 2.43 0 01-1.066-.251L23 45.031V28.41a3.062 3.062 0 00-.093-.711 6.143 6.143 0 003.721-2.21 27.318 27.318 0 004.961-9.608l.467-1.642a1.117 1.117 0 011.066-.8h.746a1.109 1.109 0 011.109 1.109v1.387a23.085 23.085 0 01-1.654 8.6 2.389 2.389 0 002.218 3.276h9.017a2.4 2.4 0 012.4 2.4z"
      data-name="Path 1496"
      transform="translate(-11.054 -11.04)"
    ></path>
    <circle cx="1.198" cy="1.198" r="1.198" data-name="Ellipse 39" transform="translate(4.764 31.14)"></circle>
  </svg>
);

export default SvgComponent;
