import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { ProfileApi } from '@app/api';
import { useAxios } from '@app/hooks';

export function updateProfile(name?: string, surname?: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new ProfileApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateProfile(name, surname).then((res) => res.data);
}

export default function useUpdateProfile(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: { name: string; surname: string }) => updateProfile(values.name, values.surname, axiosInstance), props);
}
