import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';
import type { TemplateCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateGoogleTemplate(
  companyId: string,
  templateId: string,
  templateUpdateInput: TemplateCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateGoogleTemplate(companyId, templateId, templateUpdateInput).then((res) => res.data);
}

export default function useUpdateGoogleTemplate(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; templateId: string; template: TemplateCreateInput }) =>
      updateGoogleTemplate(values.companyId, values.templateId, values.template, axiosInstance),
    props
  );
}
