import React from 'react';

import { useFormContext } from 'react-hook-form';

import { LOCALES, GOOGLE_LOCALES } from '@app/constants';
import { Box } from '@app/components';
import type { Template as TemplateType } from '@app/api';
import { useLocale } from '@app/hooks';
import { array, date, template } from '@app/lib';

import type { Campaign as CampaignType } from '@app/api';

type PreviewFormPropTypes = {
  templates: Array<TemplateType>;
  campaign?: CampaignType;
};

const PreviewForm: React.FC<PreviewFormPropTypes> = ({ templates = [], campaign }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.PreviewForm',
  };
  const { t } = useLocale();
  const { watch, getValues } = useFormContext();
  const templateIdWatch = watch('templateId');
  const selectedTemplate = templates.find((template) => template.id === templateIdWatch);
  const formData = getValues();
  const isGooglePlatform = template.hasGooglePlatform(selectedTemplate?.targeting?.publisher_platforms);
  const isSmartPlatform = template.hasSmartInCampaignType(selectedTemplate?.targeting?.sub_campaign_type);

  function getPublisherPlatforms() {
    if (!Array.isArray(selectedTemplate?.targeting.publisher_platforms)) {
      return '-';
    }

    return selectedTemplate?.targeting.publisher_platforms
      .map((platform: string) => t(`publisherPlatforms.${platform}`))
      .join(', ') as string;
  }

  function getInterests() {
    if (!Array.isArray(formData.interests)) {
      return '-';
    }

    return [...formData.interests].map((interest) => interest.label).join(', ');
  }

  function getKeywords() {
    if (!Array.isArray(formData.keywords) || array.isEmpty(formData.keywords)) {
      return '-';
    }

    return formData.keywords.map((keyword: string) => keyword).join(', ');
  }

  function getNegativeKeywords() {
    if (!Array.isArray(formData.negative_keywords) || array.isEmpty(formData.negative_keywords)) {
      return '-';
    }

    return formData.negative_keywords.map((keyword: string) => keyword).join(', ');
  }

  function getLanguages() {
    if (!Array.isArray(formData.locales) && !isGooglePlatform) {
      return '-';
    }

    if (isGooglePlatform && isSmartPlatform) {
      return formData.locales === ''
        ? '-'
        : GOOGLE_LOCALES.filter((locale) => [formData.locales].includes(locale.code))
            .map((locale) => locale.name)
            .join(', ');
    }

    return LOCALES.filter((locale) => formData.locales.includes(locale.key))
      .map((locale) => locale.name)
      .join(', ');
  }

  return (
    <>
      <Box>
        <div className="grid grid-cols-3 gap-4">
          <div className="border border-gray-200 rounded-2 flex flex-col gap-y-3 p-3">
            <span className="font-semibold text-4 text-gray-900">{t('labels.campaignDetail', SCOPE_OPTIONS)}</span>
            <span
              className="font-normal text-3 text-gray-600 line-clamp-2"
              dangerouslySetInnerHTML={{ __html: t('labels.name', { ...SCOPE_OPTIONS, value: formData.name ?? campaign?.name }) }}
            />
            <span
              className="font-normal text-3 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t('labels.startTime', {
                  ...SCOPE_OPTIONS,
                  value: formData.startTime ? date.format(date.JSDateToISO(formData.startTime), 'dd.LL.yyyy HH:mm') : '-',
                }),
              }}
            />
            <span
              className="font-normal text-3 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: t('labels.stopTime', {
                  ...SCOPE_OPTIONS,
                  value: formData.stopTime ? date.format(date.JSDateToISO(formData.stopTime), 'dd.LL.yyyy HH:mm') : '-',
                }),
              }}
            />
          </div>
          <div className="border border-gray-200 rounded-2 flex flex-col gap-y-3 p-3">
            <span className="font-semibold text-4 text-gray-900">{t('labels.targeting', SCOPE_OPTIONS)}</span>
            {isGooglePlatform && isSmartPlatform ? (
              <>
                <span
                  className="font-normal text-3 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: t('labels.languages', { ...SCOPE_OPTIONS, value: getLanguages() }) }}
                />
                <span
                  className="font-normal text-3 text-gray-600 capitalize"
                  dangerouslySetInnerHTML={{
                    __html: t('labels.keywords', { ...SCOPE_OPTIONS, value: getKeywords() }),
                  }}
                />
                <span
                  className="font-normal text-3 text-gray-600 capitalize"
                  dangerouslySetInnerHTML={{
                    __html: t('labels.negativeKeywords', { ...SCOPE_OPTIONS, value: getNegativeKeywords() }),
                  }}
                />
              </>
            ) : (
              <>
                <span
                  className="font-normal text-3 text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: t('labels.ageRange', { ...SCOPE_OPTIONS, value: `${formData.ageMin} - ${formData.ageMax}` }),
                  }}
                />
                <span
                  className="font-normal text-3 text-gray-600"
                  dangerouslySetInnerHTML={{
                    __html: t('labels.gender', { ...SCOPE_OPTIONS, value: t(`genders.${String(formData.gender).toLocaleLowerCase()}`) }),
                  }}
                />
                <span
                  className="font-normal text-3 text-gray-600"
                  dangerouslySetInnerHTML={{ __html: t('labels.languages', { ...SCOPE_OPTIONS, value: getLanguages() }) }}
                />
                <span
                  className="font-normal text-3 text-gray-600 capitalize"
                  dangerouslySetInnerHTML={{
                    __html: t('labels.interests', { ...SCOPE_OPTIONS, value: getInterests() }),
                  }}
                />
              </>
            )}
          </div>
          <div className="border border-gray-200 rounded-2 flex flex-col gap-y-3 p-3">
            <span className="font-semibold text-4 text-gray-900">{t('labels.others', SCOPE_OPTIONS)}</span>
            <span
              className="font-normal text-3 text-gray-600"
              dangerouslySetInnerHTML={{ __html: t('labels.platform', { ...SCOPE_OPTIONS, value: getPublisherPlatforms() }) }}
            />
            {formData.useNewTemplate && (
              <span
                className="font-normal text-3 text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: t('labels.leadGeneration', { ...SCOPE_OPTIONS, value: t('message.leadDescription', SCOPE_OPTIONS) }),
                }}
              />
            )}
          </div>
        </div>
      </Box>
    </>
  );
};

export default PreviewForm;
