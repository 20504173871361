import { Dispatch, SetStateAction } from 'react';

import { useLocale } from '@app/hooks';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

type ImageUploadPropTypes = {
  type: typeof IMAGE_PLACEMENT_TYPE[keyof typeof IMAGE_PLACEMENT_TYPE];
  bbTemplateId?: string;
  setBannerbearSession: Dispatch<SetStateAction<BannerbearSessionStateType>>;
  bannerbearSession: BannerbearSessionStateType;
};

const BannerbearEditor: React.FC<Partial<ImageUploadPropTypes>> = ({ bannerbearSession, setBannerbearSession }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.BannerbearInput',
  };
  const { t } = useLocale();

  function closeEditor(e: React.MouseEvent) {
    e.stopPropagation();
    setBannerbearSession?.({ url: '', type: null, isOpen: false });
  }

  return (
    <div className="fixed top-29 left-50 w-[calc(100vw-12.5rem)] h-[calc(100vh-(theme(spacing.19)+theme(spacing.10)))] z-999">
      <div
        aria-hidden="true"
        className="bg-red-500 py-3 px-2.5 flex items-center justify-center h-3 rounded-2 text-white fixed right-5 top-21 z-2"
        onClick={closeEditor}
      >
        {t(`actions.close`, SCOPE_OPTIONS)}
      </div>
      <embed src={bannerbearSession?.url} width="100%" height="100%" className="z-1" />
    </div>
  );
};

export default BannerbearEditor;
