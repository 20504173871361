import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { FileAttachmentsGMApi } from '@app/api';
import type { GenerateXlsxFileAttachmentInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function generateXlsx(
  companyId: string,
  generateXlsxInput: GenerateXlsxFileAttachmentInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new FileAttachmentsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.gmGenerateXlsxFileAttachment(companyId, generateXlsxInput).then((res) => res.data);
}

export default function useGenerateXlsx(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios({ axiosProps: { responseType: 'blob' } });
  return useMutation(
    (values: { companyId: string; generateXlsxInput: GenerateXlsxFileAttachmentInput }) =>
      generateXlsx(values.companyId, values.generateXlsxInput, axiosInstance),
    props
  );
}
