import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 3.57143C0 2.62423 0.376274 1.71582 1.04605 1.04605C1.71582 0.376274 2.62423 0 3.57143 0H18.5339C19.4811 0.000202276 20.3893 0.376609 21.0589 1.04643L23.9536 3.94107C24.6234 4.61069 24.9998 5.51895 25 6.46607V21.4286C25 22.3758 24.6237 23.2842 23.954 23.954C23.2842 24.6237 22.3758 25 21.4286 25H3.57143C2.62423 25 1.71582 24.6237 1.04605 23.954C0.376274 23.2842 0 22.3758 0 21.4286V3.57143ZM3.57143 1.78571C3.09783 1.78571 2.64362 1.97385 2.30874 2.30874C1.97385 2.64362 1.78571 3.09783 1.78571 3.57143V21.4286C1.78571 21.9022 1.97385 22.3564 2.30874 22.6913C2.64362 23.0261 3.09783 23.2143 3.57143 23.2143V15.1786C3.57143 14.4682 3.85363 13.7869 4.35596 13.2845C4.85829 12.7822 5.5396 12.5 6.25 12.5H18.75C19.4604 12.5 20.1417 12.7822 20.644 13.2845C21.1464 13.7869 21.4286 14.4682 21.4286 15.1786V23.2143C21.9022 23.2143 22.3564 23.0261 22.6913 22.6913C23.0261 22.3564 23.2143 21.9022 23.2143 21.4286V6.46607C23.2142 5.99251 23.026 5.53838 22.6911 5.20357L19.7964 2.30893C19.4616 1.97402 19.0075 1.78582 18.5339 1.78571H17.8571V6.25C17.8571 6.9604 17.5749 7.64171 17.0726 8.14404C16.5703 8.64637 15.889 8.92857 15.1786 8.92857H8.03571C7.32531 8.92857 6.64401 8.64637 6.14168 8.14404C5.63935 7.64171 5.35714 6.9604 5.35714 6.25V1.78571H3.57143ZM7.14286 1.78571V6.25C7.14286 6.4868 7.23693 6.7139 7.40437 6.88135C7.57181 7.04879 7.79891 7.14286 8.03571 7.14286H15.1786C15.4154 7.14286 15.6425 7.04879 15.8099 6.88135C15.9774 6.7139 16.0714 6.4868 16.0714 6.25V1.78571H7.14286ZM19.6429 23.2143V15.1786C19.6429 14.9418 19.5488 14.7147 19.3813 14.5472C19.2139 14.3798 18.9868 14.2857 18.75 14.2857H6.25C6.0132 14.2857 5.7861 14.3798 5.61865 14.5472C5.45121 14.7147 5.35714 14.9418 5.35714 15.1786V23.2143H19.6429Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
