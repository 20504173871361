import { useEffect, useRef, useState } from 'react';

import { ChannelNameWithParams, Consumer, Mixin, Subscription } from '@rails/actioncable';

const useChannel = (actionCable: Consumer | null) => {
  const [queue, setQueue] = useState<{ action: string; payload: object }[]>([]);
  const [connected, setConnected] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const channelRef = useRef<
    | (Subscription<Consumer> &
        Mixin & {
          received: (x: ReceivedResponseType) => void;
          initialized: () => void;
          connected: () => void;
          disconnected: () => void;
        })
    | null
  >();

  useEffect(() => {
    return () => {
      unsubscribe();
    };
  }, []);

  const subscribe = (data: ChannelNameWithParams, callbacks: CallbacksType) => {
    const channel = actionCable?.subscriptions.create(data, {
      received: (x: ReceivedResponseType) => {
        if (callbacks.received) callbacks.received(x);
      },
      initialized: () => {
        setSubscribed(true);
        if (callbacks.initialized) callbacks.initialized();
      },
      connected: () => {
        setConnected(true);
        if (callbacks.connected) callbacks.connected();
      },
      disconnected: () => {
        setConnected(false);
        if (callbacks.disconnected) callbacks.disconnected();
      },
    });
    channelRef.current = channel;
  };

  const unsubscribe = () => {
    setSubscribed(false);

    if (channelRef.current) {
      channelRef.current.unsubscribe();
      channelRef.current = null;
    }
  };

  useEffect(() => {
    if (subscribed && connected && queue.length > 0) {
      processQueue();
    }
  }, [queue[0], connected, subscribed]);

  const processQueue = () => {
    const action = queue[0];

    try {
      perform(action.action, action.payload);
      setQueue((prevState) => {
        const q = [...prevState];
        q.shift();
        return q;
      });
    } catch {
      console.log(`Unable to perform action '${action.action}'. It will stay at the front of the queue.`);
    }
  };

  const enqueue = (action: string, payload: object) => {
    setQueue((prevState) => [
      ...prevState,
      {
        action: action,
        payload: payload,
      },
    ]);
  };

  const perform = (action: string, payload: object) => {
    if (subscribed && !connected) throw 'useActionCable: not connected';
    if (!subscribed) throw 'useActionCable: not subscribed';
    try {
      channelRef.current?.perform(action, payload);
    } catch {
      throw 'useActionCable: Unknown error';
    }
  };

  const send = ({ action, payload, useQueue }: { action: string; payload: object; useQueue: boolean }) => {
    if (useQueue) {
      enqueue(action, payload);
    } else {
      perform(action, payload);
    }
  };

  return {
    subscribe,
    unsubscribe,
    send,
  };
};

export default useChannel;
