import React from 'react';

import Image from 'next/image';

import { useLocale } from '@app/hooks';

const LOADER_GIF_URL = '/images/spinner.gif';

const Loader = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Loader',
  };
  const { t } = useLocale();

  return (
    <div className="z-999 fixed top-0 left-0 bottom-0 right-0 bg-blue-900 bg-opacity-40 flex items-center justify-center">
      <div className="rounded-3 bg-white max-w-50 w-full mx-6 h-38 flex flex-col items-center justify-center">
        <Image src={LOADER_GIF_URL} alt="spinner" className="w-20 h-20" width="80" height="80" />
        <h4 className="text-blue-500 font-semibold text-3.5 mt-1 leading-none">{t('labels.loading', SCOPE_OPTIONS)}</h4>
      </div>
    </div>
  );
};

export default Loader;
