import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi } from '@app/api';
import { date } from '@app/lib';
import { useAuth, useAxios } from '@app/hooks';

export function showCampaignInsights(
  companyId: string,
  campaignId: string,
  startDate: string,
  endDate: string,
  granularity?: 'day' | 'month' | 'week',
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.showCampaignInsights(companyId, campaignId, startDate, endDate, granularity).then((res) => res.data);
}

export default function useShowCampaignInsights({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();
  const formatedStartDate = date.format(date.JSDateToISO(params.startDate), 'yyyy-MM-dd');
  const formatedEndDate = date.format(date.JSDateToISO(params.endDate), 'yyyy-MM-dd');

  return useQuery(
    ['useShowCampaignInsights', companyId, params.campaignId, formatedStartDate, formatedEndDate, params.granularity],
    () => showCampaignInsights(companyId, params.campaignId, formatedStartDate, formatedEndDate, params.granularity, axiosInstance),
    props
  );
}
