import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { useAuth, useAxios } from '@app/hooks';
import { WalletCompanyV2Api } from 'api/api';

export function getCompanyWallet(branchId: string, hasCentricPayment: boolean | undefined, axiosInstance: AxiosInstance) {
  if (!hasCentricPayment) {
    return;
  }
  const api = new WalletCompanyV2Api(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);
  return api.getCompanyWallet(branchId);
}

export default function useGetCompanyWallet() {
  const { api: axiosInstance } = useAxios();
  const { companyId, company } = useAuth();
  const hasCentricPayment = company?.feature_flags?.has_centric_payment_feature;
  return useQuery(['wallet', companyId], () => getCompanyWallet(companyId, hasCentricPayment, axiosInstance));
}
