import { useMutation } from 'react-query';
import { AxiosInstance, AxiosPromise } from 'axios';

import type { ImageAttachment } from '@app/api';

import { useAxios } from '@app/hooks';

export function uploadFacebookAdTypePhoto(companyId: string, payload: object, axiosInstance: AxiosInstance): AxiosPromise<ImageAttachment> {
  return axiosInstance
    .post(`/gm_user/companies/${companyId}/attachments/images/facebook`, payload, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    })
    .then((res) => res.data);
}

export default function useUploadFacebookAdTypePhoto(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; payload: object }) => uploadFacebookAdTypePhoto(values.companyId, values.payload, axiosInstance),
    props
  );
}
