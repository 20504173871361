import React from 'react';

import { useLocale } from '@app/hooks';
import { Icons } from '@app/components';
import { ADS_PREVIEW } from '@app/constants';

type FacebookFeedPropTypes = {
  companyName?: string;
  message?: string;
  headline?: string;
  description: string;
  image: string;
  profilePicture?: string;
  action?: string;
  targetingType?: string;
};

const FacebookFeed: React.FC<FacebookFeedPropTypes> = ({
  companyName,
  image,
  message,
  headline,
  description,
  action,
  profilePicture,
  targetingType,
}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.AdsPreviewCampaign.FacebookFeed',
  };
  const { t } = useLocale();

  return (
    <div className="bg-white select-none p-2 border-b border-t border-gray-300 w-[320px] h-[567px]">
      <div className="flex flex-row h-12">
        <div className="flex w-10 rounded-full justify-center bg-gray-200 self-center aspect-square">
          <img src={profilePicture} alt="profile" className="object-cover rounded-full" />
        </div>

        <span className="flex flex-row justify-between w-full">
          <div className="flex flex-col ml-2 align-center self-center">
            <span className="font-bold text-3.5 text-gray-900 capitalize">{companyName || ADS_PREVIEW.COMPANY_NAME}</span>
            <span className="leading-none text-gray-500 text-2.5 capitalize">{t('labels.sponsor', SCOPE_OPTIONS)}</span>
          </div>
          <div className="flex self-center">
            <Icons.TreeDot className="w-4 h-4" />
            <Icons.Close className="w-3.5 h-3.5 ml-4" />
          </div>
        </span>
      </div>

      {message && (
        <div className="flex flex-row py-1 h-auto">
          <p className="text-black-900 text-3 line-clamp-2">{message}</p>
        </div>
      )}
      <div className="flex my-1 justify-center aspect-1">
        <img src={image} alt="feed_image" className="object-cover" width="100%" height="100%" />
      </div>

      <div className="flex flex-row items-center justify-between px-2 py-2 border-b border-gray-300 bg-slate-50 h-auto">
        <div className="flex flex-col">
          {action && <span className="text-gray-600 text-3 line-clamp-2">{action}</span>}
          <span className="text-black-900 text-3 font-bold line-clamp-2">{headline}</span>
          <span className="text-gray-600 text-3 line-clamp-1">{description}</span>
        </div>

        <span className="text-slate-900 font-bold text-3 border border-none rounded-1 px-2 py-1 w-auto whitespace-nowrap bg-slate-200 items-center flex">
          {t(`callToAction.${targetingType}`)}
        </span>
      </div>

      <div className="flex flex-row items-center justify-between px-3 pb-3 mt-2 h-12">
        <div className="flex flex-row justify-start ">
          <span className="leading-none text-gray-700 text-3.5 font-medium ">
            <Icons.Like className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">Like</span>
        </div>
        <div className="flex flex-row justify-center">
          <span className="leading-none text-gray-700 text-3.5 ">
            <Icons.Comment className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">Comment</span>
        </div>
        <div className="flex flex-row justify-end">
          <span className="leading-none text-gray-700 text-3.5">
            <Icons.Share className="w-4 h-4" />
          </span>
          <span className="leading-none text-gray-700 text-3.5 ml-2">Share</span>
        </div>
      </div>
    </div>
  );
};

export default FacebookFeed;
