import React, { Fragment } from 'react';

import cn from 'classnames';

import { array } from '@app/lib';

type BreadcrumbPropTypes = {
  className?: string;
  items: string[];
};

const Breadcrumb: React.FC<BreadcrumbPropTypes> = ({ className, items = [] }) => {
  if (array.isEmpty(items)) {
    return null;
  }

  return (
    <div className={cn('h-10 bg-gray-300 px-5 flex flex-row', className)}>
      {items.map((item, index) => (
        <Fragment key={index}>
          <div className="h-10 flex items-center justify-center">
            <span className="font-semibold text-3 text-gray-500 select-none">{item}</span>
          </div>
          {!array.isLastItem(items, item) && (
            <div className="relative h-10 w-5 mx-3">
              <div className="bg-gray-300 absolute top-0 right-0 w-5 h-10">
                <div className="border-r-transparent border-5 transition-all border-t-gray-300  border-b-gray-300  border-l-gray-600 opacity-20"></div>
              </div>
              <div className="absolute right-px w-5 h-10">
                <div className="border-t-transparent border-b-transparent border-r-transparent border-5 transition-all border-l-gray-300 "></div>
              </div>
            </div>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default Breadcrumb;
