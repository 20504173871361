import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 10c0-5.522-4.478-10-10-10S0 4.478 0 10c0 4.991 3.656 9.128 8.438 9.879v-6.988h-2.54V10h2.54V7.797c0-2.506 1.493-3.891 3.777-3.891 1.094 0 2.239.195 2.239.195v2.462h-1.262c-1.242 0-1.63.77-1.63 1.562V10h2.773l-.443 2.892h-2.33v6.988C16.345 19.13 20 14.992 20 10Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
