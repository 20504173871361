import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { BranchesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function listBranches(
  companyId: string,
  page?: number,
  perPage?: number,
  query?: object,
  type?: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new BranchesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listBranches(companyId, page, perPage, query, type).then((res) => res.data);
}

export default function useListBranches({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListBranches', companyId, params.page, params.perPage, params.query, params.type],
    () => listBranches(companyId, params.page, params.perPage, params.query, params.type, axiosInstance),
    props
  );
}
