import dynamic from 'next/dynamic';

import CompanyCard from './CompanyCard';
import FacebookPageCard from './FacebookPageCard';
import IntegrationCard from './IntegrationCard';
import InstagramPageCard from './InstagramPageCard';
import LanguageCard from './LanguageCard';
import ProfileCard from './ProfileCard';
const MobileAppCard = dynamic(() => import('./MobileAppCard'), { ssr: false });
const PaymentCard = dynamic(() => import('./PaymentCard'), { ssr: false });

export default { CompanyCard, PaymentCard, FacebookPageCard, IntegrationCard, InstagramPageCard, LanguageCard, ProfileCard, MobileAppCard };
