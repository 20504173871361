import React, { useRef } from 'react';

import cn from 'classnames';
import { useRouter } from 'next/router';
import { useWait } from 'react-wait';

import { Dropdown, Icons, Alert } from '@app/components';
import { api, useAuth, useLocale, useToast } from '@app/hooks';
import { array, template } from '@app/lib';
import { DELIVERY_STATUS, PUBLISHER_PLATFORMS } from '@app/constants';
import { TemplateTargetingPublisherPlatformsEnum, TemplateTargeting as TemplateTargetingType } from 'api/model';

type TemplateCardPropTypes = {
  id: string;
  name: string;
  image: string;
  deliveryStatus?: typeof DELIVERY_STATUS[keyof typeof DELIVERY_STATUS];
  targeting?: TemplateTargetingType;
  isMultiTemplate: boolean;
  activeBranchCount: number;
};

const TEMPLATE_LIST_LOADER_KEY = 'template-list-loader-key';
const BUTTON_TYPES = {
  CAMPAIGN_CREATE: 'campaignCreate',
  DUPLICATE: 'duplicate',
  EDIT: 'edit',
  PREVIEWS: 'previews',
  DELETE: 'delete',
};

const TemplateCard: React.FC<TemplateCardPropTypes> = ({
  id,
  name,
  image,
  deliveryStatus,
  targeting,
  isMultiTemplate = false,
  activeBranchCount,
}) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Template.Card',
  };
  const { t } = useLocale();
  const alertRef = useRef<AlertRefTypes>(null);
  const { startWaiting, endWaiting } = useWait();
  const { companyId } = useAuth();
  const { mutate: deleteTemplateMutate } = api.useDeleteTemplate({
    onSettled: () => endWaiting(TEMPLATE_LIST_LOADER_KEY),
  });
  const { mutate: archiveTemplateMutate } = api.useArchiveTemplate({
    onSettled: () => endWaiting(TEMPLATE_LIST_LOADER_KEY),
  });
  const router = useRouter();
  const toast = useToast();
  const isGooglePlatform = template.hasGooglePlatform(targeting?.publisher_platforms);
  const isDynamicLocationAds = template.hasDynamicLocationAdsPlatform(targeting?.publisher_platforms);
  const isSmartPlatform = isGooglePlatform && template.hasSmartInCampaignType(targeting?.sub_campaign_type);
  const DROPDOWN_BUTTONS = Object.values(BUTTON_TYPES)
    .map((button, index, row) => ({
      value: button,
      label: (
        <div className="flex flex-row items-center w-38">
          <span className={cn('uppercase text-gray-500', { 'text-red-400': index + 1 === row.length })}>
            {t(`actions.${button}`, SCOPE_OPTIONS)}
          </span>
        </div>
      ),
    }))
    .filter((button) => {
      if (button.value === BUTTON_TYPES.DUPLICATE && isMultiTemplate) return false;

      if (button.value !== BUTTON_TYPES.PREVIEWS) return true;

      return isSmartPlatform || isDynamicLocationAds ? false : true;
    });

  function handleDropdownButtonClick(type: string, id: string) {
    if (type === BUTTON_TYPES.EDIT) {
      if (DELIVERY_STATUS.APPROVED === deliveryStatus || DELIVERY_STATUS.RUNNING === deliveryStatus) {
        toast.error(t('messages.notEdited', SCOPE_OPTIONS));
        return;
      }

      if (DELIVERY_STATUS.PENDING === deliveryStatus) {
        toast.error(t('messages.notEditedForPending', SCOPE_OPTIONS));
        return;
      }

      router.push({
        pathname: isGooglePlatform ? '/templates/google/[id]' : '/templates/meta/[id]',
        query: { id: id },
      });
      return;
    }

    if (type === BUTTON_TYPES.CAMPAIGN_CREATE) {
      if (![DELIVERY_STATUS.APPROVED, DELIVERY_STATUS.RUNNING].includes(deliveryStatus as 'approved' | 'running')) {
        toast.error(t('messages.notApproved', SCOPE_OPTIONS));
        return;
      }

      router.push({
        pathname: isGooglePlatform
          ? '/campaigns/google/create'
          : isDynamicLocationAds
          ? '/campaigns/dynamic-location-ads/create'
          : '/campaigns/create',
        query: { templateId: id },
      });
      return;
    }

    if (type === BUTTON_TYPES.DELETE) {
      if (!alertRef?.current) {
        return;
      }

      alertRef?.current.open();
      return;
    }

    if (type === BUTTON_TYPES.DUPLICATE) {
      router.push({
        pathname: isGooglePlatform
          ? '/templates/duplicate/google/[id]'
          : isDynamicLocationAds
          ? '/templates/duplicate/dynamic-location-ads/[id]'
          : '/templates/duplicate/meta/[id]',
        query: { id: id },
      });
      return;
    }

    if (type === BUTTON_TYPES.PREVIEWS) {
      router.push({
        pathname: '/templates/previews/[id]',
        query: { id: id },
      });
      return;
    }
  }

  const DeliveryStatus: React.FC = () => {
    if (DELIVERY_STATUS.APPROVED === deliveryStatus || DELIVERY_STATUS.RUNNING === deliveryStatus) {
      return (
        <div className="flex items-center justify-start gap-2 flex-1">
          <div className="flex items-center text-green-400">
            <Icons.CheckCircle className="w-4.5 h-4.5" />
            <span className="text-3.5 font-semibold uppercase ml-1.5">{t('labels.approved', SCOPE_OPTIONS)}</span>
          </div>
          {DELIVERY_STATUS.RUNNING === deliveryStatus && <div className="text-4 cursor-default">{'🚀'}</div>}
        </div>
      );
    }

    if (DELIVERY_STATUS.PENDING === deliveryStatus) {
      return (
        <div className="flex items-center flex-1 text-yellow-400">
          <Icons.ClockCircle className="w-4.5 h-4.5" />
          <span className="text-3.5 font-semibold uppercase ml-1.5">{t('labels.pending', SCOPE_OPTIONS)}</span>
        </div>
      );
    }

    if (DELIVERY_STATUS.DRAFT === deliveryStatus) {
      return (
        <div className="flex items-center flex-1 text-gray-500">
          <Icons.PlusCircle className="w-4.5 h-4.5" />
          <span className="text-3.5 font-semibold uppercase ml-1.5">{t('labels.draft', SCOPE_OPTIONS)}</span>
        </div>
      );
    }

    if (DELIVERY_STATUS.REJECTED === deliveryStatus) {
      return (
        <div className="flex items-center flex-1 text-red-400">
          <Icons.CrossCircle className="w-4.5 h-4.5" />
          <span className="text-3.5 font-semibold uppercase ml-1.5">{t('labels.rejected', SCOPE_OPTIONS)}</span>
        </div>
      );
    }

    return <div />;
  };

  function handleConfirmClick() {
    if (!alertRef?.current) {
      return;
    }

    alertRef?.current.close();

    if ([DELIVERY_STATUS.APPROVED, DELIVERY_STATUS.RUNNING].includes(deliveryStatus as 'approved' | 'running')) {
      startWaiting(TEMPLATE_LIST_LOADER_KEY);
      archiveTemplateMutate({
        companyId: companyId,
        templateId: id,
      });
      return;
    }

    startWaiting(TEMPLATE_LIST_LOADER_KEY);
    deleteTemplateMutate({
      companyId: companyId,
      templateId: id,
    });
  }

  function handleCancelClick() {
    if (!alertRef?.current) {
      return;
    }

    alertRef?.current.close();
  }

  function isPublisherPlatformsInclude(value: string) {
    return targeting?.publisher_platforms?.includes(value as TemplateTargetingPublisherPlatformsEnum);
  }

  return (
    <div className="flex flex-col bg-white py-3 px-4 rounded-3 drop-shadow-box">
      <div className="flex items-center space-between">
        <div className="flex-1">
          <h5 className="text-sm font-semibold text-gray-900 leading-4 truncate max-w-50 cursor-default" title={name}>
            {name}
          </h5>
        </div>

        <Dropdown
          button={<Icons.Dots className="text-gray-900 w-5 h-5" aria-hidden="true" />}
          items={DROPDOWN_BUTTONS}
          onButtonClick={(type: string) => handleDropdownButtonClick(type, id)}
        />
      </div>

      {isGooglePlatform ? (
        <img src={'/images/map-dummy-image.png'} className="my-3 rounded aspect-1 object-cover bg-black" alt={name} />
      ) : (
        <span className="relative">
          <img src={image} className="my-3 rounded aspect-1 object-contain bg-black" alt={name} />
          {isMultiTemplate && (
            <div className="absolute bottom-6 left-2 flex justify-center items-center bg-white rounded-2.5 py-1 px-2">
              <Icons.Group className="w-4 h-4 mr-1 text-blue-500" />
              <span className="text-3 text-blue-500 font-medium">{t('labels.multiTemplate', SCOPE_OPTIONS)}</span>
            </div>
          )}
        </span>
      )}
      <div className="flex items-center space-between">
        <DeliveryStatus />
        <div className="mr-2 text-green-400 text-sm font-semibold bg-green-100 px-2 py-1 rounded-full">{activeBranchCount}</div>
        {!array.isEmpty(targeting?.publisher_platforms) && (
          <div className="flex items-center gap-x-3">
            {isPublisherPlatformsInclude(PUBLISHER_PLATFORMS.FACEBOOK) && <Icons.Facebook className="w-5 h-5 text-gray-500" />}
            {isPublisherPlatformsInclude(PUBLISHER_PLATFORMS.INSTAGRAM) && <Icons.Instagram className="w-5 h-5 text-gray-500" />}
            {isPublisherPlatformsInclude(PUBLISHER_PLATFORMS.GOOGLE) && <Icons.GoogleIcon className="w-5 h-5 text-gray-500" />}
            {isPublisherPlatformsInclude(PUBLISHER_PLATFORMS.DYNAMICLOCATIONADS) && (
              <div className="flex items-center">
                <Icons.Dla className="text-5 text-blue-500" />
                <span className="ml-2 text-blue-500 font-semibold">{t('labels.dla', SCOPE_OPTIONS)}</span>
              </div>
            )}
          </div>
        )}
      </div>
      <Alert
        ref={alertRef}
        button={{
          confirm: {
            label: t('actions.delete', SCOPE_OPTIONS),
            theme: 'red',
          },
          cancel: {
            label: t('actions.cancel', SCOPE_OPTIONS),
            theme: 'gray',
          },
        }}
        title={t('labels.deleteTitle', SCOPE_OPTIONS)}
        description={t('labels.deteleDescription', SCOPE_OPTIONS)}
        onConfirmClick={handleConfirmClick}
        onCancelClick={handleCancelClick}
      />
    </div>
  );
};

export default TemplateCard;
