import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookAccountGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function getCompanyLeadGenForms(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookAccountGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.getCompanyLeadGenForms(companyId).then((res) => res.data);
}

export default function useGetCompanyLeadGenForms(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(['useGetCompanyLeadGenForms', companyId], () => getCompanyLeadGenForms(companyId, axiosInstance), props);
}
