/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FacebookAdTargetingCategoryErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdTargetingCategorySuccessResponse } from '../model';
// @ts-ignore
import { FacebookCitiesListResponse } from '../model';
// @ts-ignore
import { FacebookCountriesListResponse } from '../model';
// @ts-ignore
import { FacebookGeneratePreviewInput } from '../model';
// @ts-ignore
import { FacebookGeneratePreviewSuccessResponse } from '../model';
// @ts-ignore
import { FacebookInterestsListResponse } from '../model';
// @ts-ignore
import { FacebookLocalesListResponse } from '../model';
// @ts-ignore
import { FacebookReachEstimateErrorResponse } from '../model';
// @ts-ignore
import { FacebookReachEstimateInput } from '../model';
// @ts-ignore
import { FacebookReachEstimateSuccessResponse } from '../model';
/**
 * FacebookGMApi - axios parameter creator
 * @export
 */
export const FacebookGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} companyId The id of the company
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGmFacebookReachEstimate: async (companyId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('calculateGmFacebookReachEstimate', 'companyId', companyId)
            // verify required parameter 'facebookReachEstimateInput' is not null or undefined
            assertParamExists('calculateGmFacebookReachEstimate', 'facebookReachEstimateInput', facebookReachEstimateInput)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/reach_estimate`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookReachEstimateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} companyId The id of the company
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGmFacebookPreview: async (companyId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('generateGmFacebookPreview', 'companyId', companyId)
            // verify required parameter 'facebookGeneratePreviewInput' is not null or undefined
            assertParamExists('generateGmFacebookPreview', 'facebookGeneratePreviewInput', facebookGeneratePreviewInput)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/generate_preview`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(facebookGeneratePreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookAdTargetingCategories: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmFacebookAdTargetingCategories', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/ad_targeting_categories`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} companyId The id of the company
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookCities: async (companyId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmFacebookCities', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listGmFacebookCities', 'query', query)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/cities`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookCountries: async (companyId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmFacebookCountries', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listGmFacebookCountries', 'query', query)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/countries`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookInterests: async (companyId: string, query: string, perPage?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmFacebookInterests', 'companyId', companyId)
            // verify required parameter 'query' is not null or undefined
            assertParamExists('listGmFacebookInterests', 'query', query)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/interests`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} companyId The id of the company
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookLocales: async (companyId: string, perPage?: number, query?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGmFacebookLocales', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/facebook/locales`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FacebookGMApi - functional programming interface
 * @export
 */
export const FacebookGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FacebookGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} companyId The id of the company
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async calculateGmFacebookReachEstimate(companyId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookReachEstimateSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.calculateGmFacebookReachEstimate(companyId, facebookReachEstimateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} companyId The id of the company
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateGmFacebookPreview(companyId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookGeneratePreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateGmFacebookPreview(companyId, facebookGeneratePreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmFacebookAdTargetingCategories(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAdTargetingCategorySuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmFacebookAdTargetingCategories(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} companyId The id of the company
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmFacebookCities(companyId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookCitiesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmFacebookCities(companyId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmFacebookCountries(companyId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookCountriesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmFacebookCountries(companyId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmFacebookInterests(companyId: string, query: string, perPage?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookInterestsListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmFacebookInterests(companyId, query, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} companyId The id of the company
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGmFacebookLocales(companyId: string, perPage?: number, query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookLocalesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGmFacebookLocales(companyId, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FacebookGMApi - factory interface
 * @export
 */
export const FacebookGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FacebookGMApiFp(configuration)
    return {
        /**
         * Calculate reach estimate of facebook with given params
         * @summary Calculate reach estimate of facebook with given params
         * @param {string} companyId The id of the company
         * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        calculateGmFacebookReachEstimate(companyId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): AxiosPromise<FacebookReachEstimateSuccessResponse> {
            return localVarFp.calculateGmFacebookReachEstimate(companyId, facebookReachEstimateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate facebook ad preview
         * @summary Generate facebook ad preview
         * @param {string} companyId The id of the company
         * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGmFacebookPreview(companyId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): AxiosPromise<FacebookGeneratePreviewSuccessResponse> {
            return localVarFp.generateGmFacebookPreview(companyId, facebookGeneratePreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Return ad targeting categories of facebook
         * @summary Return ad targeting categories of facebook
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookAdTargetingCategories(companyId: string, options?: any): AxiosPromise<FacebookAdTargetingCategorySuccessResponse> {
            return localVarFp.listGmFacebookAdTargetingCategories(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return cities of facebook
         * @summary Return cities of facebook
         * @param {string} companyId The id of the company
         * @param {string} query City name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookCities(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCitiesListResponse> {
            return localVarFp.listGmFacebookCities(companyId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return countries of facebook
         * @summary Return countries of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Country name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookCountries(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCountriesListResponse> {
            return localVarFp.listGmFacebookCountries(companyId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return interests of facebook
         * @summary Return interests of facebook
         * @param {string} companyId The id of the company
         * @param {string} query Interest name
         * @param {number} [perPage] Per page limit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookInterests(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookInterestsListResponse> {
            return localVarFp.listGmFacebookInterests(companyId, query, perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Return locales of facebook
         * @summary Return locales of facebook
         * @param {string} companyId The id of the company
         * @param {number} [perPage] Per page limit
         * @param {string} [query] Locale name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGmFacebookLocales(companyId: string, perPage?: number, query?: string, options?: any): AxiosPromise<FacebookLocalesListResponse> {
            return localVarFp.listGmFacebookLocales(companyId, perPage, query, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FacebookGMApi - interface
 * @export
 * @interface FacebookGMApi
 */
export interface FacebookGMApiInterface {
    /**
     * Calculate reach estimate of facebook with given params
     * @summary Calculate reach estimate of facebook with given params
     * @param {string} companyId The id of the company
     * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    calculateGmFacebookReachEstimate(companyId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any): AxiosPromise<FacebookReachEstimateSuccessResponse>;

    /**
     * Generate facebook ad preview
     * @summary Generate facebook ad preview
     * @param {string} companyId The id of the company
     * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    generateGmFacebookPreview(companyId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any): AxiosPromise<FacebookGeneratePreviewSuccessResponse>;

    /**
     * Return ad targeting categories of facebook
     * @summary Return ad targeting categories of facebook
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    listGmFacebookAdTargetingCategories(companyId: string, options?: any): AxiosPromise<FacebookAdTargetingCategorySuccessResponse>;

    /**
     * Return cities of facebook
     * @summary Return cities of facebook
     * @param {string} companyId The id of the company
     * @param {string} query City name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    listGmFacebookCities(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCitiesListResponse>;

    /**
     * Return countries of facebook
     * @summary Return countries of facebook
     * @param {string} companyId The id of the company
     * @param {string} query Country name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    listGmFacebookCountries(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookCountriesListResponse>;

    /**
     * Return interests of facebook
     * @summary Return interests of facebook
     * @param {string} companyId The id of the company
     * @param {string} query Interest name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    listGmFacebookInterests(companyId: string, query: string, perPage?: number, options?: any): AxiosPromise<FacebookInterestsListResponse>;

    /**
     * Return locales of facebook
     * @summary Return locales of facebook
     * @param {string} companyId The id of the company
     * @param {number} [perPage] Per page limit
     * @param {string} [query] Locale name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApiInterface
     */
    listGmFacebookLocales(companyId: string, perPage?: number, query?: string, options?: any): AxiosPromise<FacebookLocalesListResponse>;

}

/**
 * FacebookGMApi - object-oriented interface
 * @export
 * @class FacebookGMApi
 * @extends {BaseAPI}
 */
export class FacebookGMApi extends BaseAPI implements FacebookGMApiInterface {
    /**
     * Calculate reach estimate of facebook with given params
     * @summary Calculate reach estimate of facebook with given params
     * @param {string} companyId The id of the company
     * @param {FacebookReachEstimateInput} facebookReachEstimateInput Targeting params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public calculateGmFacebookReachEstimate(companyId: string, facebookReachEstimateInput: FacebookReachEstimateInput, options?: any) {
        return FacebookGMApiFp(this.configuration).calculateGmFacebookReachEstimate(companyId, facebookReachEstimateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate facebook ad preview
     * @summary Generate facebook ad preview
     * @param {string} companyId The id of the company
     * @param {FacebookGeneratePreviewInput} facebookGeneratePreviewInput Preview params
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public generateGmFacebookPreview(companyId: string, facebookGeneratePreviewInput: FacebookGeneratePreviewInput, options?: any) {
        return FacebookGMApiFp(this.configuration).generateGmFacebookPreview(companyId, facebookGeneratePreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return ad targeting categories of facebook
     * @summary Return ad targeting categories of facebook
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public listGmFacebookAdTargetingCategories(companyId: string, options?: any) {
        return FacebookGMApiFp(this.configuration).listGmFacebookAdTargetingCategories(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return cities of facebook
     * @summary Return cities of facebook
     * @param {string} companyId The id of the company
     * @param {string} query City name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public listGmFacebookCities(companyId: string, query: string, perPage?: number, options?: any) {
        return FacebookGMApiFp(this.configuration).listGmFacebookCities(companyId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return countries of facebook
     * @summary Return countries of facebook
     * @param {string} companyId The id of the company
     * @param {string} query Country name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public listGmFacebookCountries(companyId: string, query: string, perPage?: number, options?: any) {
        return FacebookGMApiFp(this.configuration).listGmFacebookCountries(companyId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return interests of facebook
     * @summary Return interests of facebook
     * @param {string} companyId The id of the company
     * @param {string} query Interest name
     * @param {number} [perPage] Per page limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public listGmFacebookInterests(companyId: string, query: string, perPage?: number, options?: any) {
        return FacebookGMApiFp(this.configuration).listGmFacebookInterests(companyId, query, perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return locales of facebook
     * @summary Return locales of facebook
     * @param {string} companyId The id of the company
     * @param {number} [perPage] Per page limit
     * @param {string} [query] Locale name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FacebookGMApi
     */
    public listGmFacebookLocales(companyId: string, perPage?: number, query?: string, options?: any) {
        return FacebookGMApiFp(this.configuration).listGmFacebookLocales(companyId, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }
}
