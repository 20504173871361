const AD_FORMAT = Object.freeze({
  AUDIENCE_NETWORK_INSTREAM_VIDEO: 'AUDIENCE_NETWORK_INSTREAM_VIDEO',
  AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE: 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE',
  AUDIENCE_NETWORK_OUTSTREAM_VIDEO: 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO',
  AUDIENCE_NETWORK_REWARDED_VIDEO: 'AUDIENCE_NETWORK_REWARDED_VIDEO',
  BIZ_DISCO_FEED_MOBILE: 'BIZ_DISCO_FEED_MOBILE',
  DESKTOP_FEED_STANDARD: 'DESKTOP_FEED_STANDARD',
  FACEBOOK_REELS_BANNER: 'FACEBOOK_REELS_BANNER',
  FACEBOOK_REELS_MOBILE: 'FACEBOOK_REELS_MOBILE',
  FACEBOOK_REELS_STICKER: 'FACEBOOK_REELS_STICKER',
  FACEBOOK_STORY_MOBILE: 'FACEBOOK_STORY_MOBILE',
  FACEBOOK_STORY_STICKER_MOBILE: 'FACEBOOK_STORY_STICKER_MOBILE',
  INSTAGRAM_EXPLORE_CONTEXTUAL: 'INSTAGRAM_EXPLORE_CONTEXTUAL',
  INSTAGRAM_EXPLORE_IMMERSIVE: 'INSTAGRAM_EXPLORE_IMMERSIVE',
  INSTAGRAM_REELS: 'INSTAGRAM_REELS',
  INSTAGRAM_SHOP: 'INSTAGRAM_SHOP',
  INSTAGRAM_STANDARD: 'INSTAGRAM_STANDARD',
  INSTAGRAM_STORY: 'INSTAGRAM_STORY',
  INSTANT_ARTICLE_RECIRCULATION_AD: 'INSTANT_ARTICLE_RECIRCULATION_AD',
  INSTANT_ARTICLE_STANDARD: 'INSTANT_ARTICLE_STANDARD',
  INSTREAM_BANNER_DESKTOP: 'INSTREAM_BANNER_DESKTOP',
  INSTREAM_BANNER_MOBILE: 'INSTREAM_BANNER_MOBILE',
  INSTREAM_VIDEO_DESKTOP: 'INSTREAM_VIDEO_DESKTOP',
  INSTREAM_VIDEO_IMAGE: 'INSTREAM_VIDEO_IMAGE',
  INSTREAM_VIDEO_MOBILE: 'INSTREAM_VIDEO_MOBILE',
  JOB_BROWSER_DESKTOP: 'JOB_BROWSER_DESKTOP',
  JOB_BROWSER_MOBILE: 'JOB_BROWSER_MOBILE',
  MARKETPLACE_MOBILE: 'MARKETPLACE_MOBILE',
  MESSENGER_MOBILE_INBOX_MEDIA: 'MESSENGER_MOBILE_INBOX_MEDIA',
  MESSENGER_MOBILE_STORY_MEDIA: 'MESSENGER_MOBILE_STORY_MEDIA',
  MOBILE_BANNER: 'MOBILE_BANNER',
  MOBILE_FEED_BASIC: 'MOBILE_FEED_BASIC',
  MOBILE_FEED_STANDARD: 'MOBILE_FEED_STANDARD',
  MOBILE_FULLWIDTH: 'MOBILE_FULLWIDTH',
  MOBILE_INTERSTITIAL: 'MOBILE_INTERSTITIAL',
  MOBILE_MEDIUM_RECTANGLE: 'MOBILE_MEDIUM_RECTANGLE',
  MOBILE_NATIVE: 'MOBILE_NATIVE',
  RIGHT_COLUMN_STANDARD: 'RIGHT_COLUMN_STANDARD',
  SUGGESTED_VIDEO_DESKTOP: 'SUGGESTED_VIDEO_DESKTOP',
  SUGGESTED_VIDEO_MOBILE: 'SUGGESTED_VIDEO_MOBILE',
  WATCH_FEED_HOME: 'WATCH_FEED_HOME',
  WATCH_FEED_MOBILE: 'WATCH_FEED_MOBILE',
});

export default AD_FORMAT;
