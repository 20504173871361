import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CompaniesGMApi, CompanyUpdateMobileApplicationInput } from '@app/api';
import { useAxios } from '@app/hooks';

export function updateMobileApp(
  companyId: string,
  data: CompanyUpdateMobileApplicationInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CompaniesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateMobileApplication(companyId, data).then((res) => res.data);
}

export default function useUpdateMobileApp(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; mobile_application: CompanyUpdateMobileApplicationInput }) =>
      updateMobileApp(values.companyId, values.mobile_application, axiosInstance),
    props
  );
}
