import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M63 9.66667C63.0003 8.79112 62.828 7.9241 62.4931 7.11516C62.1581 6.30621 61.6671 5.57119 61.048 4.95208C60.4289 4.33298 59.6938 3.84193 58.8849 3.50698C58.0759 3.17203 57.2089 2.99976 56.3334 3M63 33V36.3333M63 19.6667V23M63 46.3333C63.0003 47.2089 62.828 48.0759 62.4931 48.8848C62.1581 49.6938 61.6671 50.4288 61.048 51.0479C60.4289 51.667 59.6938 52.1581 58.8849 52.493C58.0759 52.828 57.2089 53.0002 56.3334 53M19.6667 3C18.7911 2.99976 17.9241 3.17203 17.1152 3.50698C16.3062 3.84193 15.5712 4.33298 14.9521 4.95208C14.333 5.57119 13.8419 6.30621 13.507 7.11516C13.172 7.9241 12.9998 8.79112 13 9.66667M43 3H46.3333M29.6666 3H33M46.3334 36.7833C45.2376 36.6131 44.1308 36.5238 43.022 36.5161C39.6926 36.4912 36.3975 37.1896 33.3645 38.5628C30.3314 39.936 27.6326 41.9513 25.4546 44.4696C23.2766 46.9879 21.6714 49.949 20.7498 53.1483C19.8281 56.3477 19.6121 59.709 20.1167 63M17.2674 33.5086C17.2674 33.591 17.243 33.6715 17.1972 33.74C17.1514 33.8086 17.0863 33.862 17.0102 33.8935C16.9341 33.925 16.8503 33.9333 16.7695 33.9172C16.6886 33.9011 16.6144 33.8615 16.5561 33.8032C16.4978 33.7449 16.4582 33.6707 16.4421 33.5898C16.426 33.509 16.4343 33.4252 16.4658 33.3491C16.4973 33.273 16.5507 33.2079 16.6193 33.1621C16.6878 33.1163 16.7683 33.0919 16.8507 33.0919M16 19.6667H33.3333C40.513 19.6667 46.3333 25.487 46.3333 32.6667V50C46.3333 57.1797 40.513 63 33.3333 63H16C8.8203 63 3 57.1797 3 50V32.6667C3 25.487 8.8203 19.6667 16 19.6667Z"
      stroke="currentColor"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
