async function fileToUrl(file: any) {
  if (file) {
    return new Promise((resolve) => {
      const image = new Image();
      image.onload = function () {
        file.width = image.width;
        file.height = image.height;
        resolve(file);
      };
      image.src = URL.createObjectURL(file);
    });
  }
}

async function urlToFile(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  const file = new File([blob], 'image.png', { type: blob.type });

  return file;
}

async function urlToBase64(file: File) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = function (event) {
      if (event?.target) {
        resolve(`data:${file.type};base64,${btoa(event.target.result as string)}`);
      }
    };
  });
}

export default { fileToUrl, urlToFile, urlToBase64 };
