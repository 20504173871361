import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

import { useFormContext } from 'react-hook-form';
import cn from 'classnames';

import { useLocale, useAuth } from '@app/hooks';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';
import { BannerbearEditor } from '@app/components';

type ImageUploadPropTypes = {
  index: number;
  type: typeof IMAGE_PLACEMENT_TYPE[keyof typeof IMAGE_PLACEMENT_TYPE];
  bbTemplateId?: string;
  setBannerbearSession: Dispatch<SetStateAction<BannerbearSessionStateType>>;
  bannerbearSession: BannerbearSessionStateType;
  isMetaTemplate: boolean;
};

const TEMPLATE_SIZES = {
  STORY: {
    width: 1080,
    height: 1920,
  },
  FEED: {
    width: 1080,
    height: 1080,
  },
};

const SCOPE_OPTIONS = {
  scope: 'components.Templates.Form.BannerbearInput',
};

const BannerbearInput: React.FC<ImageUploadPropTypes> = ({
  index,
  type: imageType,
  bannerbearSession,
  setBannerbearSession,
  isMetaTemplate,
}) => {
  const { t } = useLocale();
  const { companyId } = useAuth();
  const router = useRouter();
  const queryParams = router.query;
  const {
    getValues,
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const [shouldDuplicate, setShouldDuplicate] = useState(false);
  const inputId = `templates[${index}].bannerbear_${imageType.toLocaleLowerCase()}_template_id`;
  const templateWatch = watch(inputId);
  const nameWatch = watch(`templates[${index}].name`);

  useEffect(() => {
    register(inputId, {
      required: { value: true, message: t(`${imageType.toLocaleLowerCase()}.errors.required`, SCOPE_OPTIONS) },
    });
  }, []);

  useEffect(() => {
    if (queryParams.id) {
      setShouldDuplicate(true);
    }
  }, [queryParams.id]);

  async function createBbTemplate(templateWatch: string, shouldDuplicate: boolean) {
    if (templateWatch && !shouldDuplicate) {
      return templateWatch;
    }

    const url = new URL(`${process.env.NEXT_PUBLIC_BB_URL}/templates`);

    if (shouldDuplicate) {
      url.searchParams.append('source', templateWatch);
    }

    const templateName = getValues(`templates[${index}].name`);
    const body = {
      name: `${templateName} - ${imageType}`,
      ...TEMPLATE_SIZES[imageType as keyof typeof IMAGE_PLACEMENT_TYPE],
      metadata: `{"company_id":"${companyId}", "is_meta_template": ${isMetaTemplate}, "placement_type": "${imageType}"}`,
    };

    const res = await fetch(url.href, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(process.env.NEXT_PUBLIC_BB_API_KEY),
      },
    });

    const data = await res.json();

    return data.uid;
  }

  async function createSession(templateWatch: string, shouldDuplicate: boolean) {
    const template = await createBbTemplate(templateWatch, shouldDuplicate);

    const body = {
      template,
    };

    const res = await fetch(`${process.env.NEXT_PUBLIC_BB_URL}/sessions`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        Authorization: String(process.env.NEXT_PUBLIC_BB_API_KEY),
      },
    });

    const data = await res.json();
    if (data.session_editor_url) {
      setBannerbearSession({ url: data.session_editor_url as string, type: imageType, isOpen: true });
      setValue(inputId, template);
      setShouldDuplicate(false);
    }
  }

  const Error: React.FC = () => {
    const errorKey = errors.templates?.[index]?.[`bannerbear_${imageType.toLocaleLowerCase()}_template_id`];

    if (errorKey) {
      return (
        <div className="flex items-center">
          <span className="inline text-red-400 text-3">{errorKey.message}</span>
        </div>
      );
    }

    return null;
  };

  return (
    <button disabled={!nameWatch} type="button" className={cn('w-full')} onClick={() => createSession(templateWatch, shouldDuplicate)}>
      <div className="flex items-center justify-between">
        <label className="block text-sm font-semibold text-gray-900">
          {t(`${imageType.toLocaleLowerCase()}.label`, SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </label>
        <Error />
      </div>
      <div
        className={cn('mt-1.5 border-0.5 border-dashed rounded-1 h-13 flex items-center justify-between px-4', {
          'border-gray-500': !errors.image,
          'border-red-400': !!errors.image,
        })}
      >
        {!templateWatch && (
          <span className="text-gray-500 font-medium">
            {t(
              nameWatch ? `${imageType.toLocaleLowerCase()}.placeholder` : `${imageType.toLocaleLowerCase()}.placeholder_error`,
              SCOPE_OPTIONS
            )}
          </span>
        )}
        {!!templateWatch && (
          <>
            <span className="text-gray-500 font-medium">{t(`${imageType.toLocaleLowerCase()}.success`, SCOPE_OPTIONS)}</span>
            <div className="bg-amber-500 rounded-1 px-4 py-2 text-center text-sm text-white">{t(`actions.edit`, SCOPE_OPTIONS)}</div>
          </>
        )}
      </div>
      {bannerbearSession.url && bannerbearSession.type === imageType && (
        <BannerbearEditor bannerbearSession={bannerbearSession} setBannerbearSession={setBannerbearSession} />
      )}
    </button>
  );
};

export default BannerbearInput;
