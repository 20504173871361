function limit(str: string, maxLength: number) {
  if (typeof str !== 'string') {
    return '';
  }

  if (str.length <= maxLength) {
    return str;
  }

  const handledString = str.substring(0, maxLength);

  return `${handledString}...`;
}

export default {
  limit,
};
