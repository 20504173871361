import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.499 3C11.5 1.895 9.26 1 6.5 1C3.74 1 1.502 1.896 1.5 3M1.5 3C1.5 4.105 3.738 5 6.5 5C9.262 5 11.5 4.105 11.5 3V10.758M1.5 3V15C1.501 16.105 3.739 17 6.5 17C7.839 17 9.048 16.787 9.945 16.444M1.501 7C1.501 8.105 3.739 9 6.501 9C9.263 9 11.501 8.105 11.501 7M10.005 12.425C9.103 12.78 7.866 13 6.5 13C3.739 13 1.501 12.105 1.501 11M17.182 11.318C18.939 13.075 18.939 15.925 17.182 17.682C15.425 19.439 12.575 19.439 10.818 17.682C9.061 15.925 9.061 13.075 10.818 11.318C12.575 9.561 15.425 9.561 17.182 11.318Z"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
