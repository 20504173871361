import React from 'react';

import { format, company as companyLib } from '@app/lib';
import { useAuth, useLocale } from '@app/hooks';
import { Icons } from '@app/components';
import { ANALYTICS_CONTENT, COLORS } from '@app/constants';

type CardPropTypes = {
  id: string;
  title: string;
  count: string | number;
  color: string;
  selected: boolean;
  Icon: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  onClick: () => void;
};

type LegendPropTypes = {
  selected: string[];
  onClick: (key: string) => void;
  totalInsights: any;
};

const Card: React.FC<CardPropTypes> = ({ id, onClick, title, count, color, selected, Icon }) => {
  const { company } = useAuth();
  const currency = companyLib.currency(company);

  return (
    <button onClick={onClick} className="relative bg-white h-28 border-r border-r-gray-200">
      <div
        className="pt-4 pl-5 pb-2 pr-4 top-0 left-0 right-0 bottom-0 absolute z-1 border-b-0.5 transition-colors"
        style={{ borderBottomColor: selected ? color : COLORS.WHITE }}
      >
        <div className="flex flex-row items-center" style={{ color: color }}>
          <Icon className="w-5 h-5" />
          <span className="text-3.5 block text-left ml-2 mr-auto">{title}</span>
          {selected === true && <Icons.CheckCircle className="w-5 h-5 fill-current" />}
          {selected === false && <div className="w-5 h-5 rounded-full border-0.5" style={{ borderColor: color }} />}
        </div>
        <span className="text-gray-900 text-7.5 leading-14 mt-2 block text-left"> {format.data(count, currency, id)}</span>
      </div>
      <div
        className="w-full h-full opacity-10 top-0 left-0 right-0 bottom-0 absolute z-0 transition-colors"
        style={{ background: selected ? `linear-gradient(180deg, ${color} 0%, ${COLORS.WHITE} 100%)` : COLORS.WHITE }}
      />
    </button>
  );
};

const Legend: React.FC<LegendPropTypes> = ({ totalInsights, selected, onClick }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.Legend',
  };
  const { t } = useLocale();

  function handleValue(key: string) {
    if (key === ANALYTICS_CONTENT.CPC.KEY) {
      return totalInsights?.total_cpc;
    }
    if (key === ANALYTICS_CONTENT.IMPRESSION.KEY) {
      return totalInsights?.total_impressions;
    }
    if (key === ANALYTICS_CONTENT.COST.KEY) {
      return totalInsights?.total_spend;
    }

    return totalInsights?.total_clicks;
  }

  return (
    <div className="grid grid-cols-4 drop-shadow-box mb-4 bg-white -mx-4 -mt-5">
      {Object.values(ANALYTICS_CONTENT).map((content) => (
        <Card
          key={content.KEY}
          id={content.KEY}
          title={t(`labels.${content.KEY}`, SCOPE_OPTIONS)}
          count={handleValue(content.KEY)}
          color={content.COLOR}
          selected={Array.isArray(selected) && selected.includes(content.KEY)}
          Icon={Icons.Eye}
          onClick={() => onClick(content.KEY)}
        />
      ))}
    </div>
  );
};

export default Legend;
