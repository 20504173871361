import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="682.667" height="682.667" version="1" viewBox="0 0 512 512" {...props}>
    <path
      d="M2416 4584c-90-22-193-81-267-154-71-71-115-140-150-240l-24-65-3-786c-3-883-4-870 67-1015 69-138 194-246 343-295 103-34 253-34 356 0 149 49 274 157 343 295 71 145 70 132 67 1015l-3 786-24 65c-35 100-79 169-150 240-76 75-177 132-272 155-79 18-205 18-283-1z"
      transform="matrix(.1 0 0 -.1 0 512)"
    ></path>
    <path
      d="M3577 2503c-41-563-566-999-1123-934-184 21-365 92-502 196l-69 53-162-162-162-162 63-50c241-194 522-307 836-336 178-17 402 15 602 87 552 199 942 716 977 1297l6 98h-460l-6-87z"
      transform="matrix(.1 0 0 -.1 0 512)"
    ></path>
  </svg>
);

export default SvgComponent;
