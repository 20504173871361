import React, { useEffect } from 'react';

import { useRouter } from 'next/router';
import { array } from '@app/lib';
import { api, useAuth, useCheckPaymentAlert, useLocale, useToast } from '@app/hooks';
import type { CompaniesListResponse } from '@app/api';

const CheckCompanyInformation: React.FC = ({ children }) => {
  const router = useRouter();
  const { companyId, company, storeCompany } = useAuth();
  const { showAlert } = useCheckPaymentAlert();
  const toast = useToast();
  const { t } = useLocale();
  const SCOPE_OPTIONS = {
    scope: 'components.CheckCompanyInformation.PaymentAlert',
  };
  const { refetch } = api.useListGeneralManagerCompanies({
    enabled: !!companyId,
    onSuccess: (data: CompaniesListResponse) => {
      const companies = data?.companies;

      if (array.isEmpty(companies)) {
        return;
      }

      const foundedCompany = companies.find((companyItem) => companyId === companyItem.id);

      if (!foundedCompany) {
        return;
      }

      if (JSON.stringify(foundedCompany) === JSON.stringify(company)) {
        return;
      }

      storeCompany(foundedCompany);
    },
  });

  useEffect(() => {
    refetch();
  }, [router?.asPath]);

  useEffect(() => {
    if (showAlert) {
      toast.error(t('error', SCOPE_OPTIONS));
    }
  }, [showAlert]);

  return <>{children}</>;
};

export default CheckCompanyInformation;
