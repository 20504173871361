import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { OmniauthCallbackGMApi } from '@app/api';
import type { FacebookCreateInput } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function callFacebookOAuthCallbackForGm(
  companyId: string,
  facebookCreateInput: FacebookCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new OmniauthCallbackGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.callFacebookOAuthCallbackForGm(companyId, facebookCreateInput).then((res) => res.data);
}

export default function useCallFacebookOAuthCallbackForGm(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useMutation((values: FacebookCreateInput) => callFacebookOAuthCallbackForGm(companyId, values, axiosInstance), props);
}
