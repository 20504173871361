import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.82 2.829 7.24 10.231m.005 0 1.99 8.95c.248 1.116 1.73 1.356 2.317.377l9.265-15.444A1.263 1.263 0 0 0 19.734 2.2H2.267c-1.127 0-1.69 1.361-.894 2.158l5.872 5.872Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
