import React, { PropsWithChildren, useEffect, useState } from 'react';

import { GetServerSideProps } from 'next';
import { useFormContext } from 'react-hook-form';

import { api, useLocale } from '@app/hooks';
import { DLA_GROUP_TYPES } from '@app/constants';
import { Form, Pagination } from '@app/components';
import { Branch, Branch as BranchType } from '@app/api';

type LocationFormPropsTypes = PropsWithChildren<{ id?: string }> & {
  searchLocation: string;
  groupType: string;
  selectedBranches?: Array<BranchType>;
};

const FACEBOOK_LIMIT = 999;
const BRANCH_LIMIT = 15;

const LocationForm: React.FC<LocationFormPropsTypes> = ({ searchLocation, groupType, selectedBranches, id }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Grouping.LocationForm',
  };
  const { t } = useLocale();
  const { register, watch, setValue } = useFormContext();
  const [currentPage, setCurrentPage] = useState(1);
  const branches = watch('branch_ids');
  const isLocation = groupType === DLA_GROUP_TYPES.LOCATION || groupType === DLA_GROUP_TYPES.CITY;
  const location_type_query = isLocation ? { location_type_eq: groupType.toLocaleUpperCase() } : {};
  const { data: locationList, refetch: listBranchesRefetch } = api.useListBranches({
    params: {
      groupId: id,
      page: currentPage,
      perPage: BRANCH_LIMIT,
      query: {
        s: 'created_at desc',
        name_i_cont_any: searchLocation,
        is_location_eq: isLocation,
        ...location_type_query,
      },
    },
  });
  const { data: facebookCities } = api.useGetFacebookCities({
    enabled: groupType === DLA_GROUP_TYPES.CITY && !!searchLocation,
    params: {
      perPage: FACEBOOK_LIMIT,
      query: searchLocation,
    },
  });
  const { mutate: cityBranchMutate } = api.useCreateCityBranch({
    onSuccess: (data: { id: string }) => {
      listBranchesRefetch();
      setValue(`branch_ids[${data.id}]`, true);
    },
  });
  const existingFacebookCities = locationList?.branches?.map((location: Branch) => String(location.default_facebook_city?.key));

  useEffect(() => {
    selectedBranches?.map((item) => {
      setValue(`branch_ids[${item}]`, true);
    });
  }, [selectedBranches]);

  function handleCityBranch(cityId: string) {
    cityBranchMutate({
      cityBranchInput: {
        branch: {
          key: Number(cityId),
        },
      },
    });
    setValue(`city_ids[${cityId}]`, false);
  }

  function handleBranchChecked(name: string) {
    const checkboxWatch = watch(name);
    setValue(name, !checkboxWatch);
  }

  return (
    <form className="h-auto py-2 px-4">
      <span className="grid grid-cols-4 items-center border-b-0.5 border-black pb-3">
        <span className="flex text-sm font-semibold col-span-2">{t('labels.locationName', SCOPE_OPTIONS)}</span>
        <span className="flex justify-center text-sm font-semibold">{t('labels.city', SCOPE_OPTIONS)}</span>
        <span className="flex justify-center text-sm font-semibold">{t('labels.district', SCOPE_OPTIONS)}</span>
      </span>
      <div className="overflow-y-scroll h-50 px-1">
        {locationList?.branches?.map((location: BranchType) => (
          <span key={location.id} className="grid grid-cols-4 items-center mt-4">
            <Form.Checkbox
              id={location.id}
              className="col-span-2"
              labelStyle="text-sm font-semibold text-gray-700 justify-center items-center"
              {...register(`branch_ids[${location.id}]`)}
              label={location.name}
              defaultChecked={branches?.[location.id]}
              onChange={() => handleBranchChecked(`branch_ids[${location.id}]`)}
            />
            <span className="flex justify-center text-sm font-semibold text-gray-700">{location.city ?? '-'}</span>
            <span className="flex justify-center text-sm font-semibold text-gray-700">{location.district ?? '-'}</span>
          </span>
        ))}
        {facebookCities?.cities
          ?.filter((city: { id: string }) => !existingFacebookCities?.includes(String(city.id)))
          .map((city: { id: string; name: string; region: string }) => (
            <span key={city.id} className="grid grid-cols-4 items-center mt-4">
              <Form.Checkbox
                id={city.id}
                className="col-span-2"
                labelStyle="text-sm font-semibold text-gray-700 justify-center items-center"
                {...register(`city_ids[${city.id}]`)}
                label={city.name}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleCityBranch(city.id);
                  }
                }}
              />
              <span className="flex justify-center text-sm font-semibold text-gray-700 text-center">{city.region ?? '-'}</span>
              <span className="flex justify-center text-sm font-semibold text-gray-700">-</span>
            </span>
          ))}
        {locationList?.meta.pagination.total_pages > 1 && (
          <div className="bg-white rounded-b-3 py-3 flex items-center justify-center border-t border-gray-300 mt-3">
            <Pagination
              canPreviousPage={locationList?.meta.pagination.previous !== null}
              canNextPage={locationList?.meta.pagination.next !== null}
              currentPage={currentPage}
              totalPageCount={locationList?.meta.pagination.total_pages}
              onPageChange={setCurrentPage}
            />
          </div>
        )}
      </div>
    </form>
  );
};

export default LocationForm;

export const getServerSideProps: GetServerSideProps = async ({ query }) => {
  return {
    props: {
      id: String(query.id),
    },
  };
};
