import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi } from '@app/api';
import type { DynamicLocationAdCampaignCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createDynamicLocationAdsCampaign(
  companyId: string,
  campaignCreateInput: DynamicLocationAdCampaignCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createDynamicLocationAdCampaign(companyId, campaignCreateInput).then((res) => res.data);
}

export default function useCreateDynamicLocationAdsCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; campaignCreateInput: DynamicLocationAdCampaignCreateInput }) =>
      createDynamicLocationAdsCampaign(values.companyId, values.campaignCreateInput, axiosInstance),
    props
  );
}
