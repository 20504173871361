import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { GroupsGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function unassignBranchFromGroup(
  companyId: string,
  branchId: string,
  groupId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new GroupsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.unassignBranchFromGroup(companyId, branchId, groupId).then((res) => res.data);
}

export default function useUnassignBranchFromGroup(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; branchId: string; groupId: string }) =>
      unassignBranchFromGroup(values.companyId, values.branchId, values.groupId, axiosInstance),
    props
  );
}
