/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface V2WalletTransactionsSuccessResponseTransferDetailsSender
 */
export interface V2WalletTransactionsSuccessResponseTransferDetailsSender {
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransferDetailsSender
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransferDetailsSender
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof V2WalletTransactionsSuccessResponseTransferDetailsSender
     */
    type?: V2WalletTransactionsSuccessResponseTransferDetailsSenderTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum V2WalletTransactionsSuccessResponseTransferDetailsSenderTypeEnum {
    Company = 'Company',
    Branch = 'Branch'
}



