import React from 'react';

import cn from 'classnames';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import { useLocale } from '@app/hooks';

type OptionType = {
  value: string | number;
  label: string;
};
type SelectPropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  options: OptionType[];
  defaultValue?: string | string[] | number | number[] | OptionType[];
  filterOptions?: any;
  maxMenuHeight?: number;
  placeholder?: string;
  control: any;
  isMulti?: boolean;
  rules?: any;
  automaticHandleValue?: boolean;
  onInputChange?: (query: string) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  isLoading?: boolean;
  right?: React.ReactNode;
  disabled?: boolean;
};

export const Select: React.FC<SelectPropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  options,
  defaultValue,
  filterOptions,
  maxMenuHeight,
  placeholder,
  control,
  isMulti = false,
  rules,
  automaticHandleValue = true,
  onInputChange,
  isSearchable,
  isClearable,
  isLoading,
  right,
  disabled,
}) => {
  const isRequired = getRequiredStatus();
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SettingsForm',
  };
  const { t } = useLocale();
  function getRequiredStatus() {
    if (rules?.required) {
      if (!Object.prototype.hasOwnProperty.call(rules?.required, 'value')) {
        return true;
      }

      if (rules?.required.value === true) {
        return true;
      }

      return false;
    }
    return false;
  }

  function handleOnChange(newValue: OptionType | OptionType[]) {
    if (automaticHandleValue === false) {
      return newValue;
    }

    if (Array.isArray(newValue)) {
      return newValue.map((option: OptionType) => option.value);
    }

    return newValue.value;
  }

  function handleValue(value: unknown) {
    if (typeof value === 'undefined') {
      return undefined;
    }

    if (automaticHandleValue === false) {
      return value;
    }

    if (Array.isArray(value) && isMulti === true) {
      return options.filter((option: OptionType) => value.includes(option.value));
    }

    return options.filter((option: OptionType) => value === option.value);
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {isRequired && <span className="text-red-400">*</span>}
        </label>
        <div className="flex flex-row items-center gap-4">
          {error && (
            <div className="flex items-center">
              <span className="inline text-red-400 text-3 whitespace-nowrap">{error.message}</span>
            </div>
          )}
          {right && right}
        </div>
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => {
          return (
            <ReactSelect
              onInputChange={onInputChange}
              isSearchable={isSearchable}
              isClearable={isClearable}
              isLoading={isLoading}
              options={options ?? []}
              placeholder={placeholder ?? 'Select...'}
              isMulti={isMulti}
              onChange={(selectedValues: any) => onChange(handleOnChange(selectedValues))}
              onBlur={onBlur}
              value={handleValue(value)}
              defaultValue={handleValue(value)}
              classNamePrefix="react-select"
              className={cn({
                error: error?.message,
              })}
              noOptionsMessage={() => t('form.interest.label', SCOPE_OPTIONS)}
              loadingMessage={() => t('form.interest.loading', SCOPE_OPTIONS)}
              filterOption={filterOptions}
              maxMenuHeight={maxMenuHeight}
              isDisabled={disabled}
            />
          );
        }}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default Select;
