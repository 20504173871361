import React from 'react';

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import cn from 'classnames';

import { useLocale } from '@app/hooks';
import { ANALYTICS_CONTENT } from '@app/constants';
import { array, format } from '@app/lib';

type AnalyticsPropTypes = {
  selected: string[];
  labels: string[];
  data: {
    impression: number[];
    click: number[];
    cost: string[];
    cpc: string[];
  };
};

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip);

const commonDatasetsOptions = {
  backgroundColor: '#ffffff',
  fill: true,
  borderWidth: 2,
  pointRadius: 4,
};

const Analytics: React.FC<AnalyticsPropTypes> = ({ selected, labels, data }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Analytics.Chart.LineChart',
  };
  const { t } = useLocale();

  function generateYAxis() {
    let axisData = {};

    for (const axisKey of selected) {
      const index = selected.findIndex((element) => element === axisKey);

      axisData = {
        ...axisData,
        [axisKey]: {
          type: 'linear',
          position: index !== 0 ? 'right' : 'left',
          grid: {
            borderColor: '#F0F0F0',
            tickColor: '#ffffff',
            color: '#F0F0F0',
          },
          ticks: {
            display: selected.length > 2 ? false : true,
            callback: (val: string | number) => format.data(val),
          },
          display: index > 1 ? false : true,
        },
      };
    }

    return axisData;
  }

  const options = {
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      ...generateYAxis(),
      x: {
        grid: {
          borderColor: '#F0F0F0',
          tickColor: '#ffffff',
          color: '#F0F0F0',
        },
        ticks: {
          color: '#9FA9B3',
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
        },
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  function getData(key: string): string[] {
    if (!Object.prototype.hasOwnProperty.call(data, key)) {
      return [];
    }

    return (data as any)[key];
  }

  function handleDatasets() {
    return Object.values(ANALYTICS_CONTENT)
      .map((content) => {
        return {
          yAxisID: content.KEY,
          key: content.KEY,
          label: t(`labels.${content.KEY}`, SCOPE_OPTIONS),
          data: getData(content.KEY),
          borderColor: content.COLOR,
          pointBorderColor: content.COLOR,
          ...commonDatasetsOptions,
        };
      })
      .filter((item) => selected.includes(item.key));
  }

  const handledLabels = selected.map((key) => t(`labels.${key}`, SCOPE_OPTIONS));
  const firstLabel = array.first(handledLabels);
  const secondLabel = array.second(handledLabels);

  return (
    <div className="w-full">
      <div
        className={cn('flex items-center justify-between text-gray-600 text-3 font-light pb-1.5', {
          invisible: selected.length > 2,
          visible: selected.length <= 2,
        })}
      >
        {firstLabel && <span>{firstLabel}</span>}
        {secondLabel && <span>{secondLabel}</span>}
      </div>
      <Line
        options={options as any}
        data={{
          labels,
          datasets: handleDatasets(),
        }}
        width="100%"
        height="40"
      />
    </div>
  );
};

export default Analytics;
