import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { FacebookGMApi } from '@app/api';
import { useAuth, useAxios } from '@app/hooks';

export function listGmFacebookAdTargetingCategories(companyId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new FacebookGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listGmFacebookAdTargetingCategories(companyId).then((res) => res.data);
}

export default function useListGmFacebookAdTargetingCategories(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListGmFacebookAdTargetingCategories', companyId],
    () => listGmFacebookAdTargetingCategories(companyId, axiosInstance),
    props
  );
}
