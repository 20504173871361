import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TokenGMApi } from '@app/api';
import type { GmUserTokenInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function generalManagerUserLogin(gmUserCredentials: GmUserTokenInput, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TokenGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generalManagerUserLogin(gmUserCredentials).then((res) => res.data);
}

export default function useGeneralManagerUserLogin(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation((values: GmUserTokenInput) => generalManagerUserLogin(values, axiosInstance), props);
}
