/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface DashboardInsightResponseCampaigns
 */
export interface DashboardInsightResponseCampaigns {
    /**
     * 
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    campaign_id: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    campaign_name: string;
    /**
     * 
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    campaign_type?: DashboardInsightResponseCampaignsCampaignTypeEnum;
    /**
     * Number of branches
     * @type {number}
     * @memberof DashboardInsightResponseCampaigns
     */
    branch_count: number;
    /**
     * Number of reach
     * @type {number}
     * @memberof DashboardInsightResponseCampaigns
     */
    reach: number;
    /**
     * Number of clicks
     * @type {number}
     * @memberof DashboardInsightResponseCampaigns
     */
    clicks: number;
    /**
     * Number of impressions
     * @type {number}
     * @memberof DashboardInsightResponseCampaigns
     */
    impressions: number;
    /**
     * Spend
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    spend: string;
    /**
     * CPC
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    cpc: string;
    /**
     * CTR
     * @type {string}
     * @memberof DashboardInsightResponseCampaigns
     */
    ctr: string;
}

/**
    * @export
    * @enum {string}
    */
export enum DashboardInsightResponseCampaignsCampaignTypeEnum {
    Google = 'GOOGLE',
    Facebook = 'FACEBOOK',
    Dynamiclocationads = 'DYNAMICLOCATIONADS'
}



