/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DynamicLocationAdTemplateCreateErrorResponse } from '../model';
// @ts-ignore
import { DynamicLocationAdTemplateCreateInput } from '../model';
// @ts-ignore
import { DynamicLocationAdTemplateGeneratePreviewErrorResponse } from '../model';
// @ts-ignore
import { DynamicLocationAdTemplateGeneratePreviewInput } from '../model';
// @ts-ignore
import { DynamicLocationAdTemplateGeneratePreviewSuccessResponse } from '../model';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdPreviewErrorResponse } from '../model';
// @ts-ignore
import { FacebookAdPreviewSuccessResponse } from '../model';
// @ts-ignore
import { GoogleTemplateCreateInput } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
// @ts-ignore
import { Template } from '../model';
// @ts-ignore
import { TemplateCreateErrorResponse } from '../model';
// @ts-ignore
import { TemplateCreateInput } from '../model';
// @ts-ignore
import { TemplatesListResponse } from '../model';
// @ts-ignore
import { TemplatesPreviewListResponse } from '../model';
// @ts-ignore
import { V2TemplateCreateInput } from '../model';
/**
 * TemplatesGMApi - axios parameter creator
 * @export
 */
export const TemplatesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Archives the template
         * @summary Archive the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveTemplate: async (companyId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('archiveTemplate', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/{template_id}/archive`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a dynamic location ad template
         * @summary Create a dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateCreateInput} dynamicLocationAdTemplateCreateInput Create a dynamic location ad template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDynamicLocationAdTemplate: async (companyId: string, dynamicLocationAdTemplateCreateInput: DynamicLocationAdTemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createDynamicLocationAdTemplate', 'companyId', companyId)
            // verify required parameter 'dynamicLocationAdTemplateCreateInput' is not null or undefined
            assertParamExists('createDynamicLocationAdTemplate', 'dynamicLocationAdTemplateCreateInput', dynamicLocationAdTemplateCreateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/dynamic_location_ad_templates`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicLocationAdTemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a google template
         * @summary Create a google template
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a google template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleTemplate: async (companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createGoogleTemplate', 'companyId', companyId)
            // verify required parameter 'googleTemplateCreateInput' is not null or undefined
            assertParamExists('createGoogleTemplate', 'googleTemplateCreateInput', googleTemplateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/google`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleTemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a google template and publish it
         * @summary Create a google template and publish it
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleTemplateAndPublish: async (companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createGoogleTemplateAndPublish', 'companyId', companyId)
            // verify required parameter 'googleTemplateCreateInput' is not null or undefined
            assertParamExists('createGoogleTemplateAndPublish', 'googleTemplateCreateInput', googleTemplateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/google/publish`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleTemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Works with Bannerbear API to create a new template
         * @summary Create a new Meta template with Bannerbear
         * @param {string} companyId The id of the company
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Create a new Meta template with Bannerbear for the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetaTemplateV2: async (companyId: string, v2TemplateCreateInput: V2TemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createMetaTemplateV2', 'companyId', companyId)
            // verify required parameter 'v2TemplateCreateInput' is not null or undefined
            assertParamExists('createMetaTemplateV2', 'v2TemplateCreateInput', v2TemplateCreateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/facebook`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2TemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a template
         * @summary Create a template
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate: async (companyId: string, templateCreateInput: TemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createTemplate', 'companyId', companyId)
            // verify required parameter 'templateCreateInput' is not null or undefined
            assertParamExists('createTemplate', 'templateCreateInput', templateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/facebook`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a template and publish it
         * @summary Create a template and publish it
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateAndPublish: async (companyId: string, templateCreateInput: TemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createTemplateAndPublish', 'companyId', companyId)
            // verify required parameter 'templateCreateInput' is not null or undefined
            assertParamExists('createTemplateAndPublish', 'templateCreateInput', templateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/facebook/publish`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes the template
         * @summary Delete the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate: async (companyId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('deleteTemplate', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/{template_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Duplicate a template
         * @summary Duplicate a template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateTemplate: async (companyId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('duplicateTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('duplicateTemplate', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/{template_id}/duplicate`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate facebook ad preview by template id
         * @summary Generate facebook ad preview by template id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFacebookAdPreviewByTemplateId: async (companyId: string, templateId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByTemplateId', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByTemplateId', 'templateId', templateId)
            // verify required parameter 'adFormat' is not null or undefined
            assertParamExists('generateFacebookAdPreviewByTemplateId', 'adFormat', adFormat)
            const localVarPath = `/gm_user/companies/{company_id}/templates/facebook/{template_id}/ad_preview`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (adFormat !== undefined) {
                localVarQueryParameter['ad_format'] = adFormat;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate preview for dynamic location ad template
         * @summary Generate preview for dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for dynamic location ad template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewForDynamicLocationAdTemplate: async (companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('generatePreviewForDynamicLocationAdTemplate', 'companyId', companyId)
            // verify required parameter 'dynamicLocationAdTemplateGeneratePreviewInput' is not null or undefined
            assertParamExists('generatePreviewForDynamicLocationAdTemplate', 'dynamicLocationAdTemplateGeneratePreviewInput', dynamicLocationAdTemplateGeneratePreviewInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/dynamic_location_ad_templates/generate_preview`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicLocationAdTemplateGeneratePreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generate preview for Facebook template
         * @summary Generate preview for Facebook template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for Facebook template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewForFacebookTemplate: async (companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('generatePreviewForFacebookTemplate', 'companyId', companyId)
            // verify required parameter 'dynamicLocationAdTemplateGeneratePreviewInput' is not null or undefined
            assertParamExists('generatePreviewForFacebookTemplate', 'dynamicLocationAdTemplateGeneratePreviewInput', dynamicLocationAdTemplateGeneratePreviewInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/facebook/generate_preview`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(dynamicLocationAdTemplateGeneratePreviewInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get previews for Facebook template
         * @summary Get previews for Facebook template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsForFacebookTemplate: async (companyId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getPreviewsForFacebookTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('getPreviewsForFacebookTemplate', 'templateId', templateId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/facebook/{template_id}/previews`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return templates of company
         * @summary Return templates of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesByCompany: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listTemplatesByCompany', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/templates`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRelatedFacebookBranchCampaignPreviews: async (companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showRelatedFacebookBranchCampaignPreviews', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('showRelatedFacebookBranchCampaignPreviews', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/facebook/{template_id}/previews`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRelatedGoogleBranchCampaignPreviews: async (companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showRelatedGoogleBranchCampaignPreviews', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('showRelatedGoogleBranchCampaignPreviews', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/google/{template_id}/previews`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return template with by id
         * @summary Return template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTemplate: async (companyId: string, templateId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('showTemplate', 'templateId', templateId)
            const localVarPath = `/gm_user/companies/{company_id}/templates/{template_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update google template with by id
         * @summary Update google template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Update a google template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleTemplate: async (companyId: string, templateId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGoogleTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateGoogleTemplate', 'templateId', templateId)
            // verify required parameter 'googleTemplateCreateInput' is not null or undefined
            assertParamExists('updateGoogleTemplate', 'googleTemplateCreateInput', googleTemplateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/google/{template_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(googleTemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {TemplateCreateInput} templateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate: async (companyId: string, templateId: string, templateCreateInput: TemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('updateTemplate', 'templateId', templateId)
            // verify required parameter 'templateCreateInput' is not null or undefined
            assertParamExists('updateTemplate', 'templateCreateInput', templateCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/templates/facebook/{template_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateTemplate: async (companyId: string, templateId: string, v2TemplateCreateInput: V2TemplateCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('v2UpdateTemplate', 'companyId', companyId)
            // verify required parameter 'templateId' is not null or undefined
            assertParamExists('v2UpdateTemplate', 'templateId', templateId)
            // verify required parameter 'v2TemplateCreateInput' is not null or undefined
            assertParamExists('v2UpdateTemplate', 'v2TemplateCreateInput', v2TemplateCreateInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/templates/facebook/{template_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"template_id"}}`, encodeURIComponent(String(templateId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(v2TemplateCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplatesGMApi - functional programming interface
 * @export
 */
export const TemplatesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplatesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Archives the template
         * @summary Archive the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveTemplate(companyId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveTemplate(companyId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a dynamic location ad template
         * @summary Create a dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateCreateInput} dynamicLocationAdTemplateCreateInput Create a dynamic location ad template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateCreateInput: DynamicLocationAdTemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a google template
         * @summary Create a google template
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a google template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleTemplate(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleTemplate(companyId, googleTemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a google template and publish it
         * @summary Create a google template and publish it
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGoogleTemplateAndPublish(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGoogleTemplateAndPublish(companyId, googleTemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Works with Bannerbear API to create a new template
         * @summary Create a new Meta template with Bannerbear
         * @param {string} companyId The id of the company
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Create a new Meta template with Bannerbear for the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMetaTemplateV2(companyId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMetaTemplateV2(companyId, v2TemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a template
         * @summary Create a template
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplate(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(companyId, templateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a template and publish it
         * @summary Create a template and publish it
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplateAndPublish(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateAndPublish(companyId, templateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes the template
         * @summary Delete the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteTemplate(companyId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteTemplate(companyId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Duplicate a template
         * @summary Duplicate a template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async duplicateTemplate(companyId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.duplicateTemplate(companyId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate facebook ad preview by template id
         * @summary Generate facebook ad preview by template id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateFacebookAdPreviewByTemplateId(companyId: string, templateId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FacebookAdPreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateFacebookAdPreviewByTemplateId(companyId, templateId, adFormat, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate preview for dynamic location ad template
         * @summary Generate preview for dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for dynamic location ad template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePreviewForDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePreviewForDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generate preview for Facebook template
         * @summary Generate preview for Facebook template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for Facebook template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generatePreviewForFacebookTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generatePreviewForFacebookTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get previews for Facebook template
         * @summary Get previews for Facebook template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPreviewsForFacebookTemplate(companyId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplatesPreviewListResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPreviewsForFacebookTemplate(companyId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return templates of company
         * @summary Return templates of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listTemplatesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listTemplatesByCompany(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showRelatedFacebookBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesPreviewListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showRelatedFacebookBranchCampaignPreviews(companyId, templateId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showRelatedGoogleBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplatesPreviewListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showRelatedGoogleBranchCampaignPreviews(companyId, templateId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return template with by id
         * @summary Return template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showTemplate(companyId: string, templateId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showTemplate(companyId, templateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update google template with by id
         * @summary Update google template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Update a google template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGoogleTemplate(companyId: string, templateId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGoogleTemplate(companyId, templateId, googleTemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {TemplateCreateInput} templateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateTemplate(companyId: string, templateId: string, templateCreateInput: TemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateTemplate(companyId, templateId, templateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async v2UpdateTemplate(companyId: string, templateId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.v2UpdateTemplate(companyId, templateId, v2TemplateCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplatesGMApi - factory interface
 * @export
 */
export const TemplatesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplatesGMApiFp(configuration)
    return {
        /**
         * Archives the template
         * @summary Archive the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveTemplate(companyId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a dynamic location ad template
         * @summary Create a dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateCreateInput} dynamicLocationAdTemplateCreateInput Create a dynamic location ad template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateCreateInput: DynamicLocationAdTemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a google template
         * @summary Create a google template
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a google template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleTemplate(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createGoogleTemplate(companyId, googleTemplateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a google template and publish it
         * @summary Create a google template and publish it
         * @param {string} companyId The id of the company
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoogleTemplateAndPublish(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createGoogleTemplateAndPublish(companyId, googleTemplateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Works with Bannerbear API to create a new template
         * @summary Create a new Meta template with Bannerbear
         * @param {string} companyId The id of the company
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Create a new Meta template with Bannerbear for the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMetaTemplateV2(companyId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createMetaTemplateV2(companyId, v2TemplateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a template
         * @summary Create a template
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplate(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createTemplate(companyId, templateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a template and publish it
         * @summary Create a template and publish it
         * @param {string} companyId The id of the company
         * @param {TemplateCreateInput} templateCreateInput Create a template to company and publish it
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateAndPublish(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.createTemplateAndPublish(companyId, templateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes the template
         * @summary Delete the template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteTemplate(companyId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Duplicate a template
         * @summary Duplicate a template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        duplicateTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Template> {
            return localVarFp.duplicateTemplate(companyId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate facebook ad preview by template id
         * @summary Generate facebook ad preview by template id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateFacebookAdPreviewByTemplateId(companyId: string, templateId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): AxiosPromise<FacebookAdPreviewSuccessResponse> {
            return localVarFp.generateFacebookAdPreviewByTemplateId(companyId, templateId, adFormat, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate preview for dynamic location ad template
         * @summary Generate preview for dynamic location ad template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for dynamic location ad template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewForDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse> {
            return localVarFp.generatePreviewForDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Generate preview for Facebook template
         * @summary Generate preview for Facebook template
         * @param {string} companyId The id of the company
         * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for Facebook template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generatePreviewForFacebookTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse> {
            return localVarFp.generatePreviewForFacebookTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Get previews for Facebook template
         * @summary Get previews for Facebook template
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPreviewsForFacebookTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Array<TemplatesPreviewListResponse>> {
            return localVarFp.getPreviewsForFacebookTemplate(companyId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return templates of company
         * @summary Return templates of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplatesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesListResponse> {
            return localVarFp.listTemplatesByCompany(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRelatedFacebookBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesPreviewListResponse> {
            return localVarFp.showRelatedFacebookBranchCampaignPreviews(companyId, templateId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Show related branch campaign previews
         * @summary Show related branch campaign previews
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showRelatedGoogleBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesPreviewListResponse> {
            return localVarFp.showRelatedGoogleBranchCampaignPreviews(companyId, templateId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Return template with by id
         * @summary Return template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Template> {
            return localVarFp.showTemplate(companyId, templateId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update google template with by id
         * @summary Update google template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Update a google template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoogleTemplate(companyId: string, templateId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.updateGoogleTemplate(companyId, templateId, googleTemplateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {TemplateCreateInput} templateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTemplate(companyId: string, templateId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.updateTemplate(companyId, templateId, templateCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Update template with by id
         * @summary Update template with by id
         * @param {string} companyId The id of the company
         * @param {string} templateId The id of the template
         * @param {V2TemplateCreateInput} v2TemplateCreateInput Update a template with by id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        v2UpdateTemplate(companyId: string, templateId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): AxiosPromise<Template> {
            return localVarFp.v2UpdateTemplate(companyId, templateId, v2TemplateCreateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplatesGMApi - interface
 * @export
 * @interface TemplatesGMApi
 */
export interface TemplatesGMApiInterface {
    /**
     * Archives the template
     * @summary Archive the template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    archiveTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<void>;

    /**
     * Create a dynamic location ad template
     * @summary Create a dynamic location ad template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateCreateInput} dynamicLocationAdTemplateCreateInput Create a dynamic location ad template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateCreateInput: DynamicLocationAdTemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Create a google template
     * @summary Create a google template
     * @param {string} companyId The id of the company
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a google template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createGoogleTemplate(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Create a google template and publish it
     * @summary Create a google template and publish it
     * @param {string} companyId The id of the company
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a template to company and publish it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createGoogleTemplateAndPublish(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Works with Bannerbear API to create a new template
     * @summary Create a new Meta template with Bannerbear
     * @param {string} companyId The id of the company
     * @param {V2TemplateCreateInput} v2TemplateCreateInput Create a new Meta template with Bannerbear for the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createMetaTemplateV2(companyId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Create a template
     * @summary Create a template
     * @param {string} companyId The id of the company
     * @param {TemplateCreateInput} templateCreateInput Create a template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createTemplate(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Create a template and publish it
     * @summary Create a template and publish it
     * @param {string} companyId The id of the company
     * @param {TemplateCreateInput} templateCreateInput Create a template to company and publish it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    createTemplateAndPublish(companyId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Deletes the template
     * @summary Delete the template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    deleteTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<void>;

    /**
     * Duplicate a template
     * @summary Duplicate a template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    duplicateTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Template>;

    /**
     * Generate facebook ad preview by template id
     * @summary Generate facebook ad preview by template id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    generateFacebookAdPreviewByTemplateId(companyId: string, templateId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any): AxiosPromise<FacebookAdPreviewSuccessResponse>;

    /**
     * Generate preview for dynamic location ad template
     * @summary Generate preview for dynamic location ad template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for dynamic location ad template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    generatePreviewForDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse>;

    /**
     * Generate preview for Facebook template
     * @summary Generate preview for Facebook template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for Facebook template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    generatePreviewForFacebookTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any): AxiosPromise<DynamicLocationAdTemplateGeneratePreviewSuccessResponse>;

    /**
     * Get previews for Facebook template
     * @summary Get previews for Facebook template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    getPreviewsForFacebookTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Array<TemplatesPreviewListResponse>>;

    /**
     * Return templates of company
     * @summary Return templates of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    listTemplatesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesListResponse>;

    /**
     * Show related branch campaign previews
     * @summary Show related branch campaign previews
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    showRelatedFacebookBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesPreviewListResponse>;

    /**
     * Show related branch campaign previews
     * @summary Show related branch campaign previews
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    showRelatedGoogleBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<TemplatesPreviewListResponse>;

    /**
     * Return template with by id
     * @summary Return template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    showTemplate(companyId: string, templateId: string, options?: any): AxiosPromise<Template>;

    /**
     * Update google template with by id
     * @summary Update google template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Update a google template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    updateGoogleTemplate(companyId: string, templateId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Update template with by id
     * @summary Update template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {TemplateCreateInput} templateCreateInput Update a template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    updateTemplate(companyId: string, templateId: string, templateCreateInput: TemplateCreateInput, options?: any): AxiosPromise<Template>;

    /**
     * Update template with by id
     * @summary Update template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {V2TemplateCreateInput} v2TemplateCreateInput Update a template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApiInterface
     */
    v2UpdateTemplate(companyId: string, templateId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any): AxiosPromise<Template>;

}

/**
 * TemplatesGMApi - object-oriented interface
 * @export
 * @class TemplatesGMApi
 * @extends {BaseAPI}
 */
export class TemplatesGMApi extends BaseAPI implements TemplatesGMApiInterface {
    /**
     * Archives the template
     * @summary Archive the template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public archiveTemplate(companyId: string, templateId: string, options?: any) {
        return TemplatesGMApiFp(this.configuration).archiveTemplate(companyId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a dynamic location ad template
     * @summary Create a dynamic location ad template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateCreateInput} dynamicLocationAdTemplateCreateInput Create a dynamic location ad template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateCreateInput: DynamicLocationAdTemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a google template
     * @summary Create a google template
     * @param {string} companyId The id of the company
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a google template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createGoogleTemplate(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createGoogleTemplate(companyId, googleTemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a google template and publish it
     * @summary Create a google template and publish it
     * @param {string} companyId The id of the company
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Create a template to company and publish it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createGoogleTemplateAndPublish(companyId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createGoogleTemplateAndPublish(companyId, googleTemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Works with Bannerbear API to create a new template
     * @summary Create a new Meta template with Bannerbear
     * @param {string} companyId The id of the company
     * @param {V2TemplateCreateInput} v2TemplateCreateInput Create a new Meta template with Bannerbear for the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createMetaTemplateV2(companyId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createMetaTemplateV2(companyId, v2TemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a template
     * @summary Create a template
     * @param {string} companyId The id of the company
     * @param {TemplateCreateInput} templateCreateInput Create a template to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createTemplate(companyId: string, templateCreateInput: TemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createTemplate(companyId, templateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a template and publish it
     * @summary Create a template and publish it
     * @param {string} companyId The id of the company
     * @param {TemplateCreateInput} templateCreateInput Create a template to company and publish it
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public createTemplateAndPublish(companyId: string, templateCreateInput: TemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).createTemplateAndPublish(companyId, templateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes the template
     * @summary Delete the template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public deleteTemplate(companyId: string, templateId: string, options?: any) {
        return TemplatesGMApiFp(this.configuration).deleteTemplate(companyId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Duplicate a template
     * @summary Duplicate a template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public duplicateTemplate(companyId: string, templateId: string, options?: any) {
        return TemplatesGMApiFp(this.configuration).duplicateTemplate(companyId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate facebook ad preview by template id
     * @summary Generate facebook ad preview by template id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE'} adFormat Ad format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public generateFacebookAdPreviewByTemplateId(companyId: string, templateId: string, adFormat: 'AUDIENCE_NETWORK_INSTREAM_VIDEO' | 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE' | 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO' | 'AUDIENCE_NETWORK_REWARDED_VIDEO' | 'BIZ_DISCO_FEED_MOBILE' | 'DESKTOP_FEED_STANDARD' | 'FACEBOOK_REELS_BANNER' | 'FACEBOOK_REELS_MOBILE' | 'FACEBOOK_REELS_STICKER' | 'FACEBOOK_STORY_MOBILE' | 'FACEBOOK_STORY_STICKER_MOBILE' | 'INSTAGRAM_EXPLORE_CONTEXTUAL' | 'INSTAGRAM_EXPLORE_IMMERSIVE' | 'INSTAGRAM_FEED_WEB' | 'INSTAGRAM_FEED_WEB_M_SITE' | 'INSTAGRAM_REELS' | 'INSTAGRAM_REELS_OVERLAY' | 'INSTAGRAM_SHOP' | 'INSTAGRAM_STANDARD' | 'INSTAGRAM_STORY' | 'INSTANT_ARTICLE_RECIRCULATION_AD' | 'INSTANT_ARTICLE_STANDARD' | 'INSTREAM_BANNER_DESKTOP' | 'INSTREAM_BANNER_MOBILE' | 'INSTREAM_VIDEO_DESKTOP' | 'INSTREAM_VIDEO_IMAGE' | 'INSTREAM_VIDEO_MOBILE' | 'JOB_BROWSER_DESKTOP' | 'JOB_BROWSER_MOBILE' | 'MARKETPLACE_MOBILE' | 'MESSENGER_MOBILE_INBOX_MEDIA' | 'MESSENGER_MOBILE_STORY_MEDIA' | 'MOBILE_BANNER' | 'MOBILE_FEED_BASIC' | 'MOBILE_FEED_STANDARD' | 'MOBILE_FULLWIDTH' | 'MOBILE_INTERSTITIAL' | 'MOBILE_MEDIUM_RECTANGLE' | 'MOBILE_NATIVE' | 'RIGHT_COLUMN_STANDARD' | 'SUGGESTED_VIDEO_DESKTOP' | 'SUGGESTED_VIDEO_MOBILE' | 'WATCH_FEED_HOME' | 'WATCH_FEED_MOBILE', options?: any) {
        return TemplatesGMApiFp(this.configuration).generateFacebookAdPreviewByTemplateId(companyId, templateId, adFormat, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate preview for dynamic location ad template
     * @summary Generate preview for dynamic location ad template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for dynamic location ad template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public generatePreviewForDynamicLocationAdTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).generatePreviewForDynamicLocationAdTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generate preview for Facebook template
     * @summary Generate preview for Facebook template
     * @param {string} companyId The id of the company
     * @param {DynamicLocationAdTemplateGeneratePreviewInput} dynamicLocationAdTemplateGeneratePreviewInput Generate preview for Facebook template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public generatePreviewForFacebookTemplate(companyId: string, dynamicLocationAdTemplateGeneratePreviewInput: DynamicLocationAdTemplateGeneratePreviewInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).generatePreviewForFacebookTemplate(companyId, dynamicLocationAdTemplateGeneratePreviewInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get previews for Facebook template
     * @summary Get previews for Facebook template
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public getPreviewsForFacebookTemplate(companyId: string, templateId: string, options?: any) {
        return TemplatesGMApiFp(this.configuration).getPreviewsForFacebookTemplate(companyId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return templates of company
     * @summary Return templates of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public listTemplatesByCompany(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return TemplatesGMApiFp(this.configuration).listTemplatesByCompany(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show related branch campaign previews
     * @summary Show related branch campaign previews
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public showRelatedFacebookBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return TemplatesGMApiFp(this.configuration).showRelatedFacebookBranchCampaignPreviews(companyId, templateId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show related branch campaign previews
     * @summary Show related branch campaign previews
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public showRelatedGoogleBranchCampaignPreviews(companyId: string, templateId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return TemplatesGMApiFp(this.configuration).showRelatedGoogleBranchCampaignPreviews(companyId, templateId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return template with by id
     * @summary Return template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public showTemplate(companyId: string, templateId: string, options?: any) {
        return TemplatesGMApiFp(this.configuration).showTemplate(companyId, templateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update google template with by id
     * @summary Update google template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {GoogleTemplateCreateInput} googleTemplateCreateInput Update a google template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public updateGoogleTemplate(companyId: string, templateId: string, googleTemplateCreateInput: GoogleTemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).updateGoogleTemplate(companyId, templateId, googleTemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update template with by id
     * @summary Update template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {TemplateCreateInput} templateCreateInput Update a template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public updateTemplate(companyId: string, templateId: string, templateCreateInput: TemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).updateTemplate(companyId, templateId, templateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update template with by id
     * @summary Update template with by id
     * @param {string} companyId The id of the company
     * @param {string} templateId The id of the template
     * @param {V2TemplateCreateInput} v2TemplateCreateInput Update a template with by id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplatesGMApi
     */
    public v2UpdateTemplate(companyId: string, templateId: string, v2TemplateCreateInput: V2TemplateCreateInput, options?: any) {
        return TemplatesGMApiFp(this.configuration).v2UpdateTemplate(companyId, templateId, v2TemplateCreateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
