import React from 'react';

import { Box } from '@app/components';
import { useAuth, useLocale } from '@app/hooks';

type CompanyCardPropTypes = {
  className?: string;
};

const CompanyCard: React.FC<CompanyCardPropTypes> = ({ className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.CompanyCard',
  };
  const { t } = useLocale();
  const { company, signOut } = useAuth();

  return (
    <Box className={className}>
      <div className="flex flex-row items-center justify-between">
        <div>
          <span className="font-semibold text-5 text-blue-300">{company?.name}</span>
        </div>
        <button className="font-semibold text-3.5 text-gray-500 underline px-5 py-2" onClick={signOut}>
          {t('actions.logout', SCOPE_OPTIONS)}
        </button>
      </div>
    </Box>
  );
};

export default CompanyCard;
