/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCampaignLeadCampaign } from './branch-campaign-lead-campaign';
import { BranchCampaignLeadFieldData } from './branch-campaign-lead-field-data';

/**
 * 
 * @export
 * @interface BranchCampaignLead
 */
export interface BranchCampaignLead {
    /**
     * Lead ID
     * @type {string}
     * @memberof BranchCampaignLead
     */
    id: string;
    /**
     * 
     * @type {Array<BranchCampaignLeadFieldData>}
     * @memberof BranchCampaignLead
     */
    field_data?: Array<BranchCampaignLeadFieldData>;
    /**
     * External lead ID
     * @type {string}
     * @memberof BranchCampaignLead
     */
    external_id: string;
    /**
     * External leadgen form ID on facebook
     * @type {string}
     * @memberof BranchCampaignLead
     */
    external_leadgen_form_id: string;
    /**
     * Lead submitted date
     * @type {string}
     * @memberof BranchCampaignLead
     */
    submitted_at: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignLead
     */
    contact_status: BranchCampaignLeadContactStatusEnum;
    /**
     * Lead created date
     * @type {string}
     * @memberof BranchCampaignLead
     */
    created_at: string;
    /**
     * Lead updated date
     * @type {string}
     * @memberof BranchCampaignLead
     */
    updated_at: string;
    /**
     * 
     * @type {BranchCampaignLeadCampaign}
     * @memberof BranchCampaignLead
     */
    campaign: BranchCampaignLeadCampaign;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignLeadContactStatusEnum {
    Waiting = 'WAITING',
    Expired = 'EXPIRED',
    Contacted = 'CONTACTED',
    Archived = 'ARCHIVED'
}



