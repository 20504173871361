import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';

import { useAxios } from '@app/hooks';

export function archiveTemplate(companyId: string, templateId: string, axiosInstance: AxiosInstance | undefined = undefined) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.archiveTemplate(companyId, templateId).then((res) => res.data);
}

export default function useArchiveTemplate(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; templateId: string }) => archiveTemplate(values.companyId, values.templateId, axiosInstance),
    props
  );
}
