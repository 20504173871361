/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { BranchCampaignAttachment } from './branch-campaign-attachment';

/**
 * 
 * @export
 * @interface MediaLibrary
 */
export interface MediaLibrary {
    /**
     * Campaign ID
     * @type {string}
     * @memberof MediaLibrary
     */
    id: string;
    /**
     * Campaign name
     * @type {string}
     * @memberof MediaLibrary
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLibrary
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLibrary
     */
    stop_time?: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLibrary
     */
    status: MediaLibraryStatusEnum;
    /**
     * 
     * @type {Array<BranchCampaignAttachment>}
     * @memberof MediaLibrary
     */
    attachments: Array<BranchCampaignAttachment>;
    /**
     * 
     * @type {string}
     * @memberof MediaLibrary
     */
    created_at: string;
    /**
     * 
     * @type {string}
     * @memberof MediaLibrary
     */
    updated_at: string;
}

/**
    * @export
    * @enum {string}
    */
export enum MediaLibraryStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Running = 'running',
    Rejected = 'rejected',
    Finished = 'finished',
    Paused = 'paused',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Archived = 'archived',
    CancelledPermanently = 'cancelled_permanently'
}



