import React from 'react';

import { useLocale } from '@app/hooks';
import { ACTIVITY_LOG_TYPES } from '@app/constants';
import { date } from '@app/lib';
import type { GmAuditLog as GmAuditLogType, AuditableBranchCampaign as AuditableBranchCampaignType } from '@app/api';

import AuditMessageBox from '../AuditMessageBox';

const AuditableBranchCampaign = (activities: GmAuditLogType | any) => {
  const { t } = useLocale();
  const activitiesData = activities?.activity;

  const time = date.format(activitiesData.created_at, 'dd/MM/yyyy HH:mm');
  const log = activitiesData.log;
  const auditable = activitiesData.auditable as AuditableBranchCampaignType;
  const changedBy = activitiesData.branch_user?.email ?? '';

  function getAuditMessage() {
    if (log === ACTIVITY_LOG_TYPES.BRANCH_CAMPAIGN_CREATED) {
      return t(`branchCampaign.created`, { scope: 'activities', name: auditable.campaign_name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_CAMPAIGN_UPDATED) {
      return t(`branchCampaign.updated`, { scope: 'activities', name: auditable.campaign_name, by: changedBy, time: time });
    }

    if (log === ACTIVITY_LOG_TYPES.BRANCH_CAMPAIGN_DELETED) {
      return t(`branchCampaign.deleted`, { scope: 'activities', name: auditable.campaign_name, by: changedBy, time: time });
    }
    return '';
  }

  return <AuditMessageBox getAuditMessage={getAuditMessage} />;
};

export default AuditableBranchCampaign;
