import { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';

import { useLocale } from '@app/hooks';
import { Button } from '@app/components';

function AdBlockAlert() {
  const SCOPE_OPTIONS = {
    scope: 'components.AdBlockAlert',
  };
  const { t } = useLocale();

  return (
    <Transition appear show={true} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-10 overflow-y-auto" onClose={() => null}>
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-blue-900 bg-opacity-40" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-6 font-medium text-red-400">
                {t('labels.title', SCOPE_OPTIONS)}
              </Dialog.Title>
              <div className="mt-3">
                <p className="text-3.5 text-gray-500">{t('labels.message', SCOPE_OPTIONS)}</p>
              </div>
              <Button
                label={t('actions.reload', SCOPE_OPTIONS)}
                theme="green"
                className="mt-6"
                size="md"
                onClick={() => location.reload()}
              />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

export default AdBlockAlert;
