import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Icons } from '@app/components';
import { array } from '@app/lib';
import { api, useAuth, useLocale } from '@app/hooks';
import { CREDIT_CARD_TYPE, INTEGRATION_TYPES } from '@app/constants';
import type { FacebookAccountsWithInstagramResponseAccounts } from '@app/api';

const INTEGRATION_TYPE_OPTIONS = [
  { label: 'Owner', value: INTEGRATION_TYPES.OWNER },
  { label: 'Partnership', value: INTEGRATION_TYPES.PARTNERSHIP },
];

const PageForm = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Settings.FacebookLoginButton.PageForm',
  };
  const { t } = useLocale();
  const {
    watch,
    setValue,
    control,
    formState: { errors },
  } = useFormContext();
  const { company } = useAuth();
  const { data, isLoading: isPromotePagesLoading } = api.useGetCompanyFacebookAccounts();
  const { data: payments } = api.useGetCompanyFacebookAccountPayment({ companyId: company.id });
  const promotePages = data?.accounts as Array<FacebookAccountsWithInstagramResponseAccounts>;
  const pageIdWatch = watch('pageId');
  const adAccountIdWatch = watch('adAccountId');
  const integrationTypeWatch = watch('integrationType');
  const isDynamicLocationAdsFeature = company?.feature_flags?.has_dynamic_location_ads_feature ?? false;
  const displayString = payments?.funding_source_details?.display_string;

  const CardIcon = getCardIcon();

  function getCardIcon() {
    if (!displayString) {
      return () => null;
    }

    if (displayString.includes(CREDIT_CARD_TYPE.VISA) || displayString.includes(CREDIT_CARD_TYPE.VISA_ELECTRON)) {
      return Icons.VisaCard;
    }

    if (displayString.includes(CREDIT_CARD_TYPE.MASTERCARD) || displayString.includes(CREDIT_CARD_TYPE.MAESTRO)) {
      return Icons.MastercardCard;
    }

    if (displayString.includes(CREDIT_CARD_TYPE.AMEX) || displayString.includes(CREDIT_CARD_TYPE.AMERICAN_EXPRESS)) {
      return Icons.AmericanExpressCard;
    }

    if (displayString.includes(CREDIT_CARD_TYPE.DISCOVER)) {
      return Icons.DiscoverCard;
    }

    return Icons.MastercardCard;
  }

  function getCardString() {
    if (!displayString) {
      return '-';
    }

    const lastNumbers = array.last(displayString.split('*'));

    return `**** **** **** ${lastNumbers}`;
  }

  useEffect(() => {
    const page = array.first(promotePages);

    if (page !== null) {
      const instagramAccount = array.first(page?.instagram_accounts);

      setValue('pageId', page.id);

      if (instagramAccount !== null) {
        setValue('instagramPageId', instagramAccount.id);
      }
    }
  }, [promotePages]);

  function getPartnerships() {
    if (promotePages === undefined) {
      return [];
    }

    if (array.isEmpty(promotePages)) {
      return [];
    }

    const partnerships = promotePages.find((page) => page.id === pageIdWatch)?.partnerships;

    if (partnerships === undefined) {
      return [];
    }

    if (array.isEmpty(partnerships)) {
      return [];
    }

    return partnerships.map((partnership: { id: string; name: string }) => ({ label: partnership.name, value: partnership.id }));
  }

  function getAgencies() {
    const [foundPage] = (promotePages || []).filter((page) => page.id === pageIdWatch);
    const agencies = foundPage && foundPage.partnerships.find((partnership) => partnership.id === adAccountIdWatch)?.agencies;

    const instagramAccounts = (agencies || []).flatMap((agency) =>
      (agency.instagram_accounts || []).map((account: { id: string; username: string }) => ({ label: account.username, value: account.id }))
    );
    const clientPages = (agencies || [])?.flatMap((agency) =>
      (agency.client_pages || []).map((page: { id: string; name: string }) => ({ label: page.name, value: page.id }))
    );

    return {
      instagramAccounts,
      clientPages,
    };
  }

  function getInstagramPages() {
    if (promotePages === undefined) {
      return [];
    }

    if (array.isEmpty(promotePages)) {
      return [];
    }

    const instagramAccounts = promotePages.find((page) => page.id === pageIdWatch)?.instagram_accounts;

    if (instagramAccounts === undefined) {
      return [];
    }

    if (array.isEmpty(instagramAccounts)) {
      return [];
    }

    return instagramAccounts.map((account: { id: string; username: string }) => ({ label: account.username, value: account.id }));
  }

  function getFacebookPages() {
    if (promotePages === undefined) {
      return [];
    }

    if (array.isEmpty(promotePages)) {
      return [];
    }

    return promotePages.map((page: { id: string; name: string }) => ({
      label: page.name,
      value: page.id,
    }));
  }

  const { instagramAccounts, clientPages } = getAgencies();

  if (isPromotePagesLoading) {
    return (
      <div className="p-5">
        <span className="text-5 text-gray-600 font-semibold">{t('labels.loading', SCOPE_OPTIONS)}</span>
      </div>
    );
  }

  if (array.isEmpty(getFacebookPages())) {
    return (
      <div className="p-5">
        <span className="text-5 text-gray-600 font-semibold">{t('labels.noPage', SCOPE_OPTIONS)}</span>
      </div>
    );
  }

  return (
    <form className="w-full px-5">
      <div className="flex flex-col">
        <label className="block text-3.5 font-semibold text-gray-900 mb-1.5">{t('labels.payment', SCOPE_OPTIONS)}</label>
        <div className="flex items-center justify-between bg-white border border-gray-400 p-3 rounded-xl">
          <span>{getCardString()}</span>
          <span>
            <CardIcon className="w-6 h-6" />
          </span>
        </div>
      </div>
      <Form.Select
        name="pageId"
        className="mt-4"
        label={t('form.pageId.label', SCOPE_OPTIONS)}
        rules={{
          required: { value: true, message: t('form.pageId.errors.required', SCOPE_OPTIONS) },
        }}
        control={control}
        options={getFacebookPages()}
        error={errors.pageId}
        maxMenuHeight={170}
      />
      <Form.Select
        name="integrationType"
        className="mt-4"
        label={t('form.integrationType.label', SCOPE_OPTIONS)}
        rules={{
          required: { value: true, message: t('form.integrationType.errors.required', SCOPE_OPTIONS) },
        }}
        control={control}
        options={INTEGRATION_TYPE_OPTIONS}
        error={errors.integrationType}
        defaultValue={INTEGRATION_TYPES.OWNER}
        disabled={array.isEmpty(getPartnerships()) && isDynamicLocationAdsFeature}
      />
      {integrationTypeWatch === INTEGRATION_TYPES.OWNER && (
        <Form.Select
          className="mt-4"
          name="instagramPageId"
          label={t('form.instagramPageId.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.instagramPageId.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={getInstagramPages()}
          error={errors.instagramPageId}
        />
      )}
      {!array.isEmpty(getPartnerships()) && isDynamicLocationAdsFeature && integrationTypeWatch === INTEGRATION_TYPES.PARTNERSHIP && (
        <>
          <Form.Select
            className="mt-4"
            name="adAccountId"
            label={t('form.adAccountId.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.adAccountId.errors.required', SCOPE_OPTIONS) },
            }}
            control={control}
            options={getPartnerships()}
            error={errors.adAccountId}
          />
          {adAccountIdWatch && (
            <>
              <Form.Select
                className="mt-4"
                name="clientPageId"
                label={t('form.clientPageId.label', SCOPE_OPTIONS)}
                rules={{
                  required: { value: true, message: t('form.clientPageId.errors.required', SCOPE_OPTIONS) },
                }}
                control={control}
                options={clientPages}
                error={errors.clientPageId}
              />
              <Form.Select
                className="mt-4"
                name="instagramAccountId"
                label={t('form.instagramAccountId.label', SCOPE_OPTIONS)}
                rules={{
                  required: { value: true, message: t('form.instagramAccountId.errors.required', SCOPE_OPTIONS) },
                }}
                control={control}
                options={instagramAccounts}
                error={errors.instagramAccountId}
              />
            </>
          )}
        </>
      )}
    </form>
  );
};

export default PageForm;
