const LOCATION_KEYS = Object.freeze({
  NAME: 'LOCATION_NAME',
  ADDRESS: 'LOCATION_ADDRESS',
  PHONE: 'LOCATION_PHONE',
  WEBSITE: 'LOCATION_WEBSITE',
  ADDRESS_DIRECTION: 'ADDRESS_DIRECTION',
  LOCATION_CITY: 'LOCATION_CITY',
  LOCATION_DISTRICT: 'LOCATION_DISTRICT',
  FINAL_URL: 'FINAL_URL',
});

export default LOCATION_KEYS;
