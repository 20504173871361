/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Group } from '../model';
// @ts-ignore
import { GroupAssignInput } from '../model';
// @ts-ignore
import { GroupBranchesResponse } from '../model';
// @ts-ignore
import { GroupCreateErrorResponse } from '../model';
// @ts-ignore
import { GroupCreateInput } from '../model';
// @ts-ignore
import { GroupListResponse } from '../model';
// @ts-ignore
import { GroupUpdateInput } from '../model';
/**
 * GroupsGMApi - axios parameter creator
 * @export
 */
export const GroupsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Archive a group
         * @summary Archive a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveGroup: async (companyId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('archiveGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('archiveGroup', 'groupId', groupId)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}/archive`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Assign a group
         * @summary Assign a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupAssignInput} groupAssignInput Assign branches to group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignGroup: async (companyId: string, groupId: string, groupAssignInput: GroupAssignInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('assignGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('assignGroup', 'groupId', groupId)
            // verify required parameter 'groupAssignInput' is not null or undefined
            assertParamExists('assignGroup', 'groupAssignInput', groupAssignInput)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}/assign`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupAssignInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * branches a group
         * @summary Branches of  a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchesOfGroup: async (companyId: string, groupId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('branchesOfGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('branchesOfGroup', 'groupId', groupId)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}/branches`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create group for company
         * @param {string} companyId The id of the company
         * @param {GroupCreateInput} groupCreateInput Create a group to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup: async (companyId: string, groupCreateInput: GroupCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createGroup', 'companyId', companyId)
            // verify required parameter 'groupCreateInput' is not null or undefined
            assertParamExists('createGroup', 'groupCreateInput', groupCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/groups`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a group
         * @summary Delete a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup: async (companyId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('deleteGroup', 'groupId', groupId)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a group
         * @summary Get a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup: async (companyId: string, groupId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('getGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('getGroup', 'groupId', groupId)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return groups of company
         * @summary Return groups of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups: async (companyId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listGroups', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/groups`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Unassign branch from a group
         * @summary Unassign a branch from a group
         * @param {string} companyId The id of the company
         * @param {string} branchId Branch id
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignBranchFromGroup: async (companyId: string, branchId: string, groupId: string, page?: number, perPage?: number, query?: object, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('unassignBranchFromGroup', 'companyId', companyId)
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('unassignBranchFromGroup', 'branchId', branchId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('unassignBranchFromGroup', 'groupId', groupId)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}/unassign`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (branchId !== undefined) {
                localVarQueryParameter['branch_id'] = branchId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a group
         * @summary Update a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupUpdateInput} groupUpdateInput Update a group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup: async (companyId: string, groupId: string, groupUpdateInput: GroupUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateGroup', 'companyId', companyId)
            // verify required parameter 'groupId' is not null or undefined
            assertParamExists('updateGroup', 'groupId', groupId)
            // verify required parameter 'groupUpdateInput' is not null or undefined
            assertParamExists('updateGroup', 'groupUpdateInput', groupUpdateInput)
            const localVarPath = `/gm_user/companies/{company_id}/groups/{group_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"group_id"}}`, encodeURIComponent(String(groupId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(groupUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GroupsGMApi - functional programming interface
 * @export
 */
export const GroupsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GroupsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Archive a group
         * @summary Archive a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archiveGroup(companyId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archiveGroup(companyId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Assign a group
         * @summary Assign a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupAssignInput} groupAssignInput Assign branches to group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignGroup(companyId: string, groupId: string, groupAssignInput: GroupAssignInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignGroup(companyId, groupId, groupAssignInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * branches a group
         * @summary Branches of  a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branchesOfGroup(companyId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupBranchesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branchesOfGroup(companyId, groupId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create group for company
         * @param {string} companyId The id of the company
         * @param {GroupCreateInput} groupCreateInput Create a group to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createGroup(companyId: string, groupCreateInput: GroupCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createGroup(companyId, groupCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a group
         * @summary Delete a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteGroup(companyId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteGroup(companyId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a group
         * @summary Get a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getGroup(companyId: string, groupId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getGroup(companyId, groupId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return groups of company
         * @summary Return groups of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listGroups(companyId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listGroups(companyId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Unassign branch from a group
         * @summary Unassign a branch from a group
         * @param {string} companyId The id of the company
         * @param {string} branchId Branch id
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unassignBranchFromGroup(companyId: string, branchId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unassignBranchFromGroup(companyId, branchId, groupId, page, perPage, query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a group
         * @summary Update a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupUpdateInput} groupUpdateInput Update a group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateGroup(companyId: string, groupId: string, groupUpdateInput: GroupUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Group>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateGroup(companyId, groupId, groupUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GroupsGMApi - factory interface
 * @export
 */
export const GroupsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GroupsGMApiFp(configuration)
    return {
        /**
         * Archive a group
         * @summary Archive a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archiveGroup(companyId: string, groupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.archiveGroup(companyId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Assign a group
         * @summary Assign a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupAssignInput} groupAssignInput Assign branches to group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignGroup(companyId: string, groupId: string, groupAssignInput: GroupAssignInput, options?: any): AxiosPromise<void> {
            return localVarFp.assignGroup(companyId, groupId, groupAssignInput, options).then((request) => request(axios, basePath));
        },
        /**
         * branches a group
         * @summary Branches of  a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branchesOfGroup(companyId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GroupBranchesResponse> {
            return localVarFp.branchesOfGroup(companyId, groupId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create group for company
         * @param {string} companyId The id of the company
         * @param {GroupCreateInput} groupCreateInput Create a group to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGroup(companyId: string, groupCreateInput: GroupCreateInput, options?: any): AxiosPromise<Group> {
            return localVarFp.createGroup(companyId, groupCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a group
         * @summary Delete a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGroup(companyId: string, groupId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteGroup(companyId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a group
         * @summary Get a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGroup(companyId: string, groupId: string, options?: any): AxiosPromise<Group> {
            return localVarFp.getGroup(companyId, groupId, options).then((request) => request(axios, basePath));
        },
        /**
         * Return groups of company
         * @summary Return groups of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listGroups(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GroupListResponse> {
            return localVarFp.listGroups(companyId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Unassign branch from a group
         * @summary Unassign a branch from a group
         * @param {string} companyId The id of the company
         * @param {string} branchId Branch id
         * @param {string} groupId The id of the group
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignBranchFromGroup(companyId: string, branchId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<void> {
            return localVarFp.unassignBranchFromGroup(companyId, branchId, groupId, page, perPage, query, options).then((request) => request(axios, basePath));
        },
        /**
         * Update a group
         * @summary Update a group
         * @param {string} companyId The id of the company
         * @param {string} groupId The id of the group
         * @param {GroupUpdateInput} groupUpdateInput Update a group
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGroup(companyId: string, groupId: string, groupUpdateInput: GroupUpdateInput, options?: any): AxiosPromise<Group> {
            return localVarFp.updateGroup(companyId, groupId, groupUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GroupsGMApi - interface
 * @export
 * @interface GroupsGMApi
 */
export interface GroupsGMApiInterface {
    /**
     * Archive a group
     * @summary Archive a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    archiveGroup(companyId: string, groupId: string, options?: any): AxiosPromise<void>;

    /**
     * Assign a group
     * @summary Assign a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {GroupAssignInput} groupAssignInput Assign branches to group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    assignGroup(companyId: string, groupId: string, groupAssignInput: GroupAssignInput, options?: any): AxiosPromise<void>;

    /**
     * branches a group
     * @summary Branches of  a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    branchesOfGroup(companyId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GroupBranchesResponse>;

    /**
     * 
     * @summary Create group for company
     * @param {string} companyId The id of the company
     * @param {GroupCreateInput} groupCreateInput Create a group to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    createGroup(companyId: string, groupCreateInput: GroupCreateInput, options?: any): AxiosPromise<Group>;

    /**
     * Delete a group
     * @summary Delete a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    deleteGroup(companyId: string, groupId: string, options?: any): AxiosPromise<void>;

    /**
     * Get a group
     * @summary Get a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    getGroup(companyId: string, groupId: string, options?: any): AxiosPromise<Group>;

    /**
     * Return groups of company
     * @summary Return groups of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    listGroups(companyId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<GroupListResponse>;

    /**
     * Unassign branch from a group
     * @summary Unassign a branch from a group
     * @param {string} companyId The id of the company
     * @param {string} branchId Branch id
     * @param {string} groupId The id of the group
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    unassignBranchFromGroup(companyId: string, branchId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any): AxiosPromise<void>;

    /**
     * Update a group
     * @summary Update a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {GroupUpdateInput} groupUpdateInput Update a group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApiInterface
     */
    updateGroup(companyId: string, groupId: string, groupUpdateInput: GroupUpdateInput, options?: any): AxiosPromise<Group>;

}

/**
 * GroupsGMApi - object-oriented interface
 * @export
 * @class GroupsGMApi
 * @extends {BaseAPI}
 */
export class GroupsGMApi extends BaseAPI implements GroupsGMApiInterface {
    /**
     * Archive a group
     * @summary Archive a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public archiveGroup(companyId: string, groupId: string, options?: any) {
        return GroupsGMApiFp(this.configuration).archiveGroup(companyId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Assign a group
     * @summary Assign a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {GroupAssignInput} groupAssignInput Assign branches to group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public assignGroup(companyId: string, groupId: string, groupAssignInput: GroupAssignInput, options?: any) {
        return GroupsGMApiFp(this.configuration).assignGroup(companyId, groupId, groupAssignInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * branches a group
     * @summary Branches of  a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public branchesOfGroup(companyId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return GroupsGMApiFp(this.configuration).branchesOfGroup(companyId, groupId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create group for company
     * @param {string} companyId The id of the company
     * @param {GroupCreateInput} groupCreateInput Create a group to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public createGroup(companyId: string, groupCreateInput: GroupCreateInput, options?: any) {
        return GroupsGMApiFp(this.configuration).createGroup(companyId, groupCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a group
     * @summary Delete a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public deleteGroup(companyId: string, groupId: string, options?: any) {
        return GroupsGMApiFp(this.configuration).deleteGroup(companyId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a group
     * @summary Get a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public getGroup(companyId: string, groupId: string, options?: any) {
        return GroupsGMApiFp(this.configuration).getGroup(companyId, groupId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return groups of company
     * @summary Return groups of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public listGroups(companyId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return GroupsGMApiFp(this.configuration).listGroups(companyId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Unassign branch from a group
     * @summary Unassign a branch from a group
     * @param {string} companyId The id of the company
     * @param {string} branchId Branch id
     * @param {string} groupId The id of the group
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public unassignBranchFromGroup(companyId: string, branchId: string, groupId: string, page?: number, perPage?: number, query?: object, options?: any) {
        return GroupsGMApiFp(this.configuration).unassignBranchFromGroup(companyId, branchId, groupId, page, perPage, query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update a group
     * @summary Update a group
     * @param {string} companyId The id of the company
     * @param {string} groupId The id of the group
     * @param {GroupUpdateInput} groupUpdateInput Update a group
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GroupsGMApi
     */
    public updateGroup(companyId: string, groupId: string, groupUpdateInput: GroupUpdateInput, options?: any) {
        return GroupsGMApiFp(this.configuration).updateGroup(companyId, groupId, groupUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
