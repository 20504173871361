import { date } from '@app/lib';
import { ANALYTICS_FILTERS } from '@app/constants';
import type { InsightResponse } from '@app/api';

function analysisData(data: InsightResponse, type: string) {
  if (!data?.insights) {
    return {
      labels: [],
      data: {
        impression: [],
        click: [],
        cost: [],
        cpc: [],
      },
    };
  }

  function handleLabelFormat(dateString: string) {
    if (ANALYTICS_FILTERS.DAILY === type) {
      return date.fromFormatToFormat(dateString, 'yyyy-MM-dd', 'dd MMM');
    }

    if (ANALYTICS_FILTERS.WEEKLY === type) {
      return date.fromFormatToFormat(dateString, 'yyyy-MM-dd', 'WW');
    }

    if (ANALYTICS_FILTERS.MONTHLY === type) {
      return date.fromFormatToFormat(dateString, 'yyyy-MM-dd', 'MMM');
    }

    return '';
  }

  return {
    labels: (data?.insights ?? []).map((item) => handleLabelFormat(item.date)),
    data: {
      impression: (data?.insights ?? []).map((item) => item.impressions),
      click: (data?.insights ?? []).map((item) => item.clicks),
      cost: (data?.insights ?? []).map((item) => item.spend),
      cpc: (data?.insights ?? []).map((item) => item.cpc),
    },
  };
}

export default { analysisData };
