import React from 'react';

import { FieldValues, UseFormRegister } from 'react-hook-form';
import cn from 'classnames';

type RadioPickerPropTypes = {
  name: string;
  options: Array<{ label: string; value: string; defaultChecked?: boolean }>;
  register: UseFormRegister<FieldValues>;
  labelStyle?: string;
};

const RadioGroup: React.FC<RadioPickerPropTypes> = ({ name, options, register, labelStyle }) => {
  return (
    <>
      {options.map((option) => (
        <label className="flex items-center" key={option.value}>
          <input type="radio" value={option.value} id={option.value} defaultChecked={option.defaultChecked} {...register(name)} />
          <span className={cn('ml-2', labelStyle)}>{option.label}</span>
        </label>
      ))}
    </>
  );
};

export default RadioGroup;
