import React, { Dispatch, SetStateAction, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Box } from '@app/components';
import { useLocale, useActiveInput } from '@app/hooks';

import BannerbearInput from '../DynamicLocationAdsForm/BannerbearInput';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

import { Branch } from 'api/model';

type FormPropTypes = {
  branches?: Array<Branch>;
  bannerbearSession: BannerbearSessionStateType;
  setBannerbearSession: Dispatch<SetStateAction<BannerbearSessionStateType>>;
};

const MAX_MESSAGE_LENGTH = 255;
const DEFAULT_INDEX = 0;

const MetaForm: React.FC<FormPropTypes> = ({ branches, bannerbearSession, setBannerbearSession }) => {
  const { t } = useLocale();
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.index',
  };

  const {
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
    control,
  } = useFormContext();
  const messageWatch = watch('message');
  const templateBranchTypeWatch = watch('templateBranchType');
  const activeInput = useActiveInput([
    `templates[${DEFAULT_INDEX}].message`,
    `templates[${DEFAULT_INDEX}].headline`,
    `templates[${DEFAULT_INDEX}].description`,
  ]);

  const BRANCH_OPTIONS = [
    { label: t('form.branches.all', SCOPE_OPTIONS), value: 'all', defaultChecked: true },
    { label: t('form.branches.custom', SCOPE_OPTIONS), value: 'custom' },
  ];

  const branchSelectOptions = useMemo(
    () =>
      branches?.map((branch) => ({
        label: branch.name,
        value: branch.id,
      })) || [],
    [branches]
  );

  function InputVariables({ id, activeInput }: { id: string; activeInput: string | null }) {
    if (activeInput !== id) {
      return null;
    }

    function handleText(value: string) {
      const currentText = getValues(id);

      if (!currentText) {
        setValue(id, value);
        return;
      }

      setValue(id, `${currentText} ${value}`);
    }

    return <Form.BranchButtons className="mb-4.5 -mt-1.5" onClick={handleText} />;
  }

  return (
    <form>
      <Box>
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].name`}
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          error={errors.name}
          {...register(`templates[${DEFAULT_INDEX}].name`, {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
          className="mb-4"
        />
      </Box>
      <Box>
        <BannerbearInput
          index={DEFAULT_INDEX}
          type={IMAGE_PLACEMENT_TYPE.FEED}
          setBannerbearSession={setBannerbearSession}
          bannerbearSession={bannerbearSession}
          isMetaTemplate={true}
        />
      </Box>
      <Box>
        <BannerbearInput
          index={DEFAULT_INDEX}
          type={IMAGE_PLACEMENT_TYPE.STORY}
          setBannerbearSession={setBannerbearSession}
          bannerbearSession={bannerbearSession}
          isMetaTemplate={true}
        />
      </Box>
      <Box>
        <Form.Textarea
          id={`templates[${DEFAULT_INDEX}].message`}
          label={t('form.message.label', SCOPE_OPTIONS)}
          placeholder={t('form.message.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          error={errors.message}
          maxLength={MAX_MESSAGE_LENGTH}
          length={messageWatch?.length ?? 0}
          {...register(`templates[${DEFAULT_INDEX}].message`, {
            required: { value: true, message: t('form.message.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: MAX_MESSAGE_LENGTH, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].message`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].headline`}
          label={t('form.headline.label', SCOPE_OPTIONS)}
          placeholder={t('form.headline.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${DEFAULT_INDEX}].headline`, {
            required: { value: true, message: t('form.headline.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.headline}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].headline`} activeInput={activeInput} />
        <Form.Input
          type="text"
          id={`templates[${DEFAULT_INDEX}].description`}
          label={t('form.description.label', SCOPE_OPTIONS)}
          placeholder={t('form.description.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register(`templates[${DEFAULT_INDEX}].description`, {
            required: { value: true, message: t('form.description.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.description}
        />
        <InputVariables id={`templates[${DEFAULT_INDEX}].description`} activeInput={activeInput} />
      </Box>
      <Box>
        <Form.RadioGroup name={`templateBranchType`} register={register} options={BRANCH_OPTIONS} />
        {templateBranchTypeWatch == 'custom' && (
          <Form.Select
            isMulti
            label={t('form.branches.label', SCOPE_OPTIONS)}
            name="allowedBranchIds"
            rules={{
              required: { value: true, message: t('form.branches.errors.required', SCOPE_OPTIONS) },
            }}
            className="mt-4"
            control={control}
            options={branchSelectOptions}
          />
        )}
      </Box>
    </form>
  );
};

export default MetaForm;
