/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { FileAttachment } from '../model';
// @ts-ignore
import { GenerateXlsxFileAttachmentErrorResponse } from '../model';
// @ts-ignore
import { GenerateXlsxFileAttachmentInput } from '../model';
// @ts-ignore
import { UploadXlsxFileAttachmentErrorResponse } from '../model';
/**
 * FileAttachmentsGMApi - axios parameter creator
 * @export
 */
export const FileAttachmentsGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate a xlsx file for the dla campaign
         * @summary Generate a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {GenerateXlsxFileAttachmentInput} generateXlsxFileAttachmentInput Generate a xlsx file for the dla campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmGenerateXlsxFileAttachment: async (companyId: string, generateXlsxFileAttachmentInput: GenerateXlsxFileAttachmentInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmGenerateXlsxFileAttachment', 'companyId', companyId)
            // verify required parameter 'generateXlsxFileAttachmentInput' is not null or undefined
            assertParamExists('gmGenerateXlsxFileAttachment', 'generateXlsxFileAttachmentInput', generateXlsxFileAttachmentInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/files/generate_xlsx`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(generateXlsxFileAttachmentInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Upload a xlsx file for the dla campaign
         * @summary Upload a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {any} file 
         * @param {string} locationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadXlsxFileAttachment: async (companyId: string, file: any, locationType: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('gmUploadXlsxFileAttachment', 'companyId', companyId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('gmUploadXlsxFileAttachment', 'file', file)
            // verify required parameter 'locationType' is not null or undefined
            assertParamExists('gmUploadXlsxFileAttachment', 'locationType', locationType)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/attachments/files/upload_xlsx`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
            if (locationType !== undefined) { 
                localVarFormParams.append('location_type', locationType as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileAttachmentsGMApi - functional programming interface
 * @export
 */
export const FileAttachmentsGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileAttachmentsGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate a xlsx file for the dla campaign
         * @summary Generate a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {GenerateXlsxFileAttachmentInput} generateXlsxFileAttachmentInput Generate a xlsx file for the dla campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmGenerateXlsxFileAttachment(companyId: string, generateXlsxFileAttachmentInput: GenerateXlsxFileAttachmentInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmGenerateXlsxFileAttachment(companyId, generateXlsxFileAttachmentInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Upload a xlsx file for the dla campaign
         * @summary Upload a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {any} file 
         * @param {string} locationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async gmUploadXlsxFileAttachment(companyId: string, file: any, locationType: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileAttachment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.gmUploadXlsxFileAttachment(companyId, file, locationType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FileAttachmentsGMApi - factory interface
 * @export
 */
export const FileAttachmentsGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileAttachmentsGMApiFp(configuration)
    return {
        /**
         * Generate a xlsx file for the dla campaign
         * @summary Generate a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {GenerateXlsxFileAttachmentInput} generateXlsxFileAttachmentInput Generate a xlsx file for the dla campaign
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmGenerateXlsxFileAttachment(companyId: string, generateXlsxFileAttachmentInput: GenerateXlsxFileAttachmentInput, options?: any): AxiosPromise<any> {
            return localVarFp.gmGenerateXlsxFileAttachment(companyId, generateXlsxFileAttachmentInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Upload a xlsx file for the dla campaign
         * @summary Upload a xlsx file for the dla campaign
         * @param {string} companyId The id of the company
         * @param {any} file 
         * @param {string} locationType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        gmUploadXlsxFileAttachment(companyId: string, file: any, locationType: string, options?: any): AxiosPromise<FileAttachment> {
            return localVarFp.gmUploadXlsxFileAttachment(companyId, file, locationType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileAttachmentsGMApi - interface
 * @export
 * @interface FileAttachmentsGMApi
 */
export interface FileAttachmentsGMApiInterface {
    /**
     * Generate a xlsx file for the dla campaign
     * @summary Generate a xlsx file for the dla campaign
     * @param {string} companyId The id of the company
     * @param {GenerateXlsxFileAttachmentInput} generateXlsxFileAttachmentInput Generate a xlsx file for the dla campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentsGMApiInterface
     */
    gmGenerateXlsxFileAttachment(companyId: string, generateXlsxFileAttachmentInput: GenerateXlsxFileAttachmentInput, options?: any): AxiosPromise<any>;

    /**
     * Upload a xlsx file for the dla campaign
     * @summary Upload a xlsx file for the dla campaign
     * @param {string} companyId The id of the company
     * @param {any} file 
     * @param {string} locationType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentsGMApiInterface
     */
    gmUploadXlsxFileAttachment(companyId: string, file: any, locationType: string, options?: any): AxiosPromise<FileAttachment>;

}

/**
 * FileAttachmentsGMApi - object-oriented interface
 * @export
 * @class FileAttachmentsGMApi
 * @extends {BaseAPI}
 */
export class FileAttachmentsGMApi extends BaseAPI implements FileAttachmentsGMApiInterface {
    /**
     * Generate a xlsx file for the dla campaign
     * @summary Generate a xlsx file for the dla campaign
     * @param {string} companyId The id of the company
     * @param {GenerateXlsxFileAttachmentInput} generateXlsxFileAttachmentInput Generate a xlsx file for the dla campaign
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentsGMApi
     */
    public gmGenerateXlsxFileAttachment(companyId: string, generateXlsxFileAttachmentInput: GenerateXlsxFileAttachmentInput, options?: any) {
        return FileAttachmentsGMApiFp(this.configuration).gmGenerateXlsxFileAttachment(companyId, generateXlsxFileAttachmentInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Upload a xlsx file for the dla campaign
     * @summary Upload a xlsx file for the dla campaign
     * @param {string} companyId The id of the company
     * @param {any} file 
     * @param {string} locationType 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileAttachmentsGMApi
     */
    public gmUploadXlsxFileAttachment(companyId: string, file: any, locationType: string, options?: any) {
        return FileAttachmentsGMApiFp(this.configuration).gmUploadXlsxFileAttachment(companyId, file, locationType, options).then((request) => request(this.axios, this.basePath));
    }
}
