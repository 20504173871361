/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FacebookGeneratePreviewInputCreativeLinkDataCallToActionValue } from './facebook-generate-preview-input-creative-link-data-call-to-action-value';

/**
 * 
 * @export
 * @interface FacebookGeneratePreviewInputCreativeLinkDataCallToAction
 */
export interface FacebookGeneratePreviewInputCreativeLinkDataCallToAction {
    /**
     * 
     * @type {string}
     * @memberof FacebookGeneratePreviewInputCreativeLinkDataCallToAction
     */
    type?: FacebookGeneratePreviewInputCreativeLinkDataCallToActionTypeEnum;
    /**
     * 
     * @type {FacebookGeneratePreviewInputCreativeLinkDataCallToActionValue}
     * @memberof FacebookGeneratePreviewInputCreativeLinkDataCallToAction
     */
    value?: FacebookGeneratePreviewInputCreativeLinkDataCallToActionValue;
}

/**
    * @export
    * @enum {string}
    */
export enum FacebookGeneratePreviewInputCreativeLinkDataCallToActionTypeEnum {
    BookTravel = 'BOOK_TRAVEL',
    ContactUs = 'CONTACT_US',
    Donate = 'DONATE',
    DonateNow = 'DONATE_NOW',
    Download = 'DOWNLOAD',
    GetDirections = 'GET_DIRECTIONS',
    GoLive = 'GO_LIVE',
    Interested = 'INTERESTED',
    LearnMore = 'LEARN_MORE',
    LikePage = 'LIKE_PAGE',
    MessagePage = 'MESSAGE_PAGE',
    Save = 'SAVE',
    SendTip = 'SEND_TIP',
    ShopNow = 'SHOP_NOW',
    SignUp = 'SIGN_UP',
    ViewInstagramProfile = 'VIEW_INSTAGRAM_PROFILE',
    InstagramMessage = 'INSTAGRAM_MESSAGE',
    LoyaltyLearnMore = 'LOYALTY_LEARN_MORE',
    PurchaseGiftCards = 'PURCHASE_GIFT_CARDS',
    PayToAccess = 'PAY_TO_ACCESS',
    SeeMore = 'SEE_MORE',
    TryInCamera = 'TRY_IN_CAMERA',
    WhatsappLink = 'WHATSAPP_LINK',
    GetMobileApp = 'GET_MOBILE_APP',
    InstallMobileApp = 'INSTALL_MOBILE_APP',
    UseMobileApp = 'USE_MOBILE_APP',
    InstallApp = 'INSTALL_APP',
    UseApp = 'USE_APP',
    PlayGame = 'PLAY_GAME',
    WatchVideo = 'WATCH_VIDEO',
    WatchMore = 'WATCH_MORE',
    OpenLink = 'OPEN_LINK',
    NoButton = 'NO_BUTTON',
    ListenMusic = 'LISTEN_MUSIC',
    MobileDownload = 'MOBILE_DOWNLOAD',
    GetOffer = 'GET_OFFER',
    GetOfferView = 'GET_OFFER_VIEW',
    BuyNow = 'BUY_NOW',
    BuyTickets = 'BUY_TICKETS',
    UpdateApp = 'UPDATE_APP',
    BetNow = 'BET_NOW',
    AddToCart = 'ADD_TO_CART',
    OrderNow = 'ORDER_NOW',
    SellNow = 'SELL_NOW',
    GetShowtimes = 'GET_SHOWTIMES',
    ListenNow = 'LISTEN_NOW',
    GetEventTickets = 'GET_EVENT_TICKETS',
    SearchMore = 'SEARCH_MORE',
    PreRegister = 'PRE_REGISTER',
    SwipeUpProduct = 'SWIPE_UP_PRODUCT',
    SwipeUpShop = 'SWIPE_UP_SHOP',
    Call = 'CALL',
    MissedCall = 'MISSED_CALL',
    CallNow = 'CALL_NOW',
    CallMe = 'CALL_ME',
    ApplyNow = 'APPLY_NOW',
    Buy = 'BUY',
    GetQuote = 'GET_QUOTE',
    Subscribe = 'SUBSCRIBE',
    RecordNow = 'RECORD_NOW',
    VoteNow = 'VOTE_NOW',
    GiveFreeRides = 'GIVE_FREE_RIDES',
    RegisterNow = 'REGISTER_NOW',
    OpenMessengerExt = 'OPEN_MESSENGER_EXT',
    EventRsvp = 'EVENT_RSVP',
    CivicAction = 'CIVIC_ACTION',
    SendInvites = 'SEND_INVITES',
    ReferFriends = 'REFER_FRIENDS',
    RequestTime = 'REQUEST_TIME',
    SeeMenu = 'SEE_MENU',
    WhatsappMessage = 'WHATSAPP_MESSAGE',
    Search = 'SEARCH',
    TryIt = 'TRY_IT',
    TryOn = 'TRY_ON',
    LinkCard = 'LINK_CARD',
    DialCode = 'DIAL_CODE',
    FindYourGroups = 'FIND_YOUR_GROUPS',
    StartOrder = 'START_ORDER'
}



