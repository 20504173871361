function handle(error: any) {
  const status = error.response.status;

  if (status === 422) {
    return {};
  }

  if (Object.prototype.hasOwnProperty.call(error.response, 'data')) {
    return error.response.data;
  }

  return {};
}

export default { handle };
