import PreviewForm from './PreviewForm';
import SettingsForm from './SettingsForm';
import TemplateForm from './TemplateForm';
import TemplatePreview from './TemplatePreview';
import GoogleTemplateForm from './GoogleTemplateForm';
import GoogleSettingsForm from './GoogleSettingsForm';
import GroupSelect from './GroupSelect';
import SmartSettingsForm from './SmartSettingsForm';
import DynamicLocationAdsTemplateForm from './DynamicLocationAdsTemplateForm';
import DynamicLocationAdsObjectivesInput from './DynamicLocationAdsObjectivesInput';

export default {
  PreviewForm,
  SettingsForm,
  TemplateForm,
  TemplatePreview,
  GoogleTemplateForm,
  GoogleSettingsForm,
  GroupSelect,
  SmartSettingsForm,
  DynamicLocationAdsTemplateForm,
  DynamicLocationAdsObjectivesInput,
};
