import cn from 'classnames';

import { Button, Dropdown, Icons } from '@app/components';

type DropdownButtonsTypes = {
  value: string;
  label: JSX.Element;
};

type NoContentPropTypes = {
  title: string;
  description: string;
  Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
  button?: {
    label: string;
    Icon?: (props: React.SVGProps<SVGSVGElement>) => JSX.Element;
    href?: string;
    onClick?: () => void;
  };
  dropdown?: {
    buttons: DropdownButtonsTypes[];
    label: string;
    onDropdownButtonClick?: (type: string) => void;
  };
  className?: string;
};

const NoContent: React.FC<NoContentPropTypes> = ({
  title,
  description,
  Icon,
  button: buttonProps,
  className,
  dropdown: dropdownButtons,
}) => {
  return (
    <div className={cn('flex flex-col items-center text-center bg-white py-32 px-5 rounded-3 overflow-hidden drop-shadow-box', className)}>
      {Icon && <Icon className="text-9xl text-gray-500" />}
      <h4 className="font-semibold text-gray-500 my-4">{title}</h4>
      <p className="text-sm text-gray-500 md:w-4/6 mb-6">{description}</p>
      {buttonProps && (
        <Button
          theme="blue"
          label={buttonProps.label}
          IconLeft={buttonProps.Icon}
          block={false}
          size="md"
          href={buttonProps.href}
          onClick={buttonProps.onClick}
        />
      )}
      {dropdownButtons && (
        <Dropdown
          button={
            <Button
              theme="blue"
              label={dropdownButtons.label}
              IconRight={Icons.ChevronDown}
              block={false}
              className="flex-shrink-0"
              size="sm"
            />
          }
          items={dropdownButtons.buttons}
          onButtonClick={dropdownButtons.onDropdownButtonClick}
        />
      )}
    </div>
  );
};

export default NoContent;
