import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5.28 19.958h9.44c2.91 0 5.28-2.411 5.28-5.363V5.364C19.958 2.412 17.588 0 14.678 0H5.28C2.37 0 0 2.412 0 5.364v9.23c0 2.953 2.37 5.364 5.28 5.364ZM15.8 3.326c.666 0 1.206.54 1.206 1.248 0 .707-.54 1.247-1.206 1.247-.665 0-1.205-.54-1.205-1.247 0-.707.54-1.248 1.205-1.248ZM9.98 4.99c2.702 0 4.864 2.245 4.864 4.99 0 2.743-2.162 4.989-4.865 4.989-2.702 0-4.865-2.245-4.865-4.99 0-2.744 2.163-4.99 4.865-4.99Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgComponent;
