/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorResponse } from '../model';
// @ts-ignore
import { MessagingApplication } from '../model';
// @ts-ignore
import { MessagingApplicationRequestInput } from '../model';
// @ts-ignore
import { PunditResponse } from '../model';
/**
 * MessagingApplicationGMApi - axios parameter creator
 * @export
 */
export const MessagingApplicationGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create messaging application for the company
         * @summary Create messaging application for the company
         * @param {string} companyId The id of the company
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessagingApplicationForCompany: async (companyId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createMessagingApplicationForCompany', 'companyId', companyId)
            // verify required parameter 'messagingApplicationRequestInput' is not null or undefined
            assertParamExists('createMessagingApplicationForCompany', 'messagingApplicationRequestInput', messagingApplicationRequestInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/messaging_applications`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messagingApplicationRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete messaging application for the company
         * @summary Delete messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessagingApplicationForCompany: async (companyId: string, messagingApplicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('deleteMessagingApplicationForCompany', 'companyId', companyId)
            // verify required parameter 'messagingApplicationId' is not null or undefined
            assertParamExists('deleteMessagingApplicationForCompany', 'messagingApplicationId', messagingApplicationId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/messaging_applications/{messaging_application_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"messaging_application_id"}}`, encodeURIComponent(String(messagingApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List messaging applications for the company
         * @summary List messaging applications for the company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessagingApplicationsForCompany: async (companyId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listMessagingApplicationsForCompany', 'companyId', companyId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/messaging_applications`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Show messaging application for the company
         * @summary Show messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showMessagingApplicationForCompany: async (companyId: string, messagingApplicationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showMessagingApplicationForCompany', 'companyId', companyId)
            // verify required parameter 'messagingApplicationId' is not null or undefined
            assertParamExists('showMessagingApplicationForCompany', 'messagingApplicationId', messagingApplicationId)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/messaging_applications/{messaging_application_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"messaging_application_id"}}`, encodeURIComponent(String(messagingApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update messaging application for the company
         * @summary Update messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessagingApplicationForCompany: async (companyId: string, messagingApplicationId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateMessagingApplicationForCompany', 'companyId', companyId)
            // verify required parameter 'messagingApplicationId' is not null or undefined
            assertParamExists('updateMessagingApplicationForCompany', 'messagingApplicationId', messagingApplicationId)
            // verify required parameter 'messagingApplicationRequestInput' is not null or undefined
            assertParamExists('updateMessagingApplicationForCompany', 'messagingApplicationRequestInput', messagingApplicationRequestInput)
            const localVarPath = `/v2/general_manager_account/companies/{company_id}/messaging_applications/{messaging_application_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"messaging_application_id"}}`, encodeURIComponent(String(messagingApplicationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(messagingApplicationRequestInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MessagingApplicationGMApi - functional programming interface
 * @export
 */
export const MessagingApplicationGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MessagingApplicationGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create messaging application for the company
         * @summary Create messaging application for the company
         * @param {string} companyId The id of the company
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createMessagingApplicationForCompany(companyId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createMessagingApplicationForCompany(companyId, messagingApplicationRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete messaging application for the company
         * @summary Delete messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMessagingApplicationForCompany(companyId, messagingApplicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List messaging applications for the company
         * @summary List messaging applications for the company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listMessagingApplicationsForCompany(companyId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MessagingApplication>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listMessagingApplicationsForCompany(companyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Show messaging application for the company
         * @summary Show messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showMessagingApplicationForCompany(companyId, messagingApplicationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update messaging application for the company
         * @summary Update messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MessagingApplication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMessagingApplicationForCompany(companyId, messagingApplicationId, messagingApplicationRequestInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MessagingApplicationGMApi - factory interface
 * @export
 */
export const MessagingApplicationGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MessagingApplicationGMApiFp(configuration)
    return {
        /**
         * Create messaging application for the company
         * @summary Create messaging application for the company
         * @param {string} companyId The id of the company
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createMessagingApplicationForCompany(companyId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): AxiosPromise<MessagingApplication> {
            return localVarFp.createMessagingApplicationForCompany(companyId, messagingApplicationRequestInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete messaging application for the company
         * @summary Delete messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.deleteMessagingApplicationForCompany(companyId, messagingApplicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * List messaging applications for the company
         * @summary List messaging applications for the company
         * @param {string} companyId The id of the company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listMessagingApplicationsForCompany(companyId: string, options?: any): AxiosPromise<Array<MessagingApplication>> {
            return localVarFp.listMessagingApplicationsForCompany(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * Show messaging application for the company
         * @summary Show messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): AxiosPromise<MessagingApplication> {
            return localVarFp.showMessagingApplicationForCompany(companyId, messagingApplicationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update messaging application for the company
         * @summary Update messaging application for the company
         * @param {string} companyId The id of the company
         * @param {string} messagingApplicationId The id of the messaging application
         * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): AxiosPromise<MessagingApplication> {
            return localVarFp.updateMessagingApplicationForCompany(companyId, messagingApplicationId, messagingApplicationRequestInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * MessagingApplicationGMApi - interface
 * @export
 * @interface MessagingApplicationGMApi
 */
export interface MessagingApplicationGMApiInterface {
    /**
     * Create messaging application for the company
     * @summary Create messaging application for the company
     * @param {string} companyId The id of the company
     * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApiInterface
     */
    createMessagingApplicationForCompany(companyId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): AxiosPromise<MessagingApplication>;

    /**
     * Delete messaging application for the company
     * @summary Delete messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApiInterface
     */
    deleteMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): AxiosPromise<boolean>;

    /**
     * List messaging applications for the company
     * @summary List messaging applications for the company
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApiInterface
     */
    listMessagingApplicationsForCompany(companyId: string, options?: any): AxiosPromise<Array<MessagingApplication>>;

    /**
     * Show messaging application for the company
     * @summary Show messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApiInterface
     */
    showMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any): AxiosPromise<MessagingApplication>;

    /**
     * Update messaging application for the company
     * @summary Update messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApiInterface
     */
    updateMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any): AxiosPromise<MessagingApplication>;

}

/**
 * MessagingApplicationGMApi - object-oriented interface
 * @export
 * @class MessagingApplicationGMApi
 * @extends {BaseAPI}
 */
export class MessagingApplicationGMApi extends BaseAPI implements MessagingApplicationGMApiInterface {
    /**
     * Create messaging application for the company
     * @summary Create messaging application for the company
     * @param {string} companyId The id of the company
     * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApi
     */
    public createMessagingApplicationForCompany(companyId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any) {
        return MessagingApplicationGMApiFp(this.configuration).createMessagingApplicationForCompany(companyId, messagingApplicationRequestInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete messaging application for the company
     * @summary Delete messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApi
     */
    public deleteMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any) {
        return MessagingApplicationGMApiFp(this.configuration).deleteMessagingApplicationForCompany(companyId, messagingApplicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List messaging applications for the company
     * @summary List messaging applications for the company
     * @param {string} companyId The id of the company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApi
     */
    public listMessagingApplicationsForCompany(companyId: string, options?: any) {
        return MessagingApplicationGMApiFp(this.configuration).listMessagingApplicationsForCompany(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Show messaging application for the company
     * @summary Show messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApi
     */
    public showMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, options?: any) {
        return MessagingApplicationGMApiFp(this.configuration).showMessagingApplicationForCompany(companyId, messagingApplicationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update messaging application for the company
     * @summary Update messaging application for the company
     * @param {string} companyId The id of the company
     * @param {string} messagingApplicationId The id of the messaging application
     * @param {MessagingApplicationRequestInput} messagingApplicationRequestInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MessagingApplicationGMApi
     */
    public updateMessagingApplicationForCompany(companyId: string, messagingApplicationId: string, messagingApplicationRequestInput: MessagingApplicationRequestInput, options?: any) {
        return MessagingApplicationGMApiFp(this.configuration).updateMessagingApplicationForCompany(companyId, messagingApplicationId, messagingApplicationRequestInput, options).then((request) => request(this.axios, this.basePath));
    }
}
