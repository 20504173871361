import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi, GoogleCampaignUpdateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateGoogleCampaign(
  companyId: string,
  campaignId: string,
  campaignUpdateInput: GoogleCampaignUpdateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateGoogleGmCampaign(companyId, campaignId, campaignUpdateInput).then((res) => res.data);
}

export default function useUpdateGoogleCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; campaignId: string; campaignUpdateInput: GoogleCampaignUpdateInput }) =>
      updateGoogleCampaign(values.companyId, values.campaignId, values.campaignUpdateInput, axiosInstance),
    props
  );
}
