import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import { useLocale } from '@app/hooks';
import { GOOGLE_LOCALES, GENDERS, GEO_LOCATION_TYPE, AGE } from '@app/constants';
import { array, date, date as dateLib } from '@app/lib';
import type { Campaign as CampaignType } from '@app/api';

import PermissionsForm from './PermissionsForm';
import GroupSelect from './GroupSelect';
import CustomSegmentKeyword from './CustomSegmentKeyword';

type GoogleSettingsFormPropTypes = {
  campaign?: CampaignType;
  isEdit?: boolean;
};

const GoogleSettingsForm: React.FC<GoogleSettingsFormPropTypes> = ({ campaign, isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.GoogleSettingsForm',
  };
  const { t } = useLocale();
  const {
    register,
    unregister,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();

  const ageMinWatch = watch('ageMin');
  const ageMaxWatch = watch('ageMax');
  const startTimeWatch = watch('startTime');
  const geoLocationTypeWatch = watch('geoLocationType');
  const keywordsWatch = watch('customSegments');
  const plusWeekToNow = date.ISOtoJSDate(date.plusFromNow({ days: 7 }));
  const defaultStopTime = campaign?.stop_time ? dateLib.ISOtoJSDate(campaign?.stop_time) : plusWeekToNow;
  const defaultStartTime = campaign?.start_time ? dateLib.ISOtoJSDate(campaign?.start_time) : new Date();
  const defaultGender = campaign?.targeting.gender ? campaign?.targeting.gender : GENDERS.ALL;
  const defaultAgeMin = campaign?.targeting.age_min ? campaign?.targeting.age_min : AGE.DEFAULT_AGE_MIN;
  const defaultAgeMax = campaign?.targeting.age_max ? campaign?.targeting.age_max : AGE.GOOGLE_DEFAULT_AGE_MAX;
  const defaultGeoType = campaign?.geo_location_type ? campaign?.geo_location_type : GEO_LOCATION_TYPE.FREE;
  const defaultCustomSegments = (campaign?.targeting as any)?.custom_segments ?? [];
  const defaultGroup = campaign?.allowed_group_ids ? campaign?.allowed_group_ids : [];
  const stopTimeWatch = watch('stopTime', defaultStopTime);
  const isUsingStopTimeWatch = watch('useStopTime', !!errors.stopTime);
  const DEFAULT_LANGUAGES = campaign?.targeting?.locales ?? [1037, 1000];
  const minAges = [18, 25, 35, 45, 55, 65];
  const maxAges = [24, 34, 44, 54, 64];
  const LOCATION_VALUES = [
    {
      label: t('form.geoLocationType.options.free', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.FREE,
    },
    {
      label: t('form.geoLocationType.options.range', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.RANGE,
    },
    {
      label: t('form.geoLocationType.options.city', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.CITY,
    },
  ];
  useEffect(() => {
    if (!isEdit) {
      const timer = setInterval(() => {
        setValue('startTime', new Date());
      }, 60 * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (!stopTimeWatch) {
      setValue('useStopTime', !!campaign?.stop_time);
    }
  }, [campaign?.stop_time]);

  useEffect(() => {
    if (campaign?.permissions) {
      setValue('permissions', campaign?.permissions);
    }
  }, [campaign?.permissions]);

  useEffect(() => {
    if (campaign?.allowed_objectives) {
      setValue('objectives', campaign?.allowed_objectives);
    }
  }, [campaign?.allowed_objectives]);

  useEffect(() => {
    if (isUsingStopTimeWatch === false) {
      unregister('stopTime');
    }
  }, [isUsingStopTimeWatch]);

  useEffect(() => {
    if (GEO_LOCATION_TYPE.CITY === geoLocationTypeWatch) {
      unregister('defaultLocationRange');
    }
  }, [geoLocationTypeWatch]);

  return (
    <>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: !isEdit, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          defaultValue={campaign?.name}
          disabled={isEdit}
        />
      </Box>
      <Box>
        <Form.Date //FIXME: Stuck
          id="startTime"
          name="startTime"
          label={t('form.startTime.label', SCOPE_OPTIONS)}
          dateFormat="dd/MM/yyyy HH:mm"
          rules={{
            required: { value: true, message: t('form.startTime.errors.required', SCOPE_OPTIONS) },
            validate: {
              greater: (date: Date) => {
                const now = dateLib.ISOtoJSDate(dateLib.minusFromNow({ minutes: 1 }));
                date >= now || t('form.startTime.errors.greater', SCOPE_OPTIONS);
              },
            },
          }}
          control={control}
          error={errors.startTime}
          defaultValue={defaultStartTime}
          showTimeSelect
          timeIntervals={1}
        />
        <Form.Checkbox id="useStopTime" className="mt-4" {...register('useStopTime')} label={t('form.useStopTime.label', SCOPE_OPTIONS)} />
        {isUsingStopTimeWatch && (
          <Form.Date
            showTimeSelect
            timeIntervals={1}
            id="stopTime"
            name="stopTime"
            label={t('form.stopTime.label', SCOPE_OPTIONS)}
            dateFormat="dd/MM/yyyy HH:mm"
            rules={{
              required: { value: true, message: t('form.stopTime.errors.required', SCOPE_OPTIONS) },
              validate: {
                greater: (date: Date) => date >= startTimeWatch || t('form.stopTime.errors.greater', SCOPE_OPTIONS),
              },
            }}
            control={control}
            className="mt-4"
            error={errors.stopTime}
            defaultValue={defaultStopTime}
          />
        )}
      </Box>
      <Box>
        <Form.Select
          name="locales"
          label={t('form.locales.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.locales.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          className="mt-4"
          isMulti={true}
          options={GOOGLE_LOCALES.map((locale) => ({ value: locale.key, label: locale.name }))}
          error={errors.locales}
          defaultValue={DEFAULT_LANGUAGES}
        />
      </Box>

      <Box>
        <Form.Select
          name="geoLocationType"
          label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={LOCATION_VALUES}
          error={errors.geoLocationType}
          defaultValue={defaultGeoType}
        />
        {geoLocationTypeWatch !== GEO_LOCATION_TYPE.CITY && (
          <Form.Range
            rules={{
              required: true,
            }}
            className="mt-4"
            name="defaultLocationRange"
            label={t('form.defaultLocationRange.label', SCOPE_OPTIONS)}
            id="defaultLocationRange"
            defaultValue={campaign?.targeting.default_location_range ?? 5}
            control={control}
            minValue={1}
            maxValue={80}
            error={errors.defaultLocationRange}
          />
        )}
      </Box>
      <Box>
        <Form.Select
          name="gender"
          label={t('form.gender.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.gender.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={Object.values(GENDERS).map((gender) => ({
            label: t(`genders.${gender.toLocaleLowerCase()}`),
            value: gender,
          }))}
          error={errors.gender}
          defaultValue={defaultGender}
        />
        <div className="grid grid-cols-2 gap-4 w-full mt-4">
          <Form.Select //FIXME: Stuck
            name="ageMin"
            label={t('form.ageMin.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.ageMin.errors.required', SCOPE_OPTIONS) },
              min: { value: 18, message: t('form.ageMin.errors.min', { ...SCOPE_OPTIONS, value: 18 }) },
              validate: (age: number) => {
                if (age <= ageMaxWatch) {
                  return true;
                }

                return t('form.ageMin.errors.max', SCOPE_OPTIONS);
              },
            }}
            control={control}
            options={minAges.map((index) => ({ value: index, label: String(index) }))}
            error={errors.ageMin}
            defaultValue={defaultAgeMin}
          />

          <Form.Select
            name="ageMax"
            label={t('form.ageMax.label', SCOPE_OPTIONS)}
            rules={{
              required: { value: true, message: t('form.ageMax.errors.required', SCOPE_OPTIONS) },
              max: { value: 65, message: t('form.ageMax.errors.max', { ...SCOPE_OPTIONS, value: 65 }) },
              validate: (age: number) => {
                if (age >= ageMinWatch) {
                  return true;
                }
                return t('form.ageMax.errors.min', SCOPE_OPTIONS);
              },
            }}
            control={control}
            options={maxAges.map((index) => ({ value: index, label: String(index) }))}
            error={errors.ageMax}
            defaultValue={defaultAgeMax}
          />
        </div>
      </Box>

      <Box>
        <CustomSegmentKeyword //FIXME: Stuck
          id="customSegments"
          label={t('form.customSegments.label', SCOPE_OPTIONS)}
          placeholder={t('form.customSegments.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          {...register('customSegments', {
            required: {
              value: array.isEmpty(keywordsWatch),
              message: t(`form.customSegments.errors.required`, SCOPE_OPTIONS),
            },
          })}
          error={errors.customSegments}
          defaultValue={defaultCustomSegments}
        />
      </Box>
      <PermissionsForm />
      <GroupSelect defaultValue={defaultGroup} />
    </>
  );
};

export default GoogleSettingsForm;
