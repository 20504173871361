import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Box, Form } from '@app/components';
import { useLocale } from '@app/hooks';
import { GOOGLE_LOCALES, GEO_LOCATION_TYPE, PUBLISHER_PLATFORMS } from '@app/constants';
import { array as arrayLib, date, date as dateLib, template as templateLib } from '@app/lib';
import type { Campaign as CampaignType, Template } from '@app/api';

import GroupSelect from './GroupSelect';
import CustomSegmentKeyword from './CustomSegmentKeyword';
import PermissionsForm from './PermissionsForm';

type SmartSettingsFormPropTypes = {
  campaign?: CampaignType;
  isEdit?: boolean;
  template?: Template;
};

const SMART_PERMISSION_INPUTS = ['interests', 'locations'];

const FB_LOCATION_MIN = 1;
const FB_LOCATION_MAX = 80;
const SMART_LOCATION_MIN = 5;
const SMART_LOCATION_MAX = 65;

const SmartSettingsForm: React.FC<SmartSettingsFormPropTypes> = ({ campaign, isEdit, template }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.SmartSettingsForm',
  };
  const { t } = useLocale();
  const {
    register,
    unregister,
    formState: { errors },
    watch,
    control,
    setValue,
  } = useFormContext();

  const startTimeWatch = watch('startTime');
  const geoLocationTypeWatch = watch('geoLocationType');
  const keywordsWatch = watch('keywords');
  const negativeKeywordsWatch = watch('negative_keywords');
  const plusWeekToNow = date.ISOtoJSDate(date.plusFromNow({ days: 7 }));
  const defaultStopTime = campaign?.stop_time ? dateLib.ISOtoJSDate(campaign?.stop_time) : plusWeekToNow;
  const defaultStartTime = campaign?.start_time ? dateLib.ISOtoJSDate(campaign?.start_time) : new Date();
  const isSmartPlatform = templateLib.hasSmartInCampaignType((campaign?.template ?? template)?.targeting?.sub_campaign_type);
  const defaultGeoType = campaign?.geo_location_type
    ? campaign?.geo_location_type
    : isSmartPlatform
    ? GEO_LOCATION_TYPE.RANGE
    : GEO_LOCATION_TYPE.FREE;
  const defaultKeywords = (campaign?.targeting as any)?.keywords ?? [];
  const defaultNegativeKeywords = (campaign?.targeting as any)?.negative_keywords ?? [];
  const defaultGroup = campaign?.allowed_group_ids ? campaign?.allowed_group_ids : [];
  const stopTimeWatch = watch('stopTime', defaultStopTime);
  const isUsingStopTimeWatch = watch('useStopTime', !!errors.stopTime);
  const isUsingNegativeKeywordsWatch = watch('useNegativeKeywords', !!errors.negative_keywords);
  const DEFAULT_LANGUAGES = campaign?.targeting?.locales ?? ['tr'];
  const LOCATION_VALUES = [
    {
      label: t('form.geoLocationType.options.free', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.FREE,
    },
    {
      label: t('form.geoLocationType.options.range', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.RANGE,
    },
    {
      label: t('form.geoLocationType.options.city', SCOPE_OPTIONS),
      value: GEO_LOCATION_TYPE.CITY,
    },
  ];

  useEffect(() => {
    if (!isEdit) {
      const timer = setInterval(() => {
        setValue('startTime', new Date());
      }, 60 * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, []);

  useEffect(() => {
    if (!stopTimeWatch) {
      setValue('useStopTime', !!campaign?.stop_time);
    }
  }, [campaign?.stop_time]);

  useEffect(() => {
    if (campaign?.permissions) {
      setValue('permissions', campaign?.permissions);
    }
  }, [campaign?.permissions]);

  useEffect(() => {
    if (campaign?.allowed_objectives) {
      setValue('objectives', campaign?.allowed_objectives);
    }
  }, [campaign?.allowed_objectives]);

  useEffect(() => {
    if (isUsingStopTimeWatch === false) {
      unregister('stopTime');
    }
  }, [isUsingStopTimeWatch]);

  useEffect(() => {
    if (GEO_LOCATION_TYPE.CITY === geoLocationTypeWatch) {
      unregister('defaultLocationRange');
    }
  }, [geoLocationTypeWatch]);

  useEffect(() => {
    if (!arrayLib.isEmpty(defaultNegativeKeywords)) {
      setValue('useNegativeKeywords', true);
    }
  }, [defaultNegativeKeywords]);

  useEffect(() => {
    if (!isUsingNegativeKeywordsWatch) {
      unregister('negative_keywords');
    }
  }, [isUsingNegativeKeywordsWatch]);

  return (
    <>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: !isEdit, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          defaultValue={campaign?.name}
          disabled={isEdit}
        />
      </Box>
      <Box>
        <Form.Date
          id="startTime"
          name="startTime"
          label={t('form.startTime.label', SCOPE_OPTIONS)}
          dateFormat="dd/MM/yyyy HH:mm"
          rules={{
            required: { value: true, message: t('form.startTime.errors.required', SCOPE_OPTIONS) },
            validate: {
              greater: (date: Date) => {
                const now = dateLib.ISOtoJSDate(dateLib.minusFromNow({ minutes: 1 }));
                date >= now || t('form.startTime.errors.greater', SCOPE_OPTIONS);
              },
            },
          }}
          control={control}
          error={errors.startTime}
          defaultValue={defaultStartTime}
          showTimeSelect
          timeIntervals={1}
        />
        <Form.Checkbox id="useStopTime" className="mt-4" {...register('useStopTime')} label={t('form.useStopTime.label', SCOPE_OPTIONS)} />
        {isUsingStopTimeWatch && (
          <Form.Date
            showTimeSelect
            timeIntervals={1}
            id="stopTime"
            name="stopTime"
            label={t('form.stopTime.label', SCOPE_OPTIONS)}
            dateFormat="dd/MM/yyyy HH:mm"
            rules={{
              required: { value: true, message: t('form.stopTime.errors.required', SCOPE_OPTIONS) },
              validate: {
                greater: (date: Date) => date >= startTimeWatch || t('form.stopTime.errors.greater', SCOPE_OPTIONS),
              },
            }}
            control={control}
            className="mt-4"
            error={errors.stopTime}
            defaultValue={defaultStopTime}
          />
        )}
      </Box>
      <Box>
        <Form.Select
          name="locales"
          label={t('form.locales.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.locales.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          className="mt-4"
          options={GOOGLE_LOCALES.map((locale) => ({ value: locale.code, label: locale.name }))}
          error={errors.locales}
          defaultValue={arrayLib.first(DEFAULT_LANGUAGES)}
        />
      </Box>

      <Box>
        <Form.Select
          name="geoLocationType"
          label={t('form.geoLocationType.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.geoLocationType.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={LOCATION_VALUES}
          error={errors.geoLocationType}
          defaultValue={defaultGeoType}
          disabled={isSmartPlatform}
        />
        {geoLocationTypeWatch !== GEO_LOCATION_TYPE.CITY && (
          <Form.Range
            rules={{
              required: true,
            }}
            className="mt-4"
            name="defaultLocationRange"
            label={t('form.defaultLocationRange.label', SCOPE_OPTIONS)}
            id="defaultLocationRange"
            defaultValue={campaign?.targeting.default_location_range ?? 5}
            control={control}
            minValue={isSmartPlatform ? SMART_LOCATION_MIN : FB_LOCATION_MIN}
            maxValue={isSmartPlatform ? SMART_LOCATION_MAX : FB_LOCATION_MAX}
            error={errors.defaultLocationRange}
          />
        )}
      </Box>

      <Box>
        <CustomSegmentKeyword
          id="keywords"
          label={t('form.keywords.label', SCOPE_OPTIONS)}
          placeholder={t('form.keywords.placeholder', SCOPE_OPTIONS)}
          requiredSign={true}
          {...register('keywords', {
            required: {
              value: arrayLib.isEmpty(keywordsWatch),
              message: t(`form.keywords.errors.required`, SCOPE_OPTIONS),
            },
          })}
          error={errors.keywords}
          defaultValue={defaultKeywords}
        />
        <Form.Checkbox
          id="useNegativeKeywords"
          className="my-4"
          {...register('useNegativeKeywords')}
          label={t('form.useNegativeKeywords.label', SCOPE_OPTIONS)}
        />
        {isUsingNegativeKeywordsWatch && (
          <CustomSegmentKeyword
            id="negative_keywords"
            label={t('form.negativeKeywords.label', SCOPE_OPTIONS)}
            placeholder={t('form.negativeKeywords.placeholder', SCOPE_OPTIONS)}
            requiredSign={true}
            {...register('negative_keywords', {
              required: {
                value: arrayLib.isEmpty(negativeKeywordsWatch),
                message: t(`form.negativeKeywords.errors.required`, SCOPE_OPTIONS),
              },
            })}
            error={errors.negative_keywords}
            defaultValue={defaultNegativeKeywords}
          />
        )}
      </Box>
      <PermissionsForm permissionInputs={SMART_PERMISSION_INPUTS} platform={PUBLISHER_PLATFORMS.SMART} />
      <GroupSelect defaultValue={defaultGroup} />
    </>
  );
};

export default SmartSettingsForm;
