import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15.4286 1V5.34333C15.4286 5.95667 14.9308 6.45444 14.3175 6.45444H7.65079C7.03746 6.45444 6.53968 5.95667 6.53968 5.34333V1M5.43616 21V12.4289C5.43616 11.64 6.07616 11 6.86505 11H15.1195C15.9073 11 16.5473 11.64 16.5473 12.4289V21M15.6189 1H3.22228C1.98895 1 0.99117 2.00444 1.00006 3.23889L1.11561 18.7944C1.1245 20.0156 2.11673 21 3.33784 21H18.7612C19.9889 21 20.9834 20.0056 20.9834 18.7778V6.36444C20.9834 5.77556 20.7489 5.21 20.3323 4.79333L17.1901 1.65111C16.7734 1.23444 16.2089 1 15.6189 1Z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
