import React from 'react';

import cn from 'classnames';

import { useLocale } from '@app/hooks';

type PlatformPropTypes = {
  platforms: {
    Icon: any;
    key: string;
    label: string;
    disabled: boolean;
  }[];
  onClick: (key: string) => void;
};

const Platform: React.FC<PlatformPropTypes> = ({ platforms, onClick }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.Platform',
  };
  const { t } = useLocale();

  return (
    <div className="flex-row gap-8">
      <span className="font-semibold text-3.5 text-gray-900 flex items-center mb-2">{t('labels.selectCampaignType', SCOPE_OPTIONS)}</span>
      <div className="grid grid-cols-2 gap-3.5 flex-1">
        {platforms.map(({ Icon, ...platform }) => (
          <button
            key={platform.key}
            type="button"
            disabled={platform.disabled}
            onClick={() => onClick(platform.key)}
            className={cn('p-3 rounded-3 border-0.5 flex flex-row items-center cursor-pointer transition-colors', {
              'border-gray-800 text-gray-900': !platform.disabled,
              'border-gray-300 text-gray-500': platform.disabled,
            })}
          >
            <Icon className="w-6.5 h-6.5 mr-2.5" />
            <span className="font-semibold text-3.5 text-gray-900">{platform.label}</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default Platform;
