import React, { forwardRef, useState } from 'react';

import type { FieldError } from 'react-hook-form';
import cn from 'classnames';

type CheckboxPropTypes = {
  label?: string;
  id: string;
  error?: FieldError;
  className?: string;
  labelStyle?: string;
  placeholder?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  name: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
};

export const Checkbox: React.ForwardRefRenderFunction<HTMLInputElement, React.PropsWithChildren<CheckboxPropTypes>> = (
  { label, id, error, className, onChange, onBlur, name, checked, disabled, defaultChecked, labelStyle, ...props },
  ref
) => {
  const [isFocused, setIsFocused] = useState(false);

  function onFocus() {
    setIsFocused(true);
  }

  function handleOnBlur(event: React.FocusEvent<HTMLInputElement>) {
    setIsFocused(false);
    onBlur && onBlur(event);
  }

  return (
    <div className={cn('flex items-center select-none', className)}>
      <div className="flex items-center">
        <input
          ref={ref}
          onFocus={onFocus}
          name={name}
          onChange={onChange}
          onBlur={handleOnBlur}
          type="checkbox"
          id={id}
          className={cn('w-5 h-5 border bg-white rounded-md ring-0', {
            'bg-white border-gray-400': !error,
            'bg-white border-red-400': error,
            'border-gray-600': !error && isFocused,
          })}
          checked={checked}
          disabled={disabled}
          {...props}
          defaultChecked={defaultChecked}
        />
      </div>
      <div className="ml-3">
        <label htmlFor={id} className={cn('block text-sm font-semibold text-gray-900', labelStyle)}>
          {label}
        </label>
      </div>
    </div>
  );
};

export default forwardRef(Checkbox);
