import React from 'react';

import cn from 'classnames';

import type { TemplatesPreviewListResponseAttachmentsPlacementTypeEnum } from '@app/api';
import { useLocale } from '@app/hooks';

import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

type ImagePropTypes = {
  branchName: string;
  placementType?: TemplatesPreviewListResponseAttachmentsPlacementTypeEnum;
  url: string | null;
};

const Image: React.FC<ImagePropTypes> = ({ branchName, placementType, url }) => {
  const { t } = useLocale();
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.BranchPreview.Image',
  };
  if (!url) {
    return (
      <div
        className={cn('motion-reduce:animate-pulse flex justify-center items-center w-full h-auto rounded-2 bg-gray-400', {
          'aspect-1': placementType === IMAGE_PLACEMENT_TYPE.FEED,
          'aspect-story': placementType === IMAGE_PLACEMENT_TYPE.STORY,
        })}
      >
        <p className="text-gray-700 text-center text-4 px-2">{t('labels.generating', SCOPE_OPTIONS)}</p>
      </div>
    );
  }
  return (
    <img
      src={url as string}
      alt={`${branchName}-${placementType}-preview`}
      loading="lazy"
      className={cn('w-full h-auto object-cover rounded-2 bg-gray-400', {
        'aspect-1': placementType === IMAGE_PLACEMENT_TYPE.FEED,
        'aspect-story': placementType === IMAGE_PLACEMENT_TYPE.STORY,
      })}
    />
  );
};

export default Image;
