import React, { useEffect, useMemo } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form, Box, Icons } from '@app/components';
import { useActiveInput, useLocale } from '@app/hooks';
import { PUBLISHER_PLATFORMS, IMAGE_PLACEMENT_TYPE } from '@app/constants';
import { Branch } from '@app/api';

import ImageUpload from './ImageUpload';
import Platform from './Platform';

type FormDataType = {
  name: string;
  platforms: string;
  message: string;
  headline: string;
  description: string;
  link: string;
  caption: string;
  callToAction: string;
  image: {
    url: string;
    id: string;
  };
};

type FormPropTypes = {
  defaultValues?: FormDataType;
  branches?: Array<Branch>;
};

const TemplateForm: React.FC<FormPropTypes> = ({ defaultValues, branches }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Templates.Form.index',
  };

  const { t } = useLocale();
  const {
    control,
    getValues,
    setValue,
    register,
    formState: { errors },
    watch,
  } = useFormContext();
  const platformsWatch = watch('platforms');
  const messageWatch = watch('message');
  const templateBranchTypeWatch = watch('templateBranchType');
  const activeInput = useActiveInput(['message', 'headline', 'description']);

  useEffect(() => {
    if (defaultValues) {
      setValue('name', defaultValues.name);
      setValue('platforms', defaultValues.platforms);
      setValue('message', defaultValues.message);
      setValue('headline', defaultValues.headline);
      setValue('description', defaultValues.description);
    }
  }, [defaultValues]);

  const PLATFORMS = [
    { key: PUBLISHER_PLATFORMS.FACEBOOK, label: t('labels.facebook', SCOPE_OPTIONS), Icon: Icons.Facebook },
    { key: PUBLISHER_PLATFORMS.INSTAGRAM, label: t('labels.instagram', SCOPE_OPTIONS), Icon: Icons.Instagram },
  ];
  const BRANCH_OPTIONS = [
    { label: t('form.branches.all', SCOPE_OPTIONS), value: 'all', defaultChecked: true },
    { label: t('form.branches.custom', SCOPE_OPTIONS), value: 'custom' },
  ];
  const branchSelectOptions = useMemo(
    () =>
      branches?.map((branch) => ({
        label: branch.name,
        value: branch.id,
      })) || [],
    [branches]
  );

  function handlePlatformClick(platform: string) {
    if (!Array.isArray(platformsWatch)) {
      return;
    }

    if (platformsWatch.includes(platform) && platformsWatch.length === 1) {
      return;
    }

    if (platformsWatch.includes(platform)) {
      setValue('platforms', [...platformsWatch.filter((item: string) => item !== platform)]);
      return;
    }

    setValue('platforms', [...platformsWatch, platform]);
  }

  function InputVariables({ id }: { id: string }) {
    if (activeInput !== id) {
      return null;
    }

    const currentText = getValues(id);

    function handleText(value: string) {
      if (!currentText) {
        setValue(id, value);
        return;
      }

      setValue(id, `${currentText} ${value}`);
    }

    return <Form.BranchButtons className="mb-4.5 -mt-1.5" onClick={handleText} />;
  }

  return (
    <form>
      <Box>
        <Platform platforms={PLATFORMS} value={platformsWatch} onClick={handlePlatformClick} />
      </Box>
      <Box>
        <Form.Input
          type="text"
          id="name"
          label={t('form.name.label', SCOPE_OPTIONS)}
          placeholder={t('form.name.placeholder', SCOPE_OPTIONS)}
          {...register('name', {
            required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.name}
          className="mb-4"
        />
      </Box>
      <Box>
        <ImageUpload size={{ width: 1080, height: 1080 }} type={IMAGE_PLACEMENT_TYPE.FEED} platforms={platformsWatch} />
      </Box>
      <Box>
        <ImageUpload size={{ width: 1080, height: 1920 }} type={IMAGE_PLACEMENT_TYPE.STORY} platforms={platformsWatch} />
      </Box>
      <Box>
        <Form.Textarea
          id="message"
          label={t('form.message.label', SCOPE_OPTIONS)}
          placeholder={t('form.message.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register('message', {
            required: { value: true, message: t('form.message.errors.required', SCOPE_OPTIONS) },
            maxLength: { value: 255, message: t('form.message.errors.maxLength', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          maxLength={255}
          length={messageWatch?.length ?? 0}
          error={errors.message}
        />
        <InputVariables id="message" />
        <Form.Input
          type="text"
          id="headline"
          label={t('form.headline.label', SCOPE_OPTIONS)}
          placeholder={t('form.headline.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register('headline', {
            required: { value: true, message: t('form.headline.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.headline}
        />
        <InputVariables id="headline" />
        <Form.Input
          type="text"
          id="description"
          label={t('form.description.label', SCOPE_OPTIONS)}
          placeholder={t('form.description.placeholder', SCOPE_OPTIONS)}
          className="mb-4.5"
          {...register('description', {
            required: { value: true, message: t('form.description.errors.required', SCOPE_OPTIONS) },
          })}
          requiredSign={true}
          error={errors.description}
        />
        <InputVariables id="description" />
      </Box>
      <Box>
        <Form.RadioGroup name="templateBranchType" options={BRANCH_OPTIONS} register={register} />
        {templateBranchTypeWatch === 'custom' && (
          <Form.Select
            isMulti
            label="Bayi"
            name="allowedBranchIds"
            rules={{
              required: { value: true, message: t('form.branches.errors.required', SCOPE_OPTIONS) },
            }}
            className="mt-4"
            control={control}
            options={branchSelectOptions}
          />
        )}
      </Box>
    </form>
  );
};

export default TemplateForm;
