import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M22.667 12V9.333H20" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="m13.333 12.381 1.715-1.714 3.619 2.666 4-4M18.667 18h4M18.667 22h4M4 18.133V27.2c.002 1.178 2.388 2.133 5.333 2.133 2.946 0 5.332-.955 5.334-2.133v-9.067"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.665 18.133c0 1.178-2.388 2.134-5.333 2.134C6.386 20.267 4 19.31 4 18.133 4 16.953 6.39 16 9.333 16c2.944 0 5.332.955 5.334 2.133M4 22.667C4 23.845 6.388 24.8 9.334 24.8c2.945 0 5.333-.955 5.333-2.133"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.667 12V6.667A2.667 2.667 0 0 1 9.333 4h16A2.667 2.667 0 0 1 28 6.667V24a2.667 2.667 0 0 1-2.667 2.667h-6.666"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgComponent;
