import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { TemplatesGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function generateFacebookAdPreviewByTemplateId(
  companyId: string,
  templateId: string,
  adFormat: any,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new TemplatesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.generateFacebookAdPreviewByTemplateId(companyId, templateId, adFormat).then((res) => res.data);
}

export default function useGenerateFacebookAdPreviewByTemplateId({
  params = {},
  ...props
}: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useGenerateFacebookAdPreviewByTemplateId', companyId, params.templateId, params.adFormat],
    () => generateFacebookAdPreviewByTemplateId(companyId, params.templateId, params.adFormat, axiosInstance),
    props
  );
}
