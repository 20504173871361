import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { MessagingApplicationGMApi } from 'api/api';
import { useAxios } from '@app/hooks';

export function deleteMessagingApplication(
  companyId: string,
  messagingApplicationId: string,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new MessagingApplicationGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.deleteMessagingApplicationForCompany(companyId, messagingApplicationId).then((res) => res.data);
}

export default function useDeleteMessagingApplication(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; messagingApplicationId: string }) =>
      deleteMessagingApplication(values.companyId, values.messagingApplicationId, axiosInstance),
    props
  );
}
