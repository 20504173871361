import { Fragment } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';
import cn from 'classnames';

import type { MetabaseResource } from '@app/api';
import { api, useLocale, useAuth } from '@app/hooks';
import { array } from '@app/lib';
import { Icons } from '@app/components';

type SidebarPropTypes = {
  onCollapseClick: (status: boolean) => void;
  miniSidebar: boolean;
  className: string;
};

const Sidebar: React.FC<SidebarPropTypes> = ({ onCollapseClick, miniSidebar, className }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Sidebar',
  };
  const { t, changeLocale, locale } = useLocale();
  const { company } = useAuth();
  const router = useRouter();
  const { data: profile } = api.useGetProfile({
    onSuccess: (profileData: { language: string | null }) => {
      if (!profileData.language) {
        return;
      }

      if (profileData.language === locale) {
        return;
      }

      changeLocale(profileData.language);
    },
  });
  const hasCampaignFeature = !!company?.feature_flags?.has_campaign_feature;
  const hasDynamicLocationAdsFeature = !!company?.feature_flags?.has_dynamic_location_ads_feature;
  const hasMetabaseReportFeature = !!company?.feature_flags?.has_metabase_report_feature;
  const hasContactFormFeature = !!company?.feature_flags?.has_contact_forms_feature;
  const hasCentricPaymentFeature = !!company?.feature_flags?.has_centric_payment_feature;
  const hasFileManagerFeature = !!company?.feature_flags?.has_file_manager_feature;
  const hasMessagingApplicationFeature = !!company?.feature_flags?.has_messaging_application_feature;
  const hasLeadGenerationFeature = !!company?.feature_flags?.has_lead_generation_feature;

  const { data } = api.useListGmMetabaseResources({
    enabled: hasMetabaseReportFeature,
  });

  const ROUTES = [
    {
      key: 'dashboard',
      Icon: Icons.Dashboard,
      url: '/',
      activePaths: ['/', '/dashboard'],
      show: true,
    },
    {
      key: 'campaign',
      Icon: Icons.Campaign,
      url: '/campaigns',
      activePaths: ['/campaigns', '/campaigns/create', '/campaigns/[id]'],
      show: hasCampaignFeature || hasDynamicLocationAdsFeature,
    },
    {
      key: 'templates',
      Icon: Icons.Templates,
      url: '/templates',
      activePaths: ['/templates', '/templates/create'],
      show: hasCampaignFeature || hasDynamicLocationAdsFeature,
    },
    {
      key: 'posts',
      Icon: Icons.Post,
      url: '/posts',
      activePaths: ['/posts'],
      show: hasFileManagerFeature,
    },
    {
      key: 'forms',
      Icon: Icons.Forms,
      url: '/forms',
      activePaths: ['/forms'],
      show: hasCampaignFeature && hasLeadGenerationFeature,
    },
    {
      key: 'branches',
      Icon: Icons.Branches,
      url: '/branches',
      detail: [
        {
          Icon: Icons.Location,
          name: t('actions.allLocations', SCOPE_OPTIONS),
          url: '/branches',
          activePaths: ['/branches', '/branches/create', '/branches/[id]', '/branches/edit/[id]'],
        },
        {
          Icon: Icons.Users,
          name: t('actions.locationGroups', SCOPE_OPTIONS),
          url: '/groups',
          activePaths: ['/groups', '/groups/edit/[id]'],
        },
      ],
      activePaths: ['/branches', '/branches/create', '/branches/[id]', '/branches/edit/[id]', '/groups', '/groups/edit/[id]'],
      show: hasCampaignFeature || hasDynamicLocationAdsFeature,
    },
    {
      key: 'wallet',
      Icon: Icons.Wallet,
      url: '/wallet',
      activePaths: ['/wallet', 'wallet/cards'],
      show: hasCentricPaymentFeature,
    },
    // {
    //   key: 'activities',
    //   Icon: Icons.Activities,
    //   url: '/company/activities',
    //   activePaths: ['/company/activities'],
    //   show: true
    // },
    {
      key: 'settings',
      Icon: Icons.Settings,
      url: '/settings',
      activePaths: ['/settings'],
      show: true,
    },
    {
      key: 'contactForms',
      Icon: Icons.Forms,
      url: '/contact-forms',
      activePaths: ['/contact-forms'],
      show: hasContactFormFeature,
    },
    {
      key: 'messagingApplication',
      Icon: Icons.Users,
      url: '/messaging-applications',
      activePaths: ['/messaging-application'],
      show: hasMessagingApplicationFeature,
    },
  ];
  const resources = getResources();

  function getResources(): { key: string; name?: string; Icon: any; url: string; activePaths: string[] }[] {
    if (array.isEmpty(data?.metabase_resources)) {
      return [];
    }

    return data.metabase_resources.map((resource: MetabaseResource) => ({
      key: resource.id,
      name: getResourceTitle(resource),
      Icon: Icons.Dashboard,
      url: `/reports/${resource.id}`,
      activePaths: [`/reports/${resource.id}`],
    }));
  }

  function getResourceTitle(resource: MetabaseResource) {
    if (!resource) {
      return '';
    }

    if (Object.prototype.hasOwnProperty.call(resource.name, locale)) {
      return (resource.name as Record<string, string>)[locale];
    }

    return '';
  }

  function isActive(paths: string[]) {
    if (!Array.isArray(paths)) {
      return false;
    }

    return paths.includes(router.pathname) || paths.includes(router.asPath);
  }

  function getLabel(route: { key: string; name?: string }) {
    if (route.name) {
      return route.name;
    }

    return t(`actions.${route.key}`, SCOPE_OPTIONS);
  }

  function shouldRoute(url: string) {
    if (url === '/branches' && hasDynamicLocationAdsFeature) {
      return true;
    }
    return false;
  }

  return (
    <aside className={cn('fixed top-0 left-0 flex flex-col bg-blue-300 w-full h-screen transition-all', className)}>
      <Link href="/">
        <a className="block pt-5 px-6">{miniSidebar ? <Icons.LogoIcon /> : <Icons.Logo />}</a>
      </Link>
      <div className="overflow-y-auto pt-6 mb-4 mt-4 flex-1">
        <ul className="flex flex-col gap-y-6">
          {ROUTES.map(
            ({ Icon, ...route }) =>
              route.show && (
                <Fragment key={route.key}>
                  <li className="relative px-3">
                    <Link href={route.url}>
                      <a
                        className={cn(
                          'flex items-center py-2 rounded-xl text-blue-100 hover:bg-blue-50 hover:text-blue-300 transition-colors',
                          {
                            'px-0 justify-center': miniSidebar,
                            'pl-2 pr-5': !miniSidebar,
                            '!bg-blue-50 !text-blue-300': isActive(route.activePaths),
                          }
                        )}
                      >
                        <Icon className={cn('mr-3.5 min-w-6 min-h-6 w-6 h-6', { '!mr-0': miniSidebar })} />
                        {miniSidebar || <span className="font-semibold text-sm truncate flex-1">{getLabel(route)}</span>}
                        {route.detail && (
                          <span
                            className={cn('text-blue-300 bg-blue-50 rounded-full', {
                              '!bg-blue-300 text-blue-50': isActive(route.activePaths),
                            })}
                          >
                            {isActive(route.activePaths) && shouldRoute(route.url) && (
                              <Icons.ChevronDown className={cn('min-w-5 min-h-5 w-5 h-5')} />
                            )}
                          </span>
                        )}
                      </a>
                    </Link>
                  </li>

                  {route.detail && shouldRoute(route.url) && isActive(route.activePaths) && (
                    <div className="ml-5 py-2 border-l-0.5 border-white space-y-1">
                      {route.detail.map(({ Icon, ...item }, key) => (
                        <li className="relative pl-2 pr-3" key={key}>
                          <Link href={item.url}>
                            <a
                              className={cn(
                                'flex items-center py-0.5 rounded-lg text-blue-100 hover:bg-blue-50 hover:text-blue-300 transition-colors',
                                {
                                  'px-0 justify-center': miniSidebar,
                                  'pl-2 pr-5': !miniSidebar,
                                  '!bg-blue-50 !text-blue-300': isActive(item.activePaths),
                                }
                              )}
                            >
                              <Icon className={cn('mr-1 min-w-3 min-h-3 w-3 h-3', { '!mr-0': miniSidebar })} />
                              {miniSidebar || <span className="font-semibold text-3 truncate">{item.name}</span>}
                            </a>
                          </Link>
                        </li>
                      ))}
                    </div>
                  )}
                </Fragment>
              )
          )}
          {!array.isEmpty(resources) && (
            <>
              <hr className="mx-4 opacity-50 border-blue-100" />
              {resources.map(({ Icon, ...route }) => (
                <li className="relative px-3" key={route.key}>
                  <Link href={route.url}>
                    <a
                      className={cn(
                        'flex items-center py-2 rounded-xl text-blue-100 hover:bg-blue-50 hover:text-blue-300 transition-colors',
                        {
                          'px-0 justify-center': miniSidebar,
                          'pl-2 pr-5': !miniSidebar,
                          '!bg-blue-50 !text-blue-300': isActive(route.activePaths),
                        }
                      )}
                    >
                      <Icon className={cn('mr-3.5 min-w-6 min-h-6 w-6 h-6', { '!mr-0': miniSidebar })} />
                      {miniSidebar || <span className="font-semibold text-sm truncate">{getLabel(route)}</span>}
                    </a>
                  </Link>
                </li>
              ))}
            </>
          )}
        </ul>
      </div>

      <ul className="flex flex-col gap-y-6 mb-5">
        <li className="relative px-5">
          <button
            className={cn('flex items-center w-full text-blue-100', {
              'px-0 justify-center': miniSidebar,
              'pl-2 pr-5': !miniSidebar,
            })}
            onClick={() => onCollapseClick(!miniSidebar)}
          >
            <Icons.Collapse className={cn('mr-3.5 text-sm', { 'mr-0': miniSidebar })} />
            {miniSidebar || <span className="font-semibold text-sm">{t('actions.collapse', SCOPE_OPTIONS)}</span>}
          </button>
        </li>
        <li className="mx-5">
          <Link href="/">
            <a className="flex items-center text-blue-100">
              <img src={profile?.profile_picture?.url ?? '/images/avatar.png'} className="w-9.5 h-9.5 mr-2.5" alt="" />
              {(miniSidebar && !!profile) || (
                <span className="font-semibold text-sm truncate">{`${profile?.name} ${profile?.surname}`}</span>
              )}
            </a>
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
