/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FacebookGeneratePreviewInputCreativeLinkData } from './facebook-generate-preview-input-creative-link-data';

/**
 * 
 * @export
 * @interface FacebookGeneratePreviewInputCreative
 */
export interface FacebookGeneratePreviewInputCreative {
    /**
     * 
     * @type {string}
     * @memberof FacebookGeneratePreviewInputCreative
     */
    ad_format?: FacebookGeneratePreviewInputCreativeAdFormatEnum;
    /**
     * 
     * @type {string}
     * @memberof FacebookGeneratePreviewInputCreative
     */
    attachment_id?: string;
    /**
     * 
     * @type {FacebookGeneratePreviewInputCreativeLinkData}
     * @memberof FacebookGeneratePreviewInputCreative
     */
    link_data?: FacebookGeneratePreviewInputCreativeLinkData;
}

/**
    * @export
    * @enum {string}
    */
export enum FacebookGeneratePreviewInputCreativeAdFormatEnum {
    AudienceNetworkInstreamVideo = 'AUDIENCE_NETWORK_INSTREAM_VIDEO',
    AudienceNetworkInstreamVideoMobile = 'AUDIENCE_NETWORK_INSTREAM_VIDEO_MOBILE',
    AudienceNetworkOutstreamVideo = 'AUDIENCE_NETWORK_OUTSTREAM_VIDEO',
    AudienceNetworkRewardedVideo = 'AUDIENCE_NETWORK_REWARDED_VIDEO',
    BizDiscoFeedMobile = 'BIZ_DISCO_FEED_MOBILE',
    DesktopFeedStandard = 'DESKTOP_FEED_STANDARD',
    FacebookReelsBanner = 'FACEBOOK_REELS_BANNER',
    FacebookReelsMobile = 'FACEBOOK_REELS_MOBILE',
    FacebookReelsSticker = 'FACEBOOK_REELS_STICKER',
    FacebookStoryMobile = 'FACEBOOK_STORY_MOBILE',
    FacebookStoryStickerMobile = 'FACEBOOK_STORY_STICKER_MOBILE',
    InstagramExploreContextual = 'INSTAGRAM_EXPLORE_CONTEXTUAL',
    InstagramExploreImmersive = 'INSTAGRAM_EXPLORE_IMMERSIVE',
    InstagramFeedWeb = 'INSTAGRAM_FEED_WEB',
    InstagramFeedWebMSite = 'INSTAGRAM_FEED_WEB_M_SITE',
    InstagramReels = 'INSTAGRAM_REELS',
    InstagramReelsOverlay = 'INSTAGRAM_REELS_OVERLAY',
    InstagramShop = 'INSTAGRAM_SHOP',
    InstagramStandard = 'INSTAGRAM_STANDARD',
    InstagramStory = 'INSTAGRAM_STORY',
    InstantArticleRecirculationAd = 'INSTANT_ARTICLE_RECIRCULATION_AD',
    InstantArticleStandard = 'INSTANT_ARTICLE_STANDARD',
    InstreamBannerDesktop = 'INSTREAM_BANNER_DESKTOP',
    InstreamBannerMobile = 'INSTREAM_BANNER_MOBILE',
    InstreamVideoDesktop = 'INSTREAM_VIDEO_DESKTOP',
    InstreamVideoImage = 'INSTREAM_VIDEO_IMAGE',
    InstreamVideoMobile = 'INSTREAM_VIDEO_MOBILE',
    JobBrowserDesktop = 'JOB_BROWSER_DESKTOP',
    JobBrowserMobile = 'JOB_BROWSER_MOBILE',
    MarketplaceMobile = 'MARKETPLACE_MOBILE',
    MessengerMobileInboxMedia = 'MESSENGER_MOBILE_INBOX_MEDIA',
    MessengerMobileStoryMedia = 'MESSENGER_MOBILE_STORY_MEDIA',
    MobileBanner = 'MOBILE_BANNER',
    MobileFeedBasic = 'MOBILE_FEED_BASIC',
    MobileFeedStandard = 'MOBILE_FEED_STANDARD',
    MobileFullwidth = 'MOBILE_FULLWIDTH',
    MobileInterstitial = 'MOBILE_INTERSTITIAL',
    MobileMediumRectangle = 'MOBILE_MEDIUM_RECTANGLE',
    MobileNative = 'MOBILE_NATIVE',
    RightColumnStandard = 'RIGHT_COLUMN_STANDARD',
    SuggestedVideoDesktop = 'SUGGESTED_VIDEO_DESKTOP',
    SuggestedVideoMobile = 'SUGGESTED_VIDEO_MOBILE',
    WatchFeedHome = 'WATCH_FEED_HOME',
    WatchFeedMobile = 'WATCH_FEED_MOBILE'
}



