import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" fill="none" viewBox="0 0 100 100" {...props}>
    <path
      fill="currentColor"
      d="M36.238 40.825a4.587 4.587 0 00-4.587 4.588v18.349h9.174V50h13.762v11.468l16.056-16.055-16.056-16.056v11.468H36.238zM53.243 1.343l45.414 45.414a4.587 4.587 0 010 6.486L53.243 98.657a4.587 4.587 0 01-6.486 0L1.343 53.243a4.587 4.587 0 010-6.486L46.757 1.343a4.587 4.587 0 016.486 0z"
    ></path>
  </svg>
);

export default SvgComponent;
