import React from 'react';

import cn from 'classnames';
import ReactSelect from 'react-select';

type OptionType = {
  value: string | number;
  label: string;
};

type SelectPropTypes = {
  className?: string;
  options: OptionType[];
  placeholder?: string;
  isMulti?: boolean;
  value?: string | string[] | number | number[];
  onChange: (value: any) => void;
  automaticHandleValue?: boolean;
};

export const Select: React.FC<SelectPropTypes> = ({
  className,
  options,
  placeholder,
  isMulti = false,
  value,
  onChange,
  automaticHandleValue = true,
}) => {
  function handleOnChange(newValue: OptionType | OptionType[]) {
    if (automaticHandleValue === false) {
      return newValue;
    }

    if (Array.isArray(newValue)) {
      return newValue.map((option: OptionType) => option.value);
    }

    return newValue.value;
  }

  function handleValue(value: unknown) {
    if (typeof value === 'undefined') {
      return undefined;
    }

    if (automaticHandleValue === false) {
      return value;
    }

    if (Array.isArray(value) && isMulti === true) {
      return options.filter((option: OptionType) => value.includes(option.value));
    }

    return options.filter((option: OptionType) => value === option.value);
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <ReactSelect
        options={options ?? []}
        placeholder={placeholder ?? 'Select...'}
        isMulti={isMulti}
        onChange={(selectedValues: any) => onChange(handleOnChange(selectedValues))}
        value={handleValue(value)}
        classNamePrefix="react-select"
      />
    </div>
  );
};

export default Select;
