/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BranchCampaignUpdatedLogOldAttributes
 */
export interface BranchCampaignUpdatedLogOldAttributes {
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    status?: BranchCampaignUpdatedLogOldAttributesStatusEnum;
    /**
     * 
     * @type {object}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    targeting?: object;
    /**
     * 
     * @type {object}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    creative?: object;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    start_time?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    stop_time?: string;
    /**
     * 
     * @type {string}
     * @memberof BranchCampaignUpdatedLogOldAttributes
     */
    budget?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum BranchCampaignUpdatedLogOldAttributesStatusEnum {
    Draft = 'draft',
    Pending = 'pending',
    Running = 'running',
    Rejected = 'rejected',
    Finished = 'finished',
    Paused = 'paused',
    Cancelled = 'cancelled',
    Deleted = 'deleted',
    Archived = 'archived',
    CancelledPermanently = 'cancelled_permanently'
}



