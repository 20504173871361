import React from 'react';

import { useFormContext } from 'react-hook-form';

import { array } from '@app/lib';
import { Box, Form } from '@app/components';
import type { Template as TemplateType, LeadgenForm as LeadgenFormType } from '@app/api';
import { api, useLocale } from '@app/hooks';

type TemplateFormPropTypes = {
  templates: Array<TemplateType>;
  isEdit?: boolean;
};

const TemplateForm: React.FC<TemplateFormPropTypes> = ({ templates = [], isEdit }) => {
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.TemplateForm',
  };
  const { t } = useLocale();
  const {
    formState: { errors },
    control,
    register,
    watch,
  } = useFormContext();
  const objectivesWatch = watch('objectives')?.includes('LEAD_GENERATION');
  const isUsingNewTemplateWatch = objectivesWatch;
  const { data } = api.useGetCompanyLeadGenForms();

  function getLeadFormOptions() {
    const leadgenForms = data?.leadgen_forms;
    if (array.isEmpty(leadgenForms)) {
      return [];
    }

    return leadgenForms.map((leadgen: LeadgenFormType) => ({ value: leadgen.id, label: leadgen.name }));
  }

  return (
    <>
      <Box>
        <Form.Select
          name="templateId"
          label={t('form.templateId.label', SCOPE_OPTIONS)}
          rules={{
            required: { value: true, message: t('form.templateId.errors.required', SCOPE_OPTIONS) },
          }}
          control={control}
          options={templates.map((template) => ({ value: template.id, label: template.name }))}
          error={errors.templateId}
          disabled={isEdit}
        />
      </Box>
      <Form.Checkbox
        id="useNewTemplate"
        className="mt-4"
        {...register('useNewTemplate')}
        label={t('form.message.addFormOption', SCOPE_OPTIONS)}
        checked={isUsingNewTemplateWatch}
        disabled={!objectivesWatch}
      />
      {isUsingNewTemplateWatch && (
        <Box className="mt-4">
          <Form.Select
            name="facebookLeadGenFormId"
            label={t('form.label.newTemplate', SCOPE_OPTIONS)}
            rules={{
              required: { value: isUsingNewTemplateWatch, message: t('form.message.required', SCOPE_OPTIONS) },
            }}
            className="mt-4"
            control={control}
            options={getLeadFormOptions()}
            error={errors.facebookLeadGenFormId}
          />
        </Box>
      )}
    </>
  );
};

export default TemplateForm;
