import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" {...props}>
    <path fillRule="evenodd" d="M4.5 15.75l7.5-7.5 7.5 7.5" clipRule="evenodd" stroke="currentColor" strokeWidth={3} />
  </svg>
);

export default SvgComponent;
