import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import { Switch } from '@headlessui/react';
import cn from 'classnames';

import { Box } from '@app/components';
import { useLocale } from '@app/hooks';
import array from 'lib/array';

type ObjectivesFormTypes = {
  isEdit?: boolean;
  defaultObjectives?: Array<string>;
  hasLeadGenerationFeature: boolean;
};

const ObjectivesForm: React.FC<ObjectivesFormTypes> = ({ isEdit, defaultObjectives, hasLeadGenerationFeature }) => {
  const OBJECTIVE_INPUTS = hasLeadGenerationFeature
    ? ['call', 'finalUrl', 'instagramVisit', 'leadGeneration', 'messenger', 'whatsappMessage']
    : ['call', 'finalUrl', 'instagramVisit', 'messenger', 'whatsappMessage'];
  const SCOPE_OPTIONS = {
    scope: 'components.Campaign.ObjectivesForm',
  };
  const { t } = useLocale();
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const objectivesWatch = watch('objectives') ?? OBJECTIVE_INPUTS.map((input) => t(`form.${input}.value`, SCOPE_OPTIONS));

  useEffect(() => {
    setValue(
      'objectives',
      OBJECTIVE_INPUTS.map((input) => t(`form.${input}.value`, SCOPE_OPTIONS))
    );
  }, []);

  useEffect(() => {
    if (array.isEmpty(objectivesWatch)) {
      setValue('objectives', '');
    }
  }, [objectivesWatch]);

  function hasObjective(input: string) {
    return objectivesWatch?.includes(input);
  }

  function handleToggle(input: string) {
    if (hasObjective(input)) {
      const filteredObjectives = objectivesWatch.filter((objective: string) => objective !== input);

      setValue('objectives', filteredObjectives);
      return;
    }

    setValue('objectives', [...objectivesWatch, input]);
  }

  function handleObjectiveDisabled(objective: string) {
    const isLeadObjectives = defaultObjectives?.includes(t(`form.${objective}.value`, SCOPE_OPTIONS));

    if (objective === 'leadGeneration' && isLeadObjectives && isEdit) {
      return true;
    }

    return false;
  }

  return (
    <Box>
      <div className="flex justify-between">
        <span className="text-sm font-semibold text-gray-900">
          {t(`form.label`, SCOPE_OPTIONS)}
          <span className="text-red-400">*</span>
        </span>
        {errors.objectives && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{errors.objectives.message}</span>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2">
        {OBJECTIVE_INPUTS.map((input) => (
          <Switch.Group key={input}>
            <div className="flex items-center mt-4">
              <Switch
                disabled={handleObjectiveDisabled(input)}
                checked={hasObjective(t(`form.${input}.value`, SCOPE_OPTIONS))}
                onChange={() => handleToggle(t(`form.${input}.value`, SCOPE_OPTIONS))}
                className={cn(
                  'relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2',
                  {
                    'bg-blue-300': hasObjective(t(`form.${input}.value`, SCOPE_OPTIONS)),
                    'bg-gray-400': !hasObjective(t(`form.${input}.value`, SCOPE_OPTIONS)),
                  }
                )}
              >
                <span
                  className={cn('inline-block h-4 w-4 transform rounded-full bg-white transition-transform', {
                    'translate-x-6': hasObjective(t(`form.${input}.value`, SCOPE_OPTIONS)),
                    'translate-x-1': !hasObjective(t(`form.${input}.value`, SCOPE_OPTIONS)),
                  })}
                />
              </Switch>
              <Switch.Label className="text-sm font-medium text-gray-800 ml-3">{t(`form.${input}.label`, SCOPE_OPTIONS)}</Switch.Label>
            </div>
          </Switch.Group>
        ))}
      </div>
    </Box>
  );
};

export default ObjectivesForm;
