import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { CampaignsGMApi } from '@app/api';
import type { CampaignCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function updateGmCampaign(
  companyId: string,
  campaignId: string,
  campaignCreateInput: CampaignCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new CampaignsGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.updateGmCampaign(companyId, campaignId, campaignCreateInput).then((res) => res.data);
}

export default function useUpdateCampaign(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; campaignId: string; campaignCreateInput: CampaignCreateInput }) =>
      updateGmCampaign(values.companyId, values.campaignId, values.campaignCreateInput, axiosInstance),
    props
  );
}
