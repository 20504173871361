import React, { useEffect } from 'react';

import { useFormContext } from 'react-hook-form';

import { Form as FormComponents } from '@app/components';
import { useLocale } from '@app/hooks';
import { DLA_GROUP_TYPES } from '@app/constants';

const Form: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'components.Grouping.Form',
  };
  const { t } = useLocale();
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext();
  const groupTypeWatch = watch('group_type');

  useEffect(() => {
    if (!groupTypeWatch) {
      setValue('group_type', 'location');
    }
  }, []);

  const GROUP_TYPE_OPTIONS = [
    {
      label: t(`form.groupType.options.${DLA_GROUP_TYPES.LOCATION}`, SCOPE_OPTIONS),
      value: DLA_GROUP_TYPES.LOCATION,
      defaultChecked: true,
    },
    { label: t(`form.groupType.options.${DLA_GROUP_TYPES.CITY}`, SCOPE_OPTIONS), value: DLA_GROUP_TYPES.CITY, defaultChecked: false },
  ];

  return (
    <form className="py-5 px-4">
      <div className="flex flex-col mb-4.5">
        <div className="text-3.5 font-semibold text-gray-900 mb-1.5">{t('form.groupType.label', SCOPE_OPTIONS)}</div>
        <FormComponents.RadioGroup name="group_type" options={GROUP_TYPE_OPTIONS} register={register} />
      </div>
      <FormComponents.Input
        type="text"
        id="name"
        label={t('form.name.label', SCOPE_OPTIONS)}
        className="mb-4.5"
        {...register('name', {
          required: { value: true, message: t('form.name.errors.required', SCOPE_OPTIONS) },
        })}
        requiredSign={false}
        error={errors.name}
      />
    </form>
  );
};

export default Form;
