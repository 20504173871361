import React, { createContext, useState, useContext, useEffect } from 'react';

import i18n from 'i18n-js';
import Cookies from 'js-cookie';

import { COOKIE_KEYS } from '@app/constants';
import { date } from '@app/lib';

type ReturnTypes = {
  t: (scope: string, options?: { [key: string]: string | number }) => string;
  locale: string;
  locales: { key: string; flag: string; text: string }[];
  changeLocale: (localeParam: string) => void;
};

const LOCALES = [
  {
    text: 'English',
    key: 'en',
    flag: 'US',
  },
  {
    text: 'Türkçe',
    key: 'tr',
    flag: 'tr',
  },
  {
    text: 'Romanian',
    key: 'ro',
    flag: 'ro',
  },
  {
    text: 'Polish',
    key: 'pl',
    flag: 'pl',
  },
];
const DEFAULT_LANGUAGE = 'tr';

const LocaleContext = createContext({} as ReturnTypes);
const translationGetters = {
  en: require('@app/assets/locale/en.json'),
  tr: require('@app/assets/locale/tr.json'),
  ro: require('@app/assets/locale/ro.json'),
  pl: require('@app/assets/locale/pl.json'),
};
const cookieLanguage = Cookies.get(COOKIE_KEYS.LOCALE) || DEFAULT_LANGUAGE;

export const LocaleProvider: React.FC<{ initialLocale?: string }> = ({ children, initialLocale }) => {
  const [locale, setLocale] = useState<string>(initialLocale || cookieLanguage);

  i18n.translations = translationGetters;
  i18n.locale = locale;

  useEffect(() => {
    const storegeItem = localStorage.getItem(COOKIE_KEYS.LOCALE);

    if (storegeItem !== locale) {
      localStorage.setItem(COOKIE_KEYS.LOCALE, locale);
    }

    date.setDefaultLocale(locale);
  }, [locale]);

  function changeLocale(localeParam: string) {
    setLocale(localeParam);
    Cookies.set(COOKIE_KEYS.LOCALE, localeParam);
    localStorage.setItem(COOKIE_KEYS.LOCALE, localeParam);
  }

  return <LocaleContext.Provider value={{ t: i18n.t, locale, changeLocale, locales: LOCALES }}>{children}</LocaleContext.Provider>;
};

export default function useLocale(): ReturnTypes {
  const context = useContext(LocaleContext);

  return context;
}
