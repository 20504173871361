import React from 'react';

import cn from 'classnames';
import { Controller } from 'react-hook-form';
import Phone from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { phoneNumber } from '@app/lib';

type PhoneInputPropTypes = {
  label?: string;
  id?: string;
  error?: { message: string | null };
  className?: string;
  name: string;
  defaultValue?: number;
  control: any;
  rules?: any;
  requiredSign?: boolean;
};

const DEFAULT_COUNTRY = 'tr';

const PhoneInput: React.FC<PhoneInputPropTypes> = ({
  label,
  id,
  error,
  className,
  name,
  defaultValue,
  control,
  rules,
  requiredSign = false,
}) => {
  return (
    <div className={cn('flex flex-col', className)}>
      <div className="flex items-center justify-between mb-1.5">
        <label htmlFor={id} className="block text-3.5 font-semibold text-gray-900">
          {label}
          {requiredSign && <span className="text-red-400">*</span>}
        </label>
        {error && (
          <div className="flex items-center">
            <span className="inline text-red-400 text-3">{error.message}</span>
          </div>
        )}
      </div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, onBlur } }) => (
          <Phone
            onBlur={onBlur}
            country={DEFAULT_COUNTRY}
            value={value}
            onChange={(phoneNumberText) => {
              const handledPhone = phoneNumber.format(phoneNumberText);

              onChange(handledPhone);
            }}
            containerClass={cn({
              'react-tel-input--error': error,
            })}
          />
        )}
        defaultValue={defaultValue}
      />
    </div>
  );
};

export default PhoneInput;
