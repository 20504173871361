import { useMutation } from 'react-query';
import { AxiosInstance } from 'axios';

import { AudiencesGMApi } from '@app/api';
import type { AudienceCreateInput } from '@app/api';

import { useAxios } from '@app/hooks';

export function createAudience(
  companyId: string,
  audienceCreateInput: AudienceCreateInput,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new AudiencesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.createAudience(companyId, audienceCreateInput).then((res) => res.data);
}

export default function useCreateAudience(props?: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();

  return useMutation(
    (values: { companyId: string; audienceCreateInput: AudienceCreateInput }) =>
      createAudience(values.companyId, values.audienceCreateInput, axiosInstance),
    props
  );
}
