import type { Template, TemplatesPreviewListResponseAttachmentsPlacementTypeEnum } from '@app/api';
import { IMAGE_PLACEMENT_TYPE } from '@app/constants';

function sortingByPlacementTypes(attachments: Template['attachments']) {
  return attachments.sort((a, b) => {
    if (a.placement_type === b.placement_type) {
      return 0;
    }

    if (a.placement_type === IMAGE_PLACEMENT_TYPE.FEED) {
      return 1;
    }

    if (a.placement_type === IMAGE_PLACEMENT_TYPE.STORY) {
      return -1;
    }

    return 0;
  });
}

function fillEmptyArrayForMissingAttachementwithSorting(attachments: Template['attachments']) {
  if (!attachments.find((attachment) => attachment.placement_type === IMAGE_PLACEMENT_TYPE.FEED)) {
    attachments.push({
      placement_type: IMAGE_PLACEMENT_TYPE.FEED as TemplatesPreviewListResponseAttachmentsPlacementTypeEnum,
      file: {
        url: undefined,
      },
    });
  }

  if (!attachments.find((attachment) => attachment.placement_type === IMAGE_PLACEMENT_TYPE.STORY)) {
    attachments.push({
      placement_type: IMAGE_PLACEMENT_TYPE.STORY as TemplatesPreviewListResponseAttachmentsPlacementTypeEnum,
      file: {
        url: undefined,
      },
    });
  }

  return sortingByPlacementTypes(attachments);
}

export default {
  fillEmptyArrayForMissingAttachementwithSorting,
  sortingByPlacementTypes,
};
