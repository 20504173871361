/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DoorkeeperInfoTokenInput } from '../model';
// @ts-ignore
import { DoorkeeperInfoTokenRespose } from '../model';
// @ts-ignore
import { GmUserTokenErrorResponse } from '../model';
// @ts-ignore
import { GmUserTokenInput } from '../model';
// @ts-ignore
import { GmUserTokenSuccessResponse } from '../model';
/**
 * TokenGMApi - axios parameter creator
 * @export
 */
export const TokenGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Logs general manager user into the system
         * @summary Login as general manager user
         * @param {GmUserTokenInput} gmUserTokenInput Login as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserLogin: async (gmUserTokenInput: GmUserTokenInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'gmUserTokenInput' is not null or undefined
            assertParamExists('generalManagerUserLogin', 'gmUserTokenInput', gmUserTokenInput)
            const localVarPath = `/gm_user/oauth/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(gmUserTokenInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get token info
         * @summary Get token info
         * @param {DoorkeeperInfoTokenInput} doorkeeperInfoTokenInput Token info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenInfo: async (doorkeeperInfoTokenInput: DoorkeeperInfoTokenInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'doorkeeperInfoTokenInput' is not null or undefined
            assertParamExists('tokenInfo', 'doorkeeperInfoTokenInput', doorkeeperInfoTokenInput)
            const localVarPath = `/branch_account/oauth/token_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(doorkeeperInfoTokenInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenGMApi - functional programming interface
 * @export
 */
export const TokenGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TokenGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Logs general manager user into the system
         * @summary Login as general manager user
         * @param {GmUserTokenInput} gmUserTokenInput Login as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalManagerUserLogin(gmUserTokenInput: GmUserTokenInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GmUserTokenSuccessResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalManagerUserLogin(gmUserTokenInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get token info
         * @summary Get token info
         * @param {DoorkeeperInfoTokenInput} doorkeeperInfoTokenInput Token info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async tokenInfo(doorkeeperInfoTokenInput: DoorkeeperInfoTokenInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DoorkeeperInfoTokenRespose>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.tokenInfo(doorkeeperInfoTokenInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TokenGMApi - factory interface
 * @export
 */
export const TokenGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TokenGMApiFp(configuration)
    return {
        /**
         * Logs general manager user into the system
         * @summary Login as general manager user
         * @param {GmUserTokenInput} gmUserTokenInput Login as general manager user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalManagerUserLogin(gmUserTokenInput: GmUserTokenInput, options?: any): AxiosPromise<GmUserTokenSuccessResponse> {
            return localVarFp.generalManagerUserLogin(gmUserTokenInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Get token info
         * @summary Get token info
         * @param {DoorkeeperInfoTokenInput} doorkeeperInfoTokenInput Token info request
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        tokenInfo(doorkeeperInfoTokenInput: DoorkeeperInfoTokenInput, options?: any): AxiosPromise<DoorkeeperInfoTokenRespose> {
            return localVarFp.tokenInfo(doorkeeperInfoTokenInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TokenGMApi - interface
 * @export
 * @interface TokenGMApi
 */
export interface TokenGMApiInterface {
    /**
     * Logs general manager user into the system
     * @summary Login as general manager user
     * @param {GmUserTokenInput} gmUserTokenInput Login as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenGMApiInterface
     */
    generalManagerUserLogin(gmUserTokenInput: GmUserTokenInput, options?: any): AxiosPromise<GmUserTokenSuccessResponse>;

    /**
     * Get token info
     * @summary Get token info
     * @param {DoorkeeperInfoTokenInput} doorkeeperInfoTokenInput Token info request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenGMApiInterface
     */
    tokenInfo(doorkeeperInfoTokenInput: DoorkeeperInfoTokenInput, options?: any): AxiosPromise<DoorkeeperInfoTokenRespose>;

}

/**
 * TokenGMApi - object-oriented interface
 * @export
 * @class TokenGMApi
 * @extends {BaseAPI}
 */
export class TokenGMApi extends BaseAPI implements TokenGMApiInterface {
    /**
     * Logs general manager user into the system
     * @summary Login as general manager user
     * @param {GmUserTokenInput} gmUserTokenInput Login as general manager user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenGMApi
     */
    public generalManagerUserLogin(gmUserTokenInput: GmUserTokenInput, options?: any) {
        return TokenGMApiFp(this.configuration).generalManagerUserLogin(gmUserTokenInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get token info
     * @summary Get token info
     * @param {DoorkeeperInfoTokenInput} doorkeeperInfoTokenInput Token info request
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenGMApi
     */
    public tokenInfo(doorkeeperInfoTokenInput: DoorkeeperInfoTokenInput, options?: any) {
        return TokenGMApiFp(this.configuration).tokenInfo(doorkeeperInfoTokenInput, options).then((request) => request(this.axios, this.basePath));
    }
}
