import { useQuery } from 'react-query';
import { AxiosInstance } from 'axios';

import { AudiencesGMApi, GroupsGMApi } from '@app/api';

import { useAuth, useAxios } from '@app/hooks';

export function listAudiences(
  companyId: string,
  page?: number,
  perPage?: number,
  query?: object,
  axiosInstance: AxiosInstance | undefined = undefined
) {
  const api = new AudiencesGMApi(undefined, process.env.NEXT_PUBLIC_API_URL, axiosInstance);

  return api.listAudiences(companyId, page, perPage, query).then((res) => res.data);
}

export default function useListAudiences({ params = {}, ...props }: ApiQueryHookPropTypes): ApiQueryHookReturnTypes {
  const { api: axiosInstance } = useAxios();
  const { companyId } = useAuth();

  return useQuery(
    ['useListAudiences', companyId, params.page, params.perPage, params.query],
    () => listAudiences(companyId, params.page, params.perPage, params.query, axiosInstance),
    props
  );
}
