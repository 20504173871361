import { useEffect } from 'react';

import { useChannel } from '@app/hooks';
import { useCableProvider } from './useCableProvider';

const useSocket = ({ channel, received, initialized, connected, disconnected, ...props }: any) => {
  const { actionCable } = useCableProvider();
  const { subscribe, unsubscribe } = useChannel(actionCable);

  useEffect(() => {
    if (actionCable) {
      subscribe(
        {
          channel,
          ...props,
        },
        {
          received: (data: ReceivedResponseType) => received && received(data),
          initialized: () => initialized && initialized(),
          connected: () => connected && connected(),
          disconnected: () => disconnected && disconnected(),
        }
      );
    }

    return () => {
      if (actionCable) unsubscribe();
    };
  }, [actionCable]);

  return;
};

export default useSocket;
