import React from 'react';

import { Box } from '@app/components';
import type { Template } from '@app/api';
import { templateAttachment } from '@app/lib';
import ImageComponent from './Image';

type BranchPreviewPropTypes = {
  branchName: string;
  templateAttachments: Template['attachments'];
};

const BranchPreview: React.FC<BranchPreviewPropTypes> = ({ branchName, templateAttachments }) => {
  return (
    <Box className="mb-0">
      <h3 className="text-5 font-medium text-gray-900 mb-3 truncate border-b-0.5 border-b-gray-200 pb-1">{branchName}</h3>
      <div className="grid gap-3 grid-cols-2">
        {templateAttachment.fillEmptyArrayForMissingAttachementwithSorting(templateAttachments).map((attachment, index) => (
          <ImageComponent
            key={index}
            branchName={branchName}
            placementType={attachment.placement_type}
            url={attachment?.file?.url as string}
          />
        ))}
      </div>
    </Box>
  );
};

export default BranchPreview;
