/* tslint:disable */
/* eslint-disable */
/**
 * Branchsight API
 * Branchsight API documentation
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: tech@branchsight.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Branch } from '../model';
// @ts-ignore
import { BranchCreateErrorResponse } from '../model';
// @ts-ignore
import { BranchCreateInput } from '../model';
// @ts-ignore
import { BranchUpdateInput } from '../model';
// @ts-ignore
import { BranchWithInsightResponse } from '../model';
// @ts-ignore
import { BranchesListResponse } from '../model';
// @ts-ignore
import { CityBranchCreateInput } from '../model';
/**
 * BranchesGMApi - axios parameter creator
 * @export
 */
export const BranchesGMApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create a branch to company
         * @summary Create a branch to company
         * @param {string} companyId The id of the company
         * @param {BranchCreateInput} branchCreateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranch: async (companyId: string, branchCreateInput: BranchCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createBranch', 'companyId', companyId)
            // verify required parameter 'branchCreateInput' is not null or undefined
            assertParamExists('createBranch', 'branchCreateInput', branchCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/branches`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a city branch to company
         * @summary Create a city branch to company
         * @param {string} companyId The id of the company
         * @param {CityBranchCreateInput} cityBranchCreateInput Create a city branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityBranch: async (companyId: string, cityBranchCreateInput: CityBranchCreateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('createCityBranch', 'companyId', companyId)
            // verify required parameter 'cityBranchCreateInput' is not null or undefined
            assertParamExists('createCityBranch', 'cityBranchCreateInput', cityBranchCreateInput)
            const localVarPath = `/gm_user/companies/{company_id}/branches/city_branch`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(cityBranchCreateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return branches of company
         * @summary Return branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {string} [type] Response type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranches: async (companyId: string, page?: number, perPage?: number, query?: object, type?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('listBranches', 'companyId', companyId)
            const localVarPath = `/gm_user/companies/{company_id}/branches`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Return branch with by id
         * @summary Return branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranch: async (companyId: string, branchId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('showBranch', 'companyId', companyId)
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('showBranch', 'branchId', branchId)
            // verify required parameter 'startDate' is not null or undefined
            assertParamExists('showBranch', 'startDate', startDate)
            // verify required parameter 'endDate' is not null or undefined
            assertParamExists('showBranch', 'endDate', endDate)
            const localVarPath = `/gm_user/companies/{company_id}/branches/{branch_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (granularity !== undefined) {
                localVarQueryParameter['granularity'] = granularity;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['start_date'] = (startDate as any instanceof Date) ?
                    (startDate as any).toISOString().substr(0,10) :
                    startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['end_date'] = (endDate as any instanceof Date) ?
                    (endDate as any).toISOString().substr(0,10) :
                    endDate;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {BranchUpdateInput} branchUpdateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranch: async (companyId: string, branchId: string, branchUpdateInput: BranchUpdateInput, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyId' is not null or undefined
            assertParamExists('updateBranch', 'companyId', companyId)
            // verify required parameter 'branchId' is not null or undefined
            assertParamExists('updateBranch', 'branchId', branchId)
            // verify required parameter 'branchUpdateInput' is not null or undefined
            assertParamExists('updateBranch', 'branchUpdateInput', branchUpdateInput)
            const localVarPath = `/gm_user/companies/{company_id}/branches/{branch_id}`
                .replace(`{${"company_id"}}`, encodeURIComponent(String(companyId)))
                .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(branchUpdateInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BranchesGMApi - functional programming interface
 * @export
 */
export const BranchesGMApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BranchesGMApiAxiosParamCreator(configuration)
    return {
        /**
         * Create a branch to company
         * @summary Create a branch to company
         * @param {string} companyId The id of the company
         * @param {BranchCreateInput} branchCreateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createBranch(companyId: string, branchCreateInput: BranchCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createBranch(companyId, branchCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a city branch to company
         * @summary Create a city branch to company
         * @param {string} companyId The id of the company
         * @param {CityBranchCreateInput} cityBranchCreateInput Create a city branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createCityBranch(companyId: string, cityBranchCreateInput: CityBranchCreateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createCityBranch(companyId, cityBranchCreateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return branches of company
         * @summary Return branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {string} [type] Response type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listBranches(companyId: string, page?: number, perPage?: number, query?: object, type?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchesListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listBranches(companyId, page, perPage, query, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Return branch with by id
         * @summary Return branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async showBranch(companyId: string, branchId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchWithInsightResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.showBranch(companyId, branchId, startDate, endDate, granularity, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {BranchUpdateInput} branchUpdateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateBranch(companyId: string, branchId: string, branchUpdateInput: BranchUpdateInput, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Branch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateBranch(companyId, branchId, branchUpdateInput, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BranchesGMApi - factory interface
 * @export
 */
export const BranchesGMApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BranchesGMApiFp(configuration)
    return {
        /**
         * Create a branch to company
         * @summary Create a branch to company
         * @param {string} companyId The id of the company
         * @param {BranchCreateInput} branchCreateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createBranch(companyId: string, branchCreateInput: BranchCreateInput, options?: any): AxiosPromise<Branch> {
            return localVarFp.createBranch(companyId, branchCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a city branch to company
         * @summary Create a city branch to company
         * @param {string} companyId The id of the company
         * @param {CityBranchCreateInput} cityBranchCreateInput Create a city branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCityBranch(companyId: string, cityBranchCreateInput: CityBranchCreateInput, options?: any): AxiosPromise<Branch> {
            return localVarFp.createCityBranch(companyId, cityBranchCreateInput, options).then((request) => request(axios, basePath));
        },
        /**
         * Return branches of company
         * @summary Return branches of company
         * @param {string} companyId The id of the company
         * @param {number} [page] Page
         * @param {number} [perPage] Per page limit
         * @param {object} [query] Sample filtering &amp; sorting
         * @param {string} [type] Response type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listBranches(companyId: string, page?: number, perPage?: number, query?: object, type?: string, options?: any): AxiosPromise<BranchesListResponse> {
            return localVarFp.listBranches(companyId, page, perPage, query, type, options).then((request) => request(axios, basePath));
        },
        /**
         * Return branch with by id
         * @summary Return branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {string} startDate Start date of the date range for the insights
         * @param {string} endDate End date of the date range for the insights
         * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        showBranch(companyId: string, branchId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<BranchWithInsightResponse> {
            return localVarFp.showBranch(companyId, branchId, startDate, endDate, granularity, options).then((request) => request(axios, basePath));
        },
        /**
         * Update branch with by id
         * @summary Update branch with by id
         * @param {string} companyId The id of the company
         * @param {string} branchId The id of the branch
         * @param {BranchUpdateInput} branchUpdateInput Create a branch to company
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBranch(companyId: string, branchId: string, branchUpdateInput: BranchUpdateInput, options?: any): AxiosPromise<Branch> {
            return localVarFp.updateBranch(companyId, branchId, branchUpdateInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BranchesGMApi - interface
 * @export
 * @interface BranchesGMApi
 */
export interface BranchesGMApiInterface {
    /**
     * Create a branch to company
     * @summary Create a branch to company
     * @param {string} companyId The id of the company
     * @param {BranchCreateInput} branchCreateInput Create a branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApiInterface
     */
    createBranch(companyId: string, branchCreateInput: BranchCreateInput, options?: any): AxiosPromise<Branch>;

    /**
     * Create a city branch to company
     * @summary Create a city branch to company
     * @param {string} companyId The id of the company
     * @param {CityBranchCreateInput} cityBranchCreateInput Create a city branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApiInterface
     */
    createCityBranch(companyId: string, cityBranchCreateInput: CityBranchCreateInput, options?: any): AxiosPromise<Branch>;

    /**
     * Return branches of company
     * @summary Return branches of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {string} [type] Response type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApiInterface
     */
    listBranches(companyId: string, page?: number, perPage?: number, query?: object, type?: string, options?: any): AxiosPromise<BranchesListResponse>;

    /**
     * Return branch with by id
     * @summary Return branch with by id
     * @param {string} companyId The id of the company
     * @param {string} branchId The id of the branch
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApiInterface
     */
    showBranch(companyId: string, branchId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any): AxiosPromise<BranchWithInsightResponse>;

    /**
     * Update branch with by id
     * @summary Update branch with by id
     * @param {string} companyId The id of the company
     * @param {string} branchId The id of the branch
     * @param {BranchUpdateInput} branchUpdateInput Create a branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApiInterface
     */
    updateBranch(companyId: string, branchId: string, branchUpdateInput: BranchUpdateInput, options?: any): AxiosPromise<Branch>;

}

/**
 * BranchesGMApi - object-oriented interface
 * @export
 * @class BranchesGMApi
 * @extends {BaseAPI}
 */
export class BranchesGMApi extends BaseAPI implements BranchesGMApiInterface {
    /**
     * Create a branch to company
     * @summary Create a branch to company
     * @param {string} companyId The id of the company
     * @param {BranchCreateInput} branchCreateInput Create a branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApi
     */
    public createBranch(companyId: string, branchCreateInput: BranchCreateInput, options?: any) {
        return BranchesGMApiFp(this.configuration).createBranch(companyId, branchCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a city branch to company
     * @summary Create a city branch to company
     * @param {string} companyId The id of the company
     * @param {CityBranchCreateInput} cityBranchCreateInput Create a city branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApi
     */
    public createCityBranch(companyId: string, cityBranchCreateInput: CityBranchCreateInput, options?: any) {
        return BranchesGMApiFp(this.configuration).createCityBranch(companyId, cityBranchCreateInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return branches of company
     * @summary Return branches of company
     * @param {string} companyId The id of the company
     * @param {number} [page] Page
     * @param {number} [perPage] Per page limit
     * @param {object} [query] Sample filtering &amp; sorting
     * @param {string} [type] Response type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApi
     */
    public listBranches(companyId: string, page?: number, perPage?: number, query?: object, type?: string, options?: any) {
        return BranchesGMApiFp(this.configuration).listBranches(companyId, page, perPage, query, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Return branch with by id
     * @summary Return branch with by id
     * @param {string} companyId The id of the company
     * @param {string} branchId The id of the branch
     * @param {string} startDate Start date of the date range for the insights
     * @param {string} endDate End date of the date range for the insights
     * @param {'day' | 'month' | 'week'} [granularity] Granularity of insights (default&#x3D;day if empty)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApi
     */
    public showBranch(companyId: string, branchId: string, startDate: string, endDate: string, granularity?: 'day' | 'month' | 'week', options?: any) {
        return BranchesGMApiFp(this.configuration).showBranch(companyId, branchId, startDate, endDate, granularity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update branch with by id
     * @summary Update branch with by id
     * @param {string} companyId The id of the company
     * @param {string} branchId The id of the branch
     * @param {BranchUpdateInput} branchUpdateInput Create a branch to company
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BranchesGMApi
     */
    public updateBranch(companyId: string, branchId: string, branchUpdateInput: BranchUpdateInput, options?: any) {
        return BranchesGMApiFp(this.configuration).updateBranch(companyId, branchId, branchUpdateInput, options).then((request) => request(this.axios, this.basePath));
    }
}
